import React from 'react';
import './style.less';

export interface PrintTitleProps {
  title?: string;
  description?: string;
}
export function PrintTitle(props) {
  const { title, description } = props;
  return (
    <div className="print-title-wrapper">
      <div className="print-title-title">{title}</div>
      <div className="print-title-description">{description}</div>
    </div>
  );
}
