import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Spin, Table, TableProps, Tag } from 'antd';
import { usePagePrint } from '@base-hooks';
import { MEDIA_SIZE, PAPER_FORMAT, PAPER_SIZE, stringFormatter } from '@base-helpers';
import { makeCommonDataSource } from '@base-factories';
import { LoadingOutlined } from '@ant-design/icons';
import { PrintHeader, PrintHeaderProps } from '../print-header';
import { RenderText } from '../../render-components';
import { htmlFooterContent, PrintFooter } from '../print-footer';
import { PrintSignature, PrintSignatureProps } from '../print-signature';
import { PrintTitle, PrintTitleProps } from '../print-title';

import { pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
import { Document, Page } from 'react-pdf';
import { listFilePrint } from '@pages/multiple-print/state';
import { useRecoilState } from 'recoil';
import { CheckCircleOutlined, SyncOutlined, FilePdfOutlined } from '@ant-design/icons';

type DefaultColumnType = 'status' | 'company' | 'site' | 'no' | 'code' | 'name';
export interface DataTablePrintProps<Payload> extends TableProps<Payload> {
  ignoreDefaultColumn?: DefaultColumnType[];
  printSignatureProps?: PrintSignatureProps;
  printTitleProps?: PrintTitleProps;
  printHeaderProps?: PrintHeaderProps;
  paperFormat?: PAPER_FORMAT;
  orientation?: 'landscape' | 'portrait';
}
interface GetDataProp {
  page?: number;
  limit?: number;
  prevData?: any[];
}

export function DataTablePrint<Payload = any>(props: DataTablePrintProps<Payload>) {
  const {
    ignoreDefaultColumn,
    printTitleProps,
    printSignatureProps,
    printHeaderProps,
    paperFormat = 'A4',
    orientation = 'landscape',
  } = props;
  const { id, apiUrl, subModule, code, printer, showPreviewIndex, fileName } = usePagePrint();
  const [listFilePrintRecoil, setListFilePrintRecoil] = useRecoilState(listFilePrint);

  const [loading, setLoading] = useState(false);
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [isContentReady, setIsContentReady] = useState(false);
  const [file, setFile] = useState(undefined);
  const [sendingStatus, setSendingStatus] = useState('failed');

  const contentRef: any = useRef();
  const footerContentRef: any = useRef();

  const generateDataSource = makeCommonDataSource({
    apiUrl: '/generate',
    baseUrl: process.env.REACT_APP_BASE_URL_GENERATE_PDF,
  });

  const [recordData, setRecordData] = useState([]);
  const dataSource = makeCommonDataSource({ apiUrl });

  const columns = [
    {
      title: 'No.',
      key: 'no',
      width: 40,
      align: 'center',
      style: { textAlign: 'center' },
      render(item, row, index) {
        return index + 1;
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render(item) {
        return <div style={{ minWidth: '30px' }}>{stringFormatter.capitalizeEachWord(item?.split('_').join(' '))}</div>;
      },
    },
    {
      title: 'Company',
      key: 'company',
      render(item, row) {
        return <RenderText value={row.company ?? row?.companies} renderType="multi" />;
      },
    },

    {
      title: 'Site',
      key: 'site',
      render(item, row) {
        return <RenderText value={row.site ?? row?.sites} renderType="multi" />;
      },
    },

    {
      title: 'Code',
      key: 'code',
      dataIndex: 'code',
      render(item) {
        return <RenderText value={item} renderType="multi" />;
      },
    },

    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render(item) {
        return <RenderText value={item} renderType="multi" />;
      },
    },

    ...(props.columns ?? []),
  ];

  async function updatePrintFile(id, file) {
    // localStorage.setItem(`print-file-${id}`, JSON.stringify(file));
    // setListFilePrintRecoil({ ...listFilePrintRecoil, [id]: file });
  }

  async function updateConfig(config) {
    localStorage.setItem(`print-config-${id}`, JSON.stringify(config));
  }

  async function handleGetData(props?: GetDataProp) {
    const { page = 1, limit = 10, prevData = [] } = props;
    const apiUrlArr = apiUrl.split('?');
    const moduleUrl = apiUrlArr[0];
    const paramsUrl = apiUrlArr[1] ?? '';
    const paramUrlArr = paramsUrl.split('&').filter((item) => item !== '');
    const realParamsUrl = paramUrlArr.filter((item) => !item.includes('page=') && !item.includes('limit=')).join('&');
    const url = `${process.env.REACT_APP_BASE_URL_GATEWAY}${moduleUrl}?${realParamsUrl}&page=${page}&limit=${limit}`;

    setLoading(true);
    setIsContentReady(false);

    await dataSource.handleCustomRequest({
      token: localStorage.getItem('auth_token'),
      paramRequest: {
        url,
        method: 'GET',
      },
      async onSuccess({ response }: any) {
        const items = response.items ?? [];
        const meta = response?.meta;
        const newData = [...prevData, ...items];
        setRecordData(newData);
        if (meta && meta.currentPage <= meta.totalPages) {
          await handleGetData({
            page: meta.currentPage + 1,
            prevData: newData,
          });
        } else {
          setLoading(false);
          setIsContentReady(true);
        }
      },
      onFailed(err) {
        setLoading(false);
        setIsContentReady(false);
      },
    });
  }

  useEffect(() => {
    if (apiUrl) handleGetData({ page: 1 });
  }, [apiUrl]);

  const DataTableProps: any = props;

  function makeColumn() {
    const ignore = ignoreDefaultColumn ?? [];
    return columns.filter((item) => !ignore.includes(item.key as DefaultColumnType));
  }

  async function handlePrint(file) {
    const media = MEDIA_SIZE[paperFormat];
    const printerCode = printer?.code;
    if (printerCode && file) {
      const formData: FormData = new FormData();
      let newFile;
      await fetch(file)
        .then((res) => res.blob())
        .then((res) => (newFile = res));
      formData.append('file', newFile);
      formData.append('media', media);
      await dataSource.handleCustomRequest({
        paramRequest: {
          method: 'POST',
          url: `${process.env.REACT_APP_BASE_URL}v1/print/request/${printerCode}/print`,
          data: formData,
        },
        async onSuccess({ response }: any) {
          setSendingStatus('success');
        },
        async onFailed({ message }: any) {
          setSendingStatus('failed');
        },
      });
    }
  }

  async function generatePdf(content, footerContent) {
    setLoadingGenerate(true);
    const payload = {
      ...PAPER_SIZE[paperFormat],
      content,
      orientation,
      // footerContent: htmlFooterContent,
      footerContent: `<div></div>`,
      margin: {
        top: '5px',
        left: '5px',
        right: '5px',
        bottom: '5px',
      },
    };
    await generateDataSource.handleCreate(payload, {
      async onSuccess({ response }: any) {
        setIsContentReady(false);
        setLoadingGenerate(false);
        setFile(response.file);
        handlePrint(response.file);
        await updatePrintFile(id, response.file);
      },
      async onFailed({ message }: any) {
        setIsContentReady(false);
        setLoadingGenerate(false);
        await updatePrintFile(id, null);
      },
    });
  }

  useEffect(() => {
    if (isContentReady) {
      setTimeout(() => {
        const config = {
          media: MEDIA_SIZE[paperFormat],
          pdfConfig: {
            ...PAPER_SIZE[paperFormat],
            content: contentRef?.current?.innerHTML,
            orientation,
            // footerContent: htmlFooterContent,
            footerContent: `<div></div>`,
            margin: {
              top: '5px',
              left: '5px',
              right: '5px',
              bottom: '5px',
            },
          },
        };
        updateConfig(config);
        if (showPreviewIndex) generatePdf(contentRef?.current?.innerHTML, footerContentRef?.current?.innerHTML);
      }, 500);
    }
  }, [isContentReady]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <div>
      <div style={{ display: 'none' }}>
        <div ref={contentRef}>
          <div
            id={id}
            className="print-content-wrapper"
            style={{ padding: '10px', paddingTop: '20px', paddingRight: '20px', paddingLeft: '20px' }}
          >
            <PrintTitle {...(printTitleProps ?? {})} />
            <div style={{ marginBottom: '10px' }}></div>
            <table style={{ width: '100%', marginTop: '5px', color: '#000' }}>
              <thead>
                <tr style={{ borderBottom: '1px solid black', textAlign: 'left' }}>
                  {makeColumn()?.map((column: any) => {
                    return (
                      <th
                        style={{
                          ...(column?.style ?? {}),
                          border: '1px solid #000',
                          paddingLeft: '5px',
                          paddingRight: '5px',
                          fontSize: '11px',
                        }}
                        key={column?.key}
                      >
                        {column?.title}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody style={{ textAlign: 'left' }}>
                {recordData?.map((line, index) => {
                  return (
                    <tr key={index}>
                      {makeColumn()?.map((column: any) => {
                        return (
                          <td
                            style={{
                              ...(column?.style ?? {}),
                              border: '1px solid #000',
                              paddingLeft: '5px',
                              paddingRight: '5px',
                              fontSize: '11px',
                            }}
                            key={column?.key}
                          >
                            {column?.render(line[column?.key], line, index)}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <PrintSignature {...(printSignatureProps ?? {})} />
          </div>
        </div>
        <div ref={footerContentRef}>
          <PrintFooter />
        </div>
      </div>
      <Card
        title={`${subModule} ${code ? code : ''}`}
        extra={
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 20 }}>
              <span style={{ marginLeft: 5 }}>Printer Code</span>
              <span style={{ marginLeft: 5 }}>:</span>
              <span style={{ marginLeft: 5 }}>{printer.code}</span>
            </div>
            <div style={{ marginRight: 20 }}>
              <span style={{ marginLeft: 5 }}>Printer Name</span>
              <span style={{ marginLeft: 5 }}>:</span>
              <span style={{ marginLeft: 5 }}>{printer.name}</span>
            </div>

            <div>
              {(loading || loadingGenerate) && (
                <Tag style={{ marginRight: 20 }} icon={<SyncOutlined spin />} color="processing">
                  processing
                </Tag>
              )}
              {!loading && !loadingGenerate && (
                <Tag style={{ marginRight: 20 }} icon={<CheckCircleOutlined />} color={sendingStatus}>
                  {sendingStatus === 'success' ? 'Sending' : 'Failed to print'}
                </Tag>
              )}
            </div>
          </div>
        }
      >
        <div>
          {loading && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Spin indicator={<LoadingOutlined />} /> Fetching data..
            </div>
          )}
          {loadingGenerate && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Spin indicator={<LoadingOutlined />} /> Generating file..
            </div>
          )}
        </div>
        {!file && !loading && !loadingGenerate && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No file to show.</div>
        )}
        {file && !loading && !loadingGenerate && showPreviewIndex && (
          <div>
            <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
              <Page size="A4" pageNumber={pageNumber} />
            </Document>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div>
                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                  Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                </div>
                <Button size="small" style={{ marginRight: '5px' }} disabled={pageNumber <= 1} onClick={previousPage}>
                  Prev
                </Button>
                <Button size="small" style={{ marginLeft: '5px' }} disabled={pageNumber >= numPages} onClick={nextPage}>
                  Next
                </Button>
              </div>
            </div>
          </div>
        )}
        {file && !loading && !loadingGenerate && !showPreviewIndex && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FilePdfOutlined style={{ fontSize: '20px' }} />
            <div style={{ marginLeft: '5px', fontSize: '12px' }}>{fileName}</div>
          </div>
        )}
      </Card>
    </div>
  );
}
