import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCurrency, RenderText } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter, userPrinter } from '@base-helpers';
import moment from 'moment';
import { Col, Descriptions, Row, Table } from 'antd';

export const formatUnderscore = (data: string) => data?.replace(/_/g, ' ');

export function Detail() {
  const { detailData = {}, printContent, type } = usePagePrint();
  const hasCode = detailData?.code;
  const user = userPrinter();
  const configColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      ellipsis: true,
      width: 100,
      render: (item) => RenderText({ value: item ? moment(item).format('DD/MM/YYYY') : '-' }),
    },
    {
      title: 'Cumulative Depreciation',
      dataIndex: 'cumulative_depreciation',
      key: 'cumulative_depreciation',
      ellipsis: true,
      width: 100,
      render: (item) => RenderCurrency({ value: item }),
    },
    {
      title: 'Depreciation',
      dataIndex: 'depreciation',
      key: 'depreciation',
      ellipsis: true,
      width: 100,
      render: (item) => RenderCurrency({ value: item }),
    },
    {
      title: 'Book Value',
      dataIndex: 'book_value',
      key: 'book_value',
      ellipsis: true,
      width: 100,
      render: (item) => RenderCurrency({ value: item }),
    },
  ];

  return (
    <DetailPrint
      orientation="portrait"
      printHeaderProps={{ barcode: detailData?.code }}
      paperFormat={PAPER_FORMAT.A4}
      printSignatureProps={{
        signatures: [
          // {
          //   gridColumn: { span: 6 },
          //   title: 'Created By',
          //   name: detailData?.creator_name ? detailData?.creator_name : '-',
          // },
          // {
          //   gridColumn: { span: 6 },
          //   title: 'Confirmed By',
          //   name: detailData?.editor_name ?? detailData?.creator_name ?? '-',
          // },
          // {
          //   gridColumn: { span: 6 },
          //   title: 'Received By',
          //   name: detailData?.editor_name ?? detailData?.creator_name ?? '-',
          // },
          // {
          //   gridColumn: { span: 6 },
          //   title: 'Paid By',
          //   name: detailData?.creator_name ? detailData?.creator_name : '-',
          // },
        ],
      }}
    >
      <DetailBuilder
        columns={[
          {
            fields: [
              {
                gridColumn: { span: 12 },
                renderField(form) {
                  return (
                    <PrintTitle
                      title={`Asset ${type ? `(${stringFormatter.capitalizeEachWord(type)})` : ''}`}
                      {...(hasCode ? { description: `${detailData?.name} (${detailData?.code})` } : {})}
                    />
                  );
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Created Date',
                name: 'created_at',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Printed Date',
                name: 'id',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: () => moment().format('YYYY-MM-DD'),
                },
              },
              {
                gridColumn: { span: 6 },
                label: 'Status',
                name: 'status',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    let status = value;
                    if (value?.toLowerCase() === 'waiting') status = 'in_process';
                    return stringFormatter.capitalizeEachWord(status?.split('_')?.join(' '));
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Asset Type',
                name: 'asset_type',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.name;
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Product Variant',
                name: 'id',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return detailData?.product_variant?.name ?? detailData?.product_package?.name;
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Invoice',
                name: 'purchase_invoice',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.code;
                  },
                },
              },
              {
                gridColumn: { span: 2 },
                label: 'Qty',
                name: 'qty',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return `${value} ${detailData?.uom?.code}`;
                  },
                },
              },
              {
                gridColumn: { span: 12 },
                label: 'References',
                name: 'references',
                fieldType: 'text',
                renderTextProps: {
                  renderType: 'multi',
                  renderValue(value) {
                    return value?.transaction_code ?? value?.code;
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Invoice Date',
                name: 'purchase_invoice',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value?.invoice_date ? dateFormatter(value?.invoice_date).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Start Date',
                name: 'date_start',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Acquisition Date',
                name: 'acquisition_date',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Gross Value',
                name: 'gross_value',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return RenderCurrency({ value: value });
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Total Gross Value',
                name: 'total_gross_value',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return RenderCurrency({ value: value });
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Asset Placement',
                name: 'asset_placement',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.code;
                  },
                },
              },
            ],
          },
        ]}
      />

      <table style={{ width: '100%', marginTop: '5px', lineHeight: '12px' }}>
        <thead>
          <tr style={{ borderBottom: '1px solid black', textAlign: 'left' }}>
            {configColumns?.map((column) => {
              return <th key={column?.key}>{column?.title}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {detailData?.depreciation_boards?.map((line, index) => {
            return (
              <tr key={index}>
                {configColumns?.map((column) => {
                  return <td key={column?.key}>{column?.render(line[column?.key])}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </DetailPrint>
  );
}
