import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCountCurrency, RenderCurrency } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter } from '@base-helpers';
import moment from 'moment';
import { Col, Row, Table } from 'antd';
import QRCode from 'react-qr-code';
import { columnsMaterial, columnsStep, getDetailStep, getProducts } from './helpers';
import { useBarcode } from '@createnextapp/react-barcode';
import QtyPerUnit from './qty-per-unit';
import { v4 as uuidV4 } from 'uuid';

export function Detail() {
  const { detailData = {}, printContent } = usePagePrint();
  const [products, setProducts] = useState([]);
  const [steps, setSteps] = useState([]);
  const defaultSignature: any[] = [
    {
      title: 'Hormat Kami',
      name: '',
      gridColumn: { span: 6 },
    },
  ];

  const generateProduct = async (detailId) => {
    const response = await getProducts(detailId);
    setProducts(response);
  };

  useEffect(() => {
    if (detailData?.detail) {
      generateProduct(detailData?.detail?.id);
    }
  }, [detailData?.detail]);

  const { inputRef } = useBarcode({
    value: detailData?.code ?? '-',
    options: {
      displayValue: false,
      height: 20,
      width: 1,
      marginTop: -1,
      marginLeft: -1,
      marginBottom: -1,
    },
  });

  //pengaturan qty/unit
  const [qtyUnit, setQtyUnit] = useState([]);
  const [qtyUnitOutput, setQtyUnitOutput] = useState([]);
  const [attributes, setAttributes] = useState<any>([]);
  const [treatmentPacking, setTreatmentPacking] = useState<any>([]);

  useEffect(() => {
    const materialQtyUnit = detailData?.steps?.[0]?.step_needs?.[0]?.qty_units?.[0]?.value;
    if (detailData) {
      let qtyUnits = [];

      for (let i = 0; i < 60; i++) {
        qtyUnits = [...qtyUnits, materialQtyUnit?.[i] ?? ''];
      }

      setQtyUnit(qtyUnits);
      let output = [];
      detailData?.output_qty_unit?.forEach((item) => {
        item?.value?.forEach((value) => {
          if (Array.isArray(value)) {
            output = [...output, ...value];
          } else {
            output = [...output, value];
          }
        });
      });

      let outputQtyUnit = [];
      for (let i = 0; i < 60; i++) {
        outputQtyUnit = [...outputQtyUnit, output?.[i] ?? ''];
      }
      setQtyUnitOutput(outputQtyUnit);
    }
  }, [detailData]);

  async function generateStep(data) {
    let steps = [];
    if (data?.of_steps > 0) {
      for (let i = 1; i <= data?.of_steps; i++) {
        const detailStep = await getDetailStep({ id: data?.id, step: i });
        steps = [...steps, detailStep];
      }
    }
    setSteps(steps);
    let attributes = [];
    const warna = data?.production_request_attributes?.find((item) => item?.attribute?.name?.toLowerCase() === 'warna');
    const lebar = data?.production_request_attributes?.find((item) => item?.attribute?.name?.toLowerCase() === 'lebar');
    const gramasi = data?.production_request_attributes?.find(
      (item) => item?.attribute?.name?.toLowerCase() === 'gramasi',
    );
    if (warna) attributes = [...attributes, warna];
    if (lebar) attributes = [...attributes, lebar];
    if (gramasi) attributes = [...attributes, gramasi];
    const leftAttributes = data?.production_request_attributes?.filter(
      (item) => !['warna', 'lebar', 'gramasi'].includes(item?.attribute?.name?.toLowerCase()),
    );
    if (leftAttributes) attributes = [...attributes, ...leftAttributes];
    setAttributes(attributes);
  }

  const referenceNote = detailData?.production_request_references?.find(
    (item) => item?.transaction_code?.toLowerCase().indexOf('NOTES:'?.toLowerCase()) > -1,
  );
  const note = referenceNote?.transaction_code?.split(':')?.[1];
  return (
    <DetailPrint
      paperFormat={PAPER_FORMAT.F4}
      orientation="portrait"
      printHeaderProps={{ showLogo: false }}
      onSuccessLoadData={generateStep}
    >
      <div style={{ fontSize: '11px' }}>
        <Row gutter={10} style={{ lineHeight: '16px' }}>
          <Col span={6}>
            <svg ref={inputRef} />
            <div style={{ lineHeight: '16px' }}>
              <h2>Production Order</h2>
              <h3>{detailData?.code ?? ''}</h3>
            </div>
          </Col>
          <Col span={8}>
            <table style={{ lineHeight: '16px' }}>
              <tr>
                <td>Customer</td>
                <td style={{ fontWeight: 'bold' }}>{detailData?.order_from?.name ?? '-'}</td>
              </tr>
              <tr>
                <td style={{ paddingRight: '5px' }}>Customer Reference</td>
                <td style={{ fontWeight: 'bold' }}>{detailData?.contact_references?.[0]?.name ?? '-'}</td>
              </tr>
              <tr>
                <td>References</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.production_request_references?.map((item) => item?.transaction_code)?.join(', ')}
                </td>
              </tr>
            </table>
          </Col>
          <Col span={6}>
            <table style={{ lineHeight: '16px' }}>
              <tr>
                <td>Order Date</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.order_date ? moment(detailData?.order_date).format('DD/MM/YYYY HH:mm') : '-'}
                </td>
              </tr>
              <tr>
                <td>Due Date</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.expected_finish_date
                    ? moment(detailData?.expected_finish_date).format('DD/MM/YYYY HH:mm')
                    : '-'}
                </td>
              </tr>
            </table>
          </Col>
          <Col span={4}></Col>
        </Row>
        <hr style={{ margin: 0 }} />
        <div style={{ lineHeight: '16px', marginTop: '-15px' }}>
          <DetailBuilder
            columns={[
              {
                fields: [
                  {
                    gridColumn: { span: 4 },
                    label: 'Product',
                    name: 'product_master',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return value?.name;
                      },
                    },
                  },
                  ...(attributes?.length > 0
                    ? attributes?.map((attr) => {
                        return {
                          gridColumn: { span: 3 },
                          label: attr?.attribute?.name,
                          name: 'id',
                          fieldType: 'text',
                          renderTextProps: {
                            renderValue: (value) => {
                              return attr?.attribute_value
                                ? `${attr?.attribute_value?.value} ${
                                    attr?.attribute_value?.unit_of_measurement?.code ?? ''
                                  }`
                                : '-';
                            },
                          },
                        };
                      })
                    : []),
                  {
                    gridColumn: { span: 3 },
                    label: 'Qty Order',
                    name: 'produce_qty',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return value ? `${value} ${detailData?.request_qty_unit_of_measurement?.code ?? ''}` : '-';
                      },
                    },
                  },
                  {
                    gridColumn: { span: 3 },
                    label: 'Order Unit',
                    name: 'produce_unit',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return value ? `${value} ${detailData?.unit_qty_unit_of_measurement?.code ?? ''}` : '-';
                      },
                    },
                  },
                  {
                    gridColumn: { span: 3 },
                    label: 'Order Qty/Unit',
                    name: 'produce_qty_unit',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return value ? `${value} ${detailData?.qty_unit_unit_of_measurement?.code ?? ''}` : '-';
                      },
                    },
                  },
                ],
              },
            ]}
          />
          <div style={{ marginTop: '-15px' }}>
            <DetailBuilder
              columns={[
                {
                  fields: [
                    {
                      gridColumn: { span: 4 },
                      label: 'BoM',
                      name: 'id',
                      fieldType: 'text',
                      renderTextProps: {
                        renderValue: (value) => {
                          if (detailData?.bill_of_materials?.length > 0) {
                            return detailData?.bill_of_materials?.map((item) => item?.name)?.join(', ');
                          }
                          return '-';
                        },
                      },
                    },
                    {
                      gridColumn: { span: 4 },
                      label: 'Route',
                      name: 'route',
                      fieldType: 'text',
                      renderTextProps: {
                        renderValue: (value) => {
                          return value?.name;
                        },
                      },
                    },
                    {
                      gridColumn: { span: 4 },
                      label: 'Lot',
                      name: 'id',
                      fieldType: 'text',
                      renderTextProps: {
                        renderValue: (value) => {
                          if (detailData?.steps?.length > 0) {
                            let lots = [];
                            detailData?.steps?.forEach((step) => {
                              step?.step_needs?.forEach((material) => {
                                if (material?.lot?.code) lots = [...lots, material?.lot?.code];
                              });
                            });
                            return lots?.join(', ');
                          }
                          return '-';
                        },
                      },
                    },
                    // {
                    //   gridColumn: { span: 4 },
                    //   label: 'Label Size',
                    //   name: 'steps',
                    //   fieldType: 'text',
                    //   renderTextProps: {
                    //     renderValue: (value) => {
                    //       const lastStep = value?.[value?.length - 1];
                    //       return detailData?.steps
                    //         ?.map((item) => item?.label_dimensions)
                    //         ?.filter(Boolean)
                    //         ?.join(', ');
                    //     },
                    //   },
                    // },
                    // ...(detailData?.treatment_packing?.product_attributes?.map((item) => {
                    //   return {
                    //     gridColumn: { span: 4 },
                    //     label: item?.attributes?.[0]?.name ?? '',
                    //     name: 'id',
                    //     fieldType: 'text',
                    //     renderTextProps: {
                    //       renderValue: (value) => {
                    //         if (item?.attribute_values?.[0]) {
                    //           return `${item?.attribute_values?.[0]?.item?.[0] ?? ''} ${
                    //             item?.attribute_values?.[0]?.uom?.code ?? ''
                    //           }`;
                    //         }
                    //         return '-';
                    //       },
                    //     },
                    //   };
                    // }) ?? []),
                    // {
                    //   gridColumn: { span: 8 },
                    //   label: 'Treatment Packing',
                    //   name: 'treatment_packing',
                    //   fieldType: 'text',
                    //   renderTextProps: {
                    //     renderValue: (value) => {
                    //       return value?.product_attributes
                    //         ?.map((item) => {
                    //           return `${item?.attributes?.[0]?.name} ${item?.attribute_values?.[0]?.item?.[0]} ${
                    //             item?.attribute_values?.[0]?.uom?.code ?? ''
                    //           }`;
                    //         })
                    //         .join(', ');
                    //     },
                    //   },
                    // },
                  ],
                },
              ]}
            />
          </div>
          <div style={{ marginTop: '-15px' }}>
            <DetailBuilder
              columns={[
                {
                  fields: [
                    // {
                    //   gridColumn: { span: 4 },
                    //   label: 'BoM',
                    //   name: 'id',
                    //   fieldType: 'text',
                    //   renderTextProps: {
                    //     renderValue: (value) => {
                    //       if (detailData?.bill_of_materials?.length > 0) {
                    //         return detailData?.bill_of_materials?.map((item) => item?.name)?.join(', ');
                    //       }
                    //       return '-';
                    //     },
                    //   },
                    // },
                    // {
                    //   gridColumn: { span: 4 },
                    //   label: 'Route',
                    //   name: 'route',
                    //   fieldType: 'text',
                    //   renderTextProps: {
                    //     renderValue: (value) => {
                    //       return value?.name;
                    //     },
                    //   },
                    // },
                    // {
                    //   gridColumn: { span: 4 },
                    //   label: 'Lot',
                    //   name: 'id',
                    //   fieldType: 'text',
                    //   renderTextProps: {
                    //     renderValue: (value) => {
                    //       if (detailData?.steps?.length > 0) {
                    //         let lots = [];
                    //         detailData?.steps?.forEach((step) => {
                    //           step?.step_needs?.forEach((material) => {
                    //             if (material?.lot?.code) lots = [...lots, material?.lot?.code];
                    //           });
                    //         });
                    //         return lots?.join(', ');
                    //       }
                    //       return '-';
                    //     },
                    //   },
                    // },
                    {
                      gridColumn: { span: 4 },
                      label: 'Label Size',
                      name: 'id',
                      fieldType: 'text',
                      renderTextProps: {
                        renderValue: (value) => {
                          const lastStep = value?.[value?.length - 1];
                          const labels = detailData?.steps?.map((item) => item?.label_dimensions)?.filter(Boolean);
                          if (labels?.length > 0) {
                            return labels?.join(',');
                          }
                          return '-';
                        },
                      },
                    },
                    ...(detailData?.treatment_packing?.product_attributes?.map((item) => {
                      return {
                        gridColumn: { span: 4 },
                        label: item?.attributes?.[0]?.name ?? '',
                        name: 'id',
                        fieldType: 'text',
                        renderTextProps: {
                          renderValue: (value) => {
                            if (item?.attribute_values?.[0]) {
                              return `${item?.attribute_values?.[0]?.item?.[0] ?? ''} ${
                                item?.attribute_values?.[0]?.uom?.code ?? ''
                              }`;
                            }
                            return '-';
                          },
                        },
                      };
                    }) ?? []),
                    // {
                    //   gridColumn: { span: 8 },
                    //   label: 'Treatment Packing',
                    //   name: 'treatment_packing',
                    //   fieldType: 'text',
                    //   renderTextProps: {
                    //     renderValue: (value) => {
                    //       return value?.product_attributes
                    //         ?.map((item) => {
                    //           return `${item?.attributes?.[0]?.name} ${item?.attribute_values?.[0]?.item?.[0]} ${
                    //             item?.attribute_values?.[0]?.uom?.code ?? ''
                    //           }`;
                    //         })
                    //         .join(', ');
                    //     },
                    //   },
                    // },
                  ],
                },
              ]}
            />
          </div>
          <hr />
          {/* <div style={{ marginTop: '-15px' }}>
            <DetailBuilder
              columns={[
                {
                  fields: [
                    {
                      gridColumn: { span: 6 },
                      label: 'Material',
                      name: 'id',
                      fieldType: 'text',
                      renderTextProps: {
                        renderValue: (value) => {
                          if (detailData?.steps?.length > 0) {
                            const stepMaterial = detailData?.steps?.[0]?.step_needs?.[0];
                            const product =
                              stepMaterial?.product_variant ??
                              stepMaterial?.product_master ??
                              stepMaterial?.product_category;
                            return product?.name;
                          }
                          return '-';
                        },
                      },
                    },
                    {
                      gridColumn: { span: 4 },
                      label: 'Qty Needed',
                      name: 'id',
                      fieldType: 'text',
                      renderTextProps: {
                        renderValue: (value) => {
                          if (detailData?.steps?.length > 0) {
                            const stepMaterial = detailData?.steps?.[0]?.step_needs?.[0];
                            return `${stepMaterial?.qty_needed} ${
                              stepMaterial?.qty_needed_unit_of_measurement?.code ?? ''
                            }`;
                          }
                          return '-';
                        },
                      },
                    },
                    {
                      gridColumn: { span: 4 },
                      label: 'Qty Used',
                      name: 'id',
                      fieldType: 'text',
                      renderTextProps: {
                        renderValue: (value) => {
                          if (detailData?.steps?.length > 0) {
                            const stepMaterial = detailData?.steps?.[0]?.step_needs?.[0];
                            return `${stepMaterial?.qty_received ?? stepMaterial?.unit_received ?? 0} ${
                              stepMaterial?.qty_needed_unit_of_measurement?.code ?? ''
                            }`;
                          }
                          return '-';
                        },
                      },
                    },
                  ],
                },
              ]}
            />
          </div> */}
        </div>
        {/* <h4 style={{ fontWeight: 'bold' }}>Qty/Unit</h4>
        <QtyPerUnit data={qtyUnit ?? []} isResult={false} />
        <hr /> */}
        <h3 style={{ fontWeight: 'bold', textAlign: 'center' }}>
          PASTIKAN MELAPOR KEPADA KEPALA SHIFT/ADMIN SEBELUM ATAU SESUDAH PROSES!
        </h3>
        <table style={{ width: '100%', marginTop: '5px', border: '0.2px solid black', fontSize: '9px' }}>
          <thead>
            <tr>
              {columnsStep?.map((column, index) => {
                return (
                  <th
                    style={{ ...column?.style, border: '1px solid black' }}
                    key={`${column?.key + uuidV4()}`}
                    rowSpan={column?.key === 'output' ? 1 : 2}
                    colSpan={column?.key === 'output' ? 2 : 1}
                  >
                    {column?.title}
                  </th>
                );
              })}
            </tr>
            <tr>
              <th style={{ border: '1px solid black' }}>Sesuai</th>
              <th style={{ border: '1px solid black' }}>Cacat</th>
            </tr>
          </thead>
          <tbody>
            {steps?.map((line, index) => {
              return (
                <tr key={index}>
                  {columnsStep?.map((column) => {
                    return (
                      <React.Fragment key={`${column?.key + uuidV4()}`}>
                        {column?.key === 'output' ? (
                          <React.Fragment>
                            <td style={{ ...column?.style, border: '1px solid black' }}>
                              {(line?.actual_output_qty || line?.actual_output_unit) && (
                                <React.Fragment>
                                  {line?.actual_output_qty ?? line?.actual_output_unit ?? 0}
                                  {line?.unit_of_measurement?.code ?? ''}
                                </React.Fragment>
                              )}
                            </td>
                            <td style={{ ...column?.style, border: '1px solid black' }}>
                              {(line?.actual_scrap_qty || line?.actual_scrap_unit) && (
                                <React.Fragment>
                                  {line?.actual_scrap_qty ?? line?.actual_scrap_unit ?? 0}
                                  {line?.unit_of_measurement?.code ?? ''}
                                </React.Fragment>
                              )}
                            </td>
                          </React.Fragment>
                        ) : (
                          <td style={{ ...column?.style, padding: '2px', border: '1px solid black' }}>
                            {column?.render(line[column?.key], line)}
                          </td>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* <div style={{ lineHeight: '12px' }}>
          <DetailBuilder
            columns={[
              {
                fields: [
                  {
                    gridColumn: { span: 4 },
                    label: 'Product',
                    name: 'id',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        if (detailData?.steps?.length > 0) {
                          const lastStep = detailData?.steps?.[detailData?.steps?.length - 1];
                          return lastStep?.product_output?.name;
                        }
                        return '-';
                      },
                    },
                  },
                  ...(detailData?.steps?.[detailData?.steps?.length - 1]?.work_order_attributes?.length > 0
                    ? detailData?.steps?.[detailData?.steps?.length - 1]?.work_order_attributes?.map((attr) => {
                        return {
                          gridColumn: { span: 3 },
                          label: attr?.attribute?.name,
                          name: 'id',
                          fieldType: 'text',
                          renderTextProps: {
                            renderValue: (value) => {
                              return attr?.attribute_value
                                ? `${attr?.attribute_value?.value} ${
                                    attr?.attribute_value?.unit_of_measurement?.code ?? ''
                                  }`
                                : '-';
                            },
                          },
                        };
                      })
                    : []),
                  {
                    gridColumn: { span: 4 },
                    label: 'Qty Output',
                    name: 'id',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        if (detailData?.output_qty) {
                          return `${detailData?.output_qty} ${detailData?.produce_qty_unit_of_measurement?.code ?? ''}`;
                        }
                        return '-';
                      },
                    },
                  },
                  {
                    gridColumn: { span: 4 },
                    label: 'Output Unit Qty',
                    name: 'id',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        if (detailData?.output_unit) {
                          return `${detailData?.output_unit} ${
                            detailData?.produce_unit_unit_of_measurement?.code ?? ''
                          }`;
                        }
                        return '-';
                      },
                    },
                  },
                ],
              },
            ]}
          />
        </div> */}
        {/* <h4 style={{ fontWeight: 'bold' }}>Qty/Unit</h4> */}
        {/* <QtyPerUnit data={qtyUnitOutput ?? []} isResult={true} /> */}
        <Row gutter={12} style={{ marginTop: '10px' }}>
          <Col span={8}>
            <span style={{ fontWeight: 'bold' }}>Sample</span>
            <div style={{ width: '100%', height: '150px', border: '1px solid black' }}></div>
          </Col>
          <Col span={8}>
            <span style={{ fontWeight: 'bold' }}>Handfeel</span>
            <div style={{ width: '100%', height: '150px', border: '1px solid black' }}></div>
          </Col>
          <Col span={8}>
            <span style={{ fontWeight: 'bold', color: 'white' }}>-</span>
            <div style={{ width: '100%', height: '150px', padding: '10px', border: '1px solid black' }}>
              <p style={{ fontSize: '14px' }}>{note ?? ''}</p>
            </div>
          </Col>
        </Row>
      </div>
    </DetailPrint>
  );
}
