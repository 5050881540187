import { DetailBuilder, DetailPrint, PrintTitle } from '@base-components';
import { PAPER_FORMAT, stringFormatter } from '@base-helpers';
import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { renderCurrency } from '../components';
import { generateDataList, requestPayment } from './helpers';

interface Props {
  data: any;
}

const PaymentLayout = (props: Props) => {
  const { data } = props;
  const [dataPayment, setDataPayment] = useState(null);
  const [dataList, setDataList] = useState(null);

  const generatePayment = async (id) => {
    const result = await requestPayment(id);
    const findPaymentByCustomer = result?.transactions?.find((item) => item?.customer?.id === data?.contact?.id);
    setDataPayment(findPaymentByCustomer);
  };

  useEffect(() => {
    const payment = data?.references?.find(
      (item) => item?.is_generated === true && item?.sub_module === 'sales payment',
    );
    if (payment?.transaction_id) {
      generatePayment(payment?.transaction_id);
    }
  }, [data?.references]);

  useEffect(() => {
    if (dataPayment) {
      const dataList = generateDataList(dataPayment);
      setDataList(dataList);
    }
  }, [dataPayment]);

  return (
    <DetailPrint
      orientation="portrait"
      paperFormat={PAPER_FORMAT.A4}
      printSignatureProps={{
        signatures: [
          // {
          //   gridColumn: { span: 6 },
          //   title: 'Paid By',
          //   name: detailData?.creator_name ? detailData?.creator_name : '-',
          // },
          // {
          //   gridColumn: { span: 6 },
          //   title: 'Received By',
          //   name: detailData?.editor_name ? detailData?.editor_name : '-',
          // },
          // {
          //   gridColumn: { span: 6 },
          //   title: 'Created By',
          //   name: detailData?.creator_name ? detailData?.creator_name : '-',
          // },
          // {
          //   gridColumn: { span: 6 },
          //   title: 'Confirmed By',
          //   name: detailData?.editor_name ? detailData?.editor_name : '-',
          // },
        ],
      }}
    >
      <div style={{ lineHeight: '10px' }}>
        <h2 style={{ textAlign: 'center', fontWeight: 'bold' }}>Bukti Pembayaran</h2>
        <Row style={{ fontWeight: 'bold' }}>
          <Col span={16}>
            <p>Lampiran 1. Pembayar</p>
            <p>Lampiran 2. Pembukuan</p>
          </Col>
          <Col span={8}>
            <p>
              Telah terima dari {data?.contact ? `${data?.contact?.name} (${data?.contact?.code})` : '______________'}
            </p>
            <p>Alamat</p>
            <p>
              {dataPayment?.customer?.address?.length > 0
                ? dataPayment?.customer?.address
                : '__________________________________'}
            </p>
          </Col>
        </Row>
      </div>
      <table style={{ width: '100%', border: '1px solid black' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid black' }} colSpan={3}>
              Rincian Pembayaran Untuk
            </th>
            <th style={{ border: '1px solid black' }} colSpan={5}>
              Dibayarkan Menggunakan
            </th>
          </tr>
          <tr>
            <th style={{ border: '1px solid black' }} colSpan={2}>
              Faktur-faktur
            </th>
            <th style={{ border: '1px solid black' }} rowSpan={2}>
              Nilai Faktur
            </th>
            <th style={{ border: '1px solid black', textAlign: 'right' }} colSpan={4}>
              Tunai
            </th>
            <th style={{ border: '1px solid black' }}>RP: </th>
          </tr>
          <tr>
            <th style={{ border: '1px solid black' }}>Tanggal</th>
            <th style={{ border: '1px solid black' }}>No. Faktur</th>
            <th style={{ border: '1px solid black' }}>Bank</th>
            <th style={{ border: '1px solid black' }}>No. BG</th>
            <th style={{ border: '1px solid black' }}>Jth. Tempo</th>
            <th style={{ border: '1px solid black' }}>Transfer</th>
            <th style={{ border: '1px solid black' }}></th>
          </tr>
        </thead>
        <tbody>
          {dataList?.map((list, index) => {
            return (
              <tr key={index}>
                <td style={{ border: '1px solid black' }}>
                  {list?.sales_invoice?.invoice_date
                    ? moment(list?.sales_invoice?.invoice_date).format('DD/MM/YYYY')
                    : ''}
                </td>
                <td style={{ border: '1px solid black' }}>{list?.sales_invoice?.code ?? ''}</td>
                <td style={{ border: '1px solid black' }}>
                  {list?.sales_invoice?.outcome_total?.total
                    ? renderCurrency({ value: list?.sales_invoice?.outcome_total?.total })
                    : ''}
                </td>
                <td style={{ border: '1px solid black' }}>{list?.payment?.customer_bank?.code ?? ''}</td>
                <td style={{ border: '1px solid black' }}>{list?.payment?.payment_reference ?? ''}</td>
                <td style={{ border: '1px solid black' }}>
                  {list?.payment?.payment_date ? moment(list?.payment?.payment_date).format('DD/MM/YYYY') : ''}
                </td>
                <td style={{ border: '1px solid black' }}>{list?.payment?.payment_reference ?? ''}</td>
                <td style={{ border: '1px solid black', textAlign: 'right' }}>
                  {list?.payment?.payment_amount ? renderCurrency({ value: list?.payment?.payment_amount }) : ''}
                </td>
              </tr>
            );
          })}
          {[1, 1, 1, 1, 1, 1, 1, 1]?.map((_, index) => {
            return (
              <tr key={index}>
                <td style={{ border: '1px solid black' }}>-</td>
                <td style={{ border: '1px solid black' }}> </td>
                <td style={{ border: '1px solid black' }}> </td>
                {index === 0 && (
                  <React.Fragment>
                    <td colSpan={4} style={{ border: '1px solid black', textAlign: 'right' }}>
                      TOTAL:{' '}
                    </td>
                    <td style={{ border: '1px solid black', textAlign: 'right' }}>
                      {renderCurrency({ value: data?.remaining_balance })}
                    </td>
                  </React.Fragment>
                )}
                {index === 1 && (
                  <React.Fragment>
                    <td colSpan={5} rowSpan={2} style={{ border: '1px solid black', verticalAlign: 'top' }}>
                      Catatan:{' '}
                    </td>
                  </React.Fragment>
                )}
                {index === 3 && (
                  <React.Fragment>
                    <td colSpan={4} style={{ border: '1px solid black', verticalAlign: 'top' }}>
                      Keterangan (+/-)
                    </td>
                    <td style={{ border: '1px solid black', verticalAlign: 'top' }}>Rp.</td>
                  </React.Fragment>
                )}
                {index === 4 && (
                  <React.Fragment>
                    <td colSpan={5} style={{ textAlign: 'right', borderRight: '1px solid black' }}>
                      Bandung, {moment().format('DD/MM/YYYY')}
                    </td>
                  </React.Fragment>
                )}
                {index === 5 && (
                  <React.Fragment>
                    <td colSpan={2} style={{ textAlign: 'center' }}>
                      Penerima,
                    </td>
                    <td colSpan={3} style={{ textAlign: 'center' }}>
                      Pembayar,
                    </td>
                  </React.Fragment>
                )}
                {index === 6 && <td colSpan={5} style={{ textAlign: 'center', borderRight: '1px solid black' }}></td>}
                {index === 7 && (
                  <React.Fragment>
                    <td
                      rowSpan={2}
                      colSpan={2}
                      style={{ textAlign: 'center', verticalAlign: 'text-bottom', borderBottom: '1px solid black' }}
                    >
                      (_____________________)
                    </td>
                    <td
                      colSpan={3}
                      rowSpan={2}
                      style={{ textAlign: 'center', borderRight: '1px solid black', borderBottom: '1px solid black' }}
                    >
                      (_____________________)
                    </td>
                  </React.Fragment>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </DetailPrint>
  );
};

export default PaymentLayout;
