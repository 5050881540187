import { authHelper } from '@base-helpers';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { ErrorRequest } from '../error-handlings';
import { HttpClientRepository } from '../repositories';

export class HttpClient<R> implements HttpClientRepository<R> {
  setRequestToken = (request: AxiosRequestConfig, authToken: string): AxiosRequestConfig => {
    const token = authToken ?? authHelper.getAuthToken() ?? localStorage.getItem('auth_token');
    request.headers.Authorization = `Bearer ${token}`;
    return request;
  };

  async request(params: AxiosRequestConfig, token: string): Promise<R> {
    axios.interceptors.request.use((request) => this.setRequestToken(request, token));
    try {
      const { data } = await axios.request<R>({ ...params });

      return data;
    } catch (e: any) {
      const error = e as AxiosError<any>;
      const { message, statusCode, data } = error.response.data ?? { message: 'Unexpected Error' };
      if (statusCode === 401) {
        await authHelper.clearLogout();
        window.location.replace('/login');
      }
      throw new ErrorRequest(data, message, statusCode);
    }
  }
}

export const requestHttpClient = new HttpClient().request;
