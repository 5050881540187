interface Props {
  value: any;
}

export function renderCurrency({ value }: Props) {
  if (value) {
    return `Rp ${value?.toLocaleString('ID-id')}`;
  }
  return 'Rp 0';
}
