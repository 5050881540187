import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCountCurrency, RenderCurrency } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { makeModuleBarcode, PAPER_FORMAT, stringFormatter } from '@base-helpers';
import moment from 'moment';
import { Col, Row, Table } from 'antd';
import QRCode from 'react-qr-code';
import { getProducts } from './helpers';

export function Detail() {
  const { detailData = {}, printContent, type } = usePagePrint();
  const defaultSignature: any[] = [
    {
      title: 'Hormat Kami',
      name: '',
      gridColumn: { span: 6 },
    },
  ];
  const columns: any[] = [
    {
      title: 'Item Number',
      key: 'location_transfer_product_detail',
      dataIndex: 'location_transfer_product_detail',
      render: (item, row) => item?.product_variant?.code ?? item?.product_package?.code,
    },
    {
      title: 'Description',
      key: 'location_transfer_product_detail',
      dataIndex: 'location_transfer_product_detail',
      render: (item, row) => item?.product_variant?.name ?? item?.product_package?.name,
      style: { minWidth: '300px' },
    },
    {
      title: 'Ordered',
      key: 'total_qty',
      dataIndex: 'total_qty',
      render: (item, row) => row?.qty ?? row?.unit,
      style: { textAlign: 'right' },
    },
    {
      title: 'Unit',
      key: 'unit_of_measurement',
      dataIndex: 'unit_of_measurement',
      render: (item, row) => row?.unit_of_measurement?.code ?? row?.unit_unit_of_measurement?.code,
    },
    {
      title: 'Received',
      key: 'receive_qty',
      dataIndex: 'receive_qty',
      render: (item, row) => row?.receive_qty ?? row?.receive_unit,
      style: { textAlign: 'right' },
    },
    {
      title: 'Remaining Qty',
      key: 'remaining_qty',
      dataIndex: 'remaining_qty',
      render: (item, row) => (row?.qty > 0 ? row?.qty - row?.receive_qty : row?.unit - row?.receive_unit),
      style: { textAlign: 'right' },
    },
  ];

  return (
    <DetailPrint paperFormat={PAPER_FORMAT.A4} orientation="portrait" printHeaderProps={{ showLogo: false }}>
      <div style={{ fontSize: '11px' }}>
        <Row gutter={10} style={{ lineHeight: '11px' }}>
          <Col span={8}>
            <table>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Source Location</td>
              </tr>
              <tr>
                <td>{detailData?.location_transfer?.source_location?.name ?? ''}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Receipt Location</td>
              </tr>
              <tr>
                <td>
                  {detailData?.location_transfer?.destination_site?.name} -{' '}
                  {detailData?.location_transfer?.destination_location?.name}
                </td>
              </tr>
            </table>
          </Col>
          <Col span={12}>
            <table>
              <tr>
                <td colSpan={2}>
                  <h2>Product Receipt Internal {type ? `(${stringFormatter.capitalizeEachWord(type)})` : ''}</h2>
                </td>
              </tr>
              <tr>
                <td>Date</td>
                <td>
                  {detailData?.location_transfer?.received_date
                    ? moment(detailData?.location_transfer?.received_date).format('DD/MM/YYYY')
                    : '-'}
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: '10px' }}>References</td>
                <td>
                  {detailData?.location_transfer?.references
                    ?.map((item) => {
                      return item?.transaction_code;
                    })
                    .filter(Boolean)
                    .join(', ')}
                </td>
              </tr>
              <tr>
                <td>Status</td>
                <td>
                  {detailData?.status?.toLowerCase() === 'waiting'
                    ? 'In Process'
                    : stringFormatter.capitalizeEachWord(detailData?.status?.split('_')?.join(' '))}
                </td>
              </tr>
            </table>
          </Col>
          <Col span={4} style={{ textAlign: 'right' }}>
            <div style={{ textAlign: 'center' }}>
              <QRCode
                value={
                  makeModuleBarcode({
                    id: detailData?.id,
                    module: printContent?.module,
                    subModule: printContent?.sub_module,
                  }) ?? ''
                }
                size={80}
              />
              <h4 style={{ fontWeight: 'bold', marginTop: '5px' }}>{detailData?.code ?? ''}</h4>
            </div>
          </Col>
        </Row>
        <table style={{ width: '100%', marginTop: '5px', lineHeight: '10px' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid black', textAlign: 'left' }}>
              {columns?.map((column) => {
                return (
                  <th style={column?.style} key={column?.key}>
                    {column?.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {detailData?.location_transfer?.location_transfer_products?.map((line, index) => {
              return (
                <tr key={index}>
                  {columns?.map((column) => {
                    return (
                      <td style={column?.style} key={column?.key}>
                        {column?.render(line[column?.key], line)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr style={{ borderTop: '1px solid black' }}>
              <td colSpan={2}>Total</td>
              <td style={{ textAlign: 'right' }}>
                {detailData?.location_transfer?.location_transfer_products?.reduce(
                  (partialSum, a) => partialSum + (a?.qty ?? a?.unit),
                  0,
                )}
              </td>
              <td></td>
              <td style={{ textAlign: 'right' }}>
                {detailData?.location_transfer?.location_transfer_products?.reduce(
                  (partialSum, a) => partialSum + (a?.receive_qty ?? a?.receive_unit),
                  0,
                )}
              </td>
              <td style={{ textAlign: 'right' }}>
                {detailData?.location_transfer?.location_transfer_products?.reduce(
                  (partialSum, a) => partialSum + (a?.qty > 0 ? a?.qty - a?.receive_qty : a?.unit - a?.receive_unit),
                  0,
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ width: '100%', textAlign: 'center' }}>
          <tr>
            <td>Pengirim,</td>
            <td>Gudang,</td>
            <td>Mengetahui,</td>
          </tr>
        </table>
      </div>
    </DetailPrint>
  );
}
