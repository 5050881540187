import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCountCurrency, RenderCurrency } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter } from '@base-helpers';
import moment from 'moment';
import { Col, Row } from 'antd';
import QRCode from 'react-qr-code';
import { columnsMaterial, columnsStep, getDetailStep, getProducts } from './helpers';
import { useBarcode } from '@createnextapp/react-barcode';
import { Table } from './components';
import QtyPerUnit from './qty-per-unit';

const generateStatus = (item) => {
  let status = item?.status;

  if (item?.status?.toLowerCase() === 'waiting') {
    if (item?.status_module === 'to_do') status = 'todo';
    else status = stringFormatter.capitalizeEachWord(item?.status_module?.split('_').join(' '));
  }
  if (item?.is_reprocess) {
    status = 're process';
  }
  if (item?.is_problem) {
    status = 'problem';
  }
  if (item?.is_hold) {
    status = 'on hold';
  }

  return status;
};

export function Detail() {
  const { detailData = {}, printContent, type } = usePagePrint();
  const [steps, setSteps] = useState([]);

  const { inputRef } = useBarcode({
    value: detailData?.code ?? '-',
    options: {
      displayValue: false,
      height: 20,
      width: 1,
      marginTop: -1,
      marginLeft: -1,
      marginBottom: -1,
    },
  });

  const [qtyUnitOutput, setQtyUnitOutput] = useState([]);

  useEffect(() => {
    const materialQtyUnit = detailData?.steps?.[0]?.step_needs?.[0]?.qty_units?.[0]?.value;
    if (detailData) {
      let qtyUnits = [];

      for (let i = 0; i < 60; i++) {
        qtyUnits = [...qtyUnits, materialQtyUnit?.[i] ?? ''];
      }

      let output = [];
      detailData?.output_qty_unit?.forEach((item) => {
        item?.value?.forEach((value) => {
          if (Array.isArray(value)) {
            output = [...output, ...value];
          } else {
            output = [...output, value];
          }
        });
      });

      let outputQtyUnit = [];
      for (let i = 0; i < 60; i++) {
        outputQtyUnit = [...outputQtyUnit, output?.[i] ?? ''];
      }
      setQtyUnitOutput(outputQtyUnit);
    }
  }, [detailData]);

  async function generateStep(data) {
    let steps = [];
    if (data?.of_steps > 0) {
      for (let i = 1; i <= data?.of_steps; i++) {
        const detailStep = await getDetailStep({ id: data?.id, step: i });
        steps = [...steps, detailStep];
      }
    }
    setSteps(steps);
  }

  return (
    <DetailPrint
      paperFormat={PAPER_FORMAT.A4}
      orientation="portrait"
      printHeaderProps={{ showLogo: false }}
      printSignatureProps={{
        signatures: [
          { title: 'Requestor', gridColumn: { span: 8 }, name: printContent?.creator_name },
          { title: 'Approved By', gridColumn: { span: 8 }, name: '____________' },
          { title: 'Received By', gridColumn: { span: 8 }, name: '____________' },
        ],
      }}
      onSuccessLoadData={generateStep}
    >
      <div style={{ fontSize: '11px' }}>
        <Row gutter={10} style={{ lineHeight: '12px' }}>
          <Col span={6}>
            <svg ref={inputRef} />
            <div style={{ lineHeight: '12px' }}>
              <h2>Production Order {type ? `(${stringFormatter.capitalizeEachWord(type)})` : ''}</h2>
              <h3>{detailData?.code ?? ''}</h3>
            </div>
          </Col>
        </Row>
        <Row gutter={10} style={{ lineHeight: '12px' }}>
          <Col span={6}>
            <table style={{ lineHeight: '12px' }}>
              <tr>
                <td>Status</td>
                <td style={{ fontWeight: 'bold' }}>{generateStatus(detailData) ?? ''}</td>
              </tr>
              <tr>
                <td>Start Date</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.start_date ? moment(detailData?.start_date).format('DD/MM/YYYY') : ''}
                </td>
              </tr>
              <tr>
                <td>Finished Date</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.end_date ? moment(detailData?.end_date).format('DD/MM/YYYY') : ''}
                </td>
              </tr>
            </table>
          </Col>
          <Col span={6}>
            <table style={{ lineHeight: '12px' }}>
              <tr>
                <td>Production Site</td>
                <td style={{ fontWeight: 'bold' }}>{detailData?.production_site?.code ?? ''}</td>
              </tr>
              <tr>
                <td>Order Date</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.order_date ? moment(detailData?.order_date).format('DD/MM/YYYY') : ''}
                </td>
              </tr>
              <tr>
                <td>Due Date</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.expected_date ? moment(detailData?.expected_date).format('DD/MM/YYYY') : ''}
                </td>
              </tr>
            </table>
          </Col>
          <Col span={6}>
            <table>
              <tr>
                <td>Prod. Request</td>
                <td style={{ fontWeight: 'bold' }}>{detailData?.production_request?.code ?? ''}</td>
              </tr>
              <tr>
                <td>Planning Date</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.planning_date ? moment(detailData?.planning_date).format('DD/MM/YYYY') : ''}
                </td>
              </tr>
              <tr>
                <td>Expected Date</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.expected_finish_date
                    ? moment(detailData?.expected_finish_date).format('DD/MM/YYYY')
                    : ''}
                </td>
              </tr>
            </table>
          </Col>
          <Col span={6}>
            <table>
              <tr>
                <td style={{ paddingRight: '5px' }}>Customer</td>
                <td style={{ fontWeight: 'bold' }}>{detailData?.order_from?.name ?? ''}</td>
              </tr>
              <tr>
                <td style={{ paddingRight: '5px' }}>Customer Reference</td>
                <td style={{ fontWeight: 'bold' }}>{detailData?.contact_references?.[0]?.name ?? ''}</td>
              </tr>
              <tr>
                <td>References</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.production_order?.production_request_references
                    ?.map((item) => item?.transaction_code)
                    ?.join(', ') ?? ''}
                </td>
              </tr>
            </table>
          </Col>
        </Row>
        <hr />
        <DetailBuilder
          columns={[
            {
              fields: [
                {
                  gridColumn: { span: 4 },
                  label: 'Product Order',
                  name: 'product_master',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => {
                      return value?.name;
                    },
                  },
                },
                ...(detailData?.production_request_attributes?.length > 0
                  ? detailData?.production_request_attributes?.map((attr) => {
                      return {
                        gridColumn: { span: 3 },
                        label: attr?.attribute?.name,
                        name: 'id',
                        fieldType: 'text',
                        renderTextProps: {
                          renderValue: (value) => {
                            return attr?.attribute_value
                              ? `${attr?.attribute_value?.value} ${
                                  attr?.attribute_value?.unit_of_measurement?.code ?? ''
                                }`
                              : '-';
                          },
                        },
                      };
                    })
                  : []),
                {
                  gridColumn: { span: 3 },
                  label: 'Qty Order',
                  name: 'produce_qty',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => {
                      if (value) {
                        return `${value} ${
                          detailData?.produce_qty_unit_unit_of_measurement?.code ??
                          detailData?.request_qty_unit_of_measurement?.code ??
                          ''
                        }`;
                      }
                      return '-';
                    },
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Order Unit Qty',
                  name: 'produce_unit',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => {
                      if (value) {
                        return `${value} ${
                          detailData?.produce_unit_unit_of_measurement?.code ??
                          detailData?.unit_qty_unit_of_measurement?.code ??
                          ''
                        }`;
                      }
                      return '-';
                    },
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Order Qty/Unit',
                  name: 'produce_qty_unit',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => {
                      if (value) {
                        return `${value} ${
                          detailData?.produce_qty_unit_unit_of_measurement?.code ??
                          detailData?.qty_unit_unit_of_measurement?.code ??
                          ''
                        }`;
                      }
                      return '-';
                    },
                  },
                },
              ],
            },
          ]}
        />
        <DetailBuilder
          columns={[
            {
              fields: [
                {
                  gridColumn: { span: 4 },
                  label: 'BoM',
                  name: 'bill_of_materials',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => {
                      return detailData?.bill_of_materials?.map((item) => item?.name)?.join(', ');
                    },
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Route',
                  name: 'route',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => {
                      return value?.name;
                    },
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Label Size',
                  name: 'steps',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => {
                      return detailData?.steps
                        ?.map((item) => item?.label_dimensions)
                        ?.filter(Boolean)
                        ?.join(', ');
                    },
                  },
                },
              ],
            },
          ]}
        />
        <hr />
        <Table data={steps?.[0]?.step_needs} columns={columnsMaterial} title="Materials" style={{ fontSize: '9px' }} />
        <h3 style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '10px' }}>
          PASTIKAN MELAPOR KEPADA KEPALA SHIFT/ADMIN SEBELUM ATAU SESUDAH PROSES!
        </h3>
        <table style={{ width: '100%', marginTop: '5px', border: '0.2px solid black', fontSize: '9px' }}>
          <thead>
            <tr>
              {columnsStep?.map((column) => {
                return (
                  <th
                    style={{ ...column?.style, border: '1px solid black' }}
                    key={column?.key}
                    rowSpan={column?.key === 'output' ? 1 : 2}
                    colSpan={column?.key === 'output' ? 2 : 1}
                  >
                    {column?.title}
                  </th>
                );
              })}
            </tr>
            <tr>
              <th style={{ border: '1px solid black' }}>Sesuai</th>
              <th style={{ border: '1px solid black' }}>Cacat</th>
            </tr>
          </thead>
          <tbody>
            {steps?.map((line, index) => {
              return (
                <tr key={index}>
                  {columnsStep?.map((column) => {
                    return (
                      <React.Fragment key={column?.key}>
                        {column?.key === 'output' ? (
                          <React.Fragment>
                            <td style={{ ...column?.style, border: '1px solid black' }}>
                              {(line?.actual_output_qty || line?.actual_output_unit) && (
                                <React.Fragment>
                                  {line?.actual_output_qty ?? line?.actual_output_unit ?? 0}
                                  {line?.unit_of_measurement?.code ?? ''}
                                </React.Fragment>
                              )}
                            </td>
                            <td style={{ ...column?.style, border: '1px solid black' }}>
                              {(line?.actual_scrap_qty || line?.actual_scrap_unit) && (
                                <React.Fragment>
                                  {line?.actual_scrap_qty ?? line?.actual_scrap_unit ?? 0}
                                  {line?.unit_of_measurement?.code ?? ''}
                                </React.Fragment>
                              )}
                            </td>
                          </React.Fragment>
                        ) : (
                          <td style={{ ...column?.style, border: '1px solid black' }}>
                            {column?.render(line[column?.key], line)}
                          </td>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <DetailBuilder
          columns={[
            {
              fields: [
                {
                  gridColumn: { span: 4 },
                  label: 'Product Output',
                  name: 'id',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => {
                      if (detailData?.steps?.length > 0) {
                        const lastStep = detailData?.steps?.[detailData?.steps?.length - 1];
                        return lastStep?.product_output?.name;
                      }
                      return '-';
                    },
                  },
                },
                ...(detailData?.steps?.[detailData?.steps?.length - 1]?.work_order_attributes?.length > 0
                  ? detailData?.steps?.[detailData?.steps?.length - 1]?.work_order_attributes?.map((attr) => {
                      return {
                        gridColumn: { span: 3 },
                        label: attr?.attribute?.name,
                        name: 'id',
                        fieldType: 'text',
                        renderTextProps: {
                          renderValue: (value) => {
                            return attr?.attribute_value
                              ? `${attr?.attribute_value?.value} ${
                                  attr?.attribute_value?.unit_of_measurement?.code ?? ''
                                }`
                              : '-';
                          },
                        },
                      };
                    })
                  : []),
                {
                  gridColumn: { span: 4 },
                  label: 'Qty Output',
                  name: 'id',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => {
                      if (detailData?.output_qty) {
                        return `${detailData?.output_qty} ${detailData?.produce_qty_unit_of_measurement?.code ?? ''}`;
                      }
                      return '-';
                    },
                  },
                },
                {
                  gridColumn: { span: 4 },
                  label: 'Output Unit Qty',
                  name: 'id',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => {
                      if (detailData?.output_unit) {
                        return `${detailData?.output_unit} ${detailData?.produce_unit_unit_of_measurement?.code ?? ''}`;
                      }
                      return '-';
                    },
                  },
                },
              ],
            },
          ]}
        />
        <div style={{ marginBottom: '10px' }}>
          <h4 style={{ fontWeight: 'bold' }}>Output Qty/Unit</h4>
          <QtyPerUnit data={qtyUnitOutput ?? []} isResult={true} />
        </div>
      </div>
    </DetailPrint>
  );
}
