import { APPROVAL_MODULE } from '@base-helpers';
import { ExchangeProduct } from '@pages/sales/exchange-product';
import { LimitAndToP } from '@pages/sales/limit-and-top';
import { SalesDivision } from '@pages/sales/sales-division';
import { SalesOrder } from '@pages/sales/sales-order';

export const ListSalesContent = [
  {
    module: APPROVAL_MODULE.module.SALES,
    sub_module: APPROVAL_MODULE.subModule.sales.SALES_DIVISION,
    content: SalesDivision,
  },
  {
    module: APPROVAL_MODULE.module.SALES,
    sub_module: APPROVAL_MODULE.subModule.sales.SALES_ORDER,
    content: SalesOrder,
    downloadDetail: false,
    downloadIndex: false,
  },
  {
    module: APPROVAL_MODULE.module.SALES,
    sub_module: APPROVAL_MODULE.subModule.sales.EXCHANGE_PRODUCTS,
    content: ExchangeProduct,
  },
  {
    module: APPROVAL_MODULE.module.SALES,
    sub_module: APPROVAL_MODULE.subModule.sales.SALES_LIMIT_TERMS_OF_PAYMENT_REQUEST,
    content: LimitAndToP,
  },
];
