import React, { useEffect, useState } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { AutoComplete, Button, Modal } from 'antd';
import { MapPin, MapPinLine } from 'phosphor-react';
import { MapPicker } from '../map-picker';
const { Option } = AutoComplete;

type AddressValue = {
  address?: string;
  latitude?: number | string;
  longitude?: number | string;
};

export interface InputAddressProps {
  value?: AddressValue;
  onChange?(value: any): void;
  showMapIcon?: boolean;
  placeholder?: string;
}

function generateString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = ' ';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function InputAddress(props: InputAddressProps) {
  const [showMap, setShowMap] = React.useState(false);
  const [addressMarker, setAddressMarker] = React.useState(undefined);
  const [randomString, setRandomString] = useState('');

  const { value, onChange, showMapIcon = true, placeholder } = props;
  const autoComplete = usePlacesAutocomplete();
  const ready = autoComplete.ready;
  const addressValue = autoComplete.value;
  const { status, data }: any = autoComplete.suggestions;
  const setValue = autoComplete.setValue;
  const clearSuggestions = autoComplete.clearSuggestions;

  async function handleSelect(address) {
    clearSuggestions();
    try {
      const result = await getGeocode({ address });
      const { lat, lng } = await getLatLng(result[0]);
      const addressPic = result[0].formatted_address;
      handleChange({ address: addressPic, latitude: lat, longitude: lng });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (value?.address) setValue(value.address);
  }, [value]);

  function handleChange({ address, latitude, longitude }) {
    setValue(address);
    if (onChange) onChange({ address, latitude, longitude });
  }

  function handleSaveAddress() {
    handleChange(addressMarker);
    setAddressMarker(undefined);
    setShowMap(false);
    setRandomString(generateString(10));
  }

  function handleCancel() {
    setShowMap(false);
    setRandomString(generateString(10));
  }

  return (
    <div>
      <Modal
        okText="Save"
        visible={showMap}
        title="Google Maps"
        onOk={() => handleSaveAddress()}
        onCancel={() => handleCancel()}
        width={'80vw'}
      >
        <MapPicker
          addressMarker={value as any}
          triggerResetAddress={randomString}
          onChange={(value) => setAddressMarker(value)}
        />
      </Modal>
      <div style={{ display: 'flex' }}>
        <AutoComplete
          disabled={!ready}
          value={addressValue}
          placeholder={placeholder}
          onChange={(address) => handleChange({ address, latitude: undefined, longitude: undefined })}
          onSelect={handleSelect}
        >
          {status === 'OK' &&
            data.map(({ description, place_id }) => (
              <Option key={place_id} value={description}>
                <span>
                  <MapPin style={{ marginRight: '10px' }} />
                  {description}
                </span>
              </Option>
            ))}
        </AutoComplete>
        <Button
          hidden={!showMapIcon}
          type="text"
          className="button-inline-form"
          onClick={() => setShowMap(true)}
          style={{ marginLeft: '-1px', padding: '0' }}
          icon={<MapPin size={18} color="grey" />}
        />
      </div>
    </div>
  );
}
