import React from 'react';
import { Col, ColProps, Row } from 'antd';
import './style.less';

type Signature = {
  gridColumn?: ColProps;
  title?: string;
  name?: string;
};

export interface PrintSignatureProps {
  signatures?: Signature[];
}

const defaultSignature: Signature[] = [
  {
    title: 'Signature 1',
    name: 'Signature 1',
    gridColumn: { span: 6 },
  },
  {
    title: 'Signature 2',
    name: 'Signature 2',
    gridColumn: { span: 6 },
  },
  {
    title: 'Signature 3',
    name: 'Signature 3',
    gridColumn: { span: 6 },
  },
  {
    title: 'Signature 4',
    name: 'Signature 4',
    gridColumn: { span: 6 },
  },
];
export function PrintSignature(props: PrintSignatureProps) {
  const { signatures } = props;

  function makeGrid(grid) {
    if (!grid) return { span: 12 };
    return grid;
  }

  return (
    <div className="print-signature-wrapper">
      <Row>
        {signatures?.map((item, index) => {
          return (
            <Col key={index} {...makeGrid(item.gridColumn)}>
              <div className="print-signature-item">
                <div>
                  <div
                    className="print-signature-title"
                    style={{ textAlign: 'center', color: '#000', fontWeight: '500' }}
                  >
                    {item.title}
                  </div>
                  <div
                    className="print-signature-name"
                    style={{ textAlign: 'center', color: '#000' }}
                  >{`( ${item.name} )`}</div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
