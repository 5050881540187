import React, { useEffect, useState } from 'react';
import { makeCommonDataSource } from '@base-factories';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ListContent } from './list-content/';
import { listPrintContent } from './state';
import moment from 'moment';
import { Alert, Card } from 'antd';
import { ListContentMJS } from './list-content-mjs';

function defaultContent() {
  return (
    <Card>
      <div style={{ fontSize: '12px', textAlign: 'center' }}>No available content to show.</div>;
    </Card>
  );
}

function makeDataContent(token) {
  let contents = [];
  try {
    const parseToken = JSON.parse(atob(token));
    contents = parseToken;
  } catch (error) {
    contents = [];
  }
  return contents;
}

export function MultiplePrintFactory() {
  const params = useParams();
  const token = params?.token as any;
  const [, setPrintContentRecoil] = useRecoilState(listPrintContent);
  const [printContent, setPrintContent] = useState([]);
  const [projectNameAccess, setProjectNameAccess] = useState('');

  const decodedToken = window.atob(token);
  const parsedToken = JSON.parse(decodedToken);
  const type = parsedToken?.type ?? null;

  const printRequestDataSource = makeCommonDataSource({
    apiUrl: `v1/print/request${type ? `/${type}` : ''}`,
    getIndexUrl: `v1/print/request/list${type ? `/${type}` : ''}`,
  });
  const [showContent, setShowContent] = useState(false);

  function makeListContent(projectName) {
    setProjectNameAccess(projectName);
    if (projectName === 'pmps') return ListContent;
    else if (projectName === 'mjs') return ListContentMJS;
    return ListContent;
  }

  function filterContent(item, projectName) {
    const list = makeListContent(projectName).find(
      (content) =>
        content.module?.toLowerCase() === item.module?.toLowerCase() &&
        content.sub_module?.toLowerCase() === item.sub_module?.toLowerCase(),
    );
    return list;
  }

  async function handleGetContent(printToken): Promise<void> {
    const dataIds = printToken?.ids ?? ['id'];
    const authToken = printToken?.auth_token;
    const projectName = printToken?.project_name;
    localStorage.setItem('auth_token', authToken);

    await printRequestDataSource.handleGetIndex({
      params: { ids: dataIds },
      onSuccess({ response }: any) {
        const items = response ?? [];
        const contents = items.map((item) => {
          const list = filterContent(item, projectName);
          const content = list?.content ?? defaultContent;
          return {
            showPreviewDetail: true,
            downloadDetail: true,
            showPreviewIndex: true,
            downloadIndex: true,
            ...item,
            ...list,
            loadingStatus: 'not-loading',
            printFile: null,
            fileName: `${item?.code ?? ''}${item?.code ? '-' : ''}${
              item?.sub_module?.split(' ').join('-')?.toLowerCase() ?? ''
            }-${moment().valueOf()}`,
            content,
          };
        });

        setPrintContent(contents);
        setPrintContentRecoil(contents);
        setShowContent(true);
      },
      onFailed({ message }) {
        setPrintContent([]);
        setPrintContentRecoil([]);
      },
    });
  }

  useEffect(() => {
    if (token) {
      localStorage.clear();
      setShowContent(false);
      const printToken = makeDataContent(token);
      handleGetContent(printToken);
    }
  }, [token]);

  const AlertID = {
    message: 'Informasi',
    description: (
      <div>
        <div>
          <div>1. Proses print dilakukan secara otomatis.</div>
          <div>2. Menutup halaman ini akan menghentikan proses print untuk data yang belum terkirim.</div>
        </div>
      </div>
    ),
  };

  const AlertEN = {
    message: 'Information',
    description: (
      <div>
        <div>
          <div>1. The printing process is done automatically.</div>
          <div>2. Closing this page will stop the printing process for data that has not been sent.</div>
        </div>
      </div>
    ),
  };

  return (
    <div style={{ padding: '50px' }}>
      <Alert
        {...(projectNameAccess === 'pmps' ? AlertEN : AlertID)}
        style={{ marginBottom: 20 }}
        type="info"
        showIcon
        closable
      />
      {(printContent?.length === 0 || !printContent) && (
        <Card>
          <div style={{ fontSize: '12px', textAlign: 'center' }}>No available content to show.</div>
        </Card>
      )}
      {showContent &&
        printContent.map((item, index) => {
          const Content = item.content;
          return (
            <div key={index} style={{ marginBottom: '30px' }}>
              <Content {...item} />
            </div>
          );
        })}
    </div>
  );
}
