import React from 'react';
import { Row, Col } from 'antd';
import { Form, DatePicker, TimePicker } from 'antd';
import { Input, InputNumber } from 'antd';
const { TextArea, Password } = Input;
import * as _ from 'lodash';

import { Checkbox } from 'antd';
import { Switch } from 'antd';

import './form-builder.less';
import { FormBuilderProps, FormColumnProps, FormFieldProps } from './form-builder.entity';
import { Select, SelectPaginate } from '../form-selects';
import { UploadImage } from '../form-uploader';
import { InputAddress } from '../form-address';

const FormItemIgnore = [
  'customHidden',
  'renderField',
  'fieldType',
  'gridColumn',
  'dependencies',
  'shouldUpdate',
  'noStyle',
  'inputTextProps',
  'textAreaProps',
  'inputPasswordProps',
  'inputNumberProps',
  'checkboxProps',
  'switchProps',
  'datePickerProps',
  'dateRangePickerProps',
  'timePickerProps',
  'timeRangePickerProps',
  'selectPaginateProps',
  'uploadImageProps',
  'inputAddressProps',
];

function makeGrid(itemField: any) {
  const ignoreGrid = ['datePicker', 'dateRangePicker', 'timePicker', 'timeRangePicker'];
  if (itemField?.gridColumn) return itemField.gridColumn;
  else if (ignoreGrid.includes(itemField?.fieldType)) return {};
  return { xs: 24, sm: 8, md: 6, lg: 4, xl: 4 };
}

function defaultCustomHidden(form: any): boolean {
  return false;
}

export function FormBuilder(props: FormBuilderProps) {
  const { title: RowTitle, columns = [], className = 'form-builder-component' } = props;
  return (
    <div className={className}>
      <div className="main-title">{RowTitle}</div>
      <Row gutter={[8, 2]} wrap={true}>
        {columns.map((itemColumn: FormColumnProps, index: number) => {
          const { title: ColumnTitle, gridColumn: gridColumnGroup, fields = [] } = itemColumn;
          return (
            <Col {...(gridColumnGroup ?? { span: 24 })} key={index}>
              <div className="sub-title">{ColumnTitle}</div>
              <Row gutter={[8, 2]} wrap={true}>
                {fields.map((itemField: FormFieldProps, index: number) => {
                  const {
                    customHidden = defaultCustomHidden,
                    renderField,
                    fieldType,
                    shouldUpdate,
                    dependencies,
                    inputTextProps = {},
                    inputNumberProps = {},
                    inputPasswordProps = {},
                    textAreaProps = {},
                    checkboxProps = {},
                    switchProps = {},
                    datePickerProps = {},
                    dateRangePickerProps = {},
                    timePickerProps = {},
                    timeRangePickerProps = {},
                    selectPaginateProps = {},
                    selectProps = {},
                    uploadImageProps = {},
                    inputAddressProps = {},
                  } = itemField;

                  const formItemProps = _.omit(itemField, FormItemIgnore) ?? {};
                  const formItemWrapperProps = {
                    shouldUpdate,
                    dependencies: dependencies ?? [],
                  };

                  return (
                    <React.Fragment key={index}>
                      <Form.Item {...formItemWrapperProps} noStyle>
                        {(form) => {
                          if (renderField) {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                {renderField(form)}
                              </Col>
                            );
                          } else if (fieldType === 'inputText') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <Input placeholder="Input" {...inputTextProps} />
                                </Form.Item>
                              </Col>
                            );
                          } else if (fieldType === 'inputNumber') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <InputNumber placeholder="Input" style={{ width: '100%' }} {...inputNumberProps} />
                                </Form.Item>
                              </Col>
                            );
                          } else if (fieldType === 'inputPassword') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <Password placeholder="Input" {...inputPasswordProps} />
                                </Form.Item>
                              </Col>
                            );
                          } else if (fieldType === 'textArea') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <TextArea {...textAreaProps} />
                                </Form.Item>
                              </Col>
                            );
                          } else if (fieldType === 'checkbox') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item valuePropName="checked" {...formItemProps}>
                                  <Checkbox {...checkboxProps} />
                                </Form.Item>
                              </Col>
                            );
                          } else if (fieldType === 'switch') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <Switch {...switchProps} />
                                </Form.Item>
                              </Col>
                            );
                          } else if (fieldType === 'datePicker') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <DatePicker {...datePickerProps} />
                                </Form.Item>
                              </Col>
                            );
                          } else if (fieldType === 'dateRangePicker') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <DatePicker.RangePicker {...dateRangePickerProps} />
                                </Form.Item>
                              </Col>
                            );
                          } else if (fieldType === 'timePicker') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <TimePicker {...timePickerProps} />
                                </Form.Item>
                              </Col>
                            );
                          } else if (fieldType === 'timeRangePicker') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <TimePicker.RangePicker {...timeRangePickerProps} />
                                </Form.Item>
                              </Col>
                            );
                          } else if (fieldType === 'selectPaginate') {
                            const filter = selectPaginateProps?.customFilterRequest
                              ? selectPaginateProps?.customFilterRequest(form.getFieldsValue())
                              : selectPaginateProps?.filterOption;

                            const customPaginateKey = () => {
                              if (selectPaginateProps?.customKey) return selectPaginateProps?.customKey(form);
                              return '';
                            };

                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <SelectPaginate
                                    {...selectPaginateProps}
                                    filterRequest={filter}
                                    customKey={customPaginateKey}
                                  />
                                </Form.Item>
                              </Col>
                            );
                          } else if (fieldType === 'select') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <Select {...selectProps} />
                                </Form.Item>
                              </Col>
                            );
                          } else if (fieldType === 'uploadImage') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <UploadImage {...uploadImageProps} />
                                </Form.Item>
                              </Col>
                            );
                          } else if (fieldType === 'inputAddress') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <InputAddress {...inputAddressProps} />
                                </Form.Item>
                              </Col>
                            );
                          }
                        }}
                      </Form.Item>
                    </React.Fragment>
                  );
                })}
              </Row>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
