import React, { useEffect, useState } from 'react';
import {
  DetailBuilder,
  DetailPrint,
  PrintSignature,
  PrintTitle,
  RenderCountCurrency,
  RenderCurrency,
} from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter } from '@base-helpers';
import moment from 'moment';
import { Col, Row, Table } from 'antd';
import QRCode from 'react-qr-code';
import { getProducts } from './helpers';

export function Detail() {
  const { detailData = {}, printContent } = usePagePrint();
  const hasCode = detailData?.code;
  const [products, setProducts] = useState([]);

  const generateProduct = async (detailId) => {
    const response = await getProducts(detailId);
    setProducts(response);
  };

  // useEffect(() => {
  //   if (detailData?.detail) {
  //     generateProduct(detailData?.detail?.id);
  //   }
  // }, [detailData?.detail]);

  return (
    <DetailPrint
      onSuccessLoadData={(payload) => generateProduct(payload?.detail?.id)}
      orientation="portrait"
      printHeaderProps={{ barcode: detailData?.code, logoType: 'mjs' }}
      showHeader={true}
      paperFormat={PAPER_FORMAT.A4}
    >
      <DetailBuilder
        columns={[
          {
            fields: [
              {
                gridColumn: { span: 12 },
                renderField(form) {
                  return (
                    <PrintTitle title="Receipt" {...(hasCode ? { description: `No. ${detailData?.code}` } : {})} />
                  );
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Created Date',
                name: 'created_at',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Printed Date',
                name: 'id',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: () => moment().format('YYYY-MM-DD'),
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Status',
                name: 'status',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => stringFormatter.capitalizeEachWord(value?.split('_')?.join(' ')),
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Activity Type',
                name: 'receipt_type',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.includes('_') ? value?.replace('_', ' ') : value;
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Expected Date',
                name: 'expected_date',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },

              {
                gridColumn: { span: 4 },
                label: 'PIC',
                name: 'receiver',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? value?.name : '-';
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Receipt Date',
                name: 'receipt_date',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 8 },
                label: 'Reference',
                name: 'detail',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    const references = value?.references ?? [];
                    const code: string[] = [];
                    references?.forEach((item) => {
                      const codeTransaction = item?.code ?? item?.transaction_code;
                      if (codeTransaction) code.push(codeTransaction);
                    });
                    return code?.length >= 1 ? code.toString() : '-';
                  },
                },
              },
              {
                gridColumn: { span: 12 },
                label: 'Receipt From',
                name: 'from',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    const name = value?.name;
                    const address = value?.address;
                    return (
                      <>
                        {name && address ? (
                          <div>
                            <div>{name}</div>
                            <div>{address}</div>
                          </div>
                        ) : (
                          <div>{name}</div>
                        )}
                      </>
                    );
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Courier',
                name: 'courier',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value;
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'License Plate',
                name: 'license_plate',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value;
                  },
                },
              },

              {
                gridColumn: { span: 4 },
                label: 'SJ Greige',
                name: 'delivery_order',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value;
                  },
                },
              },
            ],
          },
        ]}
      />

      <table
        style={{
          position: 'relative',
          width: '100%',
          border: '1px solid black',
          margin: '10px 0',
        }}
      >
        <thead style={{ fontWeight: 'bold', border: '1px solid black' }}>
          <tr>
            <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>No</td>
            <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>Product Variant</td>
            <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>Tracking ID</td>
            <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>Unit</td>
            <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>UoM</td>
            <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>Qty</td>
            <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>UoM</td>
          </tr>
        </thead>
        <tbody>
          {products?.map((item, index) => {
            const number = index + 1;
            const subTotal = item?.subtotal;
            const subTotalAfterDisc = item?.subtotal_before_tax ?? 0;
            const discount = subTotal - subTotalAfterDisc;
            return (
              <tr key={index}>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '5px 7px',
                    textAlign: 'center',
                    width: '50px',
                  }}
                >
                  {number}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '5px 7px',
                  }}
                >
                  {item?.product_variant?.name ?? '-'}
                </td>

                <td
                  style={{
                    border: '1px solid black',
                    padding: '5px 7px',
                  }}
                >
                  {' '}
                  {item?.batch?.code ?? item?.lot?.code ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '5px 7px',
                    width: '50px',
                  }}
                >
                  {item?.total_unit ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '5px 7px',
                    width: '50px',
                  }}
                >
                  {item?.uom_unit?.code ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '5px 7px',
                    width: '50px',
                  }}
                >
                  {item?.total_qty ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '5px 7px',
                    width: '50px',
                  }}
                >
                  {item?.uom_qty?.code ?? '-'}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ border: '1px solid black', padding: '10px', minHeight: '70px', marginBottom: '10px' }}>
        <div style={{ marginBottom: '10px' }}>Note :</div>
        <p>{detailData?.notes}</p>
      </div>
      <Row style={{ border: '1px solid black' }}>
        <Col span={14} style={{ borderRight: '1px solid black' }}>
          <div style={{ borderBottom: '1px solid black', padding: '5px' }}>Gudang Besar</div>
          <div style={{ padding: '5px' }}>
            <PrintSignature
              signatures={[
                {
                  title: 'Receipts By',
                  name: 'Admin Gudang Besar',
                  gridColumn: { span: 8 },
                },
                {
                  title: 'Approved By',
                  name: '.....................',
                  gridColumn: { span: 8 },
                },
                {
                  title: 'Approved By',
                  name: '.....................',
                  gridColumn: { span: 8 },
                },
              ]}
            />
          </div>
        </Col>
        <Col span={10}>
          <div style={{ borderBottom: '1px solid black', padding: '5px' }}>Gudang Kecil</div>
          <div style={{ padding: '5px' }}>
            <PrintSignature
              signatures={[
                {
                  title: 'Requested By',
                  name: 'Admin Gudang Kecil',
                  gridColumn: { span: 12 },
                },
                {
                  title: 'Approved By',
                  name: '.....................',
                  gridColumn: { span: 12 },
                },
              ]}
            />
          </div>
        </Col>
      </Row>
    </DetailPrint>
  );
}
