import React from 'react';
import Barcode from 'react-barcode';

const renderBarcode = (code: string) => {
  return (
    <React.Fragment>
      <Barcode value={code ?? 'hcnjknk'} width={1} height={20} displayValue={false} />
    </React.Fragment>
  );
};

export default renderBarcode;
