import React from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderText } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter, userPrinter } from '@base-helpers';
import moment from 'moment';
import { Col, Row, Table } from 'antd';
import { configColumns, productCode, productName } from './columns';

export function Detail() {
  const { detailData = {} } = usePagePrint();

  const hasCode = detailData?.code;
  const dataSource = detailData?.purchase_request_detail_products;
  const user = userPrinter();

  const isNewDesign = true;

  const configColumnsV2 = [
    {
      title: 'Item Number',
      key: 'product_variant',
      dataIndex: 'product_variant',
      render: (item: any, row: any) => RenderText({ value: productCode(row) }),
    },
    {
      title: 'Product Name',
      key: 'product_variant',
      dataIndex: 'product_variant',
      render: (item: any, row: any) => RenderText({ value: productName(row), limitString: 15 }),
    },
    // {
    //   title: 'Proucurement Category',
    //   key: 'product_category',
    //   dataIndex: 'product_category',
    //   render: (item: any) => RenderText({ value: item?.name ? item?.name : '-' }),
    // },
    // {
    //   title: 'Legal Entity',
    //   key: 'legal_entity',
    //   dataIndex: 'legal_entity',
    //   render: (item: any) => RenderText({ value: item ? item : '-' }),
    // },
    // {
    //   title: 'Operating Unit',
    //   key: 'operating_unit',
    //   dataIndex: 'operating_unit',
    //   render: (item: any) =>
    //     RenderText({ value: detailData?.purchase_division ? detailData?.purchase_division?.code : '-' }),
    // },
    {
      title: 'Requestor',
      key: 'requestor',
      dataIndex: 'requestor',
      render: (item: any) => RenderText({ value: detailData?.requestor ? detailData?.requestor?.name : '-' }),
    },
    {
      title: 'Request Date',
      key: 'date',
      dataIndex: 'date',
      render: (item: any) =>
        RenderText({ value: detailData?.date ? dateFormatter(detailData?.date).toLocal('DD/MM/YYYY') : '-' }),
    },
    {
      title: 'Vendor',
      key: 'vendor',
      dataIndex: 'vendor',
      render: (item: any) => RenderText({ value: item ? item?.name : '-' }),
    },
    {
      title: 'Quantity',
      key: 'order_qty',
      dataIndex: 'order_qty',
      render: (item: any, row: any) => RenderText({ value: item ?? row?.request_qty ?? '-' }),
    },
    {
      title: 'Unit',
      key: 'order_uom',
      dataIndex: 'order_uom',
      render: (item: any, row: any) => RenderText({ value: item?.code ?? row?.request_uom?.code ?? '-' }),
    },
  ];
  if (isNewDesign) {
    return (
      <DetailPrint orientation="portrait" paperFormat={PAPER_FORMAT.A4}>
        <Row gutter={[8, 2]}>
          <Col span={10}>
            <div>{detailData?.company?.name}</div>
            <div style={{ fontWeight: 700 }}>{detailData?.company?.address ? detailData?.company?.address : '-'}</div>
            {/* <Row gutter={[8, 8]}>
              <Col span={4}>
                <span style={{ fontWeight: 'bold' }}>Company</span>
              </Col>
              <Col span={18}>
                <span>
                  {detailData?.company?.name} <br />
                  {detailData?.company?.address ? detailData?.company?.address : '-'}
                </span>
              </Col>
              <Col span={4}>
                <span style={{ fontWeight: 'bold' }}>Code</span>
              </Col>
              <Col span={18}>
                <span>
                  {detailData?.company?.code} <br />
                </span>
              </Col>
            </Row> */}
          </Col>
          <Col span={14}>
            <h1>Purchase Requisition</h1>
            <table style={{ border: 'none' }}>
              <tr>
                <td style={{ fontWeight: 'bold', width: '100px' }}>Printed By</td>
                <td>{user?.username ? user?.username : '-'}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold', width: '100px' }}>Created By</td>
                <td>{detailData?.creator_name ? detailData?.creator_name : '-'}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold', paddingRight: '30px' }}>Print Date</td>
                <td>{moment().format('DD/MM/YYYY')}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold', paddingRight: '30px' }}>Preparer</td>
                <td>{detailData?.requestor ? detailData?.requestor?.name : '-'}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold', paddingRight: '30px' }}>ID</td>
                <td>{detailData?.code ? detailData?.code : '-'}</td>
              </tr>
            </table>
          </Col>
          <Col span={24}>
            {/* <Table
              dataSource={dataSource}
              columns={configColumnsV2}
              pagination={false}
              size="small"
              bordered
              style={{ marginBottom: '10px', fontSize: '11px' }}
            /> */}
            <table style={{ width: '100%', marginTop: 25 }}>
              <thead style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}>
                <tr style={{ textAlign: 'left' }}>
                  <th style={{ minWidth: 70 }}>Site</th>
                  <th>Transaction Status</th>
                  <th>Item Number</th>
                  <th style={{ minWidth: 100 }}>Product Name</th>
                  <th>Requestor</th>
                  <th>Request Date</th>
                  <th>Vendor</th>
                  <th>Quantitiy</th>
                  <th>Unit</th>
                </tr>
              </thead>
              <tbody>
                {dataSource?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{RenderText({ value: detailData?.site?.code })}</td>
                      <td>{RenderText({ value: stringFormatter.capitalize(detailData?.status) })}</td>
                      <td>{RenderText({ value: productCode(data) })}</td>
                      <td>{RenderText({ value: productName(data), limitString: 25 })}</td>
                      <td>{RenderText({ value: detailData?.requestor ? detailData?.requestor?.name : '-' })}</td>
                      <td>
                        {RenderText({
                          value: detailData?.date ? dateFormatter(detailData?.date).toLocal('DD/MM/YYYY') : '-',
                        })}
                      </td>
                      <td>{RenderText({ value: data ? data?.vendor?.name : '-' })}</td>
                      <td>{RenderText({ value: data?.order_qty ?? '-' })}</td>
                      <td>{RenderText({ value: data?.unit_uom?.code ?? '-' })}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Col>
        </Row>
      </DetailPrint>
    );
  } else {
    return (
      <DetailPrint
        orientation="portrait"
        printHeaderProps={{ barcode: detailData?.code }}
        paperFormat={PAPER_FORMAT.A4}
        printSignatureProps={{
          signatures: [
            {
              gridColumn: { span: 6 },
              title: 'Created By',
              name: detailData?.creator_name ? detailData?.creator_name : '-',
            },
            {
              gridColumn: { span: 6 },
              title: 'Printed By',
              name: user?.username ? user?.username : '-',
            },
            {
              gridColumn: { span: 6 },
              title: 'Requested By',
              name: detailData?.editor_name ? detailData?.editor_name : '-',
            },
            { gridColumn: { span: 6 }, title: 'Approved By', name: '_____________' },
          ],
        }}
      >
        <DetailBuilder
          columns={[
            {
              fields: [
                {
                  gridColumn: { span: 12 },
                  renderField(form) {
                    return (
                      <PrintTitle
                        title="Purchase Request"
                        {...(hasCode ? { description: `No. ${detailData?.code}` } : {})}
                      />
                    );
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Created Date',
                  name: 'created_at',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => {
                      return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                    },
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Printed Date',
                  name: 'id',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: () => moment().format('YYYY-MM-DD'),
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Canceled Date',
                  name: 'id',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => '-',
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Status',
                  name: 'status',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => stringFormatter.capitalizeEachWord(value?.split('_')?.join(' ')),
                  },
                },
                {
                  gridColumn: { span: 4 },
                  label: 'Site',
                  name: 'site',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return value?.code;
                    },
                  },
                },
                {
                  gridColumn: { span: 4 },
                  label: 'Requestor',
                  name: 'requestor',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return value?.code;
                    },
                  },
                },
                {
                  gridColumn: { span: 4 },
                  label: 'Purchase Division',
                  name: 'purchase_division',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return value?.code;
                    },
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Expected Date',
                  name: 'expected_date',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => {
                      return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                    },
                  },
                },
                {
                  gridColumn: { span: 9 },
                  label: 'References',
                  name: 'references',
                  fieldType: 'text',
                  renderTextProps: {
                    renderType: 'multi',
                    renderValue(value) {
                      return value?.transaction_code ?? value?.code;
                    },
                  },
                },
              ],
            },
          ]}
        />
        <Table
          dataSource={dataSource}
          columns={configColumns}
          pagination={false}
          size="small"
          bordered
          style={{ marginBottom: '10px' }}
        />
      </DetailPrint>
    );
  }
}
