import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { PAPER_FORMAT, stringFormatter } from '@base-helpers';
import moment from 'moment';
import { Col, Row } from 'antd';
import { columnShift, columnsMaterial, getProductionOrder, groupingProduct } from './helpers';
import { useBarcode } from '@createnextapp/react-barcode';
import QtyPerUnit from './qty-per-unit';

export function Detail() {
  const { detailData = {}, printContent, setDetailData, type } = usePagePrint();
  const hasCode = detailData?.code;
  const [productGroup, setProductGroup] = useState([]);
  const { inputRef } = useBarcode({
    value: detailData?.code ?? '-',
    options: {
      displayValue: false,
      height: 20,
      width: 1,
      marginTop: -1,
      marginLeft: -1,
      marginBottom: -1,
    },
  });

  const generateProduct = async (materials) => {
    const result = groupingProduct(materials);
    setProductGroup(result);
  };

  const generateProductionOrder = async (payload) => {
    const result = await getProductionOrder(payload?.production_order?.id);
    setDetailData({ ...payload, production_order: result });
  };
  useEffect(() => {
    if (detailData?.materials) {
      generateProduct(detailData?.materials);
    }
  }, [detailData]);

  // useEffect(() => {
  //   if (detailData?.production_order?.id) {
  //     generateProductionOrder(detailData?.production_order?.id);
  //   }
  // }, [detailData?.production_order?.id]);
  const generateStatus = (item) => {
    let status = item?.status;
    if (item?.status === 'waiting') {
      status = stringFormatter.capitalizeEachWord(item?.status_module.replace(/_/g, ' '));
    }
    if (item?.is_problem) {
      status = 'Problem';
    }
    // return status?.split('_').join(' ');
    return stringFormatter.capitalizeEachWord(status);
  };

  return (
    <DetailPrint
      paperFormat={PAPER_FORMAT.A4}
      orientation="portrait"
      onSuccessLoadData={(payload) => generateProductionOrder(payload)}
      printSignatureProps={{
        signatures: [
          {
            title: 'Created By',
            gridColumn: { span: 12 },
            name: detailData?.creator_name ?? '_____________',
          },
          {
            title: 'Approved By',
            gridColumn: { span: 12 },
            name: '_____________',
          },
        ],
      }}
      // printHeaderProps={{ barcode: detailData?.code, logoType: 'mjs' }}
      // showHeader={true}
    >
      <div style={{ fontSize: '10px' }}>
        <Row gutter={10} style={{ lineHeight: '12px' }}>
          <Col span={6}>
            <svg ref={inputRef} />
            <div style={{ lineHeight: '12px' }}>
              <h2>Work Order - QC Result {type ? `(${stringFormatter.capitalizeEachWord(type)})` : ''}</h2>
              <h3>{detailData?.code ?? ''}</h3>
            </div>
          </Col>
        </Row>
        <Row gutter={10} style={{ lineHeight: '12px' }}>
          <Col span={6}>
            <table style={{ lineHeight: '12px' }}>
              <tr>
                <td>Status</td>
                <td style={{ fontWeight: 'bold' }}>{generateStatus(detailData) ?? ''}</td>
              </tr>
              <tr>
                <td>Start Date</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.start_date ? moment(detailData?.start_date).format('DD/MM/YYYY') : ''}
                </td>
              </tr>
              <tr>
                <td>Finished Date</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.end_date ? moment(detailData?.end_date).format('DD/MM/YYYY') : ''}
                </td>
              </tr>
            </table>
          </Col>
          <Col span={6}>
            <table style={{ lineHeight: '12px' }}>
              <tr>
                <td>Work Center</td>
                <td style={{ fontWeight: 'bold' }}>{detailData?.work_center?.name ?? ''}</td>
              </tr>
              <tr>
                <td>Process</td>
                <td style={{ fontWeight: 'bold' }}>{detailData?.process?.name ?? ''}</td>
              </tr>
              <tr>
                <td>Machine</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.machines?.map((item) => item?.code)?.join(', ') ?? ''}
                </td>
              </tr>
            </table>
          </Col>
          <Col span={6}>
            <table>
              <tr>
                <td>Prod. Order</td>
                <td style={{ fontWeight: 'bold' }}>{detailData?.production_order?.code ?? ''}</td>
              </tr>
              <tr>
                <td>Planning Date</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.expected_start_date ? moment(detailData?.expected_start_date).format('DD/MM/YYYY') : ''}
                </td>
              </tr>
              <tr>
                <td>Expected Date</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.expected_date ? moment(detailData?.expected_date).format('DD/MM/YYYY') : ''}
                </td>
              </tr>
            </table>
          </Col>
          <Col span={6}>
            <table>
              <tr>
                <td style={{ paddingRight: '5px' }}>Customer</td>
                <td style={{ fontWeight: 'bold' }}>{detailData?.contact?.name ?? ''}</td>
              </tr>
              <tr>
                <td style={{ paddingRight: '5px' }}>Customer Reference</td>
                <td style={{ fontWeight: 'bold' }}>{detailData?.contact_reference?.[0]?.name ?? ''}</td>
              </tr>
              <tr>
                <td>References</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.production_order?.production_request_references
                    ?.map((item) => item?.transaction_code)
                    ?.join(', ') ?? ''}
                </td>
              </tr>
            </table>
          </Col>
        </Row>
        <hr />
        <div style={{ lineHeight: '12px' }}>
          <DetailBuilder
            columns={[
              {
                fields: [
                  {
                    gridColumn: { span: 4 },
                    label: 'Product Order',
                    name: 'product',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return value?.name;
                      },
                    },
                  },
                  ...(detailData?.work_order_attributes?.length > 0
                    ? detailData?.work_order_attributes?.map((attr) => {
                        return {
                          gridColumn: { span: 3 },
                          label: attr?.attribute?.name,
                          name: 'id',
                          fieldType: 'text',
                          renderTextProps: {
                            renderValue: (value) => {
                              return attr?.attribute_value
                                ? `${attr?.attribute_value?.value} ${
                                    attr?.attribute_value?.unit_of_measurement?.code ?? ''
                                  }`
                                : '-';
                            },
                          },
                        };
                      })
                    : []),
                  {
                    gridColumn: { span: 3 },
                    label: 'Qty Order',
                    name: 'order_qty',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        if (value) {
                          return `${value} ${detailData?.order_qty_unit_of_measurement?.code}`;
                        }
                        return '-';
                      },
                    },
                  },
                  {
                    gridColumn: { span: 3 },
                    label: 'Order Unit Qty',
                    name: 'order_unit',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        if (value) {
                          return `${value} ${detailData?.order_unit_unit_of_measurement?.code}`;
                        }
                        return '-';
                      },
                    },
                  },
                  {
                    gridColumn: { span: 3 },
                    label: 'Order Qty/Unit',
                    name: 'order_qty_unit',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        if (value) {
                          return `${value} ${detailData?.order_qty_unit_of_measurement?.code}`;
                        }
                        return '-';
                      },
                    },
                  },
                ],
              },
            ]}
          />
          <DetailBuilder
            columns={[
              {
                fields: [
                  {
                    gridColumn: { span: 4 },
                    label: 'Product Output',
                    name: 'actual_product_output',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return value?.name ?? detailData?.product?.name;
                      },
                    },
                  },
                  ...(detailData?.variants?.length > 0
                    ? detailData?.variants?.map((attr) => {
                        return {
                          gridColumn: { span: 3 },
                          label: attr?.attribute?.name,
                          name: 'id',
                          fieldType: 'text',
                          renderTextProps: {
                            renderValue: (value) => {
                              return attr?.attribute_value
                                ? `${attr?.attribute_value?.value} ${
                                    attr?.attribute_value?.unit_of_measurement?.code ?? ''
                                  }`
                                : '-';
                            },
                          },
                        };
                      })
                    : []),
                  {
                    gridColumn: { span: 3 },
                    label: 'Qty Output',
                    name: 'output_qty',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        if (value) {
                          return `${value} ${detailData?.order_qty_unit_of_measurement?.code}`;
                        }
                        return '-';
                      },
                    },
                  },
                  {
                    gridColumn: { span: 3 },
                    label: 'Output Unit Qty',
                    name: 'output_unit',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        if (value) {
                          return `${value} ${detailData?.order_unit_unit_of_measurement?.code}`;
                        }
                        return '-';
                      },
                    },
                  },
                ],
              },
            ]}
          />
          <hr />
          <h3 style={{ fontWeight: 'bold' }}>Output</h3>
          {detailData?.work_order_shift_quality_controls?.map((dataShift, index) => {
            return (
              <div key={index} style={{ marginBottom: '10px' }}>
                <table style={{ width: '100%', fontSize: '10px', marginBottom: '5px' }}>
                  <thead>
                    <tr>
                      {columnShift?.map((column, index) => {
                        return (
                          <th key={index} style={{ border: '1px solid black', padding: '2px', ...column?.style }}>
                            {column?.title}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr key={index}>
                      {columnShift?.map((column, index) => {
                        return (
                          <td key={index} style={{ border: '1px solid black', padding: '2px' }}>
                            {column?.render(dataShift?.[column?.key], dataShift, index)}
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
                <h4 style={{ fontWeight: 'bold' }}>Qty/Unit</h4>
                <QtyPerUnit data={dataShift?.qty_unit_output?.[0]?.value ?? []} />
                <h4 style={{ fontWeight: 'bold', marginTop: '5px' }}>Scrap Qty/Unit</h4>
                <QtyPerUnit data={dataShift?.qty_unit_scrap?.[0]?.value ?? []} />
              </div>
            );
          })}
        </div>
      </div>
    </DetailPrint>
  );
}
