export const getProducts = async (packingId) => {
  try {
    // let items = [];
    const response: any = await fetch(
      `https://api.testing.erp.eigen.co.id/api/v1/inventory/transfer/packing-slips/products?detail_id=${packingId}&module=inventory&sub_module=Packing%20External&action=get`,
    );
    const result = await response.json();
    return result?.message;
  } catch (error) {
    return [];
  }
};

export const grouping = (packings): any[] => {
  let destinations = [];

  packings.forEach((packing) => {
    let destinationFound = false;
    if (packing?.send_to) destinationFound = destinations?.find((item) => item?.send_to?.id === packing?.send_to?.id);
    if (packing?.destination_site)
      destinationFound = destinations?.find((item) => item?.destination_site?.id === packing?.destination_site?.id);

    if (destinationFound) {
      destinations = destinations?.map((item) => {
        return {
          ...item,
          packing_slips: [...item?.packing_slips, packing],
        };
      });
    }

    if (!destinationFound) {
      destinations = [
        ...destinations,
        { send_to: packing?.send_to, destination_site: packing?.destination_site, packing_slips: [packing] },
      ];
    }
  });

  return destinations;
};
