import { Popover } from 'antd';
import React from 'react';
import { defaultRenderDetail, makeItemValue, makeValueForRender } from '../base-render';
import { RenderTextProps } from './render-text.entity';
import './render-text.less';

function stringLimiter(string: string, limit: number): string {
  if (!limit) return string;
  return string.length > limit ? `${string.substring(0, limit)} ...` : string;
}

export function RenderText(props: RenderTextProps) {
  const {
    value,
    renderValue,
    withColon = true,
    keyValue = 'code',
    renderType = 'single',
    classNameItem = '',
    classNameWrapper = '',
    defaultRender = defaultRenderDetail,
    styleItem,
    styleWrapper,
    usePopover = false,
    popoverContent,
    limitString,
  } = props;
  if (value === null || value === undefined) return <div>{defaultRender(value)}</div>;
  const valueForRender = makeValueForRender(value);
  const singleValueForRender = valueForRender[0];

  function Rendering() {
    if (renderType === 'single') {
      return (
        <div className={`${classNameItem}`} style={{ fontSize: '11px', color: '#000' }}>
          {renderValue ? renderValue(singleValueForRender) : stringLimiter(singleValueForRender, limitString)}
        </div>
      );
    }
    return (
      <div className={`render-text-wrapper ${classNameWrapper}`} style={styleWrapper}>
        {valueForRender.map((item, idx) => {
          const itemValue = makeItemValue(item, keyValue);
          return (
            <div key={idx} className={`${withColon ? '' : 'render-text-item-gap'} ${classNameItem}`} style={styleItem}>
              <span style={{ fontSize: '11px', color: '#000' }}>
                {renderValue ? renderValue(item) : stringLimiter(itemValue, limitString)}
              </span>
              {idx !== valueForRender.length - 1 && withColon && <span className="render-text-colon">,</span>}
            </div>
          );
        })}
      </div>
    );
  }

  function makeContentPopover(value) {
    if (popoverContent) return popoverContent(value);
    return <div style={{ maxWidth: '200px', wordWrap: 'break-word' }}> {value}</div>;
  }

  if (usePopover) {
    return (
      <Popover content={makeContentPopover(value)} placement="bottomLeft">
        <div>
          <Rendering />
        </div>
      </Popover>
    );
  }
  return (
    <div className="render-text-component">
      <Rendering />
    </div>
  );
}
