import { stringFormatter } from '@base-helpers';
import moment from 'moment';

const API_URL = process.env.REACT_APP_BASE_URL;
const API_URL_GATEWAY = process.env.REACT_APP_BASE_URL_GATEWAY;

export const getProducts = async (pickingId) => {
  try {
    // let items = [];
    const response: any = await fetch(
      `${API_URL}v1/inventory/transfer/picking-lists/products?detail_id=${pickingId}&module=inventory&sub_module=Picking&action=get`,
    );
    const result = await response.json();
    return result?.message;
  } catch (error) {
    return [];
  }
};

export const getDetailStep = async ({ step, id }) => {
  try {
    const response: any = await fetch(`${API_URL}v1/productions/production-orders/${id}/steps?step=${step}`);
    const result = await response.json();
    // let steps = [];
    console.log(result);
    return result?.message;
  } catch (error) {
    return null;
  }
};

const generateStep = async (dataGeneral) => {
  try {
    const lastStep = dataGeneral?.of_steps;
    let steps = [];
    if (lastStep > 0) {
      for (let i = 1; i <= lastStep; i++) {
        const detailStep = await getDetailStep({ step: i, id: dataGeneral?.id });
        if (detailStep) steps = [...steps, detailStep?.[0]];
      }
    }
    return steps;
  } catch (err) {
    console.log(err);
  } finally {
    //
  }
};

export const getProductionOrder = async (id) => {
  try {
    const response: any = await fetch(`${API_URL}v1/productions/production-orders/${id}`);
    const result = await response.json();
    const steps = await generateStep(result?.message);
    return result?.message
      ? {
          ...result?.message,
          steps: steps,
        }
      : result?.message;
  } catch (error) {
    return null;
  }
};

const renderBaseConversion = (row) => {
  const type = stringFormatter.capitalizeEachWord(row?.compare_attribute_type);
  const baseConversion =
    row?.compare_attribute?.name ??
    row?.compare_product_variant?.name ??
    row?.compare_product_master?.name ??
    row?.compare_product_category?.name;
  return `${type}: ${baseConversion ?? '-'}`;
};

export const columnsMaterial: any[] = [
  {
    title: 'No',
    key: 'product_variant',
    dataIndex: 'product_variant',
    render: (item, row, index) => index + 1,
  },
  {
    title: 'Product Category',
    key: 'product_category',
    dataIndex: 'product_category',
    render: (item, row) => row?.product_category?.name,
  },
  {
    title: 'Material',
    key: 'product_variant',
    dataIndex: 'product_variant',
    render: (item, row) => row?.product_variant?.name ?? row?.product_master?.name ?? row?.product_category?.name,
  },
  {
    title: 'Ratio',
    key: 'ration_qty',
    dataIndex: 'ration_qty',
    render: (item, row) => row?.ratio_qty,
  },
  {
    title: 'UoM',
    key: 'ratio_unit_of_measurement',
    dataIndex: 'ratio_unit_of_measurement',
    render: (item, row) => row?.ratio_unit_of_measurement?.code,
  },
  {
    title: '',
    key: '',
    dataIndex: '',
    render: (item, row) => 'Per',
  },
  {
    title: 'Qty',
    key: 'compare_qty',
    dataIndex: 'compare_qty',
    render: (item, row) => row?.compare_qty,
  },
  {
    title: 'UoM',
    key: 'compare_unit_of_measurement',
    dataIndex: 'compare_unit_of_measurement',
    render: (item, row) => row?.compare_unit_of_measurement?.code,
  },
  {
    title: 'Base Conversion Material',
    key: '',
    dataIndex: '',
    render: (item, row) => renderBaseConversion(row),
  },
  {
    title: 'Qty Needed',
    key: 'qty_needed',
    dataIndex: 'qty_needed',
    render: (item, row) => row?.qty_needed,
  },
  {
    title: 'UoM',
    key: 'qty_needed_unit_of_measurement',
    dataIndex: 'qty_needed_unit_of_measurement',
    render: (item, row) => item?.code,
  },
];

export const groupingProduct = (materials) => {
  let groups = [];
  materials?.forEach((item) => {
    const findCategory = groups?.find((group) => item?.product_category?.id === group?.product_category?.id);
    if (!findCategory) {
      groups = [
        ...groups,
        {
          product_category: item?.product_category,
          non_category: item?.product_category ? false : true,
          products: [],
        },
      ];
    }
  });
  groups = groups?.map((group) => {
    let products = [];
    if (group?.product_category?.id) {
      products = materials?.filter((item) => item?.product_category?.id === group?.product_category?.id);
    }
    if (group?.non_category) {
      products = materials?.filter((item) => !item?.product_category?.id);
    }
    return {
      ...group,
      products: products,
    };
  });
  return groups;
};

const generateValue = (item, data) => {
  if (item?.based_on?.toLowerCase() === 'contact_data') {
    if (item?.attribute?.name?.toLowerCase() === 'contact_code') {
      return data?.contact?.code;
    }
    if (item?.attribute?.name?.toLowerCase() === 'contact_name') {
      return data?.contact?.name;
    }
    if (item?.attribute?.name?.toLowerCase() === 'contact_reference') {
      return data?.contact_reference?.[0]?.name;
    }
    return data?.contact?.name;
  }
  if (item?.based_on?.toLowerCase() === 'product_data') {
    if (item?.attribute?.name?.toLowerCase() === 'product_code') {
      return data?.product?.code ?? data?.actual_output_product_variant?.code ?? data?.actual_product_output?.code;
    }
    if (item?.attribute?.name?.toLowerCase() === 'product_name') {
      return data?.product?.name;
    }
    return data?.product?.name;
  }
  if (item?.based_on === 'company_data') {
    return '-';
  }
};

export const generateLabelNonVariant = (labels, data) => {
  if (labels?.length > 0) {
    const newLabel = labels
      ?.map((item) => {
        if (item?.based_on !== 'variant_data') {
          const value = generateValue(item, data);
          return {
            label: item?.attribute?.name
              ? stringFormatter.capitalizeEachWord(item?.attribute?.name?.replace('_', ' '))
              : '',
            value: value,
          };
        }
        return null;
      })
      .filter(Boolean);
    // console.log({ newLabel });
    return newLabel;
  }
  return [];
};

export const generateLabelVariant = (payload) => {
  if (payload?.labels?.length > 0) {
    const newLabel = payload?.labels
      ?.map((label) => {
        if (label?.based_on === 'variant_data') {
          const value = payload?.work_order_attributes?.find((item) => item?.attribute?.id === label?.attribute?.id)
            ?.attribute_value?.value;
          const result = payload?.variants?.find((item) => item?.attribute?.id === label?.attribute?.id)
            ?.attribute_value?.value;
          return {
            label: label?.attribute?.name,
            value: value ?? '-',
            result: result ?? '-',
          };
        }
        return null;
      })
      .filter(Boolean);
    return newLabel;
  }
  return [];
};

export const generateLabel = (payload) => {
  let labels = [
    {
      label: 'PO',
      value: payload?.production_order?.sales_order?.references?.map((item) => item?.transaction_code)?.join(', '),
    },
  ];
  if (payload?.labels?.length > 0) {
    payload?.labels?.forEach((label) => {
      if (label?.based_on === 'variant_data') {
        const findValue = payload?.work_order_attributes?.find(
          (item) => item?.attribute?.id === label?.attribute?.id,
        )?.attribute_value;
        const findResult = payload?.variants?.find(
          (item) => item?.attribute?.id === label?.attribute?.id,
        )?.attribute_value;
        const newLabel = {
          label: `${label?.attribute?.name} Permintaan`,
          value: `${findValue?.value ?? label?.attribute_value?.value ?? ''} ${
            findValue?.unit_of_measurement?.code ?? label?.attribute_value?.unit_of_measurement?.code ?? ''
          }`,
        };
        const newLabelJadi = {
          label: `${label?.attribute?.name} Jadi`,
          value: `${findResult?.value ?? ''} ${findValue?.unit_of_measurement?.code ?? ''}`,
        };
        labels = [...labels, newLabel, newLabelJadi];
      }
      if (label?.based_on === 'company_data') {
        labels = [
          ...labels,
          {
            label: 'No Registrasi K3L',
            value: label?.attribute?.name,
          },
        ];
      }
      if (['product_data', 'contact_data'].includes(label?.based_on)) {
        const value = generateValue(label, payload);
        labels = [
          ...labels,
          {
            label: label?.attribute?.name
              ? stringFormatter.capitalizeEachWord(label?.attribute?.name?.replace('_', ' '))
              : stringFormatter.capitalizeEachWord(label?.based_on?.replace('_', '')),
            value: value,
          },
        ];
      }
    });
  }
  labels = [
    ...labels,
    { label: 'Yard', value: '-' },
    { label: 'Netto', value: '-' },
    { label: 'Bruto', value: '-' },
    {
      label: 'Tanggal',
      value: '-',
    },
  ];
  const halfData = labels?.length / 2;
  const columns = [
    { column: 1, labels: labels?.filter((_, index) => index < labels?.length / 2) },
    { column: 2, labels: labels?.filter((_, index) => index >= labels?.length / 2) },
  ];
  return columns;
};
