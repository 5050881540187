import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCountCurrency, RenderCurrency } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { makeModuleBarcode, PAPER_FORMAT, stringFormatter } from '@base-helpers';
import moment from 'moment';
import { Col, Row, Table } from 'antd';
import QRCode from 'react-qr-code';
import { getProducts } from './helpers';

export function Detail() {
  const { detailData = {}, printContent, type } = usePagePrint();
  const [products, setProducts] = useState([]);
  const defaultSignature: any[] = [
    {
      title: 'Hormat Kami',
      name: '',
      gridColumn: { span: 6 },
    },
  ];
  const columns: any[] = [
    {
      title: 'Item Number',
      key: 'product_variant',
      dataIndex: 'product_variant',
      render: (item, row) => row?.product_variant?.code ?? row?.product_package?.code,
    },
    {
      title: 'Description',
      key: 'product_variant',
      dataIndex: 'product_variant',
      render: (item, row) => row?.product_variant?.name ?? row?.product_package?.name,
      style: { minWidth: '300px' },
    },
    {
      title: '',
      key: 'checklist',
      dataIndex: 'checklist',
      render: (item, row) => '__',
      style: { textAlign: 'right' },
    },
    {
      title: 'Order Qty',
      key: 'total_qty',
      dataIndex: 'total_qty',
      render: (item, row) => row?.total_qty ?? row?.total_unit,
      style: { textAlign: 'right' },
    },
    {
      title: 'Unit',
      key: 'uom_qty',
      dataIndex: 'uom_qty',
      render: (item, row) => row?.uom_qty?.code ?? row?.uom_unit?.code,
    },
    // {
    //   title: 'Delivery Date',
    //   key: 'delivery_date',
    //   dataIndex: 'delivery_date',
    //   render: (item, row) =>
    //     detailData?.shipping_plan_date ? moment(detailData?.shipping_plan_date).format('DD/MM/YYYY') : '-',
    // },
    {
      title: 'Quantity',
      key: 'pick_qty',
      dataIndex: 'pick_qty',
      render: (item, row) => row?.pick_qty ?? row?.pick_unit,
      style: { textAlign: 'right' },
    },
    {
      title: 'Pick',
      key: 'picked_qty',
      dataIndex: 'picked_qty',
      render: (item, row) => row?.picked_qty ?? row?.picked_unit,
      style: { textAlign: 'right' },
    },
  ];

  const generateProduct = async (detailId) => {
    const response = await getProducts(detailId);
    setProducts(response);
  };

  useEffect(() => {
    if (detailData?.detail) {
      generateProduct(detailData?.detail?.id);
    }
  }, [detailData?.detail]);

  return (
    <DetailPrint paperFormat={PAPER_FORMAT.A4} orientation="portrait" printHeaderProps={{ showLogo: false }}>
      <div style={{ fontSize: '11px' }}>
        <Row gutter={10}>
          <Col span={8}>
            {detailData?.detail?.sales_header && (
              <React.Fragment>
                <p>
                  {detailData?.detail?.sales_header?.customer?.name} ({detailData?.detail?.sales_header?.customer?.code}
                  )
                </p>
                <p>{detailData?.detail?.sales_header?.deliver_information?.address})</p>
              </React.Fragment>
            )}
            {detailData?.transfer_order && (
              <React.Fragment>
                <p>
                  {detailData?.transfer_order?.send_to?.name} ({detailData?.transfer_order?.send_to?.code})
                </p>
                <p>{detailData?.transfer_order?.send_to?.address})</p>
              </React.Fragment>
            )}
          </Col>
          <Col span={12}>
            <table>
              <tr style={{ lineHeight: '11px' }}>
                <td colSpan={2}>
                  <h2>Picking List {type ? `(${stringFormatter.capitalizeEachWord(type)})` : ''}</h2>
                </td>
              </tr>
              <tr style={{ lineHeight: '11px' }}>
                <td>Reference</td>
                <td>
                  {detailData?.detail?.sales_header
                    ? 'Sales Order'
                    : detailData?.detail?.references?.map((item) => item?.transaction_code)?.join(', ')}
                </td>
              </tr>
              <tr style={{ lineHeight: '11px' }}>
                <td>Number</td>
                <td>{detailData?.code ?? ''}</td>
              </tr>
              <tr style={{ lineHeight: '11px' }}>
                <td style={{ paddingRight: '5px' }}>Activation Date</td>
                <td>{detailData?.created_date ? moment(detailData.created_date).format('DD/MM/YYYY hh:mm A') : '-'}</td>
              </tr>
              <tr style={{ lineHeight: '11px' }}>
                <td>End Date/Time</td>
                <td>
                  {detailData?.picked_date ? moment.utc(detailData.picked_date).format('DD/MM/YYYY hh:mm A') : '-'}
                </td>
              </tr>
            </table>
          </Col>
          <Col span={4} style={{ textAlign: 'right' }}>
            <QRCode
              value={
                makeModuleBarcode({
                  id: detailData?.id,
                  module: printContent?.module,
                  subModule: printContent?.sub_module,
                }) ?? ''
              }
              size={80}
            />
          </Col>
        </Row>
        <table style={{ width: '100%', marginTop: '5px' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid black', textAlign: 'left' }}>
              {columns?.map((column) => {
                return (
                  <th style={column?.style} key={column?.key}>
                    {column?.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {products?.map((line, index) => {
              return (
                <tr key={index}>
                  {columns?.map((column) => {
                    return (
                      <td style={column?.style} key={column?.key}>
                        {column?.render(line[column?.key], line)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr style={{ borderTop: '1px solid black' }}>
              <td colSpan={3}>Total</td>
              <td style={{ textAlign: 'right' }}>
                {products?.reduce((partialSum, a) => partialSum + (a?.total_qty > 0 ? a?.total_qty : a?.total_unit), 0)}
              </td>
              <td></td>
              <td style={{ textAlign: 'right' }}>
                {products?.reduce((partialSum, a) => partialSum + (a?.pick_qty ? a?.pick_qty : a?.pick_unit), 0)}
              </td>
              <td style={{ textAlign: 'right' }}>
                {products?.reduce((partialSum, a) => partialSum + (a?.picked_qty ? a?.picked_qty : a?.picked_unit), 0)}
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ width: '100%', marginTop: '5px' }}>
          <tr>
            <td>Hormat kami,</td>
          </tr>
        </table>
      </div>
    </DetailPrint>
  );
}
