import React from 'react';
import { DetailBuilder, DetailPrint, PrintTitle } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter, userPrinter } from '@base-helpers';
import moment from 'moment';
import { capitalize } from 'lodash';

export function Detail() {
  const { detailData = {}, type: printType } = usePagePrint();
  const type = detailData?.type;
  const user = userPrinter();
  return (
    <DetailPrint
      orientation="portrait"
      printHeaderProps={{ barcode: detailData?.code }}
      paperFormat={PAPER_FORMAT.A5}
      printSignatureProps={{
        signatures: [
          {
            gridColumn: { span: 8 },
            title: 'Sales Supervisor',
            name: detailData?.creator_name ? detailData?.creator_name : '-',
          },
          {
            gridColumn: { span: 8 },
            title: 'Finance',
            name: user?.username ? user?.username : '-',
          },
          {
            gridColumn: { span: 8 },
            title: 'Disetujui',
            name: detailData?.editor_name ? detailData?.editor_name : '-',
          },
        ],
      }}
    >
      <DetailBuilder
        columns={[
          {
            fields: [
              {
                gridColumn: { span: 24 },
                renderField(form) {
                  return (
                    <PrintTitle
                      title={`${type === 'limit' ? 'Sales Limit Request' : 'Credit limit and TOP approval form'} ${
                        printType ? `(${stringFormatter.capitalizeEachWord(printType)})` : ''
                      }`}
                    />
                  );
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Created Date',
                name: 'created_at',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Printed Date',
                name: 'id',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: () => moment().format('YYYY-MM-DD'),
                },
              },
              // {
              //   gridColumn: { span: 3 },
              //   label: 'Canceled Date',
              //   name: 'id',
              //   fieldType: 'text',
              //   renderTextProps: {
              //     renderValue: (value) => '-',
              //   },
              // },
              // {
              //   gridColumn: { span: 3 },
              //   label: 'Status',
              //   name: 'status',
              //   fieldType: 'text',
              //   renderTextProps: {
              //     renderValue: (value) => stringFormatter.capitalizeEachWord(value?.split('_')?.join(' ')),
              //   },
              // },
              // {
              //   gridColumn: { span: 3 },
              //   label: 'Site',
              //   name: 'site',
              //   fieldType: 'text',
              //   renderTextProps: {
              //     renderValue(value) {
              //       return value?.code;
              //     },
              //   },
              // },
              // {
              //   gridColumn: { span: 21 },
              //   label: 'Sales Division',
              //   name: 'sales_division',
              //   fieldType: 'text',
              //   renderTextProps: {
              //     renderValue(value) {
              //       return value?.code;
              //     },
              //   },
              // },
              {
                gridColumn: { span: 6 },
                label: 'Name',
                name: 'customer',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.name;
                  },
                },
              },
              {
                gridColumn: { span: 6 },
                label: 'Address',
                name: 'customer',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.address;
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Type',
                name: 'type',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => (value === 'limit' ? 'Limit' : value.toUpperCase()),
                },
              },
              {
                gridColumn: { span: type === 'limit' ? 4 : 0 },
                label: 'Current Limit',
                name: 'current_limit',
                fieldType: 'currency',
                renderCurrencyProps: {
                  makeValue: (value) => value?.limit,
                },
                hidden: type === 'top' ? true : false,
              },
              {
                gridColumn: { span: type === 'limit' ? 4 : 0 },
                label: 'Request Limit',
                name: 'request_limit',
                fieldType: 'currency',
                renderCurrencyProps: {
                  makeValue: (value) => value?.limit,
                },
                hidden: type === 'top' ? true : false,
              },
              {
                gridColumn: { span: type === 'top' ? 4 : 0 },
                label: 'Current ToP',
                name: 'current_top',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    const dueDate = value?.due_date;
                    const dueDateType = value?.due_date_type ? capitalize(value?.due_date_type) : null;
                    if (dueDate && dueDateType) return `${dueDate} ${dueDateType}`;
                    return dueDate ?? '-';
                  },
                },
                hidden: type === 'limit' ? true : false,
              },
              // {
              //   gridColumn: { span: type === 'top' ? 16 : 0 },
              //   label: 'Request ToP',
              //   name: 'request_top',
              //   fieldType: 'text',
              //   renderTextProps: {
              //     renderValue: (value) => {
              //       const dueDate = value?.due_date;
              //       const dueDateType = value?.due_date_type ? capitalize(value?.due_date_type) : null;
              //       if (dueDate && dueDateType) return `${dueDate} ${dueDateType}`;
              //       return dueDate ?? '-';
              //     },
              //   },
              //   hidden: type === 'limit' ? true : false,
              // },
            ],
          },
        ]}
      />

      <table style={{ width: '100%', marginTop: 20, marginBottom: 20 }}>
        <thead>
          <tr style={{ textAlign: 'left', borderBottom: '1px solid black' }}>
            <th>Month</th>
            <th>Year</th>
            <th>Minimum Amount</th>
            <th>Maximum Amount</th>
            <th>Average Amount</th>
            <th>Avg days to settle</th>
            <th># of Transaction</th>
            <th>Avg days past due</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Value</td>
            <td>Value</td>
            <td>Value</td>
            <td>Value</td>
            <td>Value</td>
            <td>Value</td>
            <td>Value</td>
            <td>Value</td>
          </tr>
        </tbody>
      </table>
    </DetailPrint>
  );
}
