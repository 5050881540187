import React from 'react';
import { Layout } from 'antd';
const { Sider: SidebarContent } = Layout;
import { MenuComponent } from '../menu';
import { useCollapsibleLayout, useSidebarThemeLayout } from '@base-hooks';
import SmallImage from '@base-images/responsive-small.png';
import LargeImage from '@base-images/responsive-large.png';

import '../styles/sidebar.less';

export default function Sidebar() {
  const [collapsed] = useCollapsibleLayout();
  const [sidebarTheme] = useSidebarThemeLayout();

  return (
    <div className="custom-sidebar-wrapper">
      <SidebarContent
        theme={sidebarTheme}
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ minHeight: '100vh' }}
        collapsedWidth={65}
      >
        <div className={`logo-wrapper ${collapsed ? 'collapsed' : 'no-collapsed'}`}>
          <img src={collapsed ? SmallImage : LargeImage} height="28" />
        </div>

        <MenuComponent
          theme={sidebarTheme}
          mode="inline"
          defaultSelectedKeys={['1']}
          style={{
            paddingRight: !collapsed ? '10px' : '0',
            paddingLeft: !collapsed ? '10px' : '0',
          }}
        />
      </SidebarContent>
    </div>
  );
}
