import React from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCurrency, RenderText } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter, userPrinter } from '@base-helpers';
import moment from 'moment';
import { Col, Descriptions, Row, Table } from 'antd';
import { productName } from '../purchase-request/columns';

function getDiscount(row: any): number {
  const subTotal = row?.subtotal;
  const subTotalAfterDisc = row?.subtotal_before_tax ?? 0;
  const discount = subTotal - subTotalAfterDisc;
  return discount;
}
export function Detail() {
  const { detailData = {} } = usePagePrint();
  console.log(detailData);
  const purchaseType = detailData?.type;
  const hasCode = detailData?.code;
  const dataSource =
    purchaseType === 'purchase_return' ? detailData?.product_line_returns : detailData?.product_line_orders;
  const isNewDesign = true;
  const user = userPrinter();
  const configColumns = [
    {
      title: 'Product',
      key: 'product',
      dataIndex: 'product',
      width: 200,
      render: (item: any, row: any) => RenderText({ value: row?.pricelist?.alias_name ?? item?.name }),
    },
    {
      title: 'Unit',
      key: 'unit',
      dataIndex: 'unit',
      render: (item: any) => RenderText({ value: item }),
    },
    {
      title: 'UoM',
      key: 'uom_unit',
      dataIndex: 'uom_unit',
      render: (item: any) => RenderText({ value: item ? item?.code : '-' }),
    },
    {
      title: 'Qty',
      key: 'qty',
      dataIndex: 'qty',
      render: (item: any) => RenderText({ value: item ? item : '-' }),
    },
    {
      title: 'UoM',
      key: 'uom_qty',
      dataIndex: 'uom_qty',
      render: (item: any) => RenderText({ value: item ? item?.code : '-' }),
    },
    {
      title: 'Price/Unit',
      key: 'price_per_unit',
      dataIndex: 'price_per_unit',
      // align: 'right',
      render: (item: any) => RenderCurrency({ value: item }),
    },
    {
      title: 'Subtotal',
      key: 'subtotal',
      dataIndex: 'subtotal',
      // align: 'right',
      render: (item: any) => RenderCurrency({ value: item }),
    },
    {
      title: 'Discount',
      key: 'subtotal_before_tax',
      dataIndex: 'subtotal_before_tax',
      // align: 'right',
      render: (item: any, row: any) => RenderCurrency({ value: getDiscount(row) }),
    },
    {
      title: 'Total Price',
      key: 'total_price_before_tax',
      dataIndex: 'total_price_before_tax',
      // align: 'right',
      render: (item: any) => RenderCurrency({ value: item }),
    },
    {
      title: 'DPP',
      key: 'subtotal_after_tax',
      dataIndex: 'subtotal_after_tax',
      // align: 'right',
      render: (item: any) => RenderCurrency({ value: item }),
    },
    {
      title: 'PPN',
      key: 'tax_value_after_tax',
      dataIndex: 'tax_value_after_tax',
      // align: 'right',
      render: (item: any) => RenderCurrency({ value: item }),
    },
    {
      title: 'Net Price',
      key: 'net_price',
      dataIndex: 'net_price',
      // align: 'right',
      render: (item: any) => RenderCurrency({ value: item }),
    },
  ];

  const vendor = detailData?.vendor;
  const vendorLabel = vendor ? `${vendor?.name} (${vendor?.code})` : '-';
  if (purchaseType === 'purchase_order' && isNewDesign) {
    return (
      <DetailPrint orientation="portrait" paperFormat={PAPER_FORMAT.A4}>
        <Row gutter={[8, 8]} style={{ paddingTop: '16px' }}>
          <Col span={12}>
            <div style={{ display: 'block', width: '90%' }}>{vendorLabel}</div>
            <div style={{ display: 'block', width: '90%', fontWeight: 'bold' }}>
              {vendor?.address ? vendor?.address : '-'}
            </div>
            <div style={{ marginTop: 20 }}>Delivery Address</div>
            <div style={{ fontWeight: 'bold' }}>
              {detailData?.deliver_information?.address ? detailData?.deliver_information?.address : '-'}
            </div>
          </Col>
          <Col span={12}>
            <h1>Purchase Order</h1>
            <table style={{ border: 'none' }}>
              <tr>
                <td style={{ fontWeight: 'bold', width: '100px' }}>Printed By</td>
                <td>{user?.username ? user?.username : '-'}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold', width: '100px' }}>Created By</td>
                <td>{detailData?.creator_name ? detailData?.creator_name : '-'}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold', width: '100px' }}>Number</td>
                <td>{detailData?.code ? detailData?.code : '-'}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold', width: '100px' }}>Date</td>
                <td>{moment().format('DD/MM/YYYY')}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold', width: '100px' }}>Delivery Terms</td>
                <td>{detailData?.invoice_information?.top ?? '-'}</td>
              </tr>
              {/* <tr>
                <td style={{ fontWeight: 'bold', paddingTop: '30px' }} colSpan={2}>
                  Delivery Address
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  {detailData?.deliver_information?.address ? detailData?.deliver_information?.address : '-'}
                </td>
              </tr> */}
            </table>
          </Col>
          <Col span={24}>
            {/* <Table
              dataSource={dataSource}
              columns={configColumnsV2}
              pagination={false}
              size="small"
              bordered
              style={{ marginBottom: '10px', fontSize: '11px' }}
            /> */}
            <table style={{ width: '100%', marginTop: '15px' }}>
              <thead style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}>
                <tr>
                  <td>Item Number</td>
                  <td>Description</td>
                  <td>Qty</td>
                  <td>Unit</td>
                  <td>Unit Price</td>
                  <td>Discount</td>
                  <td>Disc %</td>
                  <td>Amount</td>
                </tr>
              </thead>
              <tbody>
                {detailData?.product_line_orders?.map((item, index) => {
                  const subTotal = item?.total_price_before_tax;
                  const subTotalAfterDisc = item?.net_price ?? 0;
                  const discount = subTotal - subTotalAfterDisc;
                  const discountPercent = (discount / subTotal) * 100;
                  return (
                    <tr key={index}>
                      <td>{RenderText({ value: item?.pricelist?.alias_code ?? item?.product?.code })}</td>
                      <td>{RenderText({ value: item?.pricelist?.alias_name ?? item?.product?.name })}</td>
                      <td>
                        {RenderText({
                          value:
                            item?.product?.breakdown_qty || item?.uom_default?.category === 'UNIT'
                              ? item?.unit
                              : item?.qty,
                        })}
                      </td>
                      <td>
                        {RenderText({
                          value:
                            item?.product?.breakdown_qty || item?.uom_default?.category === 'UNIT'
                              ? item?.uom_unit?.code
                              : item?.uom_qty?.code,
                        })}
                      </td>
                      <td>{RenderCurrency({ value: item?.price_per_unit ? Math.ceil(item.price_per_unit) : 0 })}</td>
                      <td>{discount ? RenderCurrency({ value: Math.ceil(discount) }) : '-'}</td>
                      <td>{discountPercent ? `${+discountPercent.toFixed(2)}%` : '-'}</td>
                      <td>{RenderCurrency({ value: Math.ceil(item?.net_price ?? 0) })}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <table style={{ width: '70%', marginTop: '30px' }}>
              <thead style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}>
                <tr>
                  <td>Currency</td>
                  <td>Purchase Sub Total Amount</td>
                  <td>Total Discount</td>
                  <td>Purchase Tax</td>
                  <td>Total</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>IDR</td>
                  <td>
                    {detailData?.outcome_total?.total_dpp
                      ? RenderCurrency({ value: Math.ceil(detailData?.outcome_total?.total_dpp) })
                      : '-'}
                  </td>
                  <td>
                    {detailData?.outcome_total?.disc_before_tax
                      ? RenderCurrency({ value: Math.ceil(detailData?.outcome_total?.disc_after_tax * -1) })
                      : '-'}
                  </td>
                  <td>
                    {detailData?.outcome_total?.tax
                      ? RenderCurrency({ value: Math.ceil(detailData?.outcome_total?.tax) })
                      : '-'}
                  </td>
                  <td>
                    {detailData?.outcome_total?.total
                      ? RenderCurrency({ value: Math.ceil(detailData?.outcome_total?.total) })
                      : '-'}
                  </td>
                </tr>
              </tbody>
            </table>
            <table style={{ marginTop: 20, marginBottom: 20, width: '70%' }}>
              <thead>
                <tr style={{ textAlign: 'left' }}>
                  <th>Printed Date</th>
                  <th>Page</th>
                  <th>Transaction Status</th>
                  <th>Site</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{RenderText({ value: moment().format('DD/MM/YYYY') })}</td>
                  <td>{RenderText({ value: '1' })}</td>
                  <td>{RenderText({ value: stringFormatter.capitalize(detailData?.status) })}</td>
                  <td>{RenderText({ value: detailData?.sites?.map((site) => site?.code) })}</td>
                  <td>{RenderText({ value: detailData?.notes ? detailData?.notes : '-' })}</td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col span={8}>
            <div style={{ display: 'blcok', marginBottom: '30px' }}>
              Demikianlah purchase order kami. <br />
              Atas perhatian dan kerjasamanya, kami ucapkan terima kasih.
            </div>
            <div style={{ display: 'block' }}>
              Bandung, <br />
              Hormat Kami.
            </div>
          </Col>
        </Row>
      </DetailPrint>
    );
  } else {
    return (
      <DetailPrint
        orientation="portrait"
        // printHeaderProps={{ barcode: detailData?.code }}
        paperFormat={PAPER_FORMAT.A4}
        printSignatureProps={{
          signatures: [
            {
              gridColumn: { span: 6 },
              title: 'Created By',
              name: detailData?.creator_name ? detailData?.creator_name : '-',
            },
            {
              gridColumn: { span: 6 },
              title: 'Printed By',
              name: user?.username ? user?.username : '-',
            },
            {
              gridColumn: { span: 6 },
              title: 'Requested By',
              name: detailData?.editor_name ? detailData?.editor_name : '-',
            },
            { gridColumn: { span: 6 }, title: 'Approved By', name: '_____________' },
          ],
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <div style={{ display: 'block', width: '90%' }}>{vendorLabel}</div>
            <div style={{ display: 'block', width: '90%', fontWeight: 'bold' }}>
              {vendor?.address ? vendor?.address : '-'}
            </div>
          </Col>
          <Col span={12}>
            <h1>Purchase Return</h1>
            <table style={{ border: 'none' }}>
              <tr>
                <td style={{ fontWeight: 'bold', width: '100px' }}>Printed By</td>
                <td>{user?.username ? user?.username : '-'}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold', width: '100px' }}>Created By</td>
                <td>{detailData?.creator_name ? detailData?.creator_name : '-'}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold', width: '100px' }}>Number</td>
                <td>{detailData?.code ? detailData?.code : '-'}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold', width: '100px' }}>Date</td>
                <td>{moment().format('DD/MM/YYYY')}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold', paddingTop: '30px' }} colSpan={2}>
                  Pickup Address
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  {detailData?.pickup_information?.address ? detailData?.pickup_information?.address : '-'}
                </td>
              </tr>

              {/* <tr>
                <td style={{ fontWeight: 'bold', paddingTop: '30px' }} colSpan={2}>
                  Invoice Address
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  {detailData?.invoice_information?.address ? detailData?.invoice_information?.address : '-'}
                </td>
              </tr> */}
            </table>
          </Col>
        </Row>
        {/* <DetailBuilder
          columns={[
            {
              fields: [
                {
                  gridColumn: { span: 12 },
                  renderField(form) {
                    return (
                      <PrintTitle
                        title={purchaseType === 'purchase_return' ? 'Purchase Return' : 'Purchase Order'}
                        {...(hasCode ? { description: `No. ${detailData?.code}` } : {})}
                      />
                    );
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Created Date',
                  name: 'created_at',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => {
                      return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                    },
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Printed Date',
                  name: 'id',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: () => moment().format('YYYY-MM-DD'),
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Canceled Date',
                  name: 'id',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => '-',
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Status',
                  name: 'status',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => stringFormatter.capitalizeEachWord(value?.split('_')?.join(' ')),
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Site',
                  name: 'sites',
                  fieldType: 'text',
                  renderTextProps: {
                    renderType: 'multi',
                    renderValue(value) {
                      return value?.code;
                    },
                  },
                },
                {
                  gridColumn: { span: 9 },
                  label: 'Purchase Division',
                  name: 'purchase_division',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return value?.code;
                    },
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Expected Date',
                  name: purchaseType === 'purchase_return' ? 'pickup_information' : 'deliver_information',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => {
                      return value?.expected_date ? dateFormatter(value?.expected_date).toLocal('YYYY-MM-DD') : '-';
                    },
                  },
                },
                {
                  gridColumn: { span: 9 },
                  label: 'References',
                  name: 'references',
                  fieldType: 'text',
                  renderTextProps: {
                    renderType: 'multi',
                    renderValue(value) {
                      return value?.transaction_code ?? value?.code;
                    },
                  },
                },

                {
                  gridColumn: { span: 24 },
                  label: 'Vendor',
                  name: 'vendor',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return value?.name;
                    },
                  },
                },
                {
                  gridColumn: { span: 12 },
                  label: purchaseType === 'purchase_return' ? 'Picukp Address' : 'Receive Address',
                  name: purchaseType === 'purchase_return' ? 'pickup_information' : 'deliver_information',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return value?.address ?? '-';
                    },
                  },
                },
                {
                  gridColumn: { span: 12 },
                  label: 'Invoice Address',
                  name: 'invoice_information',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return value?.address ?? '-';
                    },
                  },
                },
                {
                  gridColumn: { span: 4 },
                  label: 'Location',
                  name: purchaseType === 'purchase_return' ? 'pickup_information' : 'deliver_information',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return value?.location?.code ?? '-';
                    },
                  },
                },
                {
                  gridColumn: { span: 8 },
                  label: 'Floor',
                  name: purchaseType === 'purchase_return' ? 'pickup_information' : 'deliver_information',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return value?.floor?.code ?? '-';
                    },
                  },
                },
                {
                  gridColumn: { span: 4 },
                  label: 'Location',
                  name: 'invoice_information',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return value?.location?.code ?? '-';
                    },
                  },
                },
                {
                  gridColumn: { span: 8 },
                  label: 'Floor',
                  name: 'invoice_information',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return value?.floor?.code ?? '-';
                    },
                  },
                },
              ],
            },
          ]}
        /> */}
        <table style={{ width: '100%', marginTop: '15px', marginBottom: '15px' }}>
          <thead style={{ borderBottom: '1px solid #000000', fontWeight: 'bold' }}>
            <tr>
              <td>Product</td>
              <td>Unit</td>
              <td>UoM</td>
              <td>Qty</td>
              <td>UoM</td>
              <td>Price/Unit</td>
              <td>Discount</td>
              <td>Disc %</td>
              <td>Amount</td>
            </tr>
          </thead>
          <tbody>
            {detailData?.product_line_returns?.map((item, index) => {
              const subTotal = item?.subtotal;
              const subTotalAfterDisc = item?.subtotal_before_tax ?? 0;
              const discount = subTotal - subTotalAfterDisc;
              const discountPercent = (discount / subTotal) * 100;
              return (
                <tr key={index}>
                  <td>{RenderText({ value: item?.pricelist?.alias_name ?? item?.product?.name })}</td>
                  <td>{item?.unit ? item?.unit : '-'}</td>
                  <td>{item?.uom_unit ? item?.uom_unit?.code : '-'}</td>
                  <td>{item?.qty ? item?.qty : '-'}</td>
                  <td>{item?.uom_qty ? item?.uom_qty?.code : '-'}</td>
                  <td>{RenderCurrency({ value: Math.ceil(item?.price_per_unit ?? 0) })}</td>
                  <td>{discount ? RenderCurrency({ value: Math.ceil(discount) }) : '-'}</td>
                  <td>{discountPercent ? `${discountPercent.toFixed(2)}%` : ''}</td>
                  <td>{RenderCurrency({ value: Math.ceil(item?.subtotal_after_tax) })}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* <Table
          dataSource={dataSource}
          columns={configColumns}
          pagination={false}
          size="small"
          bordered
          style={{ marginBottom: '10px' }}
        /> */}

        <Row gutter={[8, 8]} style={{ marginBottom: '10px' }}>
          <Col span={14}>
            <div style={{ border: '1px solid #f0f0f0', padding: '20px', height: '100%' }}>
              <div style={{ display: 'block', fontWeight: 'bold', marginBottom: '10px' }}>Note :</div>
              {detailData?.notes ? detailData?.notes : '-'}
            </div>
          </Col>
          <Col span={10}>
            <Descriptions bordered size="small">
              <Descriptions.Item label="Sub Total" span={24}>
                <span style={{ textAlign: 'right' }}>
                  {RenderCurrency({ value: Math.ceil(detailData?.outcome_total?.total_dpp ?? 0) })}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Total Discount" span={24}>
                <span style={{ textAlign: 'right' }}>
                  {RenderCurrency({
                    value: Math.ceil(
                      detailData?.outcome_total?.disc_after_tax ? detailData?.outcome_total?.disc_after_tax * -1 : 0,
                    ),
                  })}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Tax" span={24}>
                <span style={{ textAlign: 'right' }}>
                  {RenderCurrency({ value: Math.ceil(detailData?.outcome_total?.tax ?? 0) })}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Total" span={24}>
                <span style={{ textAlign: 'right' }}>
                  {RenderCurrency({ value: Math.ceil(detailData?.outcome_total?.total ?? 0) })}
                </span>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </DetailPrint>
    );
  }
}
