import React from 'react';
import { WebUrlData } from '@base-configs';
import { BaseMenuEntity } from '@base-entities';
import { Buildings, Cube, MapPin, Package, Receipt, Scales, Tag, Truck, UserRectangle } from 'phosphor-react';

export const DataMenu: BaseMenuEntity[] = [
  {
    label: 'Company',
    icon: <Buildings size={16} />,
    children: [
      {
        items: [
          { label: 'Company', key: WebUrlData.companies, path: WebUrlData.companies },
          { label: 'Branch', key: WebUrlData.branches, path: WebUrlData.branches },
          { label: 'Site', key: WebUrlData.sites, path: WebUrlData.sites },
          { label: 'Location', key: WebUrlData.locations, path: WebUrlData.locations },
          { label: 'Legality', key: WebUrlData.legalities, path: WebUrlData.legalities },
          { label: 'User', key: WebUrlData.users, path: WebUrlData.users },
          { label: 'User Category', key: WebUrlData.user_categories, path: WebUrlData.user_categories },
          { label: 'Approval', key: WebUrlData.approvals, path: WebUrlData.approvals },
          { label: 'Printer', key: WebUrlData.printers, path: WebUrlData.printers },
        ],
      },
    ],
  },
  {
    label: 'Contact',
    icon: <UserRectangle size={16} />,
    children: [
      {
        items: [
          { label: 'Contact', key: WebUrlData.contacts, path: WebUrlData.contacts },
          { label: 'Bank', key: WebUrlData.banks, path: WebUrlData.banks },
          { label: 'Project Type', key: WebUrlData.project_types, path: WebUrlData.project_types },
        ],
      },
    ],
  },
  {
    label: 'Sales',
    icon: <Tag size={16} />,
    children: [
      {
        items: [
          { label: 'Customer', key: WebUrlData.sales_customers, path: WebUrlData.sales_customers },
          { label: 'Sales Team', key: WebUrlData.sales_teams, path: WebUrlData.sales_teams },
          { label: 'Basic Prices', key: WebUrlData.sales_basic_prices, path: WebUrlData.sales_basic_prices },
          {
            label: 'Customized Prices',
            key: WebUrlData.sales_customized_pricing,
            path: WebUrlData.sales_customized_pricing,
          },
          { label: 'Promotion', key: WebUrlData.sales_promotions, path: WebUrlData.sales_promotions },
          { label: 'Sales Order', key: WebUrlData.sales_orders, path: WebUrlData.sales_orders },
          {
            label: 'Exchange Product',
            key: WebUrlData.sales_exchange_products,
            path: WebUrlData.sales_exchange_products,
          },
          {
            label: 'Limit & ToP Request',
            key: WebUrlData.sales_limit_top_request,
            path: WebUrlData.sales_limit_top_request,
          },
          { label: 'Customer Group', key: WebUrlData.sales_customer_groups, path: WebUrlData.sales_customer_groups },
          { label: 'Sales Division', key: WebUrlData.sales_divisions, path: WebUrlData.sales_divisions },
          { label: 'Sales Area', key: WebUrlData.sales_areas, path: WebUrlData.sales_areas },
          { label: 'Sales Limit', key: WebUrlData.sales_limits, path: WebUrlData.sales_limits },
          { label: 'Term of Payment', key: WebUrlData.sales_top, path: WebUrlData.sales_top },
          { label: 'Sales Taxes', key: WebUrlData.sales_taxes, path: WebUrlData.sales_taxes },
          { label: 'Sales Setting', key: WebUrlData.sales_settings, path: WebUrlData.sales_settings },
        ],
      },
    ],
  },
  {
    label: 'Purchase',
    icon: <Receipt size={16} />,
    children: [
      {
        items: [
          {
            label: 'Vendor',
            key: WebUrlData.purchase_vendors,
            path: WebUrlData.purchase_vendors,
          },
          {
            label: 'Price List',
            key: WebUrlData.purchase_price_list,
            path: WebUrlData.purchase_price_list,
          },
          {
            label: 'Customized Prices',
            key: WebUrlData.purchase_customized_pricing,
            path: WebUrlData.purchase_customized_pricing,
          },
          { label: 'Promotion', key: WebUrlData.purchase_promotions, path: WebUrlData.purchase_promotions },
          { label: 'Purchase Order', key: WebUrlData.purchase_orders, path: WebUrlData.purchase_orders },
          { label: 'Purchase Request', key: WebUrlData.purchase_request, path: WebUrlData.purchase_request },
          { label: 'Vendor Group', key: WebUrlData.purchase_vendor_groups, path: WebUrlData.purchase_vendor_groups },
          { label: 'Purchase Division', key: WebUrlData.purchase_divisions, path: WebUrlData.purchase_divisions },
          { label: 'Purchase Taxes', key: WebUrlData.purchase_taxes, path: WebUrlData.purchase_taxes },
          { label: 'Purchase Setting', key: WebUrlData.purchase_settings, path: WebUrlData.purchase_settings },
        ],
      },
    ],
  },
  {
    label: 'Inventory',
    icon: <Cube size={16} />,
    children: [
      {
        items: [
          {
            label: 'Product Master',
            key: WebUrlData.inventory_product_masters,
            path: WebUrlData.inventory_product_masters,
          },
          {
            label: 'Product Variant',
            key: WebUrlData.inventory_product_variants,
            path: WebUrlData.inventory_product_variants,
          },
          {
            label: 'Product Package',
            key: WebUrlData.inventory_product_packages,
            path: WebUrlData.inventory_product_packages,
          },
          {
            label: 'Picking',
            key: WebUrlData.inventory_pickings,
            path: WebUrlData.inventory_pickings,
          },
          {
            label: 'Packing Internal',
            key: WebUrlData.inventory_packing_internal,
            path: WebUrlData.inventory_packing_internal,
          },
          {
            label: 'Packing External',
            key: WebUrlData.inventory_packing_external,
            path: WebUrlData.inventory_packing_external,
          },
          {
            label: 'Shipping Order',
            key: WebUrlData.inventory_shipping_orders,
            path: WebUrlData.inventory_shipping_orders,
          },
          {
            label: 'Receipt Internal',
            key: WebUrlData.inventory_receipt_internal,
            path: WebUrlData.inventory_receipt_internal,
          },
          {
            label: 'Receipt External',
            key: WebUrlData.inventory_receipt_external,
            path: WebUrlData.inventory_receipt_external,
          },
          {
            label: 'Transfer Order',
            key: WebUrlData.inventory_transfer_orders,
            path: WebUrlData.inventory_transfer_orders,
          },
          {
            label: 'Pack/Unpack Order',
            key: WebUrlData.inventory_pack_unpack_orders,
            path: WebUrlData.inventory_pack_unpack_orders,
          },
          {
            label: 'Scrap',
            key: WebUrlData.inventory_scraps,
            path: WebUrlData.inventory_scraps,
          },
          {
            label: 'Stock Opname',
            key: WebUrlData.inventory_stock_opname,
            path: WebUrlData.inventory_stock_opname,
          },
          {
            label: 'Brand',
            key: WebUrlData.inventory_brands,
            path: WebUrlData.inventory_brands,
          },
          {
            label: 'Attribute',
            key: WebUrlData.inventory_attributes,
            path: WebUrlData.inventory_attributes,
          },
          {
            label: 'Attribute Group',
            key: WebUrlData.inventory_attribute_groups,
            path: WebUrlData.inventory_attribute_groups,
          },
          {
            label: 'Attribute Value',
            key: WebUrlData.inventory_attribute_values,
            path: WebUrlData.inventory_attribute_values,
          },
          {
            label: 'UoM',
            key: WebUrlData.inventory_uom,
            path: WebUrlData.inventory_uom,
          },
          {
            label: 'Product Category',
            key: WebUrlData.inventory_product_categories,
            path: WebUrlData.inventory_product_categories,
          },
          {
            label: 'Product Placement and Rule',
            key: WebUrlData.inventory_product_placement_rules,
            path: WebUrlData.inventory_product_placement_rules,
          },
        ],
      },
    ],
  },
  {
    label: 'Logistics',
    icon: <Truck size={16} />,
    children: [
      {
        items: [
          { label: 'Vehicle', key: WebUrlData.logistic_vehicles, path: WebUrlData.logistic_vehicles },
          { label: 'Driver and Crew', key: WebUrlData.logistic_driver_crews, path: WebUrlData.logistic_driver_crews },
          {
            label: 'Transfer Allowance',
            key: WebUrlData.logistic_travel_allowances,
            path: WebUrlData.logistic_travel_allowances,
          },
          { label: 'Vehicle Type', key: WebUrlData.logistic_vehicle_type, path: WebUrlData.logistic_vehicle_type },
          {
            label: 'Legalities',
            key: WebUrlData.logistic_vehicle_legalities,
            path: WebUrlData.logistic_vehicle_legalities,
          },
        ],
      },
    ],
  },
  {
    label: 'Production',
    icon: <Package size={16} />,
    children: [
      {
        items: [
          {
            label: 'Bill of Material',
            key: WebUrlData.production_bill_of_materials,
            path: WebUrlData.production_bill_of_materials,
          },
          { label: 'Production Team', key: WebUrlData.production_teams, path: WebUrlData.production_teams },
          {
            label: 'Shift Planning',
            key: WebUrlData.production_shift_plannings,
            path: WebUrlData.production_shift_plannings,
          },
          { label: 'Production Request', key: WebUrlData.production_request, path: WebUrlData.production_request },
          { label: 'Production Order', key: WebUrlData.production_orders, path: WebUrlData.production_orders },
          { label: 'Production Scrap', key: WebUrlData.production_scraps, path: WebUrlData.production_scraps },
          { label: 'Production Shift', key: WebUrlData.production_shifts, path: WebUrlData.production_shifts },
          {
            label: 'Machine Type',
            key: WebUrlData.production_machine_types,
            path: WebUrlData.production_machine_types,
          },
          { label: 'Machine', key: WebUrlData.production_machines, path: WebUrlData.production_machines },
          { label: 'Production Process', key: WebUrlData.production_process, path: WebUrlData.production_process },
          { label: 'Work Center', key: WebUrlData.production_work_centers, path: WebUrlData.production_work_centers },
          { label: 'Production Route', key: WebUrlData.production_routes, path: WebUrlData.production_routes },
          {
            label: 'Machine Attribute',
            key: WebUrlData.production_machine_attributes,
            path: WebUrlData.production_machine_attributes,
          },
          {
            label: 'Machine Attribute Value',
            key: WebUrlData.production_machine_attribute_values,
            path: WebUrlData.production_machine_attribute_values,
          },
          {
            label: 'Bill of Material Category',
            key: WebUrlData.production_bill_of_material_categories,
            path: WebUrlData.production_bill_of_material_categories,
          },
        ],
      },
    ],
  },
  {
    label: 'Accounting',
    icon: <Scales size={16} />,
    children: [
      {
        items: [
          { label: 'Asset', key: WebUrlData.accounting_assets, path: WebUrlData.accounting_assets },
          { label: 'Journal', key: WebUrlData.accounting_journals, path: WebUrlData.accounting_journals },
          {
            label: 'Cost of Goods',
            key: WebUrlData.accounting_cost_of_goods,
            path: WebUrlData.accounting_cost_of_goods,
          },
          {
            label: 'Sales Invoice',
            key: WebUrlData.accounting_sales_invoices,
            path: WebUrlData.accounting_sales_invoices,
          },
          {
            label: 'Sales Invoice Bundling',
            key: WebUrlData.accounting_sales_invoice_bundlings,
            path: WebUrlData.accounting_sales_invoice_bundlings,
          },
          {
            label: 'Sales Payment',
            key: WebUrlData.accounting_sales_payments,
            path: WebUrlData.accounting_sales_payments,
          },
          {
            label: 'Sales Tax Bill',
            key: WebUrlData.accounting_sales_tax_bills,
            path: WebUrlData.accounting_sales_tax_bills,
          },
          {
            label: 'Purchase Invoice',
            key: WebUrlData.accounting_purchase_invoices,
            path: WebUrlData.accounting_purchase_invoices,
          },
          {
            label: 'Purchase Payment',
            key: WebUrlData.accounting_purchase_payments,
            path: WebUrlData.accounting_purchase_payments,
          },
          {
            label: 'Purchase Tax Bill',
            key: WebUrlData.accounting_purchase_tax_bills,
            path: WebUrlData.accounting_purchase_tax_bills,
          },
          {
            label: 'Cash and Bank',
            key: WebUrlData.accounting_cash_and_banks,
            path: WebUrlData.accounting_cash_and_banks,
          },
          { label: 'Bank Bill', key: WebUrlData.accounting_bank_bills, path: WebUrlData.accounting_bank_bills },
          {
            label: 'Bank Reconciliation',
            key: WebUrlData.accounting_bank_reconciliations,
            path: WebUrlData.accounting_bank_reconciliations,
          },
          {
            label: 'Journal Entries',
            key: WebUrlData.accounting_journal_entries,
            path: WebUrlData.accounting_journal_entries,
          },
          {
            label: 'Chart of Account',
            key: WebUrlData.accounting_chart_of_accounts,
            path: WebUrlData.accounting_chart_of_accounts,
          },
          {
            label: 'Chart of Account Label',
            key: WebUrlData.accounting_chart_of_account_labels,
            path: WebUrlData.accounting_chart_of_account_labels,
          },
          {
            label: 'Partner Account',
            key: WebUrlData.accounting_partner_accounts,
            path: WebUrlData.accounting_partner_accounts,
          },
          {
            label: 'Product Account',
            key: WebUrlData.accounting_product_accounts,
            path: WebUrlData.accounting_product_accounts,
          },
          {
            label: 'Bank Account',
            key: WebUrlData.accounting_bank_accounts,
            path: WebUrlData.accounting_bank_accounts,
          },
          {
            label: 'Cash Account',
            key: WebUrlData.accounting_cash_accounts,
            path: WebUrlData.accounting_cash_accounts,
          },
          { label: 'Asset Type', key: WebUrlData.accounting_asset_types, path: WebUrlData.accounting_asset_types },
        ],
      },
    ],
  },
];
