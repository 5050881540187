export const ApiUrlData = {
  sales_basic_price: 'v1/pricings/sales/basic-price',
  sales_customer_group: 'v1/sales/customer-groups',

  users: 'v1/users',
  user_upload: 'v1/users/upload',
  logs: 'v1/logs',
  headquarter: 'v1/headquarter',
  region: 'v1/region',
  sub_region: 'v1/sub-region',
  user_login: 'v1/users/login',
  user_login_token: 'v1/users/login-token',
  category: 'v1/category',
  email_template: 'v1/email-template',
};
