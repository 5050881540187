import { atom, useRecoilState } from 'recoil';

const sidebarLayoutRecoil = atom<boolean>({
  key: 'sidebarLayoutRecoil',
  default: false,
});

export function useShowSidebarLayout(): any {
  const [showSidebar, setShowSidebar] = useRecoilState(sidebarLayoutRecoil);
  return [showSidebar, setShowSidebar];
}
