import React from 'react';
import { PagePrintProvider } from '@base-providers';
import { PagePrintController } from '@base-components';
import { Detail } from './detail';
import { DataTable } from './data-table';

export function PackingSlipMJS(props: any) {
  return (
    <PagePrintProvider printContent={props}>
      <PagePrintController PageIndex={DataTable} PageDetail={Detail} />
    </PagePrintProvider>
  );
}
