import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { makeCommonDataSource } from '@base-factories';
import { usePagePrint } from '@base-hooks';
import { Button, Card, Form, Spin, Tag } from 'antd';
import { PrintHeader, PrintHeaderProps } from '../print-header';
import { PrintSignature, PrintSignatureProps } from '../print-signature';
import { PrintTitle, PrintTitleProps } from '../print-title';

import { Document, Page } from 'react-pdf';
import { divide, typeOf } from 'mathjs';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { useRecoilState } from 'recoil';
import { listFilePrint } from '@pages/multiple-print/state';
import { MEDIA_SIZE, PAPER_FORMAT, PAPER_SIZE, stringFormatter } from '@base-helpers';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

import { LoadingOutlined, FilePdfOutlined } from '@ant-design/icons';
import { htmlFooterContent, PrintFooter } from '../print-footer';
import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons';
export interface DetailPrintProps {
  children?: React.ReactNode;
  printSignatureProps?: PrintSignatureProps;
  printTitleProps?: PrintTitleProps;
  printHeaderProps?: PrintHeaderProps;
  paperFormat?: PAPER_FORMAT;
  orientation?: 'landscape' | 'portrait';
  showHeader?: boolean;
  onSuccessLoadData?: (data: any) => void;
}

export function DetailPrint(props: DetailPrintProps) {
  const {
    children,
    printTitleProps,
    printSignatureProps,
    printHeaderProps,
    paperFormat = 'A4',
    orientation = 'landscape',
    showHeader = false,
    onSuccessLoadData,
  } = props;
  const { id, apiUrl, subModule, setDetailData, code, showPreviewDetail, fileName, printer } = usePagePrint();
  const [listFilePrintRecoil, setListFilePrintRecoil] = useRecoilState(listFilePrint);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [isContentReady, setIsContentReady] = useState(false);
  const [file, setFile] = useState(undefined);

  const [sendingStatus, setSendingStatus] = useState('failed');

  const contentRef: any = useRef();
  const footerContentRef: any = useRef();

  const dataSource = makeCommonDataSource({ apiUrl });
  const generateDataSource = makeCommonDataSource({
    apiUrl: '/generate',
    baseUrl: process.env.REACT_APP_BASE_URL_GENERATE_PDF,
  });

  async function updatePrintFile(id, file) {
    // localStorage.setItem(`print-file-${id}`, JSON.stringify(file));
    // localStorage.setItem(`print-media-file-${id}`, MEDIA_SIZE[paperFormat]);
  }

  async function updateConfig(config) {
    localStorage.setItem(`print-config-${id}`, JSON.stringify(config));
  }

  async function handleGetData() {
    const url = `${process.env.REACT_APP_BASE_URL}${apiUrl?.split('?')[0]}`;
    setLoading(true);
    setIsContentReady(false);
    await dataSource.handleCustomRequest({
      paramRequest: {
        url,
        method: 'GET',
      },
      async onSuccess({ response }: any) {
        const isArray = typeOf(response) === 'Array';
        const payload = !isArray ? response : {};
        setLoading(false);
        setDetailData(payload);
        form.setFieldsValue(payload);
        if (onSuccessLoadData) await onSuccessLoadData(payload);
        setIsContentReady(true);
      },
      onFailed({ message }: any) {
        setLoading(false);
        setIsContentReady(false);
        form.resetFields();
      },
    });
  }

  useLayoutEffect(() => {
    if (apiUrl) handleGetData();
  }, [apiUrl]);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  async function handleAfterPrint() {
    if (id) {
      await dataSource.handleCustomRequest({
        paramRequest: {
          method: 'PATCH',
          url: `${process.env.REACT_APP_BASE_URL}v1/print/request/copy/${id}/count`,
        },
        async onSuccess({ response }: any) {
          console.log('set log success');
        },
        async onFailed({ message }: any) {
          console.log('set log failed');
        },
      });
    }
  }

  async function handlePrint(file) {
    const media = MEDIA_SIZE[paperFormat];
    const printerCode = printer?.code;
    if (printerCode && file) {
      const formData: FormData = new FormData();
      let newFile;
      await fetch(file)
        .then((res) => res.blob())
        .then((res) => (newFile = res));
      formData.append('file', newFile);
      formData.append('media', media);
      await dataSource.handleCustomRequest({
        paramRequest: {
          method: 'POST',
          url: `${process.env.REACT_APP_BASE_URL}v1/print/request/${printerCode}/print`,
          data: formData,
        },
        async onSuccess({ response }: any) {
          setSendingStatus('success');
        },
        async onFailed({ message }: any) {
          setSendingStatus('failed');
        },
      });
      await handleAfterPrint();
    }
  }

  async function generatePdf(content, footerContent) {
    setLoadingGenerate(true);
    const payload = {
      ...PAPER_SIZE[paperFormat],
      content,
      orientation,
      // footerContent: htmlFooterContent,
      footerContent: `<div></div>`,
      margin: {
        top: '5px',
        left: '5px',
        right: '5px',
        bottom: '5px',
      },
    };
    await generateDataSource.handleCreate(payload, {
      async onSuccess({ response }: any) {
        setIsContentReady(false);
        setLoadingGenerate(false);
        setFile(response.file);
        handlePrint(response.file);
        await updatePrintFile(id, response.file);
      },
      async onFailed({ message }: any) {
        setIsContentReady(false);
        setLoadingGenerate(false);
        await updatePrintFile(id, null);
      },
    });
  }

  useEffect(() => {
    if (isContentReady) {
      setTimeout(() => {
        const config = {
          media: MEDIA_SIZE[paperFormat],
          pdfConfig: {
            ...PAPER_SIZE[paperFormat],
            content: contentRef?.current?.innerHTML,
            orientation,
            // footerContent: htmlFooterContent,
            footerContent: `<div></div>`,
            margin: {
              top: '5px',
              left: '5px',
              right: '5px',
              bottom: '5px',
            },
          },
        };
        updateConfig(config);
        if (showPreviewDetail) generatePdf(contentRef?.current?.innerHTML, footerContentRef?.current?.innerHTML);
      }, 500);
    }
  }, [isContentReady]);

  return (
    <div>
      <div style={{ display: 'none' }}>
        <div ref={contentRef}>
          <div
            id={id}
            className="print-content-wrapper"
            style={{ padding: '10px', paddingTop: '20px', paddingRight: '20px', paddingLeft: '20px' }}
          >
            {showHeader && <PrintHeader {...(printHeaderProps ?? {})} />}
            {printTitleProps && <PrintTitle {...(printTitleProps ?? {})} />}
            <Form layout="vertical" form={form}>
              {children}
            </Form>
            <PrintSignature {...(printSignatureProps ?? {})} />
          </div>
        </div>
        <div ref={footerContentRef}>
          <PrintFooter />
        </div>
      </div>
      <Card
        title={`${stringFormatter.capitalizeEachWord(subModule)} ${code ? code : ''}`}
        extra={
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 20 }}>
              <span style={{ marginLeft: 5 }}>Printer Code</span>
              <span style={{ marginLeft: 5 }}>:</span>
              <span style={{ marginLeft: 5 }}>{printer.code}</span>
            </div>
            <div style={{ marginRight: 20 }}>
              <span style={{ marginLeft: 5 }}>Printer Name</span>
              <span style={{ marginLeft: 5 }}>:</span>
              <span style={{ marginLeft: 5 }}>{printer.name}</span>
            </div>

            <div>
              {(loading || loadingGenerate) && (
                <Tag style={{ marginRight: 20 }} icon={<SyncOutlined spin />} color="processing">
                  processing
                </Tag>
              )}
              {!loading && !loadingGenerate && (
                <Tag style={{ marginRight: 20 }} icon={<CheckCircleOutlined />} color={sendingStatus}>
                  {sendingStatus === 'success' ? 'Sending' : 'Failed to print'}
                </Tag>
              )}
            </div>
          </div>
        }
      >
        <div>
          {loading && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Spin indicator={<LoadingOutlined />} /> Fetching data..
            </div>
          )}
          {loadingGenerate && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Spin indicator={<LoadingOutlined />} /> Generating file..
            </div>
          )}
        </div>
        {!file && !loading && !loadingGenerate && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No file to show.</div>
        )}
        {file && !loading && !loadingGenerate && showPreviewDetail && (
          <div>
            <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
              <Page size={paperFormat} pageNumber={pageNumber} />
            </Document>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div>
                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                  Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                </div>
                <Button size="small" style={{ marginRight: '5px' }} disabled={pageNumber <= 1} onClick={previousPage}>
                  Prev
                </Button>
                <Button size="small" style={{ marginLeft: '5px' }} disabled={pageNumber >= numPages} onClick={nextPage}>
                  Next
                </Button>
              </div>
            </div>
          </div>
        )}
        {file && !loading && !loadingGenerate && !showPreviewDetail && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FilePdfOutlined style={{ fontSize: '20px' }} />
            <div style={{ marginLeft: '5px', fontSize: '12px' }}>{fileName}</div>
          </div>
        )}
      </Card>
    </div>
  );
}
