export * from './media-size';

export enum MEDIA_SIZE {
  A0 = 'iso_a0_841x1189mm',
  A1 = 'iso_a1_594x841mm',
  A2 = 'iso_a2_420x594mm',
  A3 = 'iso_a3_297x420mm',
  A4 = 'iso_a4_210x297mm',
  A5 = 'iso_a5_148x210mm',
  A6 = 'iso_a6_105x148mm',
  A7 = 'iso_a7_74x105mm',
  A8 = 'iso_a8_52x74mm',

  B0 = 'B0',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  B4 = 'B4',
  B5 = 'B5',
  B6 = 'B6',

  F0 = 'F0',
  F1 = 'F1',
  F2 = 'F2',
  F3 = 'F3',
  F4 = 'om_folio_210x330mm',
  F5 = 'F5',
  F6 = 'F6',
  F7 = 'F7',
  F8 = 'F8',
  F9 = 'F9',
  F10 = 'F10',

  //label work order
  LABEL_17 = 'LABEL_17',
  LABEL_20 = 'LABEL_20',
  LABEL_10 = 'LABEL_10',
  LABEL_8 = 'LABEL_8',

  //packing mjs
  PACKING_MJS = 'PACKING_MJS',
}

export enum PAPER_FORMAT {
  A0 = 'A0',
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  A4 = 'A4',
  A5 = 'A5',
  A6 = 'A6',
  A7 = 'A7',
  A8 = 'A8',

  B0 = 'B0',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  B4 = 'B4',
  B5 = 'B5',
  B6 = 'B6',

  F0 = 'F0',
  F1 = 'F1',
  F2 = 'F2',
  F3 = 'F3',
  F4 = 'F4',
  F5 = 'F5',
  F6 = 'F6',
  F7 = 'F7',
  F8 = 'F8',
  F9 = 'F9',
  F10 = 'F10',

  //label work order
  LABEL_17 = 'LABEL_17',
  LABEL_20 = 'LABEL_20',
  LABEL_10 = 'LABEL_10',
  LABEL_8 = 'LABEL_8',

  //packing mjs
  PACKING_MJS = 'PACKING_MJS',
}

export const PAPER_SIZE = {
  // A0: { width: '33.11in', height: '46.81in' },
  // A1: { width: '23.39in', height: '33.11in' },
  // A2: { width: '16.54in', height: '23.39in' },
  // A3: { width: '11.69in', height: '16.54in' },
  // A4: { width: '8.27in', height: '11.69in' },
  // A5: { height: '5.83in', width: '8.27in' },
  // A6: { width: '4.13in', height: '5.83in' },
  // A7: { width: '2.91in', height: '4.13in' },

  A0: { width: '841mm', height: '1188mm' },
  A1: { width: '594mm', height: '841mm' },
  A2: { width: '420mm', height: '594mm' },
  A3: { width: '297mm', height: '420mm' },
  A4: { width: '210mm', height: '297mm' },
  A5: { width: '148mm', height: '210mm' },
  A6: { width: '105mm', height: '148mm' },
  A7: { width: '74mm', height: '105mm' },
  A8: { width: '52mm', height: '74mm' },

  B0: { width: '40.48in', height: '57.32in' },
  B1: { width: '28.66in', height: '40.48in' },
  B2: { width: '20.24in', height: '28.66in' },
  B3: { width: '14.33in', height: '20.24in' },
  B4: { width: '10.12in', height: '14.33in' },
  B5: { width: '7.17in', height: '10.12in' },
  B6: { width: '5.04in', height: '7.17in' },

  F0: { width: '841mm', height: '1321mm' },
  F1: { width: '660mm', height: '841mm' },
  F2: { width: '420mm', height: '660mm' },
  F3: { width: '330mm', height: '420mm' },
  F4: { width: '210mm', height: '330mm' },
  F5: { width: '165mm', height: '210mm' },
  F6: { width: '105mm', height: '165mm' },
  F7: { width: '82mm', height: '105mm' },
  F8: { width: '52mm', height: '82mm' },
  F9: { width: '41mm', height: '52mm' },
  F10: { width: '26mm', height: '41mm' },

  //label wo
  LABEL_17: { width: '175mm', height: '80mm' },
  LABEL_20: { width: '200mm', height: '100mm' },
  LABEL_10: { width: '100mm', height: '100mm' },
  LABEL_8: { width: '80mm', height: '50mm' },

  //packing mjs
  PACKING_MJS: { width: '279mm', height: '241mm' },
};
