import React, { useState } from 'react';
import { useCollapsibleLayout, userNavbarPosition, useShowSidebarLayout } from '@base-hooks';
import { Layout, Button, Modal, List, Table, Badge, Tag } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
const { Header } = Layout;
import { ArrowLeft, DownloadSimple, Printer } from 'phosphor-react';
import '../styles/navbar.less';
import { useRecoilState, useRecoilValue } from 'recoil';
import { listFilePrint, listPrintContent } from '@pages/multiple-print/state';
import { makeCommonDataSource } from '@base-factories';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';

export default function Navbar() {
  const showControlMenu = process.env.REACT_APP_MODE === 'DEVELOPMENT';
  const dataSource = makeCommonDataSource({ apiUrl: 'v1/print/request' });
  const [showSidebar, setShowSidebar] = useShowSidebarLayout();
  const printContentRecoil = useRecoilValue(listPrintContent);
  const [navbarPosition] = userNavbarPosition();
  const [collapsed] = useCollapsibleLayout();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleDownload, setVisibleDownload] = useState(false);

  function onClose() {
    setVisible(false);
  }
  function onCloseDownload() {
    setVisibleDownload(false);
  }

  const generateDataSource = makeCommonDataSource({
    apiUrl: '/generate',
    baseUrl: process.env.REACT_APP_BASE_URL_GENERATE_PDF,
  });

  function makeClassNameHeader(): string {
    if (!showSidebar || navbarPosition === 'outside_the_layout') return 'no-sidebar';
    else if (collapsed) return 'collapsed-header';
    return 'not-collapsed-header';
  }

  function getFile(listContent = [], filterByDownload = false) {
    const newListContent = [];

    listContent.forEach((item) => {
      if (filterByDownload) {
        const shape = item.shape;
        const downloadDetail = item?.downloadDetail ?? true;
        const downloadIndex = item?.downloadIndex ?? true;

        let readyToDownload = true;
        if (shape === 'detail') {
          if (!downloadDetail) readyToDownload = false;
        } else if (shape === 'index') {
          if (!downloadIndex) readyToDownload = false;
        }

        if (readyToDownload) {
          const fileConfig = JSON.parse(localStorage.getItem(`print-config-${item.id}`));
          newListContent.push({ ...item, fileConfig });
        }
      } else {
        const fileConfig = JSON.parse(localStorage.getItem(`print-config-${item.id}`));
        newListContent.push({ ...item, fileConfig });
      }
    });

    return newListContent;
  }

  async function handlePrint(payload) {
    const media = payload?.fileConfig?.media;
    const pdfConfig = payload?.fileConfig?.pdfConfig ?? {};
    const printerCode = payload.printer?.code;

    await generateDataSource.handleCreate(pdfConfig, {
      async onSuccess({ response }: any) {
        const file = response.file;
        if (printerCode && file) {
          const formData: FormData = new FormData();
          let newFile;
          await fetch(file)
            .then((res) => res.blob())
            .then((res) => (newFile = res));
          formData.append('file', newFile);
          formData.append('media', media);
          await dataSource.handleCustomRequest({
            paramRequest: {
              method: 'POST',
              url: `${process.env.REACT_APP_BASE_URL}v1/print/request/${printerCode}/print`,
              data: formData,
            },
            async onSuccess({ response }: any) {
              console.log({ response });
            },
            async onFailed({ message }: any) {
              console.log({ message });
            },
          });
        }
      },
      async onFailed({ message }: any) {
        console.log({ message });
      },
    });
  }

  async function handleClickPrint(listPrintContent = []) {
    const listFile = listPrintContent.filter((item) => !item.jobStatus || item.jobStatus === 'failed');
    setLoading(true);
    for (let i = 0; i < listFile.length; i++) {
      const item = listFile[i];
      if (item?.fileConfig) {
        await handlePrint(item);
      }
    }
    setLoading(false);
    setVisible(false);
  }

  async function handleDownload(payload) {
    const pdfConfig = payload?.fileConfig?.pdfConfig ?? {};
    const downloadLink = document.createElement('a');
    const fileName = `${payload.fileName}.pdf`;
    await generateDataSource.handleCreate(pdfConfig, {
      async onSuccess({ response }: any) {
        const file = response.file;
        if (file) {
          downloadLink.href = file;
          downloadLink.download = fileName;
          downloadLink.id = payload.id;
          downloadLink.click();
        }
      },
      async onFailed({ message }: any) {
        console.log({ message });
      },
    });
  }

  async function handleClickDownload(listPrintContent = []) {
    const listFile = listPrintContent.filter((item) => !item.jobStatus || item.jobStatus === 'failed');
    setLoadingDownload(true);
    for (let i = 0; i < listFile.length; i++) {
      const item = listFile[i];
      if (item?.fileConfig) {
        await handleDownload(item);
      }
    }
    setLoadingDownload(false);
    setVisibleDownload(false);
  }

  return (
    <div className="custom-navbar-wrapper">
      <Modal
        okText="Download"
        width={700}
        title="Download File"
        visible={visibleDownload}
        onCancel={onCloseDownload}
        confirmLoading={loadingDownload}
        onOk={() => handleClickDownload(getFile(printContentRecoil, true))}
      >
        <Table
          pagination={false}
          scroll={{ x: 'fit-content', y: 500 }}
          dataSource={getFile(printContentRecoil, true)}
          columns={[
            {
              title: 'Filename',
              dataIndex: ['fileName'],
              render(value, record) {
                return value;
              },
            },

            {
              title: 'File Status',
              dataIndex: ['fileName'],
              render(value, record) {
                return record.fileConfig ? <Tag color="green">Ready</Tag> : <Tag color="red">File Not Found</Tag>;
              },
            },
          ]}
        />
      </Modal>
      {/* <Modal
        okText="Print"
        width={700}
        title="Print Job"
        visible={visible}
        onCancel={onClose}
        confirmLoading={loading}
        onOk={() => handleClickPrint(getFile(printContentRecoil))}
      >
        <Table
          pagination={false}
          scroll={{ x: 'fit-content', y: 500 }}
          dataSource={getFile(printContentRecoil)}
          columns={[
            {
              title: 'Printer Code',
              dataIndex: ['printer', 'code'],
            },
            {
              title: 'Printer Name',
              dataIndex: ['printer', 'name'],
            },
            {
              title: 'File Status',
              dataIndex: ['fileName'],
              render(value, record) {
                return record.fileConfig ? <Tag color="green">Ready</Tag> : <Tag color="red">File Not Found</Tag>;
              },
            },
          ]}
        />
      </Modal> */}
      <Header className={`custom-layout-header ${makeClassNameHeader()}`}>
        {showControlMenu &&
          React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            style: { marginRight: '20px' },
            onClick: () => setShowSidebar(!showSidebar),
          })}

        <div className="navbar-custom-menu">
          <div style={{ fontSize: '20px' }}>ERP Print</div>
        </div>
        <div className="navbar-right-content">
          <div className="navbar-right-item">
            {/* <Button
              type="primary"
              onClick={() => setVisible(true)}
              // disabled={printContentRecoil?.length === 0}
              icon={<Printer weight="bold" style={{ marginRight: '7px', marginTop: '3px' }} />}
              style={{ marginLeft: '10px', display: 'flex', fontSize: '12px' }}
            >
              Print
            </Button> */}
            <Button
              // disabled={printContentRecoil?.length === 0}
              onClick={() => setVisibleDownload(true)}
              icon={<DownloadSimple style={{ marginRight: '7px', marginTop: '3px' }} />}
              style={{ marginLeft: '10px', display: 'flex', fontSize: '12px' }}
            >
              Download
            </Button>
            {/* <Button
              onClick={() => navigate(-1)}
              icon={<ArrowLeft style={{ marginRight: '7px', marginTop: '3px' }} />}
              style={{ marginLeft: '10px', display: 'flex', fontSize: '12px' }}
            >
              Back
            </Button> */}
          </div>
        </div>
      </Header>
    </div>
  );
}
