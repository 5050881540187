import React from 'react';
import Barcode from 'react-barcode';

const renderBarcode = (code: string) => {
  return (
    <div style={{ padding: 0, textAlign: 'center' }}>
      <Barcode margin={2} value={code ?? '00000'} width={1} height={40} displayValue={false} />
    </div>
  );
};

export default renderBarcode;
