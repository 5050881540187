import React, { useEffect, useState } from 'react';

import { PageWrapper } from './page-wrapper';
import { PageHeader } from '../page-header';
import { Form, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { PageWrapperActionEntity } from './page-wrapper.entity';
import { usePageProviderAction, usePageProviderData } from '@base-hooks';
import { notificationFailed, notificationSuccess } from '@base-helpers';
import * as _ from 'lodash';

const typeTemplate = '${label} is not a valid ${type}!';
export const validateMessages = {
  default: 'Validation error on field ${label}!',
  required: '${label} is required!',
  enum: '${label} must be one of [${enum}]!',
  whitespace: '${label} cannot be empty!',
  date: {
    format: '${label} is invalid for format date!',
    parse: '${label} could not be parsed as date!',
    invalid: '${label} is invalid date!',
  },
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    boolean: typeTemplate,
    integer: typeTemplate,
    float: typeTemplate,
    regexp: typeTemplate,
    email: typeTemplate,
    url: typeTemplate,
    hex: typeTemplate,
  },
  string: {
    len: '${label} must be exactly ${len} characters!',
    min: '${label} must be at least ${min} characters!',
    max: '${label} cannot be longer than ${max} characters!',
    range: '${label} must be between ${min} and ${max} characters!',
  },
  number: {
    len: '${label} must equal ${len}!',
    min: '${label} cannot be less than ${min}!',
    max: '${label} cannot be greater than ${max}!',
    range: '${label} must be between ${min} and ${max}!',
  },
  array: {
    len: '${label} must be exactly ${len} in length!',
    min: '${label} cannot be less than ${min} in length!',
    max: '${label} cannot be greater than ${max} in length!',
    range: '${label} must be between ${min} and ${max} in length!',
  },
  pattern: {
    mismatch: '${label} does not match pattern ${pattern}!',
  },
};

export interface FormPageWrapperProps extends PageWrapperActionEntity {
  ignoreKeyUpdate?: string[];
  ignoreKeyDuplicate?: string[];
  handleAfterCreate?: (response: any) => void;
  handleAfterUpdate?: (response: any) => void;
}

export function FromPageWrapper(props: FormPageWrapperProps) {
  const {
    children,
    padding,
    showHeader = true,
    title,
    showButtonReload = true,
    showButtonHistory = false,

    showButtonMoreAction = false,
    showConfirmProcess = false,
    showUpdate = false,
    showDelete = false,
    showDuplicate = false,
    showPrint = false,
    showLog = false,
    showNote = false,
    showApproval = false,

    showButtonSave = true,
    forceRequest = false,
    onReload,

    contentConfirmProcess,
    contentConfirmDelete,
    contentConfirmSave,

    titleConfirmProcess,
    titleConfirmDelete,
    titleConfirmSave,

    onConfirmSave,
    className = '',
    onChangeFormValues,
    dataDummy,
    ignoreKeyDuplicate = [],
    ignoreKeyUpdate = [],
    handleAfterCreate,
    handleAfterUpdate,
  } = props;

  const [form] = Form.useForm();
  const [showContent, setShowContent] = useState(true);
  const { action, showData, id, moduleKey, isCreate, isUpdate, isDuplicate } = usePageProviderData();
  const { navigation, dataSource, transformer } = usePageProviderAction();
  const { transformerGetData } = transformer;

  async function onReloadPage(): Promise<void> {
    form.resetFields();
    setShowContent(false);
    if (onReload) await onReload();
    else await new Promise((resolve) => setTimeout(resolve, 1000));
    requestScheme();
    setShowContent(true);
  }

  function makeDataForm(payload: any): void {
    let formPayload = dataDummy ?? payload;
    if (isUpdate) formPayload = _.omit(formPayload, ignoreKeyUpdate);
    else if (isDuplicate) formPayload = _.omit(formPayload, ignoreKeyDuplicate);
    form.setFieldsValue({ form_action: action, ...(formPayload ?? {}) });
  }

  function transformerPayload(payload: any): void {
    let resultPayload = payload;
    if (transformerGetData) resultPayload = transformerGetData(payload);
    makeDataForm(resultPayload);
  }

  async function handleGetData(): Promise<void> {
    await dataSource.handleGetData(id, {
      onSuccess: ({ response }: any) => {
        transformerPayload(response);
      },
      onFailed: ({ message }: any) => {
        notificationFailed(message);
      },
    });
  }

  function requestScheme(): void {
    if ((forceRequest || !showData) && id) handleGetData();
    else if (showData) transformerPayload(showData);
    else if (dataDummy) transformerPayload(dataDummy);
    else form.setFieldsValue({ form_action: action });
  }

  useEffect(() => {
    requestScheme();
  }, [forceRequest, showData, id, dataDummy]);

  function transformerCreate(payload: any) {
    if (transformer?.transformerCreate) return transformer.transformerCreate(payload);
    return payload;
  }

  function transformerUpdate(payload: any) {
    if (transformer?.transformerUpdate) return transformer.transformerUpdate(payload);
    return payload;
  }

  function transformerDuplicate(payload: any) {
    if (transformer?.transformerDuplicate) return transformer.transformerDuplicate(payload);
    return payload;
  }

  async function create(payload: any): Promise<void> {
    await dataSource.handleCreate(payload, {
      onSuccess: ({ response }: any) => {
        if (handleAfterCreate) handleAfterCreate(response);
        else navigation.goToDetail({ id: response.id });
        notificationSuccess(['Successfully created data.']);
      },
      onFailed: ({ message }: any) => {
        notificationFailed(message);
      },
    });
  }

  async function update(payload: any): Promise<void> {
    await dataSource.handleUpdate(id, payload, {
      onSuccess: ({ response }: any) => {
        if (handleAfterUpdate) handleAfterUpdate(response);
        else navigation.goToDetail({ id: response.id });
        notificationSuccess(['Successfully updated data.']);
      },
      onFailed: ({ message }: any) => {
        notificationFailed(message);
      },
    });
  }

  async function handleSave(): Promise<void> {
    try {
      const values = await form.validateFields();
      let payload = values;
      if (isUpdate) payload = transformerUpdate(payload);
      else if (isCreate) payload = transformerCreate(payload);
      else if (isDuplicate) payload = transformerDuplicate(payload);

      const cleanVal = _.omit(payload, ['id', 'form_action']);
      if (isUpdate) await update(cleanVal);
      else await create(cleanVal);
    } catch (error) {}
  }

  async function onSave(): Promise<void> {
    if (onConfirmSave) await onConfirmSave();
    else await handleSave();
  }

  async function onChangeForm(item, values) {
    let newValues = undefined;
    if (onChangeFormValues) newValues = await onChangeFormValues(item, values);
    if (newValues) form.setFieldsValue(newValues);
  }

  return (
    <div className={className}>
      {showHeader && (
        <PageHeader
          onReload={() => onReloadPage()}
          title={title}
          showButtonMoreAction={showButtonMoreAction}
          showConfirmProcess={showConfirmProcess}
          showUpdate={showUpdate}
          showDelete={showDelete}
          showDuplicate={showDuplicate}
          showPrint={showPrint}
          showLog={showLog}
          showNote={showNote}
          showApproval={showApproval}
          showButtonSave={showButtonSave}
          contentConfirmProcess={contentConfirmProcess}
          contentConfirmDelete={contentConfirmDelete}
          contentConfirmSave={contentConfirmSave}
          titleConfirmProcess={titleConfirmProcess}
          titleConfirmDelete={titleConfirmDelete}
          titleConfirmSave={titleConfirmSave}
          onConfirmSave={onSave}
          form={form}
          showButtonHistory={showButtonHistory}
          showButtonReload={showButtonReload}
        />
      )}
      <PageWrapper padding={padding} showHeader={showHeader}>
        <Spin spinning={!showContent} indicator={<LoadingOutlined />} style={{ minHeight: '50vh' }}>
          <Form
            form={form}
            layout="vertical"
            scrollToFirstError
            onValuesChange={onChangeForm}
            name={`form-${action}-${moduleKey}`}
            validateMessages={validateMessages}
          >
            <Form.Item noStyle name="id"></Form.Item>
            <Form.Item noStyle name="form_action"></Form.Item>
            {showContent && children}
          </Form>
        </Spin>
      </PageWrapper>
    </div>
  );
}
