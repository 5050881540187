import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCurrency, RenderText } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter, userPrinter } from '@base-helpers';
import moment from 'moment';
import { Col, Descriptions, Row, Table } from 'antd';
import { getProducts } from './helpers';

export const formatUnderscore = (data: string) => data?.replace(/_/g, ' ');

export function Detail() {
  const { detailData = {}, type } = usePagePrint();
  const hasCode = detailData?.code;
  const user = userPrinter();
  const configColumns = [
    {
      title: 'ID',
      key: 'product_variant',
      dataIndex: 'product_variant',
      render: (item: any, row: any) => RenderText({ value: row?.product_variant?.code ?? row?.product_package?.code }),
    },
    {
      title: 'Product',
      key: 'product_variant',
      dataIndex: 'product_variant',
      render: (item: any, row: any) => RenderText({ value: row?.product_variant?.name ?? row?.product_package?.name }),
    },
    {
      title: 'Unit',
      key: 'unit_need',
      dataIndex: 'unit_need',
      render: (item: any, row: any) => RenderText({ value: item }),
    },
    {
      title: 'UoM',
      key: 'uom_unit',
      dataIndex: 'uom_unit',
      render: (item: any, row: any) => {
        return RenderText({ value: item?.code });
      },
    },
    {
      title: 'Qty',
      key: 'qty_need',
      dataIndex: 'qty_need',
      render: (item: any, row: any) => RenderText({ value: item }),
    },
    {
      title: 'UoM',
      key: 'uom_qty',
      dataIndex: 'uom_qty',
      render: (item: any, row: any) => RenderText({ value: item?.code }),
    },
  ];

  return (
    <DetailPrint
      orientation="portrait"
      printHeaderProps={{ barcode: detailData?.code }}
      paperFormat={PAPER_FORMAT.A5}
      printSignatureProps={{
        signatures: [
          {
            gridColumn: { span: 6 },
            title: 'Requested By',
            name: detailData?.editor_name ? detailData?.editor_name : '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Printed By',
            name: user?.username ? user?.username : '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Packed By',
            name: '_____________',
          },
          { gridColumn: { span: 6 }, title: 'Approved By', name: '_____________' },
        ],
      }}
    >
      <DetailBuilder
        columns={[
          {
            fields: [
              {
                gridColumn: { span: 12 },
                renderField(form) {
                  return (
                    <PrintTitle
                      title={`${detailData?.type} ORDER ${type ? `(${stringFormatter.capitalizeEachWord(type)})` : ''}`}
                      {...(hasCode ? { description: `No. ${detailData?.code}` } : {})}
                    />
                  );
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Created Date',
                name: 'created_at',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Printed Date',
                name: 'id',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: () => moment().format('YYYY-MM-DD'),
                },
              },
              {
                gridColumn: { span: 6 },
                label: 'Status',
                name: 'status',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    let status = value;
                    if (value?.toLowerCase() === 'waiting') status = 'in_process';
                    return stringFormatter.capitalizeEachWord(status?.split('_')?.join(' '));
                  },
                },
              },
              {
                gridColumn: { span: 2 },
                label: 'Site',
                name: 'site',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.code;
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Expected Date',
                name: 'expected_start_date',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD HH:mm') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Completion Date',
                name: 'completion_date',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD HH:mm') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 16 },
                label: 'References',
                name: 'references',
                fieldType: 'text',
                renderTextProps: {
                  renderType: 'multi',
                  renderValue(value) {
                    return value?.transaction_code ?? value?.code;
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: detailData?.type === 'PACK' ? 'Product to Pack' : 'Product to Unpack',
                name: 'product_package',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.name;
                  },
                },
              },
              {
                gridColumn: { span: 2 },
                label: detailData?.type === 'PACK' ? 'Order Qty' : 'Order Qty',
                name: 'qty_to_pack',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return `${value} ${detailData?.uom?.code}`;
                  },
                },
              },
              {
                gridColumn: { span: 2 },
                label: detailData?.type === 'PACK' ? 'Output Qty' : 'Output Qty',
                name: 'qty_output',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return `${value} ${detailData?.uom?.code}`;
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: detailData?.type === 'PACK' ? 'Pack Location' : 'Unpack Location',
                name: 'location',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.name;
                  },
                },
              },
            ],
          },
        ]}
      />
      <Table
        dataSource={detailData?.pack_unpack_products}
        columns={configColumns}
        pagination={false}
        size="small"
        bordered
        scroll={{ x: 'fit-content' }}
        style={{ marginTop: '10px', marginBottom: '10px', overflow: 'hidden', fontSize: '8px !important' }}
      />

      <Row gutter={[8, 8]} style={{ marginBottom: '10px' }}>
        <Col span={14}>
          <div style={{ border: '1px solid #f0f0f0', padding: '10px', height: '100%' }}>
            <div style={{ display: 'block', fontWeight: 'bold', marginBottom: '10px' }}>Note :</div>
            <div>{detailData?.notes ? detailData?.notes : '-'}</div>
          </div>
        </Col>
      </Row>
    </DetailPrint>
  );
}
