import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { PAPER_FORMAT, stringFormatter } from '@base-helpers';
import moment from 'moment';
import { Col, Row } from 'antd';
import { columnsMaterial, getProductionOrder, groupingProduct } from './helpers';
import { useBarcode } from '@createnextapp/react-barcode';

export function Detail() {
  const { detailData = {}, printContent, setDetailData } = usePagePrint();
  const hasCode = detailData?.code;
  const [productGroup, setProductGroup] = useState([]);
  const { inputRef } = useBarcode({
    value: detailData?.code ?? '-',
    options: {
      displayValue: false,
      height: 20,
      width: 1,
      marginTop: -1,
      marginLeft: -1,
      marginBottom: -1,
    },
  });

  const generateProduct = async (materials) => {
    const result = groupingProduct(materials);
    setProductGroup(result);
  };

  const generateProductionOrder = async (payload) => {
    const result = await getProductionOrder(payload?.production_order?.id);
    setDetailData({ ...payload, production_order: result });
  };
  useEffect(() => {
    if (detailData?.materials) {
      generateProduct(detailData?.materials);
    }
  }, [detailData]);

  // useEffect(() => {
  //   if (detailData?.production_order?.id) {
  //     generateProductionOrder(detailData?.production_order?.id);
  //   }
  // }, [detailData?.production_order?.id]);

  return (
    <DetailPrint
      paperFormat={PAPER_FORMAT.A4}
      orientation="portrait"
      onSuccessLoadData={(payload) => generateProductionOrder(payload)}
      // printHeaderProps={{ barcode: detailData?.code, logoType: 'mjs' }}
      // showHeader={true}
    >
      <div style={{ fontSize: '11px' }}>
        <Row gutter={10} style={{ lineHeight: '12px' }}>
          <Col span={6}>
            <svg ref={inputRef} />
            <div style={{ lineHeight: '12px' }}>
              <h2>Work Order</h2>
              <h3>{detailData?.code ?? ''}</h3>
            </div>
          </Col>
          <Col span={8}>
            <table style={{ lineHeight: '12px' }}>
              <tr>
                <td>Customer</td>
                <td style={{ fontWeight: 'bold' }}>{detailData?.contact?.name ?? '-'}</td>
              </tr>
              <tr>
                <td style={{ paddingRight: '5px' }}>Customer Reference</td>
                <td style={{ fontWeight: 'bold' }}>{detailData?.contact_reference?.[0]?.name ?? '-'}</td>
              </tr>
              <tr>
                <td>References</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.production_order?.production_request_references
                    ?.map((item) => item?.transaction_code)
                    ?.join(', ') ?? ''}
                </td>
              </tr>
            </table>
          </Col>
          <Col span={6}>
            <table style={{ lineHeight: '12px' }}>
              <tr>
                <td>Order Date</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.order_date ? moment(detailData?.order_date).format('DD/MM/YYYY HH:mm') : '-'}
                </td>
              </tr>
              <tr>
                <td>Due Date</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.expected_finish_date
                    ? moment(detailData?.expected_finish_date).format('DD/MM/YYYY HH:mm')
                    : '-'}
                </td>
              </tr>
              <tr>
                <td>Production Order</td>
                <td style={{ fontWeight: 'bold' }}>{detailData?.production_order?.code ?? ''}</td>
              </tr>
            </table>
          </Col>
          <Col span={4}></Col>
        </Row>
        <hr />
        <div style={{ lineHeight: '12px' }}>
          <DetailBuilder
            columns={[
              {
                fields: [
                  {
                    gridColumn: { span: 4 },
                    label: 'Product',
                    name: 'product',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return value?.name;
                      },
                    },
                  },
                  ...(detailData?.work_order_attributes?.length > 0
                    ? detailData?.work_order_attributes?.map((attr) => {
                        return {
                          gridColumn: { span: 3 },
                          label: attr?.attribute?.name,
                          name: 'id',
                          fieldType: 'text',
                          renderTextProps: {
                            renderValue: (value) => {
                              return attr?.attribute_value
                                ? `${attr?.attribute_value?.value} ${
                                    attr?.attribute_value?.unit_of_measurement?.code ?? ''
                                  }`
                                : '-';
                            },
                          },
                        };
                      })
                    : []),
                ],
              },
            ]}
          />
          <DetailBuilder
            columns={[
              {
                fields: [
                  {
                    gridColumn: { span: 4 },
                    label: 'Qty Order',
                    name: 'order_qty',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return value ? `${value} ${detailData?.order_qty_unit_of_measurement?.code ?? ''}` : '-';
                      },
                    },
                  },
                  {
                    gridColumn: { span: 4 },
                    label: 'Order Unit',
                    name: 'order_unit',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return value ? `${value} ${detailData?.order_unit_unit_of_measurement?.code ?? ''}` : '-';
                      },
                    },
                  },
                  {
                    gridColumn: { span: 16 },
                    label: 'Order Qty/Unit',
                    name: 'produce_qty_unit',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return value ? `${value} ${detailData?.order_qty_unit_of_measurement?.code ?? ''}` : '-';
                      },
                    },
                  },
                  {
                    gridColumn: { span: 4 },
                    label: 'Shift',
                    name: 'id',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        if (detailData?.step_shifts?.length > 0) {
                          return detailData?.step_shifts?.map((item) => item?.name)?.join(', ');
                        }
                        return '-';
                      },
                    },
                  },
                  {
                    gridColumn: { span: 4 },
                    label: 'Machine',
                    name: 'id',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        if (detailData?.machines?.length > 0) {
                          return detailData?.machines?.map((item) => item?.code)?.join(', ');
                        }
                        return '-';
                      },
                    },
                  },
                  {
                    gridColumn: { span: 4 },
                    label: 'BoM',
                    name: 'id',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return detailData?.production_order?.bill_of_materials?.[0]?.name;
                      },
                    },
                  },
                  ...(detailData?.bill_of_material_attributes?.length > 0
                    ? detailData?.bill_of_material_attributes?.map((attr) => {
                        return {
                          gridColumn: { span: 3 },
                          label: attr?.attribute?.name,
                          name: 'id',
                          fieldType: 'text',
                          renderTextProps: {
                            renderValue: (value) => {
                              return attr?.attribute_value
                                ? `${attr?.attribute_value?.value} ${
                                    attr?.attribute_value?.unit_of_measurement?.code ?? ''
                                  }`
                                : '-';
                            },
                          },
                        };
                      })
                    : []),
                ],
              },
            ]}
          />
          <hr />
          <h4 style={{ fontWeight: 'bold' }}>Materials</h4>
          {productGroup?.map((group, index) => {
            return (
              <table style={{ width: '100%', fontSize: '10px', marginBottom: '10px' }} key={index}>
                <thead>
                  <tr>
                    {columnsMaterial?.map((column, index) => {
                      return (
                        <th key={index} style={{ border: '1px solid black' }}>
                          {column?.title}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {group?.products?.map((product, index) => {
                    return (
                      <tr key={index}>
                        {columnsMaterial?.map((column, index) => {
                          return (
                            <td key={index} style={{ border: '1px solid black' }}>
                              {column?.render(product?.[column?.key], product, index)}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            );
          })}
        </div>
      </div>
    </DetailPrint>
  );
}
