import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCurrency, RenderText } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter, userPrinter } from '@base-helpers';
import moment from 'moment';
import { Col, Descriptions, Row, Table } from 'antd';

export const formatUnderscore = (data: string) => data?.replace(/_/g, ' ');

export function Detail() {
  const { detailData = {}, type } = usePagePrint();
  const hasCode = detailData?.code;
  const user = userPrinter();
  const configColumns = [
    {
      title: 'A/c',
      dataIndex: 'account',
      key: 'account',
      width: 150,
      render: (item: any, row: any) =>
        RenderText({ value: row?.account ? `${row?.account?.nick_name} (${row?.account?.code})` : '-' }),
    },
    {
      title: 'A/c Type',
      dataIndex: 'account_type',
      key: 'account_type',
      render: (item: any, row: any) => RenderText({ value: row?.coa_type?.nick_name }),
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      render: (item: any, row: any) => RenderText({ value: row?.label?.name }),
    },
    {
      title: 'Sub Label',
      dataIndex: 'sub_label',
      key: 'sub_label',
      render: (item: any, row: any) => RenderText({ value: row?.sub_label?.name }),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (item: any, row: any) => RenderText({ value: row?.description }),
    },
    {
      title: 'Transaction Reference',
      dataIndex: 'transaction_reference',
      key: 'transaction_reference',
      width: 150,
      render: (item: any, row: any) =>
        RenderText({ value: row?.transaction_references?.map((item) => item?.transaction_code)?.join(', ') }),
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      key: 'debit',
      width: 150,
      render: (item: any, row: any) => RenderCurrency({ value: row?.debit }),
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      key: 'credit',
      width: 150,
      render: (item: any, row: any) => RenderCurrency({ value: row?.credit }),
    },
  ];

  return (
    <DetailPrint
      orientation="portrait"
      printHeaderProps={{ barcode: detailData?.code }}
      paperFormat={PAPER_FORMAT.A4}
      printSignatureProps={{
        signatures: [
          {
            gridColumn: { span: 6 },
            title: 'Created By',
            name: detailData?.creator_name ? detailData?.creator_name : '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Confirmed By',
            name: detailData?.editor_name ?? detailData?.creator_name ?? '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Received By',
            name: detailData?.editor_name ?? detailData?.creator_name ?? '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Paid By',
            name: detailData?.creator_name ? detailData?.creator_name : '-',
          },
        ],
      }}
    >
      <DetailBuilder
        columns={[
          {
            fields: [
              {
                gridColumn: { span: 12 },
                renderField(form) {
                  return (
                    <PrintTitle
                      title={`Journal Entry ${type ? `(${stringFormatter.capitalizeEachWord(type)})` : ''}`}
                      {...(hasCode ? { description: `No. ${detailData?.code}` } : {})}
                    />
                  );
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Created Date',
                name: 'created_at',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Printed Date',
                name: 'id',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: () => moment().format('YYYY-MM-DD'),
                },
              },
              {
                gridColumn: { span: 6 },
                label: 'Status',
                name: 'status',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    let status = value;
                    if (value?.toLowerCase() === 'waiting') status = 'in_process';
                    return stringFormatter.capitalizeEachWord(status?.split('_')?.join(' '));
                  },
                },
              },
              {
                gridColumn: { span: 2 },
                label: 'Site',
                name: 'site',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.code;
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Sales Division',
                name: 'sales_division',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.code;
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Purchase Division',
                name: 'purchase_division',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.code;
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Journal Entry Date',
                name: 'date',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD HH:mm') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Reconcile',
                name: 'reconcile_date',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD HH:mm') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 6 },
                label: 'References',
                name: 'references',
                fieldType: 'text',
                renderTextProps: {
                  renderType: 'multi',
                  renderValue(value) {
                    return value?.transaction_code ?? value?.code;
                  },
                },
              },
              {
                gridColumn: { span: 6 },
                label: 'Information',
                name: 'information',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value;
                  },
                },
              },
            ],
          },
        ]}
      />
      <Table
        dataSource={detailData?.journal_items}
        columns={configColumns}
        pagination={false}
        size="small"
        bordered
        scroll={{ x: 'fit-content' }}
        key={JSON.stringify(detailData?.journal_items)}
        style={{ marginTop: '10px', marginBottom: '10px', overflow: 'hidden', fontSize: '8px !important' }}
        summary={() => (
          <Table.Summary>
            <Table.Summary.Row>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={7}></Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
              <Table.Summary.Cell index={9}></Table.Summary.Cell>
              <Table.Summary.Cell index={10}></Table.Summary.Cell>
              <Table.Summary.Cell index={4} className="padding-5 text">
                <h3 hidden={detailData?.type === 'correction'}>
                  {detailData?.total_credit === detailData?.total_debit ? (
                    'Balance'
                  ) : (
                    <span style={{ color: 'red' }}>Not Balance</span>
                  )}
                </h3>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>{RenderCurrency({ value: detailData?.total_debit })}</Table.Summary.Cell>
              <Table.Summary.Cell index={6}>{RenderCurrency({ value: detailData?.total_credit })}</Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />

      <Row gutter={[8, 8]} style={{ marginBottom: '10px' }}>
        <Col span={14}>
          <div style={{ border: '1px solid #f0f0f0', padding: '10px', height: '100%' }}>
            <div style={{ display: 'block', fontWeight: 'bold', marginBottom: '10px' }}>Information :</div>
            <div>{detailData?.information ? detailData?.information : '-'}</div>
          </div>
        </Col>
      </Row>
    </DetailPrint>
  );
}
