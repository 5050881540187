import React from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCurrency } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter, userPrinter } from '@base-helpers';
import moment from 'moment';
import { Col, Row } from 'antd';

export function Detail() {
  const { detailData = {} } = usePagePrint();
  const hasCode = detailData?.code;
  const dataSource = detailData?.purchase_request_detail_products ?? [];

  return (
    <DetailPrint
      orientation="landscape"
      printHeaderProps={{ barcode: detailData?.code, logoType: 'mjs' }}
      showHeader={true}
      // paperFormat={dataSource?.length <= 8 ? PAPER_FORMAT.A5 : PAPER_FORMAT.A4}
      paperFormat={PAPER_FORMAT.A5}
      printSignatureProps={{
        signatures: [
          {
            gridColumn: { span: 6 },
            title: 'Requestor/Warehouese',
            name: detailData?.creator_name ? detailData?.creator_name : '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Head of Production',
            name: 'Chen',
          },
          {
            gridColumn: { span: 6 },
            title: 'Purchasing & Accounting',
            name: 'M. Abdu',
          },
          {
            gridColumn: { span: 6 },
            title: 'Director',
            name: 'Charles H. Senjaya',
          },
        ],
      }}
    >
      <DetailBuilder
        columns={[
          {
            fields: [
              {
                gridColumn: { span: 12 },
                renderField(form) {
                  return (
                    <PrintTitle
                      title="Purchase Request"
                      {...(hasCode ? { description: `No. ${detailData?.code}` } : {})}
                    />
                  );
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Created Date',
                name: 'created_at',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Printed Date',
                name: 'id',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: () => moment().format('YYYY-MM-DD'),
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Status',
                name: 'status',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => stringFormatter.capitalizeEachWord(value?.split('_')?.join(' ')),
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Requestor',
                name: 'requestor',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.name ?? value?.username ?? '-';
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Purchase Divison',
                name: 'purchase_division',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.name ?? '-';
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Expected Date',
                name: 'expected_date',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 12 },
                label: 'Reference',
                name: 'references',
                fieldType: 'text',
                renderTextProps: {
                  renderType: 'multi',
                  renderValue: (value) => {
                    const code = value?.transaction_code ?? value?.code;
                    return code;
                  },
                },
              },
            ],
          },
        ]}
      />

      <table
        style={{
          position: 'relative',
          width: '100%',
          border: '1px solid black',
          margin: '10px 0',
        }}
      >
        <thead style={{ fontWeight: 'bold', border: '1px solid black' }}>
          <tr>
            <td style={{ borderRight: '1px solid black', padding: '2px 3px' }}>No</td>
            <td style={{ borderRight: '1px solid black', padding: '2px 3px' }}>Product Variant</td>
            <td style={{ borderRight: '1px solid black', padding: '2px 3px' }}>Unit</td>
            <td style={{ borderRight: '1px solid black', padding: '2px 3px' }}>UoM</td>
            <td style={{ borderRight: '1px solid black', padding: '2px 3px' }}>Qty</td>
            <td style={{ borderRight: '1px solid black', padding: '2px 3px' }}>UoM</td>
            <td style={{ borderRight: '1px solid black', padding: '2px 3px' }}>Stock Available</td>
            <td style={{ borderRight: '1px solid black', padding: '2px 3px' }}>Average of Used</td>
            <td style={{ borderRight: '1px solid black', padding: '2px 3px' }}>Asset</td>
            <td style={{ borderRight: '1px solid black', padding: '2px 3px' }}>Description</td>
          </tr>
        </thead>
        <tbody>
          {dataSource?.map((item, index) => {
            const number = index + 1;
            return (
              <tr key={index}>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 3px',
                    textAlign: 'center',
                  }}
                >
                  {number}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 3px',
                  }}
                >
                  {item?.product_variant?.name ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 3px',
                  }}
                >
                  {item?.unit ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 3px',
                  }}
                >
                  {item?.unit_uom?.code ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 3px',
                  }}
                >
                  {item?.qty ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 3px',
                  }}
                >
                  {item?.uom?.code ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 3px',
                  }}
                >
                  {item?.qty_available ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 3px',
                  }}
                >
                  {item?.qty_average ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 3px',
                  }}
                >
                  {item?.is_asset ? 'Yes' : 'No'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px 3px',
                  }}
                >
                  {item?.description ?? '-'}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Row style={{ marginBottom: '20px' }}>
        <Col span={24} style={{ border: '1px solid black', padding: '10px', minHeight: '70px' }}>
          Note : <br />
        </Col>
      </Row>
    </DetailPrint>
  );
}
