import { APPROVAL_MODULE } from '@base-helpers';
import { ProductionOrder } from '@pages/production/production-order';
import { ProductionRequest } from '@pages/production/production-request';
import { ShiftPlanning } from '@pages/production/shift-planning';
import { WorkOrderQc } from '@pages/production/work-order-qc';
import { WorkOrderResult } from '@pages/production/work-order-result';

export const ListProductionContent = [
  {
    module: APPROVAL_MODULE.module.PRODUCTION,
    sub_module: APPROVAL_MODULE.subModule.production.PRODUCTION_ORDER,
    content: ProductionOrder,
  },
  {
    module: APPROVAL_MODULE.module.PRODUCTION,
    sub_module: APPROVAL_MODULE.subModule.production.PRODUCTION_REQUEST,
    content: ProductionRequest,
  },
  {
    module: APPROVAL_MODULE.module.PRODUCTION,
    sub_module: APPROVAL_MODULE.subModule.production.WORK_ORDER_QC,
    content: WorkOrderQc,
  },
  {
    module: APPROVAL_MODULE.module.PRODUCTION,
    sub_module: APPROVAL_MODULE.subModule.production.WORK_ORDER,
    content: WorkOrderResult,
  },
  {
    module: APPROVAL_MODULE.module.PRODUCTION,
    sub_module: APPROVAL_MODULE.subModule.production.SHIFT_PLANNING,
    content: ShiftPlanning,
  },
];
