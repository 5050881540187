const API_URL = process.env.REACT_APP_BASE_URL;
const API_URL_GATEWAY = process.env.REACT_APP_BASE_URL_GATEWAY;

export const getProducts = async (pickingId) => {
  try {
    // let items = [];
    const response: any = await fetch(
      `${API_URL}v1/inventory/transfer/picking-lists/products?detail_id=${pickingId}&module=inventory&sub_module=Picking&action=get`,
    );
    const result = await response.json();
    return result?.message;
  } catch (error) {
    return [];
  }
};
