import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

enum KeyData {
  SHIFT = 'SHIFT',
  WORK_CENTER = 'WORK_CENTER',
  USER = 'USER',
}

export const detailShiftPlanningTransformer = (planning_details) => {
  const newData = planning_details?.map((data) => {
    return {
      key: `${data?.shift?.id}@${KeyData.SHIFT}`,
      name: data?.shift?.name,
      shift: data?.shift,
      pic: data?.pic_shifts?.map((pic) => {
        return {
          date: pic?.date,
          pic: {
            id: pic?.pic?.id,
            code: pic?.pic?.code,
            name: pic?.pic?.name,
          },
        };
      }),
      children: data?.work_centers?.map((work_center) => {
        return {
          key: `${data?.shift?.id}@${KeyData.SHIFT}@${work_center?.work_center?.id}@${KeyData.WORK_CENTER}`,
          name: work_center?.work_center?.name,
          work_center: work_center?.work_center,
          pic: work_center?.pic_work_centers?.map((pic) => {
            return {
              pic: {
                id: pic?.pic?.id,
                code: pic?.pic?.code,
                name: pic?.pic?.name,
              },
              date: pic?.date,
            };
          }),
          children: work_center?.users?.map((user) => {
            return {
              key: `${data?.shift?.id}@${KeyData.SHIFT}@${work_center?.work_center?.id}@${KeyData.WORK_CENTER}@${user?.user?.name}@${KeyData.USER}`,
              name: user?.user?.name,
              age: 16,
              address: 'New York No. 3 Lake Park',
              user: {
                id: user?.user?.id,
                code: user?.user?.code,
                name: user?.user?.name,
              },
              schedule: user?.schedules?.map((schedule) => {
                return {
                  date: schedule?.date,
                  has_schedule: schedule.has_schedule,
                  machines: schedule?.machines?.map((machine) => {
                    return {
                      id: machine?.id,
                      code: machine?.code,
                    };
                  }),
                };
              }),
            };
          }),
        };
      }),
    };
  });
  return newData;
};

export const updateCalendarTransformer = (calendars) => {
  if (calendars?.length > 0) {
    return calendars?.map((dataShift) => {
      const schedules = dataShift?.schedules;
      schedules?.sort((a, b) => {
        return moment(a?.date).diff(b?.date);
      });
      return {
        key: uuidv4(),
        shift: dataShift?.shift,
        section: 'SHIFT',
        shift_leader: dataShift?.shift_leader,
        schedules: schedules?.map((schedule) => {
          return {
            date: schedule?.date,
            pic: schedule?.pic,
          };
        }),
        //work center
        children: dataShift?.work_centers?.map((dataWorkCenter) => {
          return {
            key: uuidv4(),
            section: 'WORK_CENTER',
            work_center: dataWorkCenter?.work_center,
            shift: dataShift?.shift,
            //process
            children: dataWorkCenter?.processes?.map((dataProcess) => {
              return {
                key: uuidv4(),
                section: 'PROCESS',
                production_process: dataProcess?.process,
                work_center: dataWorkCenter?.work_center,
                //data team
                children: dataProcess?.production_teams?.map((dataTeam) => {
                  const schedules = dataTeam?.schedules;
                  schedules?.sort((a, b) => {
                    return moment(a?.date).diff(b?.date);
                  });
                  return {
                    key: uuidv4(),
                    section: 'TEAM',
                    production_team: dataTeam?.production_team,
                    production_team_leader: dataTeam?.production_team_leader,
                    production_process: dataProcess?.process,
                    work_center: dataWorkCenter?.work_center,
                    shift: dataShift?.shift,
                    schedules: schedules?.map((schedule) => {
                      return {
                        date: schedule?.date,
                        production_team: schedule?.production_team,
                      };
                    }),
                    //data user
                    children: dataTeam?.users?.map((dataUser) => {
                      const schedules = dataUser?.schedules;
                      schedules?.sort((a, b) => {
                        return moment(a?.date).diff(b?.date);
                      });
                      return {
                        key: uuidv4(),
                        section: 'USER',
                        user: dataUser?.user,
                        production_team: dataTeam?.production_team,
                        production_process: dataProcess?.process,
                        work_center: dataWorkCenter?.work_center,
                        shift: dataShift?.shift,
                        schedules: schedules?.map((schedule) => {
                          return {
                            date: schedule?.date,
                            machines: schedule?.machines?.map((machine) => {
                              return machine;
                            }),
                            has_schedule: schedule?.has_schedule,
                          };
                        }),
                      };
                    }),
                  };
                }),
              };
            }),
          };
        }),
      };
    });
  }
  return [];
};

export const dateToString = (date, withTime = false, separator = '-', format = 'YYYY/MM/DD'): string => {
  if (!date) return null;
  const filterValue = (date, format, separator) => {
    const formatArr = format.split('/');
    const newData = [];
    formatArr.forEach((item) => {
      if (item === 'YYYY') {
        newData.push(date.getFullYear());
      } else if (item === 'MM') {
        const newMonth = date.getMonth() + 1;
        if (newMonth.toString().length === 1) {
          newData.push('0' + newMonth);
        } else {
          newData.push(newMonth);
        }
      } else if (item === 'DD') {
        const newDate = date.getDate();
        if (newDate.toString().length === 1) {
          newData.push('0' + newDate);
        } else {
          newData.push(newDate);
        }
      }
    });

    return newData.join(separator);
  };

  if (date) {
    let newDate = null;
    let newTime = null;

    if (format) {
      newDate = filterValue(date, format, separator ? separator : '-');
      newTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    } else {
      if (separator) {
        newDate = `${date.getDate() + separator}${date.getMonth() + separator}${date.getFullYear()}`;
        newTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      } else {
        newDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
        newTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      }
    }

    if (withTime) {
      return `${newDate} ${newTime}`;
    } else {
      return newDate;
    }
  } else {
    return null;
  }
};
