import React, { useEffect, useState } from 'react';
import { PageWrapper } from './page-wrapper';
import { PageHeader } from '../page-header';
import { Form, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { PageWrapperEntity } from './page-wrapper.entity';
import { useFilterDataTable, useMetaDataTable, usePageProviderAction, usePageProviderData } from '@base-hooks';
import { DataTableContextEntity, DataTableProvider } from '@base-providers';
import { notificationFailed, notificationSuccess } from '@base-helpers';
import { v4 as uuidV4 } from 'uuid';
import * as _ from 'lodash';
import { FormBuilderProps } from '../../form-components';
import { validateMessages } from './form-page-wrapper';

const defaultInitialFormValue = {
  key: uuidV4(),
};

export interface IndexPageWrapperProps extends PageWrapperEntity {
  tableType?: 'inline-form' | 'modal-form' | 'normal';
  initialFormValue?: { key: string; [key: string]: any };
  handleDetail?: (payload?: any) => void;
  handleUpdate?: (payload?: any) => void;
  handleDuplicate?: (payload?: any) => void;
  onConfirmActivate?: (payload?: any) => void;
  onConfirmDeactivate?: (payload?: any) => void;
  onCancelEditing?: (payload?: any) => void;
  formBuilderConfig?: FormBuilderProps;
  ignoreKeyUpdate?: string[];
  ignoreKeyDuplicate?: string[];
}

export function IndexPageWrapper(props: IndexPageWrapperProps) {
  const {
    children,
    padding,
    showHeader = true,
    title,
    showButtonAddNew = true,
    showButtonBack = true,
    showButtonReload = true,
    showButtonHistory = false,

    showButtonMoreAction = false,
    showConfirmProcess = false,
    showUpdate = false,
    showDelete = false,
    showDuplicate = false,
    showPrint = false,
    showLog = false,
    showNote = false,
    showApproval = false,

    showButtonSave = false,

    contentConfirmProcess,
    contentConfirmDelete,
    contentConfirmSave,

    titleConfirmProcess,
    titleConfirmDelete,
    titleConfirmSave,
    className = '',
    handleAddNew,
    handleUpdate,
    handleDetail,
    handleDuplicate,
    onConfirmActivate,
    onConfirmDeactivate,
    onConfirmDelete,
    onCancelEditing,
    onConfirmSave,
    tableType = 'normal',
    initialFormValue,
    onChangeFormValues,
    ignoreKeyDuplicate = [],
    ignoreKeyUpdate = [],
    formBuilderConfig,
  } = props;

  const { moduleKey } = usePageProviderData();
  const [showContent, setShowContent] = useState(true);
  const { dataSource, transformer, navigation } = usePageProviderAction();
  const { transformerFilterIndexTable, transformerGetIndex } = transformer;

  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [meta, setMeta] = useMetaDataTable(moduleKey);
  const [filter, setFilter] = useFilterDataTable(moduleKey);
  const [disableAddNew, setDisableAddNew] = useState(false);

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [formAction, setFormAction] = useState<'create' | 'update' | 'duplicate'>('create');
  const isEditing = (record: any) => record.key === editingKey;

  async function onReload(): Promise<void> {
    customOnCancelEditing();
    setShowContent(false);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setShowContent(true);
  }

  function manipulationKeyData(response: any[]): any[] {
    const data = transformerGetIndex(response ?? []);
    const newData = data.map((item: any) => {
      return {
        ...item,
        key: item.id,
      };
    });
    return newData;
  }

  async function handleGetDataIndex(filter?: any, isLoadMore?: boolean): Promise<void> {
    setLoading(true);
    await dataSource.handleGetIndex({
      params: transformerFilterIndexTable(filter),
      onSuccess: ({ response }: any) => {
        if (isLoadMore) {
          const currentData = recordData ?? [];
          const manipulateData = manipulationKeyData(response.items) ?? [];
          const newData = [...currentData, ...manipulateData];
          setRecordData(newData);
        } else {
          setRecordData(manipulationKeyData(response.items));
        }
        setMeta(response.meta);
        setFilter(filter);
        setLoading(false);
      },
      onFailed: ({ message }: any) => {
        setLoading(false);
      },
    });
  }

  function makeDataForm(payload: any, action: string): void {
    let formPayload = payload;
    const isUpdate = action === 'update';
    const isDuplicate = action === 'duplicate';

    if (isUpdate) formPayload = _.omit(formPayload, ignoreKeyUpdate);
    else if (isDuplicate) formPayload = _.omit(formPayload, ignoreKeyDuplicate);
    form.setFieldsValue({ form_action: action, ...(formPayload ?? {}) });
  }

  function customHandleUpdate(row: any) {
    if (handleUpdate) handleUpdate(row);
    else if (tableType === 'normal') navigation.goToUpdate({ id: row.id });
    else if (tableType === 'inline-form') {
      setDisableAddNew(true);
      form.resetFields();
      makeDataForm(row, 'update');
      setEditingKey(row.key);
      setFormAction('update');
    }
  }

  function customHandleDuplicate(row: any) {
    if (handleDuplicate) handleDuplicate(row);
    else if (tableType === 'normal') navigation.goToDuplicate({ id: row.id });
    else if (tableType === 'inline-form') {
      const initData = _.omit(row, ['id', ...ignoreKeyDuplicate]);
      Object.assign(initData, {
        key: uuidV4(),
      });
      customHandleAddNew(initData, 'duplicate');
    }
  }

  function customHandleDetail(row: any) {
    if (handleDetail) handleDetail(row);
    else if (tableType === 'normal') navigation.goToDetail({ id: row.id });
  }

  async function handleDelete(payload): Promise<void> {
    if (onConfirmDelete) await onConfirmDelete(payload);
    else {
      if (!payload.id) {
        setRecordData(recordData.filter((item: any) => item.key !== payload.key));
        setMeta({ ...meta, totalItems: meta.totalItems - 1 });
      } else {
        await dataSource.handleDelete(payload, {
          onSuccess: ({ response }: any) => {
            notificationSuccess([response]);
            setRecordData(recordData.filter((item: any) => item.id !== payload.id));
            setMeta({ ...meta, totalItems: meta.totalItems - 1 });
          },
          onFailed: ({ message }: any) => {
            notificationFailed([message]);
          },
        });
      }
    }
  }

  async function handleActivate(payload): Promise<void> {
    if (onConfirmActivate) await onConfirmActivate(payload);
    else {
      await dataSource.handleActivate(payload, {
        onSuccess: ({ response }: any) => {
          notificationSuccess(['Successfully activated']);
        },
        onFailed: ({ message }: any) => {
          notificationFailed([message]);
        },
      });
    }
  }

  async function handleDeactivate(payload): Promise<void> {
    if (onConfirmDeactivate) await onConfirmDeactivate(payload);
    else {
      await dataSource.handleDeactivate(payload, {
        onSuccess: ({ response }: any) => {
          notificationSuccess(['Successfully deactivated']);
        },
        onFailed: ({ message }: any) => {
          notificationFailed([message]);
        },
      });
    }
  }

  function customHandleAddNew(initData?: any, action?: 'create' | 'update' | 'duplicate') {
    if (handleAddNew) handleAddNew();
    else if (tableType === 'normal') navigation.goToCreate();
    else {
      const actionForm = action ?? 'create';
      setDisableAddNew(true);
      const row = initData ?? initialFormValue ?? defaultInitialFormValue;
      setEditingKey(row.key);
      setRecordData([row, ...recordData]);
      setFormAction(actionForm);
      setMeta({ ...meta, totalItems: meta.totalItems + 1 });
      makeDataForm(row, actionForm);
    }
  }

  function customOnCancelEditing() {
    if (onCancelEditing) onCancelEditing();
    else {
      const id = recordData.find((item: any) => item.id === editingKey)?.id;
      if (id) {
        setDisableAddNew(false);
        form.resetFields();
        setEditingKey('');
      } else {
        setDisableAddNew(false);
        setRecordData(recordData.filter((item: any) => item.key !== editingKey));
        setMeta({ ...meta, totalItems: meta.totalItems - 1 });
        form.resetFields();
        setEditingKey('');
      }
    }
  }

  async function create(payload: any): Promise<void> {
    await dataSource.handleCreate(payload, {
      onSuccess: ({ response }: any) => {
        const cleanResponse = transformer?.transformerGetData?.(response) ?? response;
        setRecordData((recordData) =>
          recordData.map((item) => {
            if (item.key !== editingKey) return item;
            return {
              ...cleanResponse,
              key: cleanResponse.id,
              id: cleanResponse.id,
            };
          }),
        );

        setEditingKey('');
        setDisableAddNew(false);
        form.resetFields();
        notificationSuccess(['Successfully created data.']);
      },
      onFailed: ({ message }: any) => {
        notificationFailed(message);
      },
    });
  }

  async function update(id: string, payload: any): Promise<void> {
    await dataSource.handleUpdate(id, payload, {
      onSuccess: ({ response }: any) => {
        const cleanResponse = transformer?.transformerGetData?.(response) ?? response;
        setRecordData((recordData) =>
          recordData.map((item) => {
            if (item.key !== editingKey) return item;
            return {
              ...cleanResponse,
              key: cleanResponse.id,
              id: cleanResponse.id,
            };
          }),
        );
        setEditingKey('');
        setDisableAddNew(false);
        form.resetFields();
        notificationSuccess(['Successfully updated data.']);
      },
      onFailed: ({ message }: any) => {
        notificationFailed(message);
      },
    });
  }

  function transformerCreate(payload: any) {
    if (transformer?.transformerCreate) return transformer.transformerCreate(payload);
    return payload;
  }

  function transformerUpdate(payload: any) {
    if (transformer?.transformerUpdate) return transformer.transformerUpdate(payload);
    return payload;
  }

  function transformerDuplicate(payload: any) {
    if (transformer?.transformerDuplicate) return transformer.transformerDuplicate(payload);
    return payload;
  }

  async function customOnConfirmSave(): Promise<void> {
    try {
      const values = await form.validateFields();

      const isUpdate = formAction === 'update';
      const isDuplicate = formAction === 'duplicate';
      const isCreate = formAction === 'create';
      let payload = values;
      if (isUpdate) payload = transformerUpdate(payload);
      else if (isCreate) payload = transformerCreate(payload);
      else if (isDuplicate) payload = transformerDuplicate(payload);
      if (onConfirmSave) await onConfirmSave(payload);
      else {
        const cleanVal = _.omit(payload, ['id', 'form_action']);
        if (isUpdate) await update(values?.id, cleanVal);
        else await create(cleanVal);
      }
      setDisableAddNew(false);
    } catch (error) {}
  }

  const initValueDataTableProvider: DataTableContextEntity = {
    editingKey,
    setEditingKey,
    recordData,
    setRecordData,
    selectedData,
    setSelectedData,
    form,
    meta,
    filter,
    setMeta,
    loading,
    setFilter,
    tableType,
    formAction,
    isEditing,
    setLoading,
    handleDelete,
    setFormAction,
    handleActivate,
    handleDeactivate,
    handleGetDataIndex,
    handleUpdate: customHandleUpdate,
    handleDetail: customHandleDetail,
    handleDuplicate: customHandleDuplicate,
    onCancelEditing: customOnCancelEditing,
    onConfirmSave: customOnConfirmSave,
    disableAddNew,
    setDisableAddNew,
  };

  async function onChangeForm(item, values) {
    let newValues = undefined;
    if (onChangeFormValues) newValues = await onChangeFormValues(item, values);
    if (newValues) form.setFieldsValue(newValues);
  }

  return (
    <div className={className}>
      {showHeader && (
        <PageHeader
          disableAddNew={disableAddNew}
          onReload={() => onReload()}
          title={title}
          showButtonAddNew={showButtonAddNew}
          showButtonBack={showButtonBack}
          showButtonReload={showButtonReload}
          showButtonHistory={showButtonHistory}
          showButtonMoreAction={showButtonMoreAction}
          showConfirmProcess={showConfirmProcess}
          showUpdate={showUpdate}
          showDelete={showDelete}
          showDuplicate={showDuplicate}
          showPrint={showPrint}
          showLog={showLog}
          showNote={showNote}
          showApproval={showApproval}
          showButtonSave={showButtonSave}
          contentConfirmProcess={contentConfirmProcess}
          contentConfirmDelete={contentConfirmDelete}
          contentConfirmSave={contentConfirmSave}
          titleConfirmProcess={titleConfirmProcess}
          titleConfirmDelete={titleConfirmDelete}
          titleConfirmSave={titleConfirmSave}
          handleAddNew={customHandleAddNew}
        />
      )}
      <PageWrapper padding={padding} showHeader={showHeader}>
        <Spin spinning={!showContent} indicator={<LoadingOutlined />} style={{ minHeight: '50vh' }}>
          {showContent && (
            <DataTableProvider initialValue={initValueDataTableProvider}>
              <Form
                form={form}
                component={false}
                name={`form-inline-table-${moduleKey}`}
                validateMessages={validateMessages}
                onValuesChange={onChangeForm}
              >
                {children}
              </Form>
            </DataTableProvider>
          )}
        </Spin>
      </PageWrapper>
    </div>
  );
}
