import { APPROVAL_MODULE } from '@base-helpers';
import { PurchaseOrder } from '@pages/purchases/purchase-order';
import { PurchaseRequest } from '@pages/purchases/purchase-request';

export const ListPurchaseContent = [
  {
    module: APPROVAL_MODULE.module.PURCHASE,
    sub_module: APPROVAL_MODULE.subModule.purchase.PURCHASE_ORDER,
    content: PurchaseOrder,
  },
  {
    module: APPROVAL_MODULE.module.PURCHASE,
    sub_module: APPROVAL_MODULE.subModule.purchase.PURCHASE_REQUEST,
    content: PurchaseRequest,
  },
];
