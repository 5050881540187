import React from 'react';
import { RouteEntity } from '@base-entities';
import { useParams } from 'react-router-dom';
import { WebUrlData } from '@base-configs';
import { MultiplePrintFactory } from '@pages/multiple-print';

export const DefaultPage = (): JSX.Element => {
  const params = useParams();
  return <div>You are in default page {params.action}</div>;
};

export const DefaultPath = '/print/page/W10=';
export const AuthRoutes: RouteEntity[] = [
  {
    label: 'Multiple Print',
    path: WebUrlData.multiple_print,
    element: MultiplePrintFactory,
  },
  {
    label: 'Company',
    path: WebUrlData.companies,
    element: DefaultPage,
  },
  {
    label: 'Branch',
    path: WebUrlData.branches,
    element: DefaultPage,
  },
];
