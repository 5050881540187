import moment from 'moment';
import renderBarcode from './rendering';
const API_URL = process.env.REACT_APP_BASE_URL;

export const getProducts = async (pickingId) => {
  try {
    // let items = [];
    const response: any = await fetch(
      `https://api.testing.erp.eigen.co.id/api/v1/inventory/transfer/picking-lists/products?detail_id=${pickingId}&module=inventory&sub_module=Picking&action=get`,
    );
    const result = await response.json();
    return result?.message;
  } catch (error) {
    return [];
  }
};

export const getDetailStep = async ({ step, id }) => {
  try {
    // let items = [];
    const response: any = await fetch(`${API_URL}v1/productions/production-orders/${id}/steps?step=${step}`);
    const result = await response.json();
    return result?.message?.[0];
  } catch (error) {
    return [];
  }
};

export const columnsMaterial: any[] = [
  {
    title: 'Material',
    key: 'product_variant',
    dataIndex: 'product_variant',
    render: (item, row) => row?.product_variant?.name ?? row?.product_master?.name ?? row?.product_category?.name,
  },
  {
    title: 'Tracking ID',
    key: 'lot',
    dataIndex: 'lot',
    render: (item, row) => row?.lot?.code ?? row?.batch?.code,
  },
  {
    title: 'Ratio',
    key: 'ratio',
    dataIndex: 'ratio',
    render: (item, row) => '-',
  },
  {
    title: 'Qty Needed',
    key: 'qty_needed',
    dataIndex: 'qty_needed',
    render: (item, row) => item,
  },
  {
    title: 'Qty Received',
    key: 'qty_received',
    dataIndex: 'qty_received',
    render: (item, row) => row?.qty_received ?? row?.unit_received,
  },
  {
    title: 'Qty Used',
    key: 'qty_used',
    dataIndex: 'qty_used',
    render: (item, row) => row?.qty_used ?? row?.unit_used,
  },
  {
    title: 'UoM',
    key: 'unit_of_measurement',
    dataIndex: 'unit_of_measurement',
    render: (item, row) => item?.code,
  },
];

export const columnsStep: any[] = [
  {
    title: 'Step',
    key: 'step',
    dataIndex: 'step',
    render: (item, row) => item,
    style: { textAlign: 'center' },
    grid: { rowSpan: 2 },
  },
  {
    title: 'Process',
    key: 'production_process',
    dataIndex: 'production_process',
    render: (item, row) => item?.name,
    // style: { textAlign: 'center' },
  },
  {
    title: 'Work Center',
    key: 'work_center',
    dataIndex: 'work_center',
    render: (item, row) => item?.name,
    // style: { textAlign: 'center' },
  },
  {
    title: 'Machine',
    key: 'machines',
    dataIndex: 'machines',
    render: (item, row) => item?.[0]?.code,
    // style: { textAlign: 'center' },
  },
  {
    title: 'No Trolley',
    key: 'no_trolley',
    dataIndex: 'no_trolley',
    render: (item, row) => '',
    // style: { textAlign: 'center' },
  },
  {
    title: 'Barcode',
    key: 'work_order',
    dataIndex: 'work_order',
    render: (item, row) => renderBarcode(row?.code),
    // style: { textAlign: 'center' },
  },
  {
    title: 'Output',
    key: 'output',
    dataIndex: '',
    render: (item, row) => '',
    // style: { textAlign: 'center' },
  },
  {
    title: 'Shift and Team',
    key: '',
    dataIndex: '',
    render: (item, row) =>
      row?.step_shifts
        ?.map((item) => {
          return `${item?.shift?.name}: ${item?.production_team?.name ?? '-'}`;
        })
        ?.join(', '),
    // style: { textAlign: 'center' },
  },
  {
    title: 'Start Date',
    key: 'date_start',
    dataIndex: 'date_start',
    render: (item, row) => (item ? moment(item)?.format('DD/MM/YYYY') : '-'),
    // style: { textAlign: 'center' },
  },
  {
    title: 'End Date',
    key: 'expected_date',
    dataIndex: 'expected_date',
    render: (item, row) => (item ? moment(item)?.format('DD/MM/YYYY') : '-'),
    // style: { textAlign: 'center' },
  },
  {
    title: 'Check By',
    key: 'expected_date',
    dataIndex: 'expected_date',
    render: (item, row) => '',
    // style: { textAlign: 'center' },
  },
];
