import { stringFormatter } from '@base-helpers';
import moment from 'moment';

const API_URL = process.env.REACT_APP_BASE_URL;
const API_URL_GATEWAY = process.env.REACT_APP_BASE_URL_GATEWAY;

export const getProducts = async (pickingId) => {
  try {
    // let items = [];
    const response: any = await fetch(
      `${API_URL}v1/inventory/transfer/picking-lists/products?detail_id=${pickingId}&module=inventory&sub_module=Picking&action=get`,
    );
    const result = await response.json();
    return result?.message;
  } catch (error) {
    return [];
  }
};

export const getProductionOrder = async (id) => {
  try {
    const response: any = await fetch(`${API_URL}v1/productions/production-orders/${id}`);
    const result = await response.json();
    return result?.message;
  } catch (error) {
    return null;
  }
};

const renderBaseConversion = (row) => {
  const type = stringFormatter.capitalizeEachWord(row?.compare_attribute_type);
  const baseConversion =
    row?.compare_attribute?.name ??
    row?.compare_product_variant?.name ??
    row?.compare_product_master?.name ??
    row?.compare_product_category?.name;
  return `${type}: ${baseConversion ?? '-'}`;
};

export const columnsMaterial: any[] = [
  {
    title: 'No',
    key: 'product_variant',
    dataIndex: 'product_variant',
    render: (item, row, index) => index + 1,
  },
  {
    title: 'Product Category',
    key: 'product_category',
    dataIndex: 'product_category',
    render: (item, row) => row?.product_category?.name,
  },
  {
    title: 'Material',
    key: 'product_variant',
    dataIndex: 'product_variant',
    render: (item, row) => row?.product_variant?.name ?? row?.product_master?.name ?? row?.product_category?.name,
  },
  {
    title: 'Ratio',
    key: 'ration_qty',
    dataIndex: 'ration_qty',
    render: (item, row) => row?.ratio_qty,
  },
  {
    title: 'UoM',
    key: 'ratio_unit_of_measurement',
    dataIndex: 'ratio_unit_of_measurement',
    render: (item, row) => row?.ratio_unit_of_measurement?.code,
  },
  {
    title: '',
    key: '',
    dataIndex: '',
    render: (item, row) => 'Per',
  },
  {
    title: 'Qty',
    key: 'compare_qty',
    dataIndex: 'compare_qty',
    render: (item, row) => row?.compare_qty,
  },
  {
    title: 'UoM',
    key: 'compare_unit_of_measurement',
    dataIndex: 'compare_unit_of_measurement',
    render: (item, row) => row?.compare_unit_of_measurement?.code,
  },
  {
    title: 'Base Conversion Material',
    key: '',
    dataIndex: '',
    render: (item, row) => renderBaseConversion(row),
  },
  {
    title: 'Qty Needed',
    key: 'qty_needed',
    dataIndex: 'qty_needed',
    render: (item, row) => row?.qty_needed,
  },
  {
    title: 'UoM',
    key: 'qty_needed_unit_of_measurement',
    dataIndex: 'qty_needed_unit_of_measurement',
    render: (item, row) => item?.code,
  },
];

export const groupingProduct = (materials) => {
  let groups = [];
  materials?.forEach((item) => {
    const findCategory = groups?.find((group) => item?.product_category?.id === group?.product_category?.id);
    if (!findCategory) {
      groups = [
        ...groups,
        {
          product_category: item?.product_category,
          non_category: item?.product_category ? false : true,
          products: [],
        },
      ];
    }
  });
  groups = groups?.map((group) => {
    let products = [];
    if (group?.product_category?.id) {
      products = materials?.filter((item) => item?.product_category?.id === group?.product_category?.id);
    }
    if (group?.non_category) {
      products = materials?.filter((item) => !item?.product_category?.id);
    }
    return {
      ...group,
      products: products,
    };
  });
  return groups;
};

export const columnShift: any[] = [
  {
    title: 'Shift',
    key: 'shift',
    dataIndex: 'shift',
    render: (item, row, index) => item?.name,
  },
  {
    title: 'Team',
    key: 'production_team',
    dataIndex: 'production_team',
    render: (item, row, index) => item?.name,
  },
  {
    title: 'PIC',
    key: 'pic',
    dataIndex: 'pic',
    render: (item, row, index) => item?.name,
  },
  {
    title: 'Machine',
    key: 'machine',
    dataIndex: 'machine',
    render: (item, row, index) => item?.code,
  },
  {
    title: 'Start',
    key: 'start_date',
    dataIndex: 'start_date',
    render: (item, row, index) => (item ? moment.utc(item).format('DD/MM/YYYY HH:mm') : ''),
  },
  {
    title: 'End',
    key: 'end_date',
    dataIndex: 'end_date',
    render: (item, row, index) => (item ? moment.utc(item).format('DD/MM/YYYY HH:mm') : ''),
  },
  {
    title: 'Qty Output',
    key: 'total_qty_output',
    dataIndex: 'total_qty_output',
    render: (item, row, index) => item,
  },
  {
    title: 'Scrap Qty',
    key: 'total_qty_scrap',
    dataIndex: 'total_qty_scrap',
    render: (item, row, index) => item,
  },
  {
    title: 'UoM',
    key: 'unit_of_measurement',
    dataIndex: 'unit_of_measurement',
    render: (item, row, index) => item?.code,
  },
  {
    title: 'Unit Output',
    key: 'total_unit_output',
    dataIndex: 'total_unit_output',
    render: (item, row, index) => item,
  },
  {
    title: 'Scrap Unit',
    key: 'total_unit_scrap',
    dataIndex: 'total_unit_scrap',
    render: (item, row, index) => item,
  },
  {
    title: 'UoM',
    key: 'unit_of_measurement_unit',
    dataIndex: 'unit_of_measurement_unit',
    render: (item, row, index) => item?.code,
  },
];
