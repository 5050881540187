const API_URL = process.env.REACT_APP_BASE_URL;
const API_URL_GATEWAY = process.env.REACT_APP_BASE_URL_GATEWAY;

export const getProducts = async (soId, limit?: number) => {
  try {
    const response: any = await fetch(
      `${API_URL}v1/inventory/stock/opnames/products?page=1&limit=${
        limit ?? 1
      }&so_id=${soId}&module=inventory&sub_module=Stock%20Opname&action=get`,
    );
    const result = await response.json();
    if (result?.message?.meta?.totalItems > 1 && !limit) {
      const products = await getProducts(soId, result?.message?.meta?.totalItems);
      return products;
    }
    return result?.message?.items;
  } catch (error) {
    return [];
  }
};
