import React from 'react';

interface Props {
  columns: any[];
  data: any[];
  title?: string;
  style?: any;
}

export const Table = (props: Props) => {
  const { columns, data, title, style } = props;

  return (
    <React.Fragment>
      {title && <h3 style={{ fontWeight: 'bold' }}>{title}</h3>}
      <div style={{ marginBottom: '10px' }}>
        <table style={{ width: '100%', fontSize: '10px', marginBottom: '5px', ...style }}>
          <thead>
            <tr>
              {columns?.map((column, index) => {
                return (
                  <th key={index} style={{ border: '1px solid black', padding: '2px', ...column?.style }}>
                    {column?.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data?.map((dataShift, index) => {
              return (
                <tr key={index}>
                  {columns?.map((column, index) => {
                    return (
                      <td key={index} style={{ border: '1px solid black', padding: '2px', ...column?.style }}>
                        {column?.render(dataShift?.[column?.key], dataShift, index)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
