import moment from 'moment';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

interface PagePrintProviderProps {
  printContent: any;
  children?: JSX.Element | React.ReactNode;
}

export interface PagePrintProviderValue {
  id: string;
  printer: any;
  moduleName: string;
  module: string;
  subModule: string;
  code: string;
  fileName: string;
  isDetail: boolean;
  apiUrl: string;
  detailData: any;
  printContent: any;
  setDetailData: (detailData: any) => void;
  downloadDetail: boolean;
  showPreviewDetail: boolean;
  downloadIndex: boolean;
  showPreviewIndex: boolean;
  type?: string;
}

export const PagePrintProviderContext = React.createContext<PagePrintProviderValue>(null);

export function PagePrintProvider(props: PagePrintProviderProps): JSX.Element {
  const { printContent, children } = props;

  const {
    downloadDetail = true,
    showPreviewDetail = true,
    downloadIndex = true,
    showPreviewIndex = true,
  } = printContent;

  const id = printContent?.id;
  const printer = printContent?.printer;
  const isDetail = printContent?.shape === 'detail';
  const moduleName = `${printContent?.module ?? ''}--${printContent?.sub_module ?? ''}`;
  const module = printContent?.module;
  const subModule = printContent?.sub_module;
  const code = printContent?.code;
  const fileName = printContent?.fileName;
  const apiUrl = printContent?.request_body ? JSON.parse(atob(printContent?.request_body))[0] : '';
  const [detailData, setDetailData] = React.useState(undefined);

  const params = useParams();
  const token = params?.token as any;

  const decodedToken = window.atob(token);
  const parsedToken = JSON.parse(decodedToken);
  const type = parsedToken?.type ?? null;

  const PageProviderPrintValue: PagePrintProviderValue = {
    id,
    apiUrl,
    printer,
    isDetail,
    moduleName,
    fileName,
    detailData,
    setDetailData,
    module,
    code,
    subModule,
    printContent,
    downloadDetail,
    showPreviewDetail,
    downloadIndex,
    showPreviewIndex,
    type,
  };

  return (
    <React.Fragment>
      <PagePrintProviderContext.Provider value={PageProviderPrintValue}>{children}</PagePrintProviderContext.Provider>
    </React.Fragment>
  );
}
