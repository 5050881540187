import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCurrency, RenderText } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter, userPrinter } from '@base-helpers';
import moment from 'moment';
import { Col, Descriptions, Row, Table } from 'antd';
import { getProducts } from './helpers';

export const formatUnderscore = (data: string) => data?.replace(/_/g, ' ');

export function Detail() {
  const { detailData = {}, type } = usePagePrint();
  const [products, setProducts] = useState([]);
  const hasCode = detailData?.code;
  const user = userPrinter();
  const configColumns = [
    {
      title: 'Sub Location',
      key: 'sub_location',
      dataIndex: 'sub_location',
      render: (item: any, row: any) => RenderText({ value: item?.code }),
    },
    {
      title: 'Product',
      key: 'product_variant',
      dataIndex: 'product_variant',
      render: (item: any, row: any) => RenderText({ value: row?.product_variant?.name ?? row?.product_package?.name }),
    },
    {
      title: 'On Hand Unit',
      key: 'on_hand_unit',
      dataIndex: 'on_hand_unit',
      render: (item: any, row: any) => RenderText({ value: item }),
    },
    {
      title: 'Real Unit',
      key: 'real_unit',
      dataIndex: 'real_unit',
      render: (item: any, row: any) => RenderText({ value: item }),
    },
    {
      title: 'UoM',
      key: 'uom_fraction',
      dataIndex: 'uom_fraction',
      render: (item: any, row: any) => {
        if (row?.product_variant?.breakdown_qty) {
          return RenderText({ value: item?.category !== 'UNIT' ? row?.uom?.code : item?.code });
        }
        return RenderText({ value: item?.category === 'UNIT' ? item?.code : '' });
      },
    },
    {
      title: 'On Hand Qty',
      key: 'on_hand_qty',
      dataIndex: 'on_hand_qty',
      render: (item: any, row: any) => RenderText({ value: item }),
    },
    {
      title: 'Real Qty',
      key: 'real_qty',
      dataIndex: 'real_qty',
      render: (item: any, row: any) => RenderText({ value: item }),
    },
    {
      title: 'UoM',
      key: 'uom',
      dataIndex: 'uom',
      render: (item: any, row: any) =>
        RenderText({ value: item?.category !== 'UNIT' ? item?.code : row?.uom_fraction?.code }),
    },
  ];

  const generateProduct = async (soId) => {
    const response = await getProducts(soId);
    setProducts(response);
  };

  useEffect(() => {
    if (detailData?.id) {
      generateProduct(detailData?.id);
    }
  }, [detailData?.id]);

  return (
    <DetailPrint
      orientation="portrait"
      printHeaderProps={{ barcode: detailData?.code }}
      paperFormat={PAPER_FORMAT.A4}
      printSignatureProps={{
        signatures: [
          {
            gridColumn: { span: 6 },
            title: 'Requested By',
            name: detailData?.editor_name ? detailData?.editor_name : '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Printed By',
            name: user?.username ? user?.username : '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Checked By',
            name: detailData?.checker?.name ? detailData?.checker?.name : '_____________',
          },
          { gridColumn: { span: 6 }, title: 'Approved By', name: '_____________' },
        ],
      }}
    >
      <DetailBuilder
        columns={[
          {
            fields: [
              {
                gridColumn: { span: 12 },
                renderField(form) {
                  return (
                    <PrintTitle
                      title={`Stock Opname ${type ? `(${stringFormatter.capitalizeEachWord(type)})` : ''}`}
                      {...(hasCode ? { description: `No. ${detailData?.code}` } : {})}
                    />
                  );
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Created Date',
                name: 'created_at',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Printed Date',
                name: 'id',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: () => moment().format('YYYY-MM-DD'),
                },
              },
              {
                gridColumn: { span: 6 },
                label: 'Status',
                name: 'status',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    let status = value;
                    if (value?.toLowerCase() === 'waiting') status = 'in_process';
                    return stringFormatter.capitalizeEachWord(status?.split('_')?.join(' '));
                  },
                },
              },
              {
                gridColumn: { span: 2 },
                label: 'Site',
                name: 'site',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.code;
                  },
                },
              },
              {
                gridColumn: { span: 2 },
                label: 'Initial Stock',
                name: 'initial_stock',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value ? 'Yes' : 'No';
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Expected Start Date',
                name: 'expected_start_date',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD HH:mm') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Expected End Date',
                name: 'expected_end_date',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD HH:mm') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Start Date',
                name: 'start_date',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD HH:mm') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'End Date',
                name: 'end_date',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD HH:mm') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 6 },
                label: 'Stock Opname Location',
                name: 'location',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value?.name;
                  },
                },
              },
              {
                gridColumn: { span: 9 },
                label: 'References',
                name: 'references',
                fieldType: 'text',
                renderTextProps: {
                  renderType: 'multi',
                  renderValue(value) {
                    return value?.transaction_code ?? value?.code;
                  },
                },
              },
            ],
          },
        ]}
      />
      <Table
        dataSource={products}
        columns={configColumns}
        pagination={false}
        size="small"
        bordered
        scroll={{ x: 'fit-content' }}
        style={{ marginTop: '10px', marginBottom: '10px', overflow: 'hidden', fontSize: '8px !important' }}
      />

      <Row gutter={[8, 8]} style={{ marginBottom: '10px' }}>
        <Col span={14}>
          <div style={{ border: '1px solid #f0f0f0', padding: '10px', height: '100%' }}>
            <div style={{ display: 'block', fontWeight: 'bold', marginBottom: '10px' }}>Note :</div>
            <div>{detailData?.notes ? detailData?.notes : '-'}</div>
          </div>
        </Col>
      </Row>
    </DetailPrint>
  );
}
