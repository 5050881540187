import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderText } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter, userPrinter } from '@base-helpers';
import moment from 'moment';
import { Col, Descriptions, Row, Table } from 'antd';
import PaymentLayout from './payment-layout';
import { renderCurrency } from '../components';

export const formatUnderscore = (data: string) => data?.replace(/_/g, ' ');

export function Detail() {
  const { detailData = {}, type } = usePagePrint();
  const hasCode = detailData?.code;
  const user = userPrinter();

  const renderTransactionReference = ({ data }) => {
    let reference = data?.reference_code;
    if (['CASH', 'TRANSFER']?.includes(data?.payment_method)) {
      reference = data?.reference_code;
    }
    if (data?.payment_method === 'CHEQUE' && detailData?.type === 'CASH OUT') {
      reference = data?.cheque?.code;
    }
    if (data?.payment_method === 'GIRO' && detailData?.type === 'CASH OUT') {
      reference = data?.giro?.code;
    }
    return reference;
  };

  const customLabelContact = (value) => {
    if (value) {
      return `${value?.name} (${value?.code})`;
    }
    return '-';
  };

  const configColumns = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      width: 150,
      render: (item: any, row: any) => RenderText({ value: row?.label?.name }),
    },
    {
      title: 'Sub Label',
      dataIndex: 'sub_label',
      key: 'sub_label',
      width: 150,
      render: (item: any, row: any) => RenderText({ value: row?.sub_label?.name }),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 150,
      render: (item: any, row: any) => RenderText({ value: row?.description }),
    },
    ...((detailData?.account?.type !== 'CASH' && detailData?.type === 'CASH OUT') || detailData?.type === 'CASH IN'
      ? [
          {
            title: 'Payment Method',
            dataIndex: 'payment_method',
            key: 'payment_method',
            width: 150,
            render: (item: any, row: any) => RenderText({ value: row?.payment_method }),
          },
          {
            title: 'Source Bank',
            dataIndex: 'source_bank',
            key: 'source_bank',
            width: 150,
            render: (item: any, row: any) => RenderText({ value: row?.source_bank?.code }),
          },
          {
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            width: 120,
            render: (item: any, row: any) =>
              RenderText({ value: row?.due_date ? moment(row?.due_date).format('DD/MM/YYYY') : '-' }),
          },
        ]
      : []),
    {
      title: 'Reference ID',
      dataIndex: 'references',
      key: 'references',
      width: 250,
      render: (item: any, row: any) => renderTransactionReference({ data: row }),
    },
    {
      title: detailData?.type === 'CASH IN' ? 'Partner' : 'Contact',
      dataIndex: 'contact',
      key: 'contact',
      width: 200,
      render: (item: any, row: any) => RenderText({ value: row?.partner?.code ?? row?.contact?.code }),
    },
    ...(detailData?.type === 'CASH OUT' && detailData?.account?.type === 'BANK'
      ? [
          {
            title: 'Destination Bank',
            dataIndex: 'destination_bank',
            key: 'destination_bank',
            editable: true,
            width: 120,
            render: (item: any, row: any) => RenderText({ value: customLabelContact(row?.destination_bank) }),
          },
          {
            title: 'Destination Bank Account',
            dataIndex: 'destination_bank_account',
            key: 'destination_bank_account',
            editable: true,
            width: 140,
            render: (item: any, row: any) =>
              RenderText({
                value: row?.destination_bank_account
                  ? `${row?.destination_bank_account?.bank_account_no} ${row?.destination_bank_account?.on_behalf_of}`
                  : '-',
              }),
          },
        ]
      : []),
    {
      title: detailData?.type === 'CASH IN' ? 'Income' : 'Cost',
      dataIndex: 'debit',
      key: 'debit',
      width: 100,
      render: (item: any, row: any) => renderCurrency({ value: row?.debit }),
    },
    {
      title: detailData?.type === 'CASH IN' ? 'Income Reduction' : 'Cost Reduction',
      dataIndex: 'credit',
      key: 'credit',
      width: 100,
      render: (item: any, row: any) => renderCurrency({ value: row?.credit }),
    },
  ];

  const payment = detailData?.references?.find(
    (item) => item?.is_generated === true && item?.sub_module === 'sales payment',
  );

  if (payment) {
    return <PaymentLayout data={detailData} />;
  }

  return (
    <DetailPrint
      orientation="portrait"
      printHeaderProps={{ barcode: detailData?.code }}
      paperFormat={PAPER_FORMAT.A4}
      printSignatureProps={{
        signatures: [
          {
            gridColumn: { span: 6 },
            title: 'Paid By',
            name: detailData?.creator_name ? detailData?.creator_name : '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Received By',
            name: detailData?.editor_name ? detailData?.editor_name : '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Created By',
            name: detailData?.creator_name ? detailData?.creator_name : '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Confirmed By',
            name: detailData?.editor_name ? detailData?.editor_name : '-',
          },
        ],
      }}
    >
      <DetailBuilder
        columns={[
          {
            fields: [
              {
                gridColumn: { span: 12 },
                renderField(form) {
                  return (
                    <PrintTitle
                      title={`${stringFormatter.capitalizeEachWord(detailData?.type?.toLowerCase())} ${
                        type ? `(${stringFormatter.capitalizeEachWord(type)})` : ''
                      }`}
                      {...(hasCode ? { description: `No. ${detailData?.code}` } : {})}
                    />
                  );
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Created Date',
                name: 'created_at',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Printed Date',
                name: 'id',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: () => moment().format('YYYY-MM-DD'),
                },
              },
              {
                gridColumn: { span: 6 },
                label: 'Status',
                name: 'status',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    let status = value;
                    if (value?.toLowerCase() === 'waiting') status = 'in_process';
                    return stringFormatter.capitalizeEachWord(status?.split('_')?.join(' '));
                  },
                },
              },
              {
                gridColumn: { span: 2 },
                label: 'Site',
                name: 'site',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.code;
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Date',
                name: 'date',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD HH:mm') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Source Account',
                name: 'account',
                fieldType: 'text',
                renderTextProps: {
                  renderType: 'multi',
                  renderValue(value) {
                    if (value?.bank_account) {
                      return `${value?.bank_account?.account_name} - ${value?.bank_account?.account_no} (${value?.code})`;
                    } else {
                      return `${value?.cash_account?.account_name} (${value?.code})`;
                    }
                  },
                },
              },
              {
                gridColumn: { span: 15 },
                label: 'Account Reference',
                name: 'account_reference',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.code;
                  },
                },
              },
              {
                gridColumn: { span: 9 },
                label: 'Contact',
                name: 'contact',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    if (value) {
                      return `${value?.name} (${value?.code})`;
                    }
                  },
                },
              },
              {
                gridColumn: { span: 6 },
                label: 'References',
                name: 'references',
                fieldType: 'text',
                renderTextProps: {
                  renderType: 'multi',
                  renderValue(value) {
                    return value?.transaction_code ?? value?.code;
                  },
                },
              },
              {
                gridColumn: { span: 6 },
                label: 'For',
                name: 'for',
                fieldType: 'text',
                renderTextProps: {
                  renderType: 'multi',
                  renderValue(value) {
                    return value;
                  },
                },
              },
            ],
          },
        ]}
      />
      <Table
        dataSource={detailData?.transaction_lines}
        columns={configColumns}
        pagination={false}
        size="small"
        bordered
        scroll={{ x: 'fit-content' }}
        key={JSON.stringify(detailData?.transaction_lines)}
        style={{ marginTop: '10px', marginBottom: '10px', overflow: 'hidden', fontSize: '8px !important' }}
      />

      <Row gutter={[8, 8]} style={{ marginBottom: '10px' }}>
        <Col span={14}>
          <div style={{ border: '1px solid #f0f0f0', padding: '10px', height: '100%' }}>
            <div style={{ display: 'block', fontWeight: 'bold', marginBottom: '10px' }}>Note :</div>
            <div>{detailData?.notes ? detailData?.notes : '-'}</div>
          </div>
        </Col>
        <Col span={10}>
          <Descriptions bordered size="small">
            <Descriptions.Item label="Initial Balance" span={24}>
              <span style={{ textAlign: 'right' }}>{renderCurrency({ value: detailData?.initial_balance })}</span>
            </Descriptions.Item>
            <Descriptions.Item label="Remaining Balance" span={24}>
              <span style={{ textAlign: 'right' }}>{renderCurrency({ value: detailData?.remaining_balance })}</span>
            </Descriptions.Item>
            <Descriptions.Item label="Total" span={24}>
              <span style={{ textAlign: 'right' }}>{renderCurrency({ value: detailData?.total })}</span>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </DetailPrint>
  );
}
