import { stringFormatter } from '@base-helpers';
import moment from 'moment';

const API_URL = process.env.REACT_APP_BASE_URL;
const API_URL_GATEWAY = process.env.REACT_APP_BASE_URL_GATEWAY;

export const getProducts = async (pickingId) => {
  try {
    // let items = [];
    const response: any = await fetch(
      `${API_URL}v1/inventory/transfer/picking-lists/products?detail_id=${pickingId}&module=inventory&sub_module=Picking&action=get`,
    );
    const result = await response.json();
    return result?.message;
  } catch (error) {
    return [];
  }
};

export const getProductionOrder = async (id) => {
  try {
    const response: any = await fetch(`${API_URL}v1/productions/production-orders/${id}`);
    const result = await response.json();
    return result?.message;
  } catch (error) {
    return null;
  }
};

const renderBaseConversion = (row) => {
  const type = stringFormatter.capitalizeEachWord(row?.compare_attribute_type);
  const baseConversion =
    row?.compare_attribute?.name ??
    row?.compare_product_variant?.name ??
    row?.compare_product_master?.name ??
    row?.compare_product_category?.name;
  return `${type}: ${baseConversion ?? '-'}`;
};

export const generateColumnMaterial = (materials: any[]) => {
  const renderQty = ({ data, type, category }) => {
    const findOther = materials?.find(
      (item) => item?.product_variant?.id === data?.product_variant?.id && item?.id !== data?.id,
    );
    if (category === 'non_unit') {
      if (type === 'standard' && data?.qty_needed_unit_of_measurement?.category?.toLowerCase() !== 'unit') {
        return findOther?.qty_needed;
      }
      if (type === 'requested' && data?.qty_needed_unit_of_measurement?.category?.toLowerCase() !== 'unit') {
        return data?.qty_needed;
      }
      if (type === 'received') return findOther?.qty_received;
    }
    if (category === 'unit') {
      if (type === 'standard' && data?.qty_needed_unit_of_measurement?.category?.toLowerCase() === 'unit') {
        return findOther?.qty_needed;
      }
      if (type === 'requested' && data?.qty_needed_unit_of_measurement?.category?.toLowerCase() === 'unit') {
        return data?.qty_needed;
      }
      if (type === 'received') return findOther?.unit_received;
    }
  };

  const columnsMaterial: any[] = [
    {
      title: 'Material',
      key: 'product_variant',
      dataIndex: 'product_variant',
      render: (item, row) => row?.product_variant?.name ?? row?.product_master?.name ?? row?.product_category?.name,
    },
    {
      title: 'Tracking ID',
      key: 'lot',
      dataIndex: 'lot',
      render: (item, row) => row?.lot?.code ?? row?.batch?.code,
    },
    {
      title: 'Ratio',
      key: 'ratio_qty',
      dataIndex: 'ratio_qty',
      render: (item, row) => item,
    },
    {
      title: 'Qty Standard',
      key: 'qty_standard',
      dataIndex: 'qty_standard',
      render: (item, row) => renderQty({ data: row, type: 'standard', category: 'non_unit' }),
    },
    {
      title: 'Qty Received',
      key: 'qty_received',
      dataIndex: 'qty_received',
      render: (item, row) => renderQty({ data: row, type: 'received', category: 'non_unit' }),
    },
    {
      title: 'Qty Request',
      key: 'qty_needed',
      dataIndex: 'qty_needed',
      render: (item, row) => renderQty({ data: row, type: 'requested', category: 'non_unit' }),
    },
    {
      title: 'UoM',
      key: 'product_variant',
      dataIndex: 'product_variant',
      render: (item, row) => {
        if (item?.breakdown_qty) {
          if (item?.uom?.category !== 'UNIT') return item?.uom?.code;
          if (item?.uom?.category === 'UNIT') return item?.fraction_uom?.code;
        }
        if (!item?.breakdown_qty) {
          if (item?.uom?.category !== 'UNIT') return item?.uom?.code;
        }
      },
    },
    {
      title: 'Unit Standard',
      key: 'unit_standard',
      dataIndex: 'unit_standard',
      render: (item, row) => renderQty({ data: row, type: 'standard', category: 'unit' }),
    },
    {
      title: 'Unit Received',
      key: 'unit_received',
      dataIndex: 'unit_received',
      render: (item, row) => renderQty({ data: row, type: 'received', category: 'unit' }),
    },
    {
      title: 'Unit Request',
      key: 'unit_needed',
      dataIndex: 'unit_needed',
      render: (item, row) => renderQty({ data: row, type: 'requested', category: 'unit' }),
    },
    {
      title: 'UoM',
      key: 'product_variant',
      dataIndex: 'product_variant',
      render: (item, row) => {
        if (item?.breakdown_qty) {
          if (item?.uom?.category === 'UNIT') return item?.uom?.code;
          if (item?.uom?.category !== 'UNIT') return item?.fraction_uom?.code;
        }
        if (!item?.breakdown_qty) {
          if (item?.uom?.category === 'UNIT') return item?.uom?.code;
        }
      },
    },
  ];
  return columnsMaterial;
};

export const groupingProduct = (materials) => {
  let groups = [];
  materials?.forEach((item) => {
    groups = [
      ...groups,
      {
        product_category: item?.product_category,
        non_category: item?.product_category ? false : true,
        products: [],
      },
    ];
  });
  groups = groups?.map((group) => {
    let products = [];
    if (group?.product_category?.id) {
      products = materials?.filter((item) => item?.product_category?.id === group?.product_category?.id);
    }
    if (group?.non_category) {
      products = materials?.filter((item) => !item?.product_category?.id);
    }
    return {
      ...group,
      products: products,
    };
  });
  return groups;
};
