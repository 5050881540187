import React from 'react';
import { defaultRenderDetail, makeItemValue, makeValueForRender } from '../base-render';
import { RenderTagProps } from './render-tag.entity';
import { Tag } from 'antd';
import * as _ from 'lodash';
import './render-tag.less';

const IgnoreKeyTag = [
  'value',
  'renderValue',
  'keyValue',
  'renderType',
  'classNameItem',
  'classNameWrapper',
  'defaultRender',
];

export function RenderTag(props: RenderTagProps) {
  const {
    value,
    renderValue,
    keyValue = 'code',
    renderType = 'single',
    classNameItem = '',
    classNameWrapper = '',
    defaultRender = defaultRenderDetail,
    color,
    customColor,
    styleWrapper,
    styleItem,
  } = props;
  if (value === null || value === undefined) return <div>{defaultRender(value)}</div>;
  const valueForRender = makeValueForRender(value);
  const singleValueForRender = valueForRender[0];
  const propsTag = _.omit(props, IgnoreKeyTag) ?? {};

  function Rendering() {
    if (renderType === 'single') {
      const colorTag = customColor ? customColor(singleValueForRender) : color;
      return (
        <Tag {...propsTag} className={`${classNameItem}`} color={colorTag}>
          {renderValue ? renderValue(singleValueForRender) : singleValueForRender}
        </Tag>
      );
    }
    return (
      <div className={`render-tag-wrapper ${classNameWrapper}`} style={styleWrapper}>
        {valueForRender.map((item, idx) => {
          const itemValue = makeItemValue(item, keyValue);
          const colorTag = customColor ? customColor(item) : color;

          return (
            <Tag
              {...propsTag}
              key={idx}
              className={`render-tag-item ${classNameItem}`}
              color={colorTag}
              style={styleItem}
            >
              <span>{renderValue ? renderValue(item) : itemValue}</span>
            </Tag>
          );
        })}
      </div>
    );
  }
  return (
    <div className="render-tag-component">
      <Rendering />
    </div>
  );
}
