import React from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCurrency, RenderText } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter, userPrinter } from '@base-helpers';
import moment from 'moment';
import { Col, Descriptions, Row, Table } from 'antd';

export const formatUnderscore = (data: string) => data?.replace(/_/g, ' ');

export function Detail() {
  const { detailData = {}, type } = usePagePrint();
  const salesType = detailData?.type;
  const hasCode = detailData?.code;
  const dataSource = detailData?.transfer_order_products;
  const user = userPrinter();
  const configColumns = [
    {
      title: 'ID',
      key: 'product_variant',
      dataIndex: 'product_variant',
      render: (item: any, row: any) => RenderText({ value: row?.product_variant?.code ?? row?.product_package?.code }),
    },
    {
      title: 'Name',
      key: 'product_variant',
      dataIndex: 'product_variant',
      render: (item: any, row: any) => RenderText({ value: row?.product_variant?.name ?? row?.product_package?.name }),
    },
    {
      title: 'Unit',
      key: 'total_unit',
      dataIndex: 'total_unit',
      render: (item: any, row: any) => RenderText({ value: item }),
    },
    {
      title: 'UoM',
      key: 'uom_unit',
      dataIndex: 'uom_unit',
      render: (item: any, row: any) => RenderText({ value: item?.code }),
    },
    {
      title: 'Qty',
      key: 'total_qty',
      dataIndex: 'total_qty',
      render: (item: any, row: any) => RenderText({ value: item }),
    },
    {
      title: 'UoM',
      key: 'uom_qty',
      dataIndex: 'uom_qty',
      render: (item: any, row: any) => RenderText({ value: item?.code }),
    },
  ];
  return (
    <DetailPrint
      orientation="portrait"
      printHeaderProps={{ barcode: detailData?.code }}
      paperFormat={PAPER_FORMAT.A5}
      printSignatureProps={{
        signatures: [
          {
            gridColumn: { span: 6 },
            title: 'Requested By',
            name: detailData?.editor_name ? detailData?.editor_name : '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Printed By',
            name: user?.username ? user?.username : '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Delivered By',
            name: '_____________',
          },
          { gridColumn: { span: 6 }, title: 'Approved By', name: '_____________' },
        ],
      }}
    >
      <DetailBuilder
        columns={[
          {
            fields: [
              {
                gridColumn: { span: 12 },
                renderField(form) {
                  return (
                    <PrintTitle
                      title={`Transfer Order ${type ? `(${stringFormatter.capitalizeEachWord(type)})` : ''}`}
                      {...(hasCode ? { description: `No. ${detailData?.code}` } : {})}
                    />
                  );
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Created Date',
                name: 'created_at',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Printed Date',
                name: 'id',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: () => moment().format('YYYY-MM-DD'),
                },
              },
              {
                gridColumn: { span: 6 },
                label: 'Status',
                name: 'status',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => stringFormatter.capitalizeEachWord(value?.split('_')?.join(' ')),
                },
              },
              {
                gridColumn: { span: 2 },
                label: 'Site',
                name: 'site',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.code;
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Transfer Type',
                name: 'activity_type',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return formatUnderscore(value);
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Transfer Plan Date',
                name: 'plan_date',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD HH:mm') : '-';
                  },
                },
              },
              // {
              //   gridColumn: { span: 3 },
              //   label: 'Delivered Date',
              //   name: 'plan_date',
              //   fieldType: 'text',
              //   renderTextProps: {
              //     renderValue: (value) => {
              //       return value ? dateFormatter(value).toLocal('YYYY-MM-DD HH:mm') : '-';
              //     },
              //   },
              // },
              {
                gridColumn: { span: 3 },
                label: 'Received Date',
                name: 'receipt_date',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD HH:mm') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 9 },
                label: 'References',
                name: 'references',
                fieldType: 'text',
                renderTextProps: {
                  renderType: 'multi',
                  renderValue(value) {
                    return value?.transaction_code ?? value?.code;
                  },
                },
              },
              {
                gridColumn: { span: 12 },
                label: 'From',
                name: 'source_location',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    if (detailData?.activity_type === 'LOCATION_TRANSFER') {
                      return detailData?.source_location?.name;
                    }
                    if (detailData?.source_site?.code) {
                      return (
                        <div>
                          <div>
                            {detailData?.source_site?.code} - {detailData?.source_site?.name}
                          </div>
                          <div>{detailData?.source_site?.address}</div>
                        </div>
                      );
                    }
                  },
                },
              },
              {
                gridColumn: { span: 12 },
                label: 'To',
                name: detailData?.activity_type === 'GENERAL' ? 'send_to' : 'destination_site',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    if (detailData?.activity_type === 'LOCATION_TRANSFER') {
                      return detailData?.destination_location?.name;
                    }
                    if (detailData?.activity_type === 'WAREHOUSE_TRANSFER') {
                      return (
                        <div>
                          <div>
                            {detailData?.destination_site?.code} - {detailData?.destination_site?.name}
                          </div>
                          <div>{detailData?.destination_site?.address}</div>
                        </div>
                      );
                    }
                    return (
                      <React.Fragment>
                        <div>{detailData?.send_to?.name}</div>
                        <div>{detailData?.address}</div>
                      </React.Fragment>
                    );
                  },
                },
              },
            ],
          },
        ]}
      />
      <Table
        dataSource={dataSource}
        columns={configColumns}
        pagination={false}
        size="small"
        bordered
        scroll={{ x: 'fit-content' }}
        style={{ marginTop: '10px', marginBottom: '10px', overflow: 'hidden', fontSize: '8px !important' }}
      />

      <Row gutter={[8, 8]} style={{ marginBottom: '10px' }}>
        <Col span={14}>
          <div style={{ border: '1px solid #f0f0f0', padding: '10px', height: '100%' }}>
            <div style={{ display: 'block', fontWeight: 'bold', marginBottom: '10px' }}>Note :</div>
            <div>{detailData?.notes ? detailData?.notes : '-'}</div>
          </div>
        </Col>
      </Row>
    </DetailPrint>
  );
}
