import React from 'react';
import { delimiterNumber } from './delimiter-number';

const QtyPerUnit = (props) => {
  const { data, isResult } = props;
  const number = [];
  for (let i = 0; i < 20; i++) {
    number.push(i + 1);
  }
  const line = Math.ceil(data?.length / 20) ?? 1;
  const limit = 20;

  let newData = [];
  const totalDataLength = limit * line;
  for (let i = 0; i < totalDataLength; i++) {
    newData = [...newData, data?.[i] ?? ''];
  }

  return (
    // <>
    <table
      className="w-100 table-qty-per-unit table-work-order my-0 py-0"
      style={{ padding: 0, margin: 0, width: '100%' }}
    >
      {data?.length > 0 ? (
        <>
          <thead>
            <tr>
              <th style={{ border: '1px solid black' }}></th>
              {number?.map((s, i) => {
                if (i < limit) {
                  return (
                    <th style={{ border: '1px solid black', fontSize: '8px' }} className="cell-mw-35" key={i}>
                      {i + 1}
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
          <tbody>
            {newData?.map((x, i) => {
              if (i % limit === 0) {
                return (
                  <tr key={i}>
                    <td
                      // className={`cell-mw-35${isResult ? ' h-75' : ''}`}
                      style={{
                        // fontWeight: 900,
                        width: '80px',
                        fontSize: '8px',
                        border: '1px solid black',
                        textAlign: 'center',
                      }}
                    >
                      {i > 0 ? i / limit + 1 : i + 1}
                    </td>
                    {newData?.map((y, j) => {
                      if (j >= i && j < ((i + limit) / limit) * limit) {
                        return (
                          <td
                            style={{
                              border: '1px solid black',
                              fontSize: '8px',
                              width: '4.9%',
                              height: '20px',
                              textAlign: 'center',
                            }}
                            className={`cell-mw-35 ${isResult ? 'm-h-100' : ''}`}
                            key={j}
                          >
                            {typeof y === 'number' ? delimiterNumber(y) : y}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              }
            })}
          </tbody>
        </>
      ) : (
        <tbody>Tidak memiliki data qty/unit</tbody>
      )}
    </table>
  );
};

export default QtyPerUnit;
