import React, { useEffect, useState } from 'react';
import { DetailPrint, RenderCountCurrency } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { makeModuleBarcode, PAPER_FORMAT, stringFormatter } from '@base-helpers';
import moment from 'moment';
import { Col, Row } from 'antd';
import { getVirtualAccounts } from './helpers';
import QRCode from 'react-qr-code';
import { renderCurrency } from '../components';

export function Detail() {
  const { detailData = {}, printContent } = usePagePrint();
  const [virtualAccounts, setVirtualAccounts] = useState([]);
  const defaultSignature: any[] = [
    {
      title: 'Hormat Kami',
      name: '',
      gridColumn: { span: 6 },
    },
  ];
  const columns: any[] = [
    {
      title: 'Item Number',
      key: 'product',
      dataIndex: 'product',
      render: (item) => item?.code,
    },
    {
      title: 'Description',
      key: 'product',
      dataIndex: 'product',
      render: (item) => item?.name,
      style: { minWidth: '300px' },
    },
    {
      title: 'Quantity',
      key: 'real_qty',
      dataIndex: 'real_qty',
      render: (item, row) => row?.qty ?? row?.unit,
      style: { textAlign: 'right' },
    },
    {
      title: 'Unit',
      key: 'uom_default',
      dataIndex: 'uom_default',
      render: (item) => item?.code,
      style: { textAlign: 'right' },
    },
    {
      title: 'Unit Price',
      key: 'price_per_unit',
      dataIndex: 'price_per_unit',
      render: (item) => renderCurrency({ value: item ?? '0' }),
      style: { textAlign: 'right' },
    },
    {
      title: 'Discount',
      key: 'disc_total_after_tax',
      dataIndex: 'disc_total_after_tax',
      render: (item) => (item ? renderCurrency({ value: item ?? '0' }) : '-'),
      style: { textAlign: 'right' },
    },
    {
      title: 'Amount',
      key: 'subtotal_after_tax',
      dataIndex: 'subtotal_after_tax',
      render: (item) => renderCurrency({ value: item ?? '0' }),
      style: { textAlign: 'right' },
    },
  ];

  const generateVirtualAccount = async (company) => {
    const response = await getVirtualAccounts(company?.id);
    const virtualAccounts = response?.filter((item) => item?.customer?.id === detailData?.customer?.id);
    setVirtualAccounts(virtualAccounts);
  };

  useEffect(() => {
    generateVirtualAccount(detailData?.company);
  }, [detailData?.company]);

  return (
    <DetailPrint paperFormat={PAPER_FORMAT.A4} orientation="portrait" printHeaderProps={{ showLogo: false }}>
      <div style={{ fontSize: '11px', lineHeight: '12px' }}>
        <Row gutter={10}>
          <Col span={10}>
            <p>
              {detailData?.customer?.name} ({detailData?.customer?.code})
            </p>
            <p>{detailData?.invoice_address ?? ''}</p>
          </Col>
          <Col span={10}>
            <h2>{detailData?.type ? stringFormatter.capitalizeEachWord(detailData?.type) : '-'}</h2>
            <table>
              <tr>
                <td>Number</td>
                <td>{detailData?.code ?? '-'}</td>
              </tr>
              <tr>
                <td>Invoice date</td>
                <td>{detailData?.invoice_date ? moment(detailData?.invoice_date).format('DD/MM/YYYY') : '-'}</td>
              </tr>
              <tr>
                <td>Sales order</td>
                <td>
                  {detailData?.references?.find((item) => item?.sub_module === 'sales_order')?.transaction_code ?? '-'}
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: '10px' }}>Your reference</td>
                <td>
                  {detailData?.references
                    ?.map((item) => {
                      if (item?.sub_module !== 'sales_order') {
                        return item?.transaction_code;
                      }
                      return null;
                    })
                    .filter(Boolean)
                    .join(', ')}
                </td>
              </tr>
            </table>
          </Col>
          <Col span={4} style={{ paddingTop: 0 }}>
            <QRCode
              value={
                makeModuleBarcode({
                  id: detailData?.id,
                  module: printContent?.module,
                  subModule: printContent?.sub_module,
                }) ?? ''
              }
              size={80}
            />
          </Col>
        </Row>
        <p style={{ fontSize: '11px' }}>
          {printContent?.editor_name ?? printContent?.creator_name ?? ''}@{moment().format('DD/MM/YYYY HH:mm:ss')}WRH:{' '}
          {printContent?.site?.code ?? ''}
          {detailData?.sales_division?.code ?? ''}
        </p>
        <table style={{ width: '100%', marginTop: '5px' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid black', textAlign: 'left' }}>
              {columns?.map((column, index) => {
                return (
                  <th style={column?.style} key={`${column?.key}-header-${index}`}>
                    {column?.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {detailData?.product_line?.map((line, index) => {
              return (
                <tr key={`line-${index}`}>
                  {columns?.map((column, colIndex) => {
                    return (
                      <td style={column?.style} key={`${column?.key}-${index}-${colIndex}`}>
                        {column?.render(line[column?.key], line)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr style={{ borderTop: '1px solid black' }}>
              <td colSpan={2}>Total</td>
              <td style={{ textAlign: 'right' }}>
                {detailData?.product_line?.reduce((partialSum, a) => partialSum + (a?.qty ?? a?.unit), 0)}
              </td>
              <td colSpan={4}></td>
            </tr>
          </tbody>
        </table>
        <table style={{ width: '100%', marginTop: '5px', textAlign: 'right' }}>
          <thead>
            <tr>
              <th>Sales Subtotal</th>
              <th colSpan={3}></th>
            </tr>
            <tr>
              <th style={{ borderBottom: '1px solid black' }}>Amount</th>
              <th style={{ borderBottom: '1px solid black' }}>Sales Tax</th>
              <th style={{ borderBottom: '1px solid black' }}>Total</th>
              <th style={{ minWidth: '500px', borderBottom: '1px solid black' }}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{renderCurrency({ value: detailData?.outcome_total?.sub_total })}</td>
              <td>
                {detailData?.outcome_total?.tax ? renderCurrency({ value: detailData?.outcome_total?.tax }) : '-'}
              </td>
              <td>{renderCurrency({ value: detailData?.outcome_total?.total })}</td>
              <td style={{ textAlign: 'left', paddingLeft: '20px' }}>
                Terbilang: # {RenderCountCurrency({ value: detailData?.outcome_total?.total })} #
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ marginTop: '10px' }}>
          <tr>
            <td style={{ minWidth: '200px' }}>Hormat kami,</td>
            <td>Virtual no</td>
            <td>:{virtualAccounts?.map((item) => `${item?.bank_account?.bank?.code}-${item?.code}`)?.join(', ')}</td>
          </tr>
          <tr>
            <td></td>
            <td>Name</td>
            <td>
              :{detailData?.customer?.name} ({detailData?.customer?.code})
            </td>
          </tr>
        </table>
      </div>
    </DetailPrint>
  );
}
