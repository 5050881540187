import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useCollapsibleLayout, userNavbarPosition, useShowSidebarLayout } from '@base-hooks';

import { Layout } from 'antd';

import Sidebar from './sidebar';
import Navbar from './navbar';
import './styles/auth-layout.less';

export default function AuthLayout({ children }: { children: JSX.Element }) {
  const [collapsed] = useCollapsibleLayout();
  const [showSidebar] = useShowSidebarLayout();
  const [navbarPosition] = userNavbarPosition();
  const isNavbarOutOfLayout = navbarPosition === 'outside_the_layout';

  function makeClassNameLayout(): string {
    if (!showSidebar) return '';
    else if (collapsed) return 'margin-sidebar-collapsed';
    return 'margin-sidebar-not-collapsed';
  }

  return (
    <React.Fragment>
      <Outlet />
      <Layout hasSider>
        {isNavbarOutOfLayout && <Navbar />}
        {showSidebar && <Sidebar />}
        <Layout className={`${makeClassNameLayout()} custom-layout-background`}>
          {!isNavbarOutOfLayout && <Navbar />}
          <div className="auth-layout-content-wrapper">{children}</div>
        </Layout>
      </Layout>
    </React.Fragment>
  );
}
