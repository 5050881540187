import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { PAPER_FORMAT, stringFormatter } from '@base-helpers';
import moment from 'moment';
import { Col, Row } from 'antd';
import {
  columnsMaterial,
  generateLabel,
  generateLabelNonVariant,
  generateLabelVariant,
  getProductionOrder,
  groupingProduct,
} from './helpers';
import { useBarcode } from '@createnextapp/react-barcode';

export function Detail() {
  const { detailData = {}, printContent, setDetailData } = usePagePrint();
  const hasCode = detailData?.code;
  const [productGroup, setProductGroup] = useState([]);
  const [size, setSize] = useState(PAPER_FORMAT.LABEL_10);
  const [labelNonVariant, setLabelNonVariant] = useState([]);
  const [labelVariant, setLabelVariant] = useState([]);
  const [labels, setLabels] = useState([]);
  const [qtyUnit, setQtyUnit] = useState([]);

  const generateProduct = async (materials) => {
    const result = groupingProduct(materials);
    setProductGroup(result);
  };

  const generateQtyUnit = (shifts) => {
    let qtyUnit = [];
    shifts?.forEach((shift) => {
      if (shift?.qty_unit_output?.[0]?.value) {
        qtyUnit = [...qtyUnit, ...shift?.qty_unit_output?.[0]?.value];
      }
    });
    return qtyUnit;
  };

  const generateProductionOrder = async (payload) => {
    const result = await getProductionOrder(payload?.production_order?.id);
    const labelNonVariant = generateLabelNonVariant(payload?.labels, payload);
    const labelVariant = generateLabelVariant(payload);
    const labels = generateLabel(payload);
    const qtyUnit = generateQtyUnit(payload?.shifts);
    setQtyUnit(qtyUnit);
    setLabels(labels);
    setLabelVariant(labelVariant);
    setLabelNonVariant(labelNonVariant);
    setDetailData({ ...payload, production_order: result });
  };

  // console.log({ labels })

  return (
    <DetailPrint
      paperFormat={size}
      orientation="portrait"
      onSuccessLoadData={(payload) => generateProductionOrder(payload)}
    >
      {qtyUnit?.length > 0 ? (
        qtyUnit?.map((qty, index) => {
          return (
            <div
              key={index}
              style={{
                fontSize: '10px',
                fontWeight: 'bold',
                lineHeight: '24px',
                // height: '100mm',
                // pageBreakAfter: 'always',
                pageBreakBefore: 'always',
                // width: '100mm',
                padding: 0,
                overflow: 'hidden',
                marginTop: index === 0 ? '-20px' : 0,
                // border: '1px solid black',
              }}
            >
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ paddingRight: '10px', width: '50%' }}></td>
                    <td style={{ paddingLeft: '10px' }}>
                      LOT : {detailData?.production_order?.steps?.[0]?.step_needs?.[0]?.lot?.code ?? ''}
                    </td>
                  </tr>
                  {/* <tr>
                    <td style={{ paddingRight: '10px' }}></td>
                    <td style={{ fontWeight: 'bold', paddingLeft: '10px' }}>
                      {detailData?.production_order?.steps?.[0]?.step_needs?.[0]?.lot?.code ?? ''}
                    </td>
                  </tr> */}
                  <tr style={{ borderBottom: '1px solid black' }}>
                    <td style={{ paddingRight: '10px' }}></td>
                    <td style={{ fontWeight: 'bold', paddingLeft: '10px' }}>
                      {detailData?.production_order?.code ?? ''}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ display: 'flex', justifyContent: 'between' }}>
                {labels?.map((column, colIdx) => {
                  return (
                    <table style={{ width: '50%' }} key={colIdx}>
                      <tbody>
                        {column?.labels?.map((row, rowIdx) => {
                          return (
                            <tr key={`${colIdx} - ${rowIdx}`}>
                              <td style={{ paddingLeft: colIdx === 1 ? '10px' : '0' }}>
                                {row?.label ? row?.label?.toUpperCase() : ''} :{' '}
                                {row?.label?.toLowerCase() === 'yard' ? qty : row?.value ?? ''}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  );
                })}
              </div>
            </div>
          );
        })
      ) : (
        <div
          style={{
            fontSize: '10px',
            fontWeight: 'bold',
            lineHeight: '24px',
            // height: '100mm',
            // width: '100mm',
            padding: 0,
            overflow: 'hidden',
            marginTop: '-20px',
            // border: '1px solid black',
          }}
        >
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ paddingRight: '10px', width: '50%' }}></td>
                <td style={{ paddingLeft: '10px' }}>
                  LOT : {detailData?.production_order?.steps?.[0]?.step_needs?.[0]?.lot?.code ?? ''}
                </td>
              </tr>
              {/* <tr>
                <td style={{ paddingRight: '10px' }}></td>
                <td style={{ fontWeight: 'bold', paddingLeft: '10px' }}>
                  {detailData?.production_order?.steps?.[0]?.step_needs?.[0]?.lot?.code ?? ''}
                </td>
              </tr> */}
              <tr style={{ borderBottom: '1px solid black' }}>
                <td style={{ paddingRight: '10px' }}></td>
                <td style={{ fontWeight: 'bold', paddingLeft: '10px' }}>{detailData?.production_order?.code ?? ''}</td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: 'flex', justifyContent: 'between' }}>
            {labels?.map((column, colIdx) => {
              return (
                <table style={{ width: '50%' }} key={colIdx}>
                  <tbody>
                    {column?.labels?.map((row, rowIdx) => {
                      return (
                        <tr key={`${colIdx} - ${rowIdx}`}>
                          <td style={{ paddingLeft: colIdx === 1 ? '10px' : '0' }}>
                            {row?.label ? row?.label?.toUpperCase() : ''} :{' '}
                            {row?.label?.toLowerCase() === 'yard'
                              ? detailData?.output_qty ?? detailData?.order_qty
                              : row?.value ?? ''}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              );
            })}
          </div>
        </div>
      )}
    </DetailPrint>
  );
}
