const API_URL = process.env.REACT_APP_BASE_URL;
const API_URL_GATEWAY = process.env.REACT_APP_BASE_URL_GATEWAY;

export const getProducts = async (packingId) => {
  try {
    // let items = [];
    const response: any = await fetch(
      `${API_URL}v1/inventory/transfer/packing-slips/products?detail_id=${packingId}&module=inventory&sub_module=Packing%20External&action=get`,
    );
    const result = await response.json();
    return result?.message;
  } catch (error) {
    return [];
  }
};

export const getProductionOrder = async (productionOrderCode, accessToken) => {
  try {
    // let items = [];
    const defaultOptions = {
      headers: {
        Authorization: accessToken,
      },
    };
    const response: any = await fetch(
      `${API_URL_GATEWAY}v1/productions/production-orders?q_index=${productionOrderCode}&page=1&limit=10&module=production&sub_module=Production%20Order&action=get`,
      defaultOptions,
    );
    const result = await response.json();
    return result?.message?.items?.[0];
  } catch (error) {
    return [];
  }
};

export const generateQtyUnitProduct = async (data, accessToken) => {
  let products = [];

  for (const productLine of data) {
    if (productLine?.batch) {
      const productionOrder = await getProductionOrder(productLine?.batch?.code, accessToken);
      let output = [];
      productionOrder?.output_qty_unit?.forEach((item) => {
        item?.value?.forEach((value) => {
          if (Array.isArray(value)) {
            output = [...output, ...value];
          } else {
            output = [...output, value];
          }
        });
      });

      let outputQtyUnit = [];
      for (let i = 0; i < 60; i++) {
        outputQtyUnit = [...outputQtyUnit, output?.[i] ?? ''];
      }
      const newProduct = {
        ...productLine,
        lot_material: productionOrder?.sales_order_product_line?.tracking_material,
        qty_per_unit: output ?? productLine?.qty_per_unit,
      };
      products = [...products, newProduct];
    } else {
      products = [...products, productLine];
    }
  }

  return products;
};
