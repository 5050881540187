import { RenderText } from '@base-components';

export function productCode(row: any): string {
  if (!row) return '-';
  const product = row?.product_variant ?? row?.product_package;
  const productCode = product?.alias_code ?? product?.code;
  return productCode;
}

export function productName(row: any): string {
  if (!row) return '-';
  const product = row?.product_variant ?? row?.product_package;
  const productName = product?.alias_name ?? product?.name;
  return productName;
}

export const configColumns = [
  {
    title: 'Product',
    key: 'product_variant',
    dataIndex: 'product_variant',
    render: (item: any, row: any) => RenderText({ value: productName(row) }),
  },
  {
    title: 'Unit',
    key: 'unit',
    dataIndex: 'unit',
    render: (item: any) => RenderText({ value: item }),
  },
  {
    title: 'UoM',
    key: 'unit_uom',
    dataIndex: 'unit_uom',
    render: (item: any) => RenderText({ value: item ? item?.code : '-' }),
  },
  {
    title: 'Qty',
    key: 'qty',
    dataIndex: 'qty',
    render: (item: any) => RenderText({ value: item ? item : '-' }),
  },
  {
    title: 'UoM',
    key: 'qty_uom',
    dataIndex: 'qty_uom',
    render: (item: any) => RenderText({ value: item ? item?.code : '-' }),
  },
  {
    title: 'Asset',
    key: 'is_asset',
    dataIndex: 'is_asset',
    // align: 'right',
    render: (item: any) => RenderText({ value: item ? 'Yes' : 'No' }),
  },
];
