import { stringFormatter } from '@base-helpers';
import moment from 'moment';

const API_URL = process.env.REACT_APP_BASE_URL;
const API_URL_GATEWAY = process.env.REACT_APP_BASE_URL_GATEWAY;

export const getProducts = async (pickingId) => {
  try {
    // let items = [];
    const response: any = await fetch(
      `${API_URL}v1/inventory/transfer/picking-lists/products?detail_id=${pickingId}&module=inventory&sub_module=Picking&action=get`,
    );
    const result = await response.json();
    return result?.message;
  } catch (error) {
    return [];
  }
};

export const getProductionOrder = async (id) => {
  try {
    const response: any = await fetch(`${API_URL}v1/productions/production-orders/${id}`);
    const result = await response.json();
    return result?.message;
  } catch (error) {
    return null;
  }
};

const renderBaseConversion = (row) => {
  const type = stringFormatter.capitalizeEachWord(row?.compare_attribute_type);
  const baseConversion =
    row?.compare_attribute?.name ??
    row?.compare_product_variant?.name ??
    row?.compare_product_master?.name ??
    row?.compare_product_category?.name;
  return `${type}: ${baseConversion ?? '-'}`;
};

const renderRatio = (data: any): string => {
  const ratio = `${data?.ratio_qty} ${data?.ratio_unit_of_measurement?.code}`;
  const compare = `${data?.compare_qty} ${data?.compare_unit_of_measurement?.code}`;
  let compareAttribute = '';

  if (data?.compare_attribute_type === 'product') {
    if (data?.compare_product_category) {
      compareAttribute = `${data?.compare_product_category?.name}`;
    }
    if (data?.compare_product_master) {
      compareAttribute = `${data?.compare_product_master?.name}`;
    }
    if (data?.compare_product_variant) {
      compareAttribute = `${data?.compare_product_variant?.name}`;
    }
  }

  if (data?.ratio_qty && data?.ratio_unit_of_measurement) {
    return `${ratio} Per ${compare} ${compareAttribute}`;
  }

  return null;
};

const renderQtyUsed = (data, type) => {
  if (type === 'non_unit') {
    const result = data?.work_order_transactions?.reduce((partialSum, a) => partialSum + a?.total_qty_used ?? 0, 0);
    return result;
  }
  if (type === 'unit') {
    const result = data?.work_order_transactions?.reduce((partialSum, a) => partialSum + a?.total_unit_used ?? 0, 0);
    return result;
  }
  return '';
};

export const columnsMaterial: any[] = [
  {
    title: 'Material',
    key: 'product_variant',
    dataIndex: 'product_variant',
    render: (item, row) => row?.product_variant?.name ?? row?.product_master?.name ?? row?.product_category?.name,
  },
  {
    title: 'Tracking ID',
    key: 'lot',
    dataIndex: 'lot',
    render: (item, row) => row?.lot?.code ?? row?.batch?.code ?? '',
  },
  {
    title: 'Ratio',
    key: 'ratio_qty',
    dataIndex: 'ratio_qty',
    render: (item, row) => renderRatio(row),
  },
  {
    title: 'Qty Needed',
    key: 'qty_needed',
    dataIndex: 'qty_needed',
    render: (item, row) => (row?.qty_needed_unit_of_measurement?.category !== 'UNIT' ? item : ''),
  },
  {
    title: 'Qty Received',
    key: 'qty_received',
    dataIndex: 'qty_received',
    render: (item, row) => item,
  },
  {
    title: 'Qty Used',
    key: 'qty_used',
    dataIndex: 'qty_used',
    render: (item, row) => renderQtyUsed(row, 'non_unit'),
  },
  {
    title: 'Qty Remaining',
    key: 'qty_remaining',
    dataIndex: 'qty_remaining',
    render: (item, row) => item,
  },
  {
    title: 'UoM',
    key: 'product_variant',
    dataIndex: 'product_variant',
    render: (item, row) => item?.uom?.code,
  },
  {
    title: 'Unit Needed',
    key: 'qty_needed',
    dataIndex: 'qty_needed',
    render: (item, row) => (row?.qty_needed_unit_of_measurement?.category === 'UNIT' ? item : ''),
  },
  {
    title: 'Unit Received',
    key: 'unit_received',
    dataIndex: 'unit_received',
    render: (item, row) => item,
  },
  {
    title: 'Qty Used',
    key: 'qty_used',
    dataIndex: 'qty_used',
    render: (item, row) => item,
  },
  {
    title: 'Qty Remaining',
    key: 'qty_remaining',
    dataIndex: 'qty_remaining',
    render: (item, row) => item,
  },
  {
    title: 'UoM',
    key: 'product_variant',
    dataIndex: 'product_variant',
    render: (item, row) => item?.fraction_uom?.code,
  },
];

export const groupingProduct = (materials) => {
  let groups = [];
  materials?.forEach((item) => {
    const findCategory = groups?.find((group) => item?.product_category?.id === group?.product_category?.id);
    if (!findCategory) {
      groups = [
        ...groups,
        {
          product_category: item?.product_category,
          non_category: item?.product_category ? false : true,
          products: [],
        },
      ];
    }
  });
  groups = groups?.map((group) => {
    let products = [];
    if (group?.product_category?.id) {
      products = materials?.filter((item) => item?.product_category?.id === group?.product_category?.id);
    }
    if (group?.non_category) {
      products = materials?.filter((item) => !item?.product_category?.id);
    }
    return {
      ...group,
      products: products,
    };
  });
  return groups;
};

export const columnShift: any[] = [
  {
    title: 'Shift',
    key: 'shift',
    dataIndex: 'shift',
    render: (item, row, index) => item?.name,
  },
  {
    title: 'Team',
    key: 'production_team',
    dataIndex: 'production_team',
    render: (item, row, index) => item?.name,
  },
  {
    title: 'PIC',
    key: 'pic',
    dataIndex: 'pic',
    render: (item, row, index) => item?.name,
  },
  {
    title: 'Machine',
    key: 'machine',
    dataIndex: 'machine',
    render: (item, row, index) => item?.code,
  },
  {
    title: 'Start',
    key: 'start_date',
    dataIndex: 'start_date',
    render: (item, row, index) => (item ? moment.utc(item).format('DD/MM/YYYY HH:mm') : ''),
  },
  {
    title: 'End',
    key: 'end_date',
    dataIndex: 'end_date',
    render: (item, row, index) => (item ? moment.utc(item).format('DD/MM/YYYY HH:mm') : ''),
  },
  {
    title: 'Qty Output',
    key: 'total_qty_output',
    dataIndex: 'total_qty_output',
    render: (item, row, index) => item,
  },
  {
    title: 'Scrap Qty',
    key: 'total_qty_scrap',
    dataIndex: 'total_qty_scrap',
    render: (item, row, index) => item,
  },
  {
    title: 'UoM',
    key: 'unit_of_measurement',
    dataIndex: 'unit_of_measurement',
    render: (item, row, index) => item?.code,
  },
  {
    title: 'Unit Output',
    key: 'total_unit_output',
    dataIndex: 'total_unit_output',
    render: (item, row, index) => item,
  },
  {
    title: 'Scrap Unit',
    key: 'total_unit_scrap',
    dataIndex: 'total_unit_scrap',
    render: (item, row, index) => item,
  },
  {
    title: 'UoM',
    key: 'unit_of_measurement_unit',
    dataIndex: 'unit_of_measurement_unit',
    render: (item, row, index) => item?.code,
  },
];

export const columnOtherProduct: any[] = [
  {
    title: 'Product',
    key: 'product_variant',
    dataIndex: 'product_variant',
    render: (item, row, index) => item?.name,
  },
  {
    title: 'Qty',
    key: 'total_qty',
    dataIndex: 'total_qty',
    render: (item, row, index) => item,
  },
  {
    title: 'UoM',
    key: 'unit_of_measurement',
    dataIndex: 'unit_of_measurement',
    render: (item, row, index) => item?.code,
  },
  {
    title: 'Unit',
    key: 'total_unit',
    dataIndex: 'total_unit',
    render: (item, row, index) => item,
  },
  {
    title: 'UoM',
    key: 'unit_of_measurement_unit',
    dataIndex: 'unit_of_measurement_unit',
    render: (item, row, index) => item?.code,
  },
];

export const columnProblem: any[] = [
  {
    title: 'Time Stop',
    key: 'time_stop',
    dataIndex: 'time_stop',
    render: (item, row, index) => (item ? moment(item).format('DD/MM/YYYY HH:mm') : ''),
  },
  {
    title: 'Time End',
    key: 'time_end',
    dataIndex: 'time_end',
    render: (item, row, index) => (item ? moment(item).format('DD/MM/YYYY HH:mm') : ''),
  },
  {
    title: 'Duration',
    key: 'duration',
    dataIndex: 'duration',
    render: (item, row, index) => item?.duration,
  },
  {
    title: 'Problem',
    key: 'problem',
    dataIndex: 'problem',
    render: (item, row, index) => '-',
    style: { width: '60%' },
  },
];
