import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailFieldProps, DetailPrint, PrintTitle } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter, userPrinter } from '@base-helpers';
import moment from 'moment';
import { generateQtyUnitProduct, getProducts } from './helpers';
import { Row } from 'antd';
import { useBarcode } from '@createnextapp/react-barcode';
import { logoMJS } from 'base/presentation/components/print-components/print-header/logo';

export function Detail() {
  const { detailData = {}, printContent } = usePagePrint();
  const hasCode = detailData?.code;
  const [products, setProducts] = useState([]);
  const user = userPrinter();
  const generateProduct = async (detailId) => {
    const response = await getProducts(detailId);
    const result = await generateQtyUnitProduct(response, printContent?.access_token);
    setProducts(result);
  };

  // useEffect(() => {
  //   if (detailData?.detail) {
  //     generateProduct(detailData?.detail?.id);
  //   }
  // }, [detailData?.detail]);

  const tableQtyPerUnit = (data, limit): any => {
    let tableQtyPerUnit = '';
    for (let i = 0; i < data?.length; i++) {
      if (i % limit === 0) {
        let cell = '';
        for (let j = i; j < i + limit; j++) {
          if (data[j]) {
            cell += `<td style="padding:3px; border:1px solid black">${data[j]}</td>`;
          }
        }
        tableQtyPerUnit += `<tr>${cell}</tr>`;
      }
    }
    if (tableQtyPerUnit) {
      return (
        <table style={{ width: '100%' }}>
          <tbody dangerouslySetInnerHTML={{ __html: tableQtyPerUnit }} />
        </table>
      );
    }
    return '-';
  };

  //Inisasi total unit dan qty
  let totalUnit = 0;
  let totalQty = 0;

  const treatmentPacking = ({ data, detailColumns }: { data: any[]; detailColumns: DetailFieldProps[] }): any[] => {
    // if (data?.length >= 1) {
    //   const newDetailColumns = data?.map((item) => {
    //     const attributes = item?.attributes ?? [];
    //     const attributeValues = item?.attribute_values ?? [];
    //     if (attributes?.length >= 1 && attributeValues?.length >= 1) {
    //       const label = attributes?.[0]?.name;
    //       const val = attributeValues?.[0]?.item;
    //       return {
    //         gridColumn: { span: 3 },
    //         label: label,
    //         name: 'treatment_packing',
    //         fieldType: 'text',
    //         renderTextProps: {
    //           defaultRender: () => '-',
    //           renderValue: () => {
    //             return val ?? '-';
    //           },
    //         },
    //       };
    //     }
    //   });
    //   return [...detailColumns, ...newDetailColumns];
    // } else {
    return detailColumns;
    // }
  };

  const detailColumns: DetailFieldProps[] = [
    {
      gridColumn: { span: 12 },
      renderField(form) {
        return <PrintTitle title="Packing Slip" {...(hasCode ? { description: `No. ${detailData?.code}` } : {})} />;
      },
    },
    {
      gridColumn: { span: 4 },
      label: 'Created Date',
      name: 'created_at',
      fieldType: 'text',
      renderTextProps: {
        renderValue: (value) => {
          return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
        },
      },
    },
    {
      gridColumn: { span: 4 },
      label: 'Printed Date',
      name: 'id',
      fieldType: 'text',
      renderTextProps: {
        renderValue: () => moment().format('YYYY-MM-DD'),
      },
    },
    {
      gridColumn: { span: 4 },
      label: 'Status',
      name: 'status',
      fieldType: 'text',
      renderTextProps: {
        renderValue: (value) => stringFormatter.capitalizeEachWord(value?.split('_')?.join(' ')),
      },
    },
    {
      gridColumn: { span: 3 },
      label: 'Activity Type',
      name: 'activity_type',
      fieldType: 'text',
      renderTextProps: {
        renderValue(value) {
          return value?.includes('_') ? value?.replace('_', ' ') : value;
        },
      },
    },
    {
      gridColumn: { span: 3 },
      label: 'PIC',
      name: 'pic',
      fieldType: 'text',
      renderTextProps: {
        defaultRender: () => '-',
        renderValue: (value) => {
          return value ? value?.name : '-';
        },
      },
    },
    {
      gridColumn: { span: 3 },
      label: 'Ship Plan Date',
      name: 'shipping_plan_date',
      fieldType: 'text',
      renderTextProps: {
        renderValue: (value) => {
          return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
        },
      },
    },
    {
      gridColumn: { span: 3 },
      label: 'Shipped Date',
      name: 'departure_date',
      fieldType: 'text',
      renderTextProps: {
        renderValue: (value) => {
          return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
        },
      },
    },
    {
      gridColumn: { span: 3 },
      label: 'Contact',
      name: 'send_to',
      fieldType: 'text',
      renderTextProps: {
        renderValue: (value) => {
          return value ? value?.name : '-';
        },
      },
    },
    {
      gridColumn: { span: 3 },
      label: 'Contact Reference',
      name: 'contact_reference',
      fieldType: 'text',
      renderTextProps: {
        defaultRender: () => '-',
        renderValue: (value) => {
          return value ? value?.name : '-';
        },
      },
    },
    {
      gridColumn: { span: 6 },
      label: 'Address',
      name: 'id',
      fieldType: 'text',
      renderTextProps: {
        defaultRender: () => '-',
        renderValue: (value) => {
          let address = null;
          if (detailData?.contact_reference) address = detailData?.contact_reference?.address;
          else if (detailData?.send_to?.address) address = detailData?.send_to?.address;
          return address;
        },
      },
    },
    {
      gridColumn: { span: 3 },
      label: 'Courier',
      name: 'id',
      fieldType: 'text',
      renderTextProps: {
        defaultRender: () => '-',
        renderValue: (value) => {
          let couriers = [];
          detailData?.shipping_orders?.map((item) => {
            if (item?.courier) couriers = [...couriers, item?.courier];
          });
          return couriers?.join(', ');
        },
      },
    },
    {
      gridColumn: { span: 3 },
      label: 'Driver',
      name: 'id',
      fieldType: 'text',
      renderTextProps: {
        defaultRender: () => '-',
        renderValue: (value) => {
          let drivers = [];
          detailData?.shipping_orders?.map((item) => {
            if (item?.external_driver) drivers = [...drivers, item?.external_driver];
          });
          return drivers?.join(', ');
        },
      },
    },
    {
      gridColumn: { span: 3 },
      label: 'Crew',
      name: 'id',
      fieldType: 'text',
      renderTextProps: {
        defaultRender: () => '-',
        renderValue: (value) => {
          let crews = [];
          detailData?.shipping_orders?.map((item) => {
            if (item?.external_crews) crews = [...crews, item?.external_crews];
          });
          return crews?.join(', ');
        },
      },
    },
    {
      gridColumn: { span: 3 },
      label: 'Telepon',
      name: 'id',
      fieldType: 'text',
      renderTextProps: {
        defaultRender: () => '-',
        renderValue: (value) => {
          let phones = [];
          detailData?.shipping_orders?.map((item) => {
            if (item?.phone_number) phones = [...phones, item?.phone_number];
          });
          return phones?.join(', ');
        },
      },
    },
    {
      gridColumn: { span: 3 },
      label: 'License No.',
      name: 'id',
      fieldType: 'text',
      renderTextProps: {
        defaultRender: () => '-',
        renderValue: (value) => {
          let licenses = [];
          detailData?.shipping_orders?.map((item) => {
            if (item?.external_plat_no) licenses = [...licenses, item?.external_plat_no];
          });
          return licenses?.join(', ');
        },
      },
    },
    {
      gridColumn: { span: 9 },
      label: 'Reference',
      name: 'detail',
      fieldType: 'text',
      renderTextProps: {
        renderValue: (value) => {
          return value?.references?.map((item) => item?.transaction_code ?? item?.code)?.join(', ');
          // const references = value?.references ?? [];
          // const code: string[] = [];
          // references?.forEach((item) => {
          //   const codeTransaction = item?.code ?? item?.transaction_code;
          //   if (codeTransaction) code.push(codeTransaction);
          // });
          // return code?.length >= 1 ? code.toString() : '-';
        },
      },
    },
  ];

  const { inputRef } = useBarcode({
    value: detailData?.code ?? '-',
    options: {
      displayValue: false,
      height: 30,
      width: 1,
      marginTop: -1,
      marginLeft: -1,
      marginBottom: -1,
    },
  });

  let drivers = [];
  detailData?.shipping_orders?.map((item) => {
    if (item?.external_driver) drivers = [...drivers, item?.external_driver];
  });

  return (
    <DetailPrint
      onSuccessLoadData={(payload) => generateProduct(payload?.detail?.id)}
      orientation="portrait"
      // printHeaderProps={{ barcode: detailData?.code, logoType: 'mjs' }}
      // showHeader={true}
      paperFormat={PAPER_FORMAT.PACKING_MJS}
      printSignatureProps={{
        signatures: [
          { title: 'Created By', name: user?.username ?? '-', gridColumn: { span: 5 } },
          {
            title: 'Checked By',
            name: '.....................',
            gridColumn: { span: 5 },
          },
          {
            title: 'Security',
            name: '.....................',
            gridColumn: { span: 5 },
          },
          {
            title: 'Driver',
            name: drivers?.length > 0 ? drivers?.join(', ') : '.....................',
            gridColumn: { span: 4 },
          },
          {
            title: 'Received By',
            name: detailData?.contact_reference?.name ?? detailData?.send_to?.name ?? '-',
            gridColumn: { span: 5 },
          },
        ],
      }}
    >
      <div style={{ fontSize: '12px', lineHeight: '12px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '-20px',
            marginTop: 0,
          }}
        >
          <svg ref={inputRef} {...(!detailData?.code ? { display: 'none' } : {})} />
          <img className="print-logo" src={logoMJS} height={40} />
        </div>
        <DetailBuilder
          columns={[
            {
              fields: treatmentPacking({
                data: detailData?.treatment_packing?.[0]?.treatment_packing?.product_attributes ?? [],
                detailColumns,
              }),
            },
          ]}
        />

        <table
          style={{
            position: 'relative',
            width: '100%',
            border: '1px solid black',
            margin: 0,
          }}
        >
          <thead style={{ fontWeight: 'bold', border: '1px solid black' }}>
            <tr>
              <td style={{ borderRight: '1px solid black', padding: '2px' }}>No.</td>
              <td style={{ borderRight: '1px solid black', padding: '2px' }}>Product Variant</td>
              <td style={{ borderRight: '1px solid black', padding: '2px' }}>#SO</td>
              <td style={{ borderRight: '1px solid black', padding: '2px' }}>SJ Greige</td>
              <td style={{ borderRight: '1px solid black', padding: '2px' }}>No. KP</td>
              <td style={{ borderRight: '1px solid black', padding: '2px' }}>Qty/Unit</td>
              <td style={{ borderRight: '1px solid black', padding: '2px' }}>Unit</td>
              <td style={{ borderRight: '1px solid black', padding: '2px' }}>UoM</td>
              <td style={{ borderRight: '1px solid black', padding: '2px' }}>Qty</td>
              <td style={{ borderRight: '1px solid black', padding: '2px' }}>UoM</td>
            </tr>
          </thead>
          <tbody>
            {products?.map((item, index) => {
              const number = index + 1;

              const qty = item?.total_qty ?? 0;
              totalQty += qty;

              const unit = item?.total_unit ?? 0;
              totalUnit += unit;
              return (
                <tr key={index}>
                  <td
                    style={{
                      border: '1px solid black',
                      padding: '2px',
                      textAlign: 'center',
                      width: '50px',
                    }}
                  >
                    {number}
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                      padding: '2px',
                    }}
                  >
                    {item?.product_variant?.name_with_hidden_attributes ?? item?.product_variant?.name ?? ''}
                  </td>

                  <td
                    style={{
                      border: '1px solid black',
                      padding: '2px',
                    }}
                  >
                    {item?.sales_order?.code ?? '-'}
                  </td>

                  <td
                    style={{
                      border: '1px solid black',
                      padding: '2px',
                    }}
                  >
                    {item?.tracking_material?.code ?? '-'}
                  </td>

                  <td
                    style={{
                      border: '1px solid black',
                      padding: '2px',
                    }}
                  >
                    {item?.batch?.code ?? item?.lot?.code ?? '-'}
                  </td>
                  <td>{tableQtyPerUnit(item?.qty_per_unit, 10) ?? '-'}</td>
                  <td
                    style={{
                      border: '1px solid black',
                      padding: '2px',
                      // width: '50px',
                    }}
                  >
                    {/* {item?.total_unit && item?.uom_unit
                      ? item?.total_unit + ' ' + item?.uom_unit?.code
                      : item?.total_unit ?? '-'} */}

                    {item?.total_unit ?? '-'}
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                      padding: '2px',
                      // width: '50px',
                    }}
                  >
                    {item?.uom_unit?.code ?? '-'}
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                      padding: '2px',
                      // width: '50px',
                    }}
                  >
                    {/* {item?.total_qty && item?.uom_qty
                      ? item?.total_qty + ' ' + item?.uom_qty?.code
                      : item?.total_qty ?? '-'} */}
                    {item?.total_qty}
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                      padding: '2px',
                      // width: '50px',
                    }}
                  >
                    {item?.uom_qty?.code ?? '-'}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td
                style={{
                  border: '1px solid black',
                  padding: '2px',
                  fontWeight: 'bold',
                  textAlign: 'right',
                }}
                colSpan={6}
              >
                Total
              </td>
              <td
                style={{
                  border: '1px solid black',
                  padding: '2px',
                }}
              >
                {totalUnit}
              </td>
              <td
                style={{
                  border: '1px solid black',
                  padding: '2px',
                }}
              ></td>
              <td
                style={{
                  border: '1px solid black',
                  padding: '2px',
                }}
              >
                {totalQty}
              </td>
              <td
                style={{
                  border: '1px solid black',
                  padding: '2px',
                }}
              ></td>
            </tr>
          </tfoot>
        </table>
        <div style={{ border: '1px solid black', padding: '10px', minHeight: '40px', margin: '5px 0' }}>
          <div style={{ marginBottom: '10px' }}>Note :</div>
          <p>{detailData?.detail?.notes ?? ''}</p>
        </div>
      </div>
    </DetailPrint>
  );
}
