import { APPROVAL_MODULE } from '@base-helpers';
import { PackingSlipMJS } from '@pages/inventory-mjs/packing-slip';
import { ProductionOrder } from '@pages/production-mjs/production-order';
import { ProductionRequest } from '@pages/production-mjs/production-request';
import { WorkOrderCustomMaterial } from '@pages/production-mjs/work-order-custom-material';
import { WorkOrderLabel } from '@pages/production-mjs/work-order-label';
import { WorkOrderMaterial } from '@pages/production-mjs/work-order-material';

export const ListProductionContent = [
  {
    module: APPROVAL_MODULE.module.PRODUCTION,
    sub_module: APPROVAL_MODULE.subModule.production.PRODUCTION_REQUEST,
    content: ProductionRequest,
  },
  {
    module: APPROVAL_MODULE.module.PRODUCTION,
    sub_module: APPROVAL_MODULE.subModule.production.PRODUCTION_ORDER,
    content: ProductionOrder,
    // showPreviewDetail: false,
  },
  {
    module: APPROVAL_MODULE.module.PRODUCTION,
    sub_module: APPROVAL_MODULE.subModule.production.WORK_ORDER_MATERIAL,
    content: WorkOrderMaterial,
  },
  {
    module: APPROVAL_MODULE.module.PRODUCTION,
    sub_module: APPROVAL_MODULE.subModule.production.WORK_ORDER_CUSTOM_MATERIAL,
    content: WorkOrderCustomMaterial,
  },
  {
    module: APPROVAL_MODULE.module.PRODUCTION,
    sub_module: APPROVAL_MODULE.subModule.production.WORK_ORDER_LABEL,
    content: WorkOrderLabel,
  },
];
