export const PrintStyle = ` 
.print-content-wrapper {
	padding: 0px 30px 30px 30px;
}

.print-content-wrapper .print-header-wrapper {
	display: flex;
	align-items: center;
}

.print-content-wrapper .print-header-wrapper .print-logo {
	margin-left: auto;
}

.print-content-wrapper thead th {
	background: #f3f3f3;
}

.print-content-wrapper .ant-table.ant-table-small .ant-table-title,
.print-content-wrapper .ant-table.ant-table-small .ant-table-footer,
.print-content-wrapper .ant-table.ant-table-small .ant-table-thead > tr > th,
.print-content-wrapper .ant-table.ant-table-small .ant-table-tbody > tr >td,
.print-content-wrapper .ant-table.ant-table-small tfoot > tr > th,
.print-content-wrapper .ant-table.ant-table-small tfoot > tr >td {
	padding: 2px 8px;
}

.print-title-wrapper {
	display: flex;
}

.print-title-wrapper .print-title-title {
	font-size: 30px;
	font-weight: 400;
	margin-bottom: 10px;
}

.print-title-wrapper .print-title-description {
	font-size: 14px;
	font-weight: bold;
	margin-left: 10px;
	margin-top: 19px;
	margin-bottom: 10px;
}

.ant-form-item-label>label {
	font-weight: 400;
	color: #777;
}

.print-signature-wrapper {
	 margin-top: auto;
}
.print-signature-wrapper .print-signature-item {
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 margin-top: 30px;
	 margin-bottom: 30px;
}
 .print-signature-wrapper .print-signature-item .print-signature-title {
	 font-weight: bold;
}
.print-signature-wrapper .print-signature-item .print-signature-name {
	 margin-top: 50px;
}
`;
