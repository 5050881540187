import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCountCurrency, RenderCurrency } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { makeModuleBarcode, PAPER_FORMAT, stringFormatter } from '@base-helpers';
import moment from 'moment';
import { Col, Row, Table } from 'antd';
import { getVirtualAccounts } from './helpers';
import QRCode from 'react-qr-code';
import { renderCurrency } from '../components';

export function Detail() {
  const { detailData = {}, printContent } = usePagePrint();
  // const [virtualAccounts, setVirtualAccounts] = useState([]);
  const defaultSignature: any[] = [
    {
      title: 'Hormat Kami',
      name: '',
      gridColumn: { span: 6 },
    },
  ];
  const renderDiscount = (data) => {
    if (data?.after_tax_formula_render) {
      let formula = [];
      if (Array.isArray(data?.after_tax_formula_render)) formula = data?.after_tax_formula_render;
      else formula = JSON.parse(data?.after_tax_formula_render);
      const newFormulaInNumber = formula
        ?.map((item) => {
          if (item?.label !== NaN) {
            return Number(item?.label);
          }
          return null;
        })
        .filter(Boolean);
      const totalDisc = newFormulaInNumber?.reduce((partialSum, a) => partialSum + a, 0);
      return Math.abs(totalDisc);
    }
    return '-';
  };
  const columns: any[] = [
    {
      title: 'Item Number',
      key: 'product',
      dataIndex: 'product',
      render: (item) => item?.code,
    },
    {
      title: 'Description',
      key: 'product',
      dataIndex: 'product',
      render: (item) => item?.name,
      style: { minWidth: '200px' },
    },
    {
      title: 'Quantity',
      key: 'real_qty',
      dataIndex: 'real_qty',
      render: (item, row) => row?.qty ?? row?.unit,
      style: { textAlign: 'right' },
    },
    {
      title: 'Unit',
      key: 'uom_default',
      dataIndex: 'uom_default',
      render: (item) => item?.code,
      style: { textAlign: 'right' },
    },
    {
      title: 'Unit Price',
      key: 'price_per_unit',
      dataIndex: 'price_per_unit',
      render: (item) => renderCurrency({ value: item }),
      style: { textAlign: 'right' },
    },
    {
      title: 'Discount Percent',
      key: '',
      dataIndex: '',
      render: (item, row) => renderDiscount(row),
      style: { textAlign: 'right' },
    },
    {
      title: 'Discount',
      key: 'disc_total_after_tax',
      dataIndex: 'disc_total_after_tax',
      render: (item) => renderCurrency({ value: item }),
      style: { textAlign: 'right' },
    },
    {
      title: 'Amount',
      key: 'subtotal_after_tax',
      dataIndex: 'subtotal_after_tax',
      render: (item) => renderCurrency({ value: item }),
      style: { textAlign: 'right' },
    },
  ];

  // const generateVirtualAccount = async (company) => {
  //   const response = await getVirtualAccounts(company?.id);
  //   const virtualAccounts = response?.filter((item) => item?.customer?.id === detailData?.customer?.id);
  //   setVirtualAccounts(virtualAccounts);
  // };

  // useEffect(() => {
  //   generateVirtualAccount(detailData?.company);
  // }, [detailData?.company]);

  return (
    <DetailPrint paperFormat={PAPER_FORMAT.A4} orientation="portrait" printHeaderProps={{ showLogo: false }}>
      <div style={{ fontSize: '11px' }}>
        <Row gutter={10}>
          <Col span={10}>
            <p>
              {detailData?.vendor?.name} ({detailData?.vendor?.code})
            </p>
            <p>{detailData?.invoice_address ?? ''}</p>
          </Col>
          <Col span={10}>
            <h2>
              {detailData?.type === 'PURCHASE INVOICE'
                ? stringFormatter.capitalizeEachWord(detailData?.type)
                : 'PURCHASE INVOICE RETURN'}
            </h2>
            <table>
              <tr>
                <td>Number</td>
                <td>{detailData?.code ?? '-'}</td>
              </tr>
              <tr>
                <td>Invoice date</td>
                <td>{detailData?.invoice_date ? moment(detailData?.invoice_date).format('DD/MM/YYYY') : '-'}</td>
              </tr>
              <tr>
                <td>Purchase order</td>
                <td>
                  {detailData?.references?.find((item) => item?.sub_module === 'purchase_order')?.transaction_code ??
                    '-'}
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: '10px' }}>Your reference</td>
                <td>
                  {detailData?.references
                    ?.map((item) => {
                      if (item?.sub_module !== 'purchase_order') {
                        return item?.transaction_code;
                      }
                      return null;
                    })
                    .filter(Boolean)
                    .join(', ')}
                </td>
              </tr>
            </table>
          </Col>
          <Col span={4} style={{ paddingTop: '10px' }}>
            <QRCode
              value={
                makeModuleBarcode({
                  id: detailData?.id,
                  module: printContent?.module,
                  subModule: printContent?.sub_module,
                }) ?? ''
              }
              size={90}
            />
          </Col>
        </Row>
        <p style={{ fontSize: '11px' }}>
          {printContent?.editor_name ?? printContent?.creator_name ?? ''}@{moment().format('DD/MM/YYYY HH:mm:ss')}WRH:{' '}
          {printContent?.site?.code ?? ''}
          {detailData?.purchase_division?.code ?? ''}
        </p>
        <table style={{ width: '100%', marginTop: '5px' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid black', textAlign: 'left' }}>
              {columns?.map((column) => {
                return (
                  <th style={{ ...column?.style, paddingRight: '10px' }} key={column?.key}>
                    {column?.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {detailData?.product_line?.map((line, index) => {
              return (
                <tr key={index}>
                  {columns?.map((column) => {
                    return (
                      <td style={{ ...column?.style, paddingRight: '10px' }} key={column?.key}>
                        {column?.render(line[column?.key], line)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr style={{ borderTop: '1px solid black' }}>
              <td colSpan={2}>Total</td>
              <td style={{ textAlign: 'right', paddingRight: '10px' }}>
                {detailData?.product_line?.reduce((partialSum, a) => partialSum + (a?.qty ?? a?.unit), 0)}
              </td>
              <td colSpan={5}></td>
            </tr>
          </tbody>
        </table>
        <table style={{ width: '80%', marginTop: '5px', textAlign: 'right' }}>
          <thead>
            {/* <tr>
              <th>Sales Subtotal</th>
              <th colSpan={3}></th>
            </tr> */}
            <tr>
              <th style={{ borderBottom: '1px solid black', paddingRight: '10px', textAlign: 'left' }}>Currency</th>
              <th style={{ borderBottom: '1px solid black' }}>Purchase Subtotal Amount</th>
              <th style={{ borderBottom: '1px solid black' }}>Total Discount</th>
              <th style={{ borderBottom: '1px solid black' }}>Charges</th>
              <th style={{ borderBottom: '1px solid black' }}>Purchase Tax</th>
              <th style={{ borderBottom: '1px solid black' }}>Round-off</th>
              <th style={{ borderBottom: '1px solid black' }}>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ textAlign: 'left' }}>IDR</td>
              <td>{renderCurrency({ value: detailData?.outcome_total?.sub_total })}</td>
              <td>
                {renderCurrency({
                  value: detailData?.outcome_total?.disc_before_tax + detailData?.outcome_total?.disc_after_tax,
                })}
              </td>
              <td>{renderCurrency({ value: detailData?.charges })}</td>
              <td>{renderCurrency({ value: detailData?.outcome_total?.tax })}</td>
              <td>{renderCurrency({ value: detailData?.round_off })}</td>
              <td>{renderCurrency({ value: detailData?.outcome_total?.total })}</td>
              {/* <td style={{ textAlign: 'left', paddingLeft: '20px' }}>
                Terbilang: # {RenderCountCurrency({ value: detailData?.outcome_total?.total })} #
              </td> */}
            </tr>
            <tr>
              <td colSpan={7}>Terbilang: # {RenderCountCurrency({ value: detailData?.outcome_total?.total })} #</td>
            </tr>
          </tbody>
        </table>
      </div>
    </DetailPrint>
  );
}
