import React from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCurrency, RenderText } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, makeModuleBarcode, PAPER_FORMAT, stringFormatter, userPrinter } from '@base-helpers';
import moment from 'moment';
import { Col, Descriptions, Row, Table } from 'antd';
import QRCode from 'react-qr-code';
import { pick } from 'lodash';

export function Detail() {
  const { detailData = {}, module, subModule, type } = usePagePrint();

  const salesType = detailData?.type;
  const hasCode = detailData?.code;
  const dataSource = salesType === 'sales_return' ? detailData?.product_line_returns : detailData?.product_line_orders;
  const user = userPrinter();
  const barcode = makeModuleBarcode({ id: detailData?.id, module, subModule });

  const configColumns = [
    {
      title: 'Created Date',
      key: 'created_at',
      dataIndex: 'created_at',
      style: { minWidth: '100px' },
      render: (item: any) => RenderText({ value: dateFormatter(item).toLocal('DD/MM/YYY') }),
    },
    {
      title: 'Product',
      key: 'product',
      dataIndex: 'product',
      style: { minWidth: '300px' },
      render: (item: any) => RenderText({ value: item?.name }),
    },
    {
      title: 'Code',
      key: 'product',
      dataIndex: 'product',
      style: { minWidth: '150px' },
      render: (item: any) => RenderText({ value: item?.code }),
    },
    {
      title: 'Unit',
      key: 'unit',
      dataIndex: 'unit',
      render: (item: any) => RenderText({ value: item ? item : '-' }),
    },
    {
      title: 'UoM',
      key: 'uom_unit',
      dataIndex: 'uom_unit',
      render: (item: any) => RenderText({ value: item ? item?.code : '-' }),
    },
    {
      title: 'Qty',
      key: 'qty',
      dataIndex: 'qty',
      render: (item: any) => RenderText({ value: item ? item : '-' }),
    },
    {
      title: 'UoM',
      key: 'uom_qty',
      dataIndex: 'uom_qty',
      render: (item: any) => RenderText({ value: item ? item?.code : '-' }),
    },
    {
      title: 'Price/Unit',
      key: 'price_per_unit',
      dataIndex: 'price_per_unit',
      render: (item: any) =>
        RenderCurrency({
          value: item.toFixed(),
          currencyOptions: { currency: 'IDR' },
        }),
    },
    {
      title: 'DPP',
      key: 'subtotal_after_tax',
      dataIndex: 'subtotal_after_tax',
      render: (item: any) =>
        RenderCurrency({
          value: item.toFixed(),
          currencyOptions: { currency: 'IDR' },
        }),
    },
    {
      title: 'PPN',
      key: 'tax_value_after_tax',
      dataIndex: 'tax_value_after_tax',
      render: (item: any) =>
        RenderCurrency({
          value: item.toFixed(),
          currencyOptions: { currency: 'IDR' },
        }),
    },
    {
      title: 'Net Price',
      key: 'net_price',
      dataIndex: 'net_price',
      render: (item: any) =>
        RenderCurrency({
          value: item.toFixed(),
          currencyOptions: { currency: 'IDR' },
        }),
    },
  ];

  const informationValue: any =
    salesType === 'sales_return' ? detailData?.receive_information : detailData?.deliver_information;

  return (
    <DetailPrint
      orientation="portrait"
      printHeaderProps={{ barcode: detailData?.code }}
      paperFormat={PAPER_FORMAT.A4}
      printSignatureProps={{
        signatures: [
          // {
          //   gridColumn: { span: 6 },
          //   title: 'Created By',
          //   name: detailData?.creator_name ? detailData?.creator_name : '-',
          // },
          // {
          //   gridColumn: { span: 6 },
          //   title: 'Printed By',
          //   name: user?.username ? user?.username : '-',
          // },
          {
            gridColumn: { span: 6 },
            title: 'Requested By',
            name: detailData?.editor_name ? detailData?.editor_name : '-',
          },
          { gridColumn: { span: 6 }, title: 'Approved By', name: '_____________' },
        ],
      }}
    >
      <Row gutter={10}>
        <Col span={8}>
          <p style={{ color: '#000' }}>
            {detailData?.customer?.name} ({detailData?.customer?.code})
          </p>
          <p style={{ color: '#000' }}>
            {detailData?.receive_information?.address ?? detailData?.deliver_information?.address ?? ''}
          </p>
        </Col>
        <Col span={6}>
          <h2>
            {detailData?.type ? stringFormatter.capitalizeEachWord(detailData?.type?.split('_').join(' ')) : '-'}{' '}
            {type ? `(${stringFormatter.capitalizeEachWord(type)})` : ''}
          </h2>
          <table style={{ color: '#000' }}>
            <tr>
              <td>Number</td>
              <td>:</td>
              <td>{detailData?.code ?? '-'}</td>
            </tr>
            <tr>
              <td>Site</td>
              <td>:</td>
              <td>{detailData?.site?.code ?? ''}</td>
            </tr>
            <tr>
              <td>Sales Division</td>
              <td>:</td>
              <td>{detailData?.sales_division?.code ?? ''}</td>
            </tr>

            <tr>
              <td>Salesman</td>
              <td>:</td>
              <td>{detailData?.salesman?.staff?.name}</td>
            </tr>

            <tr>
              <td>Created By</td>
              <td>:</td>
              <td>{detailData?.creator_name ? detailData?.creator_name : '-'}</td>
            </tr>

            <tr>
              <td>Printed By</td>
              <td>:</td>
              <td>{user?.username ? user?.username : '-'}</td>
            </tr>
          </table>
        </Col>

        <Col span={6}>
          <div style={{ marginTop: '30px' }}></div>
          <table style={{ color: '#000' }}>
            <tr>
              <td>Printed Date</td>
              <td>:</td>
              <td>{moment().format('YYYY-MM-DD')}</td>
            </tr>

            <tr>
              <td>Expected Date</td>
              <td>:</td>
              <td>
                {informationValue?.expected_date
                  ? dateFormatter(informationValue?.expected_date).toLocal('YYYY-MM-DD')
                  : '-'}
              </td>
            </tr>

            <tr>
              <td>Term of Payment</td>
              <td>:</td>
              <td>{detailData?.invoice_information?.top?.name ?? '-'}</td>
            </tr>

            <tr>
              <td>Your reference</td>
              <td>:</td>
              <td>
                {detailData?.references
                  ?.map((item) => {
                    if (item?.sub_module !== 'sales_order') {
                      return item?.transaction_code;
                    }
                    return null;
                  })
                  .filter(Boolean)
                  .join(', ')}
              </td>
            </tr>
          </table>
        </Col>
        <Col span={4} style={{ paddingTop: '10px' }}>
          <QRCode value={barcode} size={90} />
        </Col>
      </Row>
      <div style={{ marginBottom: '10px' }}></div>
      <table style={{ width: '100%', marginTop: '5px', lineHeight: '10px', color: '#000' }}>
        <thead>
          <tr style={{ borderBottom: '1px solid black', textAlign: 'left' }}>
            {configColumns?.map((column) => {
              return (
                <th style={column?.style} key={column?.key}>
                  {column?.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody style={{ borderBottom: '1px solid black', textAlign: 'left' }}>
          {dataSource?.map((line, index) => {
            return (
              <tr key={index}>
                {configColumns?.map((column: any) => {
                  return (
                    <td style={column?.style} key={column?.key}>
                      {column?.render(line[column?.key], line)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ marginBottom: '10px' }}></div>
      <Row gutter={[8, 8]} style={{ marginBottom: '10px' }}>
        <Col span={14}>
          <div style={{ border: '1px solid #000', padding: '10px', height: '100%' }}>
            <div style={{ display: 'block', fontWeight: 'bold', marginBottom: '10px' }}>Note :</div>
            <div>{detailData?.notes ? detailData?.notes : '-'}</div>
          </div>
        </Col>
        <Col span={10}>
          <table style={{ width: '100%', color: '#000' }}>
            <tr style={{ borderBottom: '1px solid #000' }}>
              <td>DPP</td>
              <td>:</td>
              <td style={{ textAlign: 'right' }}>
                {RenderCurrency({
                  value: detailData?.outcome_total?.total_dpp?.toFixed(),
                  currencyOptions: { currency: 'IDR' },
                })}
              </td>
            </tr>
            <tr style={{ borderBottom: '1px solid #000' }}>
              <td>PPN</td>
              <td>:</td>
              <td style={{ textAlign: 'right' }}>
                {RenderCurrency({
                  value: detailData?.outcome_total?.tax?.toFixed(),
                  currencyOptions: { currency: 'IDR' },
                })}
              </td>
            </tr>
            <tr style={{ borderBottom: '1px solid #000' }}>
              <td>NET</td>
              <td>:</td>
              <td style={{ textAlign: 'right' }}>
                {RenderCurrency({
                  value: detailData?.outcome_total?.total?.toFixed(),
                  currencyOptions: { currency: 'IDR' },
                })}
              </td>
            </tr>
          </table>
        </Col>
      </Row>
    </DetailPrint>
  );
}
