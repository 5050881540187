import { ListInventoryContent } from './inventory-content';
import { ListProductionContent } from './production-content';
import { ListPurchaseContent } from './purchase-content';
import { ListSalesContent } from './sales-content';

//NOTE:
// Tambahkan data di bawah pada setiap content untuk mengontrol filte yang bisa di preview/download bisa di gunakan salah satu
// showPreviewDetail: false,
// downloadDetail: false,
// showPreviewIndex: false,
// downloadIndex: false,

export const ListContentMJS = [
  ...ListSalesContent,
  ...ListPurchaseContent,
  ...ListInventoryContent,
  ...ListProductionContent,
];
