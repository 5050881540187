import React from 'react';
import { Card } from 'antd';
import { usePagePrint } from '@base-hooks';

interface PageControllerProps {
  PageIndex?: any;
  PageDetail?: any;
}

function NotFoundPage() {
  return (
    <Card style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>No Content.</Card>
  );
}

export function PagePrintController(props: PageControllerProps) {
  const { isDetail } = usePagePrint();
  const { PageIndex = NotFoundPage, PageDetail = NotFoundPage } = props;

  if (!isDetail) return <PageIndex />;
  else if (isDetail) return <PageDetail />;
  else return <NotFoundPage />;
}
