import React from 'react';
import { Image, ImageProps } from 'antd';
import { RenderImageProps } from './render-image.entity';
import { errorAvatarImage, errorImage } from './render-image.helper';

export function RenderImage(props: RenderImageProps) {
  const {
    baseImageUrl = process.env.REACT_APP_BASE_ASSET_URL,
    imageUrl,
    dummyImage,
    image,
    fallbackType,
    makeImageUrl,
  } = props;
  function makeImage() {
    const previewUrl = `${baseImageUrl}${imageUrl}`;

    if (dummyImage) return dummyImage;
    else if (makeImageUrl) return makeImageUrl({ imageUrl, dummyImage, image, baseImageUrl });
    else if (imageUrl) return previewUrl;
    else if (image) return image;
    else return 'error';
  }

  function makeFallback() {
    if (fallbackType === 'user') return errorAvatarImage;
    return errorImage;
  }

  const ImageProps: ImageProps = {
    src: makeImage(),
    fallback: makeFallback(),
    ...props,
  };
  return <Image {...ImageProps} />;
}
