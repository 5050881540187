import React from 'react';
import { RenderText } from '../render-text';

function terbilang(a) {
  const bilangan = [
    '',
    'Satu',
    'Dua',
    'Tiga',
    'Empat',
    'Lima',
    'Enam',
    'Tujuh',
    'Delapan',
    'Sembilan',
    'Sepuluh',
    'Sebelas',
  ];

  let kalimat;

  // 1 - 11
  if (a < 12) {
    kalimat = bilangan[a];
  }
  // 12 - 19
  else if (a < 20) {
    kalimat = bilangan[a - 10] + ' Belas';
  }
  // 20 - 99
  else if (a < 100) {
    const utama = a / 10;
    const depan = parseInt(String(utama).substr(0, 1));
    const belakang = a % 10;
    kalimat = bilangan[depan] + ' Puluh ' + bilangan[belakang];
  }
  // 100 - 199
  else if (a < 200) {
    kalimat = 'Seratus ' + terbilang(a - 100);
  }
  // 200 - 999
  else if (a < 1000) {
    const utama = a / 100;
    const depan = parseInt(String(utama).substr(0, 1));
    const belakang = a % 100;
    kalimat = bilangan[depan] + ' Ratus ' + terbilang(belakang);
  }
  // 1,000 - 1,999
  else if (a < 2000) {
    kalimat = 'Seribu ' + terbilang(a - 1000);
  }
  // 2,000 - 9,999
  else if (a < 10000) {
    const utama = a / 1000;
    const depan = parseInt(String(utama).substr(0, 1));
    const belakang = a % 1000;
    kalimat = bilangan[depan] + ' Ribu ' + terbilang(belakang);
  }
  // 10,000 - 99,999
  else if (a < 100000) {
    const utama = a / 100;
    const depan = parseInt(String(utama).substr(0, 2));
    const belakang = a % 1000;
    kalimat = terbilang(depan) + ' Ribu ' + terbilang(belakang);
  }
  // 100,000 - 999,999
  else if (a < 1000000) {
    const utama = a / 1000;
    const depan = parseInt(String(utama).substr(0, 3));
    const belakang = a % 1000;
    kalimat = terbilang(depan) + ' Ribu ' + terbilang(belakang);
  }
  // 1,000,000 - 	99,999,999
  else if (a < 100000000) {
    const utama = a / 1000000;
    const depan = parseInt(String(utama).substr(0, 4));
    const belakang = a % 1000000;
    kalimat = terbilang(depan) + ' Juta ' + terbilang(belakang);
  } else if (a < 1000000000) {
    const utama = a / 1000000;
    const depan = parseInt(String(utama).substr(0, 4));
    const belakang = a % 1000000;
    kalimat = terbilang(depan) + ' Juta ' + terbilang(belakang);
  } else if (a < 10000000000) {
    const utama = a / 1000000000;
    const depan = parseInt(String(utama).substr(0, 1));
    const belakang = a % 1000000000;
    kalimat = terbilang(depan) + ' Milyar ' + terbilang(belakang);
  } else if (a < 100000000000) {
    const utama = a / 1000000000;
    const depan = parseInt(String(utama).substr(0, 2));
    const belakang = a % 1000000000;
    kalimat = terbilang(depan) + ' Milyar ' + terbilang(belakang);
  } else if (a < 1000000000000) {
    const utama = a / 1000000000;
    const depan = parseInt(String(utama).substr(0, 3));
    const belakang = a % 1000000000;
    kalimat = terbilang(depan) + ' Milyar ' + terbilang(belakang);
  } else if (a < 10000000000000) {
    const utama = a / 10000000000;
    const depan = parseInt(String(utama).substr(0, 1));
    const belakang = a % 10000000000;
    kalimat = terbilang(depan) + ' Triliun ' + terbilang(belakang);
  } else if (a < 100000000000000) {
    const utama = a / 1000000000000;
    const depan = parseInt(String(utama).substr(0, 2));
    const belakang = a % 1000000000000;
    kalimat = terbilang(depan) + ' Triliun ' + terbilang(belakang);
  } else if (a < 1000000000000000) {
    const utama = a / 1000000000000;
    const depan = parseInt(String(utama).substr(0, 3));
    const belakang = a % 1000000000000;
    kalimat = terbilang(depan) + ' Triliun ' + terbilang(belakang);
  } else if (a < 10000000000000000) {
    const utama = a / 1000000000000000;
    const depan = parseInt(String(utama).substr(0, 1));
    const belakang = a % 1000000000000000;
    kalimat = terbilang(depan) + ' Kuadriliun ' + terbilang(belakang);
  }

  const pisah = kalimat?.split(' ');
  const full = [];
  if (pisah?.length > 0) {
    for (let i = 0; i < pisah.length; i++) {
      if (pisah[i] != '') {
        full.push(pisah[i]);
      }
    }
  }
  return full.join(' ');
}

function pembilang(nilai) {
  nilai = Math.abs(nilai);
  let simpanNilaiBagi = 0;
  const huruf = [
    '',
    'Satu',
    'Dua',
    'Tiga',
    'Empat',
    'Lima',
    'Enam',
    'Tujuh',
    'Delapan',
    'Sembilan',
    'Sepuluh',
    'Sebelas',
  ];
  let temp = '';

  if (nilai < 12) {
    temp = ' ' + huruf[nilai];
  } else if (nilai < 20) {
    temp = pembilang(nilai - 10) + ' Belas';
  } else if (nilai < 100) {
    simpanNilaiBagi = Math.floor(nilai / 10);
    temp = pembilang(simpanNilaiBagi) + ' Puluh' + pembilang(nilai % 10);
  } else if (nilai < 200) {
    temp = ' Seratus' + pembilang(nilai - 100);
  } else if (nilai < 1000) {
    simpanNilaiBagi = Math.floor(nilai / 100);
    temp = pembilang(simpanNilaiBagi) + ' Ratus' + pembilang(nilai % 100);
  } else if (nilai < 2000) {
    temp = ' Seribu' + pembilang(nilai - 1000);
  } else if (nilai < 1000000) {
    simpanNilaiBagi = Math.floor(nilai / 1000);
    temp = pembilang(simpanNilaiBagi) + ' Ribu' + pembilang(nilai % 1000);
  } else if (nilai < 1000000000) {
    simpanNilaiBagi = Math.floor(nilai / 1000000);
    temp = pembilang(simpanNilaiBagi) + ' Juta' + pembilang(nilai % 1000000);
  } else if (nilai < 1000000000000) {
    simpanNilaiBagi = Math.floor(nilai / 1000000000);
    temp = pembilang(simpanNilaiBagi) + ' Miliar' + pembilang(nilai % 1000000000);
  } else if (nilai < 1000000000000000) {
    simpanNilaiBagi = Math.floor(nilai / 1000000000000);
    temp = pembilang(nilai / 1000000000000) + ' Triliun' + pembilang(nilai % 1000000000000);
  }

  return temp;
}

interface Props {
  value: number;
}

export const RenderCountCurrency = ({ value }: Props) => {
  if (value) {
    return terbilang(Math.abs(value).toFixed(2)) + ' Rupiah';
  }
  return '-';
};
