export const getVirtualAccounts = async (companyId) => {
  try {
    let items = [];
    const response: any = await fetch(
      `https://api.testing.erp.eigen.co.id/api/v1/finance/banks/list?company_ids[]=${companyId}`,
    );
    const result = await response.json();
    for (const bankAccount of result?.message) {
      const responseBankAccount = await fetch(
        `https://api.testing.erp.eigen.co.id/api/v1/finance/banks/${bankAccount?.id}`,
      );
      const detailBankAccount = await responseBankAccount.json();
      if (detailBankAccount?.message?.virtual_accounts?.length > 0) {
        items = [
          ...items,
          ...detailBankAccount?.message?.virtual_accounts?.map((item) => {
            return {
              ...item,
              bank_account: detailBankAccount?.message,
            };
          }),
        ];
      }
    }
    return items;
  } catch (error) {
    return [];
  }
};
