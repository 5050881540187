import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCurrency, RenderText } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, makeModuleBarcode, PAPER_FORMAT, stringFormatter, userPrinter } from '@base-helpers';
import moment from 'moment';
import { Col, Descriptions, Row, Table } from 'antd';
import { configColumns, getInvoices } from './helpers';
import QRCode from 'react-qr-code';

export const formatUnderscore = (data: string) => data?.replace(/_/g, ' ');

export function Detail() {
  const { detailData = {}, printContent } = usePagePrint();
  const [invoices, setInvoices] = useState([]);
  const hasCode = detailData?.code;
  const user = userPrinter();

  const generateInvoices = async (dataInvoices) => {
    const result = await getInvoices(dataInvoices);
    setInvoices(result);
  };

  useEffect(() => {
    if (detailData?.invoices?.length > 0) {
      generateInvoices(detailData?.invoices);
    }
  }, [detailData?.id]);

  return (
    <DetailPrint
      orientation="portrait"
      printHeaderProps={{ barcode: detailData?.code }}
      paperFormat={PAPER_FORMAT.A4}
      printSignatureProps={{
        signatures: [
          {
            gridColumn: { span: 6 },
            title: 'Created By',
            name: detailData?.creator_name ? detailData?.creator_name : '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Confirmed By',
            name: detailData?.editor_name ? detailData?.editor_name : '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Salesman By',
            name: '_____________',
          },
          { gridColumn: { span: 6 }, title: 'Approved By', name: '_____________' },
        ],
      }}
    >
      <div style={{ fontSize: '10px' }}>
        <DetailBuilder
          columns={[
            {
              fields: [
                {
                  gridColumn: { span: 12 },
                  renderField(form) {
                    return (
                      <PrintTitle
                        title="Sales Invoice Bundling"
                        {...(hasCode ? { description: `No. ${detailData?.code}` } : {})}
                      />
                    );
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Created Date',
                  name: 'created_at',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => {
                      return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                    },
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Printed Date',
                  name: 'id',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: () => moment().format('YYYY-MM-DD'),
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Status',
                  name: 'status',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => {
                      let status = value;
                      if (value?.toLowerCase() === 'waiting') status = 'in_process';
                      return stringFormatter.capitalizeEachWord(status?.split('_')?.join(' '));
                    },
                  },
                },
                {
                  gridColumn: { span: 3 },
                  renderField(form) {
                    return (
                      <QRCode
                        value={
                          makeModuleBarcode({
                            id: detailData?.id,
                            module: printContent?.module,
                            subModule: printContent?.sub_module,
                          }) ?? ''
                        }
                        size={70}
                      />
                    );
                  },
                },
                {
                  gridColumn: { span: 2 },
                  label: 'Site',
                  name: 'site',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return value?.code;
                    },
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Date',
                  name: 'collect_date',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => {
                      return value ? dateFormatter(value).toLocal('YYYY-MM-DD HH:mm') : '-';
                    },
                  },
                },
                {
                  gridColumn: { span: 4 },
                  label: 'Sales Divisions',
                  name: 'sales_divisions',
                  fieldType: 'text',
                  renderTextProps: {
                    renderType: 'multi',
                    renderValue(value) {
                      return value?.transaction_code ?? value?.code;
                    },
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Collector',
                  name: 'collector',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return value?.staff?.name;
                    },
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'No of Invoice',
                  name: 'number_of_invoices',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return value;
                    },
                  },
                },
                {
                  gridColumn: { span: 9 },
                  label: 'No of Customer',
                  name: 'number_of_contacts',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return value;
                    },
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Total Invoice',
                  name: 'total_invoices',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return RenderCurrency({ value: value });
                    },
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Total Payment',
                  name: 'total_payment',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return RenderCurrency({ value: value, currencyOptions: { currency: 'Rp' } });
                    },
                  },
                },
                {
                  gridColumn: { span: 12 },
                  label: 'References',
                  name: 'references',
                  fieldType: 'text',
                  renderTextProps: {
                    renderType: 'multi',
                    renderValue(value) {
                      return value?.transaction_code ?? value?.code;
                    },
                  },
                },
              ],
            },
          ]}
        />

        <table style={{ width: '100%', marginTop: '5px', lineHeight: '12px' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid black', textAlign: 'left' }}>
              {configColumns?.map((column) => {
                return <th key={column?.key}>{column?.title}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {detailData?.invoices?.map((line, index) => {
              return (
                <tr key={index}>
                  {configColumns?.map((column) => {
                    return <td key={column?.key}>{column?.render(line[column?.key], line)}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* <Table
          dataSource={invoices}
          columns={configColumns}
          pagination={false}
          size="small"
          bordered
          scroll={{ x: 'fit-content' }}
          key={JSON.stringify(invoices)}
          style={{ marginTop: '10px', marginBottom: '10px', overflow: 'hidden', fontSize: '8px !important' }}
        /> */}

        <Row gutter={[8, 8]} style={{ marginBottom: '10px' }}>
          <Col span={14}>
            <div style={{ border: '1px solid #f0f0f0', padding: '10px', height: '100%' }}>
              <div style={{ display: 'block', fontWeight: 'bold', marginBottom: '10px' }}>Note :</div>
              <div>{detailData?.notes ? detailData?.notes : '-'}</div>
            </div>
          </Col>
        </Row>
      </div>
    </DetailPrint>
  );
}
