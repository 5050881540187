import React from 'react';
import { defaultRenderDetail, makeItemValue, makeValueForRender } from '../base-render';
import { RenderLinkProps } from './render-link.entity';
import './render-link.less';

import { Link } from 'react-router-dom';

export function RenderLink(props: RenderLinkProps) {
  const {
    value,
    target = '_self',
    href = '#',
    customHref,
    renderValue,
    withColon = true,
    keyValue = 'code',
    renderType = 'single',
    classNameItem = '',
    classNameWrapper = '',
    useDefaultStyle = false,
    defaultRender = defaultRenderDetail,
    styleWrapper,
    styleItem,
  } = props;
  if (value === null || value === undefined) return <div>{defaultRender(value)}</div>;
  const valueForRender = makeValueForRender(value);
  const singleValueForRender = valueForRender[0];

  function Rendering() {
    if (renderType === 'single') {
      const hrefLink = customHref ? customHref(singleValueForRender) : href;
      return (
        <Link
          target={target}
          className={`render-link-item ${useDefaultStyle ? 'default-style-link' : ''} ${classNameItem}`}
          to={hrefLink}
        >
          {renderValue ? renderValue(singleValueForRender) : singleValueForRender}
        </Link>
      );
    }
    return (
      <div className={`render-link-wrapper ${classNameWrapper}`} style={styleWrapper}>
        {valueForRender.map((item, idx) => {
          const itemValue = makeItemValue(item, keyValue);
          const hrefLink = customHref ? customHref(item) : href;

          return (
            <div key={idx}>
              <Link
                to={hrefLink}
                target={target}
                className={` render-link-item ${useDefaultStyle ? 'default-style-link' : ''}  ${
                  withColon ? '' : 'render-link-item-gap'
                } ${classNameItem}`}
                style={styleItem}
              >
                <span>{renderValue ? renderValue(item) : itemValue}</span>
              </Link>
              {idx !== valueForRender.length - 1 && withColon && <span className="render-link-colon">,</span>}
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <div className="render-link-component">
      <Rendering />
    </div>
  );
}
