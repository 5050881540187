import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCountCurrency, RenderCurrency } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { makeModuleBarcode, PAPER_FORMAT, stringFormatter } from '@base-helpers';
import moment from 'moment';
import { Col, Row, Table } from 'antd';
import QRCode from 'react-qr-code';

export function Detail() {
  const { detailData = {}, printContent, type } = usePagePrint();
  const defaultSignature: any[] = [
    {
      title: 'Hormat Kami',
      name: '',
      gridColumn: { span: 6 },
    },
  ];
  const columns: any[] = [
    {
      title: 'Description',
      key: 'location_transfer_product_detail',
      dataIndex: 'location_transfer_product_detail',
      render: (item, row) => item?.product_variant?.name ?? item?.product_package?.name,
      style: { minWidth: '300px' },
    },
    {
      title: 'Unit',
      key: 'uom_qty',
      dataIndex: 'uom_qty',
      render: (item, row) => row?.qty_unit_of_measurement?.code ?? row?.unit_unit_of_measurement?.code,
      style: { textAlign: 'right' },
    },
    {
      title: 'Qty',
      key: 'total_qty',
      dataIndex: 'total_qty',
      render: (item, row) => row?.qty ?? row?.unit,
      style: { textAlign: 'right' },
    },
  ];

  return (
    <DetailPrint paperFormat={PAPER_FORMAT.A4} orientation="portrait" printHeaderProps={{ showLogo: false }}>
      <div style={{ fontSize: '11px' }}>
        <Row gutter={10}>
          <Col span={8}>
            <p>Destination Location</p>
            <p>{detailData?.location_transfer?.destination_location?.name}</p>
          </Col>
          <Col span={8}>
            <table>
              <tr>
                <td colSpan={2}>Packing Internal {type ? `(${stringFormatter.capitalizeEachWord(type)})` : ''}</td>
              </tr>
              <tr>
                <td>Packing Slip</td>
                <td>{detailData?.code ?? ''}</td>
              </tr>
              <tr>
                <td style={{ paddingRight: '10px' }}>Goods Receive Date</td>
                <td>
                  {detailData?.location_transfer?.received_date
                    ? moment(detailData?.location_transfer?.received_date).format('DD/MM/YYYY HH:mm')
                    : ''}
                </td>
              </tr>
              <tr>
                <td>Source Location</td>
                <td>{detailData?.location_transfer?.source_location?.name ?? ''}</td>
              </tr>
            </table>
          </Col>
          <Col span={8}>
            <table>
              <tr>
                <td>Received By</td>
                <td>{detailData?.location_transfer?.receiver?.name ?? ''}</td>
              </tr>
              <tr>
                <td>Printed By</td>
                <td>{printContent?.editor_name ?? printContent?.creator_name ?? ''}</td>
              </tr>
              <tr>
                <td>Printed On</td>
                <td>{moment().format('DD/MM/YYYY HH:mm:ss')}</td>
              </tr>
            </table>
          </Col>
        </Row>
        NB:
        <table style={{ width: '100%', marginTop: '5px', lineHeight: '10px' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid black', textAlign: 'left' }}>
              {columns?.map((column) => {
                return (
                  <th style={column?.style} key={column?.key}>
                    {column?.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {detailData?.location_transfer?.location_transfer_products?.map((line, index) => {
              return (
                <tr key={index}>
                  {columns?.map((column) => {
                    return (
                      <td style={column?.style} key={column?.key}>
                        {column?.render(line[column?.key], line)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr style={{ borderTop: '1px solid black' }}>
              <td colSpan={2} style={{ textAlign: 'right' }}>
                Jumlah Qty
              </td>
              <td style={{ textAlign: 'right' }}>
                {detailData?.location_transfer?.location_transfer_products?.reduce(
                  (partialSum, a) => partialSum + (a?.qty ?? a?.unit),
                  0,
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ width: '100%', marginTop: '5px' }}>
          <tr>
            <td>Diterima oleh,</td>
            <td style={{ textAlign: 'right' }}>Hormat kami,</td>
          </tr>
        </table>
        <div style={{ marginTop: '80px' }}>
          <QRCode
            size={90}
            value={
              makeModuleBarcode({
                id: detailData?.id,
                module: printContent?.module,
                subModule: printContent?.sub_module,
              }) ?? ''
            }
          />
        </div>
      </div>
    </DetailPrint>
  );
}
