import { APPROVAL_MODULE } from '@base-helpers';
import { Asset } from '@pages/accounting/asset';
import { BankReconciliation } from '@pages/accounting/bank-reconciliation';
import { CashAndBank } from '@pages/accounting/cash-and-bank';
import { InvoiceBundling } from '@pages/accounting/invoice-bundling';
import { JournalEntry } from '@pages/accounting/journal-entry';
import { PurchaseInvoiceV2 } from '@pages/accounting/purchase-invoice-v2';
import { PurchasePayment } from '@pages/accounting/purchase-payment';
import { SalesInvoice } from '@pages/accounting/sales-invoice';
import { SalesPayment } from '@pages/accounting/sales-payment';
import { SalesPaymentV2 } from '@pages/accounting/sales-payment-v2';

export const ListAccountingContent = [
  {
    module: APPROVAL_MODULE.module.ACCOUNTING,
    sub_module: APPROVAL_MODULE.subModule.accounting.SALES_INVOICE,
    content: SalesInvoice,
    // showPreviewDetail: false,
    // downloadDetail: false,
  },
  {
    module: APPROVAL_MODULE.module.ACCOUNTING,
    sub_module: APPROVAL_MODULE.subModule.accounting.SALES_INVOICE_PAYMENT,
    content: SalesPaymentV2,
  },
  {
    module: APPROVAL_MODULE.module.ACCOUNTING,
    sub_module: APPROVAL_MODULE.subModule.accounting.INVOICE_BUNDLING,
    content: InvoiceBundling,
  },
  {
    module: APPROVAL_MODULE.module.ACCOUNTING,
    sub_module: APPROVAL_MODULE.subModule.accounting.PURCHASE_INVOICE,
    content: PurchaseInvoiceV2,
  },
  {
    module: APPROVAL_MODULE.module.ACCOUNTING,
    sub_module: APPROVAL_MODULE.subModule.accounting.PURCHASE_PAYMENT,
    content: PurchasePayment,
  },
  {
    module: APPROVAL_MODULE.module.ACCOUNTING,
    sub_module: APPROVAL_MODULE.subModule.accounting.CASH_IN_OUT,
    content: CashAndBank,
  },
  {
    module: APPROVAL_MODULE.module.ACCOUNTING,
    sub_module: APPROVAL_MODULE.subModule.accounting.BANK_RECONCILIATION,
    content: BankReconciliation,
  },
  {
    module: APPROVAL_MODULE.module.ACCOUNTING,
    sub_module: APPROVAL_MODULE.subModule.accounting.JOURNAL_ENTRY,
    content: JournalEntry,
  },
  {
    module: APPROVAL_MODULE.module.ACCOUNTING,
    sub_module: APPROVAL_MODULE.subModule.accounting.DATA_ASSET,
    content: Asset,
  },
];
