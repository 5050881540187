import React from 'react';
import PrintLogo from '@base-images/logo_pipamas.png';
import Barcode from 'react-barcode';
import { logoMJS, printLogo } from './logo';
import { useBarcode } from '@createnextapp/react-barcode';

export interface PrintHeaderProps {
  barcode?: string;
  showLogo?: boolean;
  logoType?: 'mjs' | 'pmps';
}

export function PrintHeader(props: PrintHeaderProps) {
  const { barcode, showLogo = true, logoType } = props;
  const { inputRef } = useBarcode({
    value: barcode,
    options: {
      displayValue: false,
      height: 20,
      width: 1,
      marginTop: -1,
      marginLeft: -1,
      marginBottom: -1,
    },
  });

  const showingLogoByType = (type: string): any => {
    if (type === 'mjs') {
      return <img className="print-logo" src={logoMJS} height={30} width={89} />;
    } else {
      return <img className="print-logo" src={printLogo} height={15} width={120} />;
    }
  };
  return (
    <div className="print-header-wrapper">
      <svg ref={inputRef} {...(!barcode ? { display: 'none' } : {})} />
      {showLogo && showingLogoByType(logoType)}
    </div>
  );
}
