import React, { useEffect, useState } from 'react';
import { Table, Popover, List } from 'antd';
import { dateToString } from '../helpers';
import { v4 as uuidV4 } from 'uuid';
enum KeyData {
  SHIFT = 'SHIFT',
  WORK_CENTER = 'WORK_CENTER',
  USER = 'USER',
  PROCESS = 'PROCESS',
  TEAM = 'TEAM',
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const dayNames = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

function makeTitleDate(propsDate): string {
  if (!propsDate) return '';
  const date = new Date(propsDate);
  const newDate = date.getDate();
  const day = dayNames[date.getDay()];
  return `${newDate} ${day}`;
}

function makeTitleMonth(propsDate): string {
  if (!propsDate) return '';
  const date = new Date(propsDate);
  const month = monthNames[date.getMonth()];
  const years = date.getFullYear();
  return `${month} ${years}`;
}

interface Props {
  show: any;
  defaultData?: any;
}

const App = (props: Props) => {
  const { defaultData, show } = props;
  //default row yg terbuka
  const [defaultExpandedRowKeys, setDefaultExpandedRowKeys] = useState(null);

  const [paramDate, setParamDate] = useState({
    startDate: new Date('2021-08-01'), //YYYY-MM-DD
    endDate: new Date('2021-09-07'), //YYYY-MM-DD
  });

  //mengatur tgl sesuai form
  useEffect(() => {
    if (show?.start_date && show?.end_date) {
      setParamDate({
        startDate: show?.start_date,
        endDate: show?.end_date,
      });
    }
  }, [show]);

  const [dateArr, setDateArr] = useState([]);
  const initialDataPlanning = {
    key: uuidV4(),
    pic: [],
    children: [],
  };

  const [data, setData] = useState([initialDataPlanning]);

  useEffect(() => {
    setDateArr(getDateArray(paramDate.startDate, paramDate.endDate));
  }, [paramDate]);

  const getDateArray = function (start, end): any[] {
    if (!start || !end) return [];
    const arr = [];
    const dt = new Date(start);
    const et = new Date(end);
    while (dt <= et) {
      arr.push(new Date(dt));
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  };

  function renderScheduleBar(item, row, index, column): JSX.Element {
    const noContent = <div></div>;
    const parentItem = item;

    function renderContentShift(item): JSX.Element {
      return (
        <div
          className="d-flex align-items-center p-0"
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            // ...(item?.pic ? { background: parentItem?.shift?.color ?? '#432158' } : {}),
            background: item?.shift?.color ?? '#432158',
            height: '100%',
            width: '100%',
            // color: item?.shift_leader ? 'white' : item?.shift?.color,
            color: 'black',
            // ...(item?.pic ? { color: 'white' } : {}),
          }}
        >
          <div className="mr-auto cell-content-ellipsis padding-left-10" style={{ paddingLeft: '10px' }}>
            {item?.shift_leader?.name ?? '-'}
          </div>
        </div>
      );
    }

    function renderContentWorkCenter(row, item, title): JSX.Element {
      return (
        <div
          className="d-flex align-items-center p-0"
          style={{ background: '#1890ff', height: '100%', width: '100%', color: 'black' }}
        >
          <div className="mr-auto cell-content-ellipsis padding-left-10">
            {item[title]['name'] ?? item[title]['code']}
          </div>
        </div>
      );
    }

    function renderContentTeam(item): JSX.Element {
      return (
        <div
          className="d-flex align-items-center p-0"
          style={{
            ...(item?.production_team ? { background: parentItem?.shift?.color ?? '#432158' } : {}),
            height: '100%',
            width: '100%',
            color: 'black',
            // ...(item?.production_team ? { color: 'white' } : {}),
          }}
        >
          {item?.production_team && (
            <div className="mr-auto cell-content-ellipsis padding-left-10">{item?.production_team?.name}</div>
          )}
        </div>
      );
    }

    function renderContentMachines(item): JSX.Element {
      const content = (
        <List
          size="small"
          bordered
          dataSource={item?.machines}
          renderItem={(item: any) => <List.Item>{item?.code}</List.Item>}
        />
      );
      return (
        <Popover content={content} title="Machines">
          <div
            className="d-flex align-items-center p-0"
            style={{
              ...(item?.has_schedule ? { background: parentItem?.shift?.color ?? '#432158' } : {}),
              height: '100%',
              width: '100%',
              color: 'black',
              // ...(item?.has_schedule ? { color: 'white' } : {}),
            }}
          >
            {item?.has_schedule && (
              <div className="mr-auto cell-content-ellipsis padding-left-10">
                {item?.machines?.map((item: any) => item?.code)}
                {item?.machines?.length < 1 ? '-' : ''}
              </div>
            )}
          </div>
        </Popover>
      );
    }

    const keyArr = row?.section;
    const key = keyArr;
    const findColumn = row.schedules?.find((itemD) => itemD.date === column);
    if (KeyData.SHIFT === key) {
      return renderContentShift(row);
    }
    if (KeyData.TEAM === key) {
      return renderContentTeam(findColumn);
    }
    if (KeyData.USER === key) {
      return renderContentMachines(findColumn);
    }
    if (KeyData.WORK_CENTER === key) {
      const findColumn = row.pic?.find((itemD) => itemD.date === column);
      if (findColumn) {
        return renderContentWorkCenter(row, findColumn, 'pic');
      } else {
        return noContent;
      }
    }
    return noContent;
  }

  function makeColumn(dateProps) {
    if (!dateProps) return [];
    const parentMonth = [];
    const childMonth = [];
    const newHeader = [];
    dateProps.forEach((item) => {
      const dateStr = dateToString(item, false, '-', 'YYYY/MM/DD');
      childMonth.push(dateStr);
      const findMonth = parentMonth.find(
        (iFind) => iFind.split('-')[0] === dateStr.split('-')[0] && iFind.split('-')[1] === dateStr.split('-')[1],
      );
      if (!findMonth) {
        parentMonth.push(dateStr);
      }
    });
    parentMonth.forEach((item) => {
      const children = childMonth.filter(
        (iFind) => iFind.split('-')[0] === item.split('-')[0] && iFind.split('-')[1] === item.split('-')[1],
      );
      newHeader.push({
        title: makeTitleMonth(item),
        className: 'text-center',
        children: children.map((iChild) => {
          return {
            title: makeTitleDate(iChild),
            key: iChild,
            ellipsis: true,
            className: 'text-center p-0',
            width: 150,
            render: (item, row, index) => renderScheduleBar(item, row, index, iChild),
          };
        }),
      });
    });

    return newHeader;
  }

  function renderShift(item, row, index): JSX.Element {
    return (
      <div className="d-flex my-0 p-0">
        <div className="mr-auto">{row?.shift?.name}</div>
      </div>
    );
  }

  function renderWorkCenter(item, row, index): JSX.Element {
    return (
      <div className="d-flex my-0 p-0">
        <div className="mr-auto">{row?.work_center?.name}</div>
      </div>
    );
  }

  function renderProcess(item, row, index): JSX.Element {
    return (
      <div className="d-flex my-0 p-0">
        <div className="mr-auto">{row?.production_process?.name}</div>
      </div>
    );
  }

  function renderTeam(item, row, index): JSX.Element {
    return (
      <div className="d-flex my-0 p-0">
        <div className="mr-auto">
          {row?.production_team?.name}
          {(row?.production_team_leader?.name || row?.production_team_leader?.username) && (
            <span className="mr-auto">
              : {row?.production_team_leader?.name ?? row?.production_team_leader?.username}
            </span>
          )}
        </div>
      </div>
    );
  }

  function renderUser(item, row, index): JSX.Element {
    return (
      <div className="d-flex my-0 p-0">
        <div className="mr-auto">{row?.user?.name}</div>
      </div>
    );
  }

  function renderResource(item, row, index): JSX.Element {
    const keyArr = row?.section;
    const key = keyArr;
    if (KeyData.SHIFT === key) {
      return renderShift(item, row, index);
    }
    if (KeyData.WORK_CENTER === key) {
      return renderWorkCenter(item, row, index);
    }
    if (KeyData.PROCESS === key) {
      return renderProcess(item, row, index);
    }
    if (KeyData.TEAM === key) {
      return renderTeam(item, row, index);
    }
    if (KeyData.USER === key) {
      return renderUser(item, row, index);
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: true,
      width: 200,
      className: 'th-name-shift-planing py-0',
      render: renderResource,
    },
    ...(makeColumn(dateArr)?.[0]?.children ?? []),
  ];

  //set berdasarkan default data
  useEffect(() => {
    if (defaultData) {
      setData(defaultData);
    }
  }, [defaultData]);

  //set default data yg terbuka
  // useEffect(() => {
  //   if (data) {
  //     let openedRow = [];
  //     data?.forEach((dataShift: any) => {
  //       if (dataShift?.shift) {
  //         openedRow = [...openedRow, dataShift?.key];
  //       }
  //     });
  //     setDefaultExpandedRowKeys(openedRow);
  //   }
  // }, [data]);

  return (
    <div style={{ marginBottom: '10px' }}>
      <Table
        columns={columns}
        size="small"
        style={{ color: 'black' }}
        sticky={true}
        bordered
        key={JSON.stringify(data, columns)}
        pagination={false}
        dataSource={data}
        // expandedRowKeys={defaultExpandedRowKeys}
        // defaultExpandedRowKeys={defaultExpandedRowKeys}
        defaultExpandAllRows
        expandIcon={() => <span></span>}
      />
    </div>
  );
};

export default App;
