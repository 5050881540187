import { APPROVAL_MODULE } from '@base-helpers';
import { SalesOrderMJS } from '@pages/sales-mjs/sales-order';

export const ListSalesContent = [
  {
    module: APPROVAL_MODULE.module.SALES,
    sub_module: APPROVAL_MODULE.subModule.sales.SALES_ORDER,
    content: SalesOrderMJS,
  },
];
