import { HttpClient, ErrorRequest, HttpClientRepository } from '@base-protocols';
import { IBaseDataSourceRepository } from '@base-repositories';
import {
  BaseEntity,
  BaseParamsEntity,
  BaseResponseEntity,
  BaseResponseIndexEntity,
  BaseManagerParamsEntity,
  BaseResponseMultiDataEntity,
  BaseDataSourceConstructorEntity,
} from '@base-entities';

export abstract class BaseRemoteDataSource<E extends BaseEntity = BaseEntity> implements IBaseDataSourceRepository<E> {
  protected baseUrl: string;
  protected apiUrl: string;
  protected getIndexUrl: string;
  protected getDataUrl: string;
  protected createUrl: string;
  protected updateUrl: string;
  protected deleteUrl: string;
  protected multiDeleteUrl: string;
  protected confirmProcessUrl: string;
  protected multiConfirmProcessUrl: string;
  protected activateUrl: string;
  protected multiActivateUrl: string;
  protected deactivateUrl: string;
  protected multiDeactivateUrl: string;
  protected requestHttpClient: HttpClientRepository<BaseResponseEntity<E>> = new HttpClient<BaseResponseEntity<E>>();

  constructor(params: BaseDataSourceConstructorEntity) {
    this.baseUrl = params.baseUrl ?? process.env.REACT_APP_BASE_URL;
    this.apiUrl = params.apiUrl;
    this.getIndexUrl = params.getIndexUrl ?? params.apiUrl;
    this.getDataUrl = params.getDataUrl ?? params.apiUrl;
    this.createUrl = params.createUrl ?? params.apiUrl;
    this.updateUrl = params.updateUrl ?? params.apiUrl;
    this.deleteUrl = params.deleteUrl ?? params.apiUrl;
    this.multiDeleteUrl = params.multiDeleteUrl ?? params.apiUrl;
    this.confirmProcessUrl = params.confirmProcessUrl ?? params.apiUrl;
    this.multiConfirmProcessUrl = params.multiConfirmProcessUrl ?? params.apiUrl;
    this.activateUrl = params.activateUrl ?? params.apiUrl;
    this.multiActivateUrl = params.multiActivateUrl ?? params.apiUrl;
    this.deactivateUrl = params.deactivateUrl ?? params.apiUrl;
    this.multiDeactivateUrl = params.multiDeactivateUrl ?? params.apiUrl;
  }

  protected makeParams(params: BaseParamsEntity): any {
    return params;
  }

  protected makeApiUrl(url: string, baseUrl?: string): string {
    return `${baseUrl ?? this.baseUrl}${url}`;
  }

  protected handleError(error: any): any {
    const { message: messages, statusCode } = error;
    const message: any = messages ? (Array.isArray(messages) ? messages : [messages]) : ['Unexpected Error'];
    return {
      message,
      statusCode,
    };
  }

  protected makeIds(payload: E[]): string[] {
    const ids: string[] = [];
    for (const entity of payload) {
      ids.push(entity.id);
    }
    return ids;
  }

  protected makeId(payload: E): string {
    return payload.id;
  }

  async handleGetIndex(manager: BaseManagerParamsEntity<BaseResponseIndexEntity<E>>): Promise<void> {
    const { params, onSuccess, onFailed } = manager;
    try {
      const response: any = await this.requestHttpClient.request({
        url: this.makeApiUrl(this.getIndexUrl),
        method: 'GET',
        params: this.makeParams(params),
      });
      const { message } = response as BaseResponseEntity<BaseResponseIndexEntity<E>>;
      onSuccess({ response: message });
    } catch (error) {
      const { message, statusCode } = this.handleError(error) as ErrorRequest;
      onFailed({ message, statusCode });
    }
  }

  async handleGetData(id: string, manager: BaseManagerParamsEntity<E>): Promise<void> {
    const { params, onSuccess, onFailed } = manager;
    try {
      const response: any = await this.requestHttpClient.request({
        url: this.makeApiUrl(`${this.getDataUrl}/${id}`),
        method: 'GET',
        params: this.makeParams(params),
      });
      const { message } = response as BaseResponseEntity<E>;
      onSuccess({ response: message });
    } catch (error) {
      const { message, statusCode } = this.handleError(error) as ErrorRequest;
      onFailed({ message, statusCode });
    }
  }

  async handleCreate(payload: E, manager: BaseManagerParamsEntity<E>): Promise<void> {
    const { params, onSuccess, onFailed } = manager;
    try {
      const response: any = await this.requestHttpClient.request({
        url: this.makeApiUrl(this.createUrl),
        method: 'POST',
        params: this.makeParams(params),
        data: payload,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Accept: '*/*',
        },
      });
      const { message } = response as BaseResponseEntity<E>;
      onSuccess({ response: message ?? response });
    } catch (error) {
      const { message, statusCode } = this.handleError(error) as ErrorRequest;
      onFailed({ message, statusCode });
    }
  }

  async handleUpdate(id: string, payload: E, manager: BaseManagerParamsEntity<E>): Promise<void> {
    const { params, onSuccess, onFailed } = manager;
    try {
      const response: any = await this.requestHttpClient.request({
        url: this.makeApiUrl(`${this.updateUrl}/${id}`),
        method: 'PUT',
        params: this.makeParams(params),
        data: payload,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Accept: '*/*',
        },
      });
      const { message } = response as BaseResponseEntity<E>;
      onSuccess({ response: message });
    } catch (error) {
      const { message, statusCode } = this.handleError(error) as ErrorRequest;
      onFailed({ message, statusCode });
    }
  }

  async handleDelete(payload: E, manager: BaseManagerParamsEntity<E>): Promise<void> {
    const { params, onSuccess, onFailed } = manager;
    const id = this.makeId(payload);
    try {
      const response: any = await this.requestHttpClient.request({
        url: this.makeApiUrl(`${this.deleteUrl}/${id}`),
        method: 'DELETE',
        params: this.makeParams(params),
      });
      const { message } = response as BaseResponseEntity<E>;
      onSuccess({ response: message });
    } catch (error) {
      const { message, statusCode } = this.handleError(error) as ErrorRequest;
      onFailed({ message, statusCode });
    }
  }

  async handleMultiDelete(payload: E[], manager: BaseManagerParamsEntity<BaseResponseMultiDataEntity>): Promise<void> {
    const { params = {}, onSuccess, onFailed } = manager;
    params.ids = this.makeIds(payload);
    try {
      const response: any = await this.requestHttpClient.request({
        url: this.makeApiUrl(this.deleteUrl),
        method: 'DELETE',
        params: this.makeParams(params),
      });
      const { message } = response as BaseResponseEntity<BaseResponseMultiDataEntity>;
      onSuccess({ response: message });
    } catch (error) {
      const { message, statusCode } = this.handleError(error) as ErrorRequest;
      onFailed({ message, statusCode });
    }
  }

  async handleConfirmProcess(payload: E, manager: BaseManagerParamsEntity<E>): Promise<void> {
    const { params, onSuccess, onFailed } = manager;
    const id = this.makeId(payload);
    try {
      const response: any = await this.requestHttpClient.request({
        url: this.makeApiUrl(`${this.confirmProcessUrl}/${id}`),
        method: 'PUT',
        params: this.makeParams(params),
      });
      const { message } = response as BaseResponseEntity<E>;
      onSuccess({ response: message });
    } catch (error) {
      const { message, statusCode } = this.handleError(error) as ErrorRequest;
      onFailed({ message, statusCode });
    }
  }

  async handleMultiConfirmProcess(
    payload: E[],
    manager: BaseManagerParamsEntity<BaseResponseMultiDataEntity>,
  ): Promise<void> {
    const { params = {}, onSuccess, onFailed } = manager;
    params.ids = this.makeIds(payload);
    try {
      const response: any = await this.requestHttpClient.request({
        url: this.makeApiUrl(this.multiConfirmProcessUrl),
        method: 'PUT',
        params: this.makeParams(params),
      });
      const { message } = response as BaseResponseEntity<BaseResponseMultiDataEntity>;
      onSuccess({ response: message });
    } catch (error) {
      const { message, statusCode } = this.handleError(error) as ErrorRequest;
      onFailed({ message, statusCode });
    }
  }

  async handleActivate(payload: E, manager: BaseManagerParamsEntity<E>): Promise<void> {
    const { params, onSuccess, onFailed } = manager;
    const id = this.makeId(payload);
    try {
      const response: any = await this.requestHttpClient.request({
        url: this.makeApiUrl(`${this.activateUrl}/${id}/active`),
        method: 'PUT',
        params: this.makeParams(params),
      });
      const { message } = response as BaseResponseEntity<E>;
      onSuccess({ response: message });
    } catch (error) {
      const { message, statusCode } = this.handleError(error) as ErrorRequest;
      onFailed({ message, statusCode });
    }
  }

  async handleDeactivate(payload: E, manager: BaseManagerParamsEntity<E>): Promise<void> {
    const { params, onSuccess, onFailed } = manager;
    const id = this.makeId(payload);
    try {
      const response: any = await this.requestHttpClient.request({
        url: this.makeApiUrl(`${this.deactivateUrl}/${id}/inactive`),
        method: 'PUT',
        params: this.makeParams(params),
      });
      const { message } = response as BaseResponseEntity<E>;
      onSuccess({ response: message });
    } catch (error) {
      const { message, statusCode } = this.handleError(error) as ErrorRequest;
      onFailed({ message, statusCode });
    }
  }

  async handleCustomRequest(manager: BaseManagerParamsEntity<BaseResponseIndexEntity<E>>): Promise<void> {
    const { onSuccess, onFailed, token, paramRequest } = manager;
    try {
      const response: any = await this.requestHttpClient.request(paramRequest, token);
      const { message } = response as BaseResponseEntity<BaseResponseIndexEntity<E>>;
      onSuccess({ response: message ?? response });
    } catch (error) {
      const { message, statusCode } = this.handleError(error) as ErrorRequest;
      onFailed({ message, statusCode });
    }
  }
}
