import { RenderText } from '@base-components';
import moment from 'moment';
import { renderCurrency } from '../components';

const generateDueDate = (row) => {
  const dueDate =
    row?.invoice_date && row?.top?.due_date ? moment(row?.invoice_date).add(row?.top?.due_date, 'days') : null;
  const aging = row?.invoice_date && dueDate ? moment(dueDate).diff(row?.invoice_date, 'days') : null;
  return { dueDate, aging: aging ? `${aging} days` : null };
};

export const configColumns = [
  {
    title: 'Document Type',
    key: 'document_type',
    dataIndex: 'document_type',
    render: (item: any, row: any) => RenderText({ value: 'Sales Invoice' }),
  },
  {
    title: 'Sales Document ID',
    key: 'sales_invoice',
    dataIndex: 'sales_invoice',
    render: (item: any, row: any) => RenderText({ value: item?.code }),
  },
  {
    title: 'Sales Invoice Date',
    key: 'sales_invoice',
    dataIndex: 'sales_invoice',
    render: (item: any, row: any) =>
      RenderText({ value: item?.invoice_date ? moment(item?.invoice_date).format('DD/MM/YYYY') : '-' }),
  },
  {
    title: 'Due Date',
    key: 'sales_invoice',
    dataIndex: 'sales_invoice',
    render: (item: any, row: any) =>
      RenderText({
        value: generateDueDate(row?.sales_invoice).dueDate
          ? moment(generateDueDate(row?.sales_invoice).dueDate).format('DD/MM/YYYY')
          : '-',
      }),
  },
  {
    title: 'Aging',
    key: 'sales_invoice',
    dataIndex: 'sales_invoice',
    render: (item: any, row: any) => RenderText({ value: generateDueDate(row?.sales_invoice).aging }),
  },
  {
    title: 'Rounding',
    key: 'rounding',
    dataIndex: 'rounding',
    render: (item: any, row: any) => renderCurrency({ value: item ? item : 0 }),
  },
  {
    title: 'Invoice Amount',
    key: 'sales_invoice',
    dataIndex: 'sales_invoice',
    render: (item: any, row: any) => renderCurrency({ value: item?.outcome_total?.total }),
  },
  {
    title: 'Payment',
    key: 'sales_invoice',
    dataIndex: 'sales_invoice',
    render: (item: any, row: any) => renderCurrency({ value: item?.payment }),
  },
  {
    title: 'Invoice Balance',
    key: 'sales_invoice',
    dataIndex: 'sales_invoice',
    render: (item: any, row: any) => renderCurrency({ value: item?.balance }),
  },
];

const requestInvoice = async (invoiceId) => {
  try {
    const response: any = await fetch(`${process.env.REACT_APP_BASE_URL}v1/finance/sales-invoices/${invoiceId}`);
    const result = await response.json();
    return result?.message;
  } catch (error) {
    return null;
  }
};

export const getInvoices = async (dataInvoices) => {
  try {
    let invoices = [];
    for (const dataInvoice of dataInvoices) {
      const detailInvoice = await requestInvoice(dataInvoice?.sales_invoice?.id);
      const newData = {
        ...dataInvoice,
        sales_invoice: detailInvoice,
      };
      invoices = [...invoices, newData];
    }
    return invoices;
  } catch (error) {
    return [];
  }
};
