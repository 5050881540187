import React, { Fragment, useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCountCurrency, RenderCurrency } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { PAPER_FORMAT, stringFormatter, dateFormatter } from '@base-helpers';
import moment from 'moment';
import { Col, Row, Table } from 'antd';
import { getVirtualAccounts } from './helpers';
import QRCode from 'react-qr-code';
import { renderCurrency } from '../components';

export function Detail() {
  const { detailData = {}, printContent } = usePagePrint();
  const transactions = detailData?.transactions;
  console.log(detailData);
  // const [virtualAccounts, setVirtualAccounts] = useState([]);
  const defaultSignature: any[] = [
    {
      title: 'Hormat Kami',
      name: '',
      gridColumn: { span: 6 },
    },
  ];
  const renderDiscount = (data) => {
    if (data?.after_tax_formula_render) {
      let formula = [];
      if (Array.isArray(data?.after_tax_formula_render)) formula = data?.after_tax_formula_render;
      else formula = JSON.parse(data?.after_tax_formula_render);
      const newFormulaInNumber = formula
        ?.map((item) => {
          if (item?.label !== NaN) {
            return Number(item?.label);
          }
          return null;
        })
        .filter(Boolean);
      const totalDisc = newFormulaInNumber?.reduce((partialSum, a) => partialSum + a, 0);
      return Math.abs(totalDisc);
    }
    return '-';
  };
  const columns: any[] = [
    {
      title: 'Trans date',
      key: 'trans_date',
      dataIndex: 'trans_date',
      render: (item) => item?.code,
    },
    {
      title: 'Due',
      key: 'due',
      dataIndex: 'due',
      render: (item) => item?.name,
      style: { minWidth: '100px' },
    },
    {
      title: 'Invoice',
      key: 'invoice',
      dataIndex: 'invoice',
      render: (item, row) => row?.qty ?? row?.unit,
      // style: { textAlign: 'right' },
    },
    {
      title: 'Surat Jalan',
      key: 'surat_jalan',
      dataIndex: 'surat_jalan',
      render: (item) => item?.code,
      // style: { textAlign: 'right' },
    },
    {
      title: 'Jumlah Tagihan',
      key: '',
      dataIndex: '',
      render: (item) => item?.toLocaleString('id-ID'),
      style: { textAlign: 'right' },
    },
    {
      title: 'Sisa Tagihan',
      key: '',
      dataIndex: '',
      render: (item, row) => renderDiscount(row),
      style: { textAlign: 'right' },
    },
    // {
    //   title: 'Discount',
    //   key: 'disc_total_after_tax',
    //   dataIndex: 'disc_total_after_tax',
    //   render: (item) => item?.toLocaleString('id-ID') ?? '0',
    //   style: { textAlign: 'right' },
    // },
    // {
    //   title: 'Amount',
    //   key: 'subtotal_after_tax',
    //   dataIndex: 'subtotal_after_tax',
    //   render: (item) => item?.toLocaleString('id-ID'),
    //   style: { textAlign: 'right' },
    // },
  ];

  return (
    <DetailPrint paperFormat={PAPER_FORMAT.A4} orientation="portrait" printHeaderProps={{ showLogo: false }}>
      <div style={{ fontSize: '11px' }}>
        <p>(C) Laporan Tagihan Salesman (Pelunasan AR)</p>
        <table style={{ width: '100%', marginTop: '5px' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid black', textAlign: 'left' }}>
              {columns?.map((column) => {
                return (
                  <th style={{ ...column?.style, paddingRight: '10px' }} key={column?.key}>
                    {column?.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {transactions?.map((transaction) => {
              return transaction?.invoices?.map((invoice) => (
                <tr key={invoice?.id} style={{ borderBottom: '1px solid black' }}>
                  <td>{dateFormatter(invoice?.sales_invoice?.invoice_date).toLocal('DD/MM/YYYY')}</td>
                  <td>
                    {invoice?.sales_invoice?.top
                      ? moment(invoice?.sales_invoice?.invoice_date)
                          .add(invoice?.sales_invoice?.top?.due_date, 'days')
                          .format('DD/MM/YYYY')
                      : '-'}
                  </td>
                  <td>{invoice?.sales_invoice?.code}</td>
                  <td>{invoice?.sales_invoice?.packing_slip?.code}</td>
                  <td style={{ textAlign: 'right' }}>
                    {renderCurrency({ value: invoice?.sales_invoice?.outcome_total?.total })}
                  </td>
                  <td style={{ textAlign: 'right' }}>{renderCurrency({ value: invoice?.sales_invoice?.balance })}</td>
                </tr>
              ));
            })}
            <tr style={{ marginBottom: 10 }}>
              <td colSpan={4}>
                Delivery name :{' '}
                {`${transactions?.map(
                  (transaction) => `${transaction?.customer?.name} (${transaction?.customer?.code})`,
                )}`}
              </td>
              <td style={{ textAlign: 'right' }}>
                {transactions?.map((transaction) => {
                  return renderCurrency({ value: transaction?.total_invoice_amount });
                })}
              </td>
              <td style={{ textAlign: 'right' }}>
                {transactions?.map((transaction) => {
                  return renderCurrency({ value: transaction?.total_invoice_balance });
                })}
              </td>
            </tr>
            <tr>
              <td>Norm Acc #C00</td>
            </tr>
            <tr>
              <td colSpan={4}>Grand Total</td>
              <td style={{ textAlign: 'right' }}>{renderCurrency({ value: detailData?.total_invoices })}</td>
              <td style={{ textAlign: 'right' }}>
                {renderCurrency({
                  value: transactions?.reduce(
                    (accumulator, currentValue) => accumulator + currentValue?.total_invoice_balance,
                    0,
                  ),
                })}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </DetailPrint>
  );
}
