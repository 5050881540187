import { APPROVAL_MODULE } from '@base-helpers';
import { PackingSlipMJS } from '@pages/inventory-mjs/packing-slip';
import { ReceiptMJS } from '@pages/inventory-mjs/receipt';
import { StockOpnameMJS } from '@pages/inventory-mjs/stock-adjustment';
import { TransferOrderMJS } from '@pages/inventory-mjs/transfer-order';

export const ListInventoryContent = [
  {
    module: APPROVAL_MODULE.module.INVENTORY,
    sub_module: APPROVAL_MODULE.subModule.inventory.PACKING_EXTERNAL,
    content: PackingSlipMJS,
  },
  {
    module: APPROVAL_MODULE.module.INVENTORY,
    sub_module: APPROVAL_MODULE.subModule.inventory.RECEIPTS_EXTERNAL,
    content: ReceiptMJS,
  },
  {
    module: APPROVAL_MODULE.module.INVENTORY,
    sub_module: APPROVAL_MODULE.subModule.inventory.TRANSFER_ORDER,
    content: TransferOrderMJS,
  },
  {
    module: APPROVAL_MODULE.module.INVENTORY,
    sub_module: APPROVAL_MODULE.subModule.inventory.STOCK_ADJUSTMENT,
    content: StockOpnameMJS,
  },
];
