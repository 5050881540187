import React from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCurrency, RenderText } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, makeModuleBarcode, PAPER_FORMAT, stringFormatter, userPrinter } from '@base-helpers';
import moment from 'moment';
import { Col, Row, Table } from 'antd';
import QRCode from 'react-qr-code';

export function Detail() {
  const { detailData = {}, printContent, type } = usePagePrint();
  const hasCode = detailData?.code;
  const dataSource = detailData?.exchange_product_details;

  const user = userPrinter();
  const configColumns = [
    {
      title: 'Ref',
      key: 'packing_slip',
      dataIndex: 'packing_slip',
      width: 110,
      render: (item: any) => RenderText({ value: item?.code ? item?.code : '-' }),
    },
    {
      title: 'Product',
      key: 'product',
      dataIndex: 'product',
      width: 300,
      render: (item: any) => RenderText({ value: item?.name }),
    },
    {
      title: 'Unit',
      key: 'unit',
      dataIndex: 'unit',
      render: (item: any) => RenderText({ value: item ? item : '-' }),
    },
    {
      title: 'UoM',
      key: 'unit_uom',
      dataIndex: 'unit_uom',
      render: (item: any) => RenderText({ value: item ? item?.code : '-' }),
    },
    {
      title: 'Qty',
      key: 'qty',
      dataIndex: 'qty',
      render: (item: any) => RenderText({ value: item ? item : '-' }),
    },
    {
      title: 'UoM',
      key: 'qty_uom',
      dataIndex: 'qty_uom',
      render: (item: any) => RenderText({ value: item ? item?.code : '-' }),
    },
    {
      title: 'Reason',
      key: 'reasons',
      dataIndex: 'reasons',
      width: 150,
      // align: 'right',
      render: (item: any) => RenderText({ value: item ? item : '-' }),
    },
  ];

  const customer = detailData?.contact_reference ?? detailData?.customer;
  const customerLabel = customer ? `${customer?.name} (${customer?.code})` : '-';
  return (
    <DetailPrint
      orientation="portrait"
      // printHeaderProps={{ barcode: detailData?.code }}
      paperFormat={PAPER_FORMAT.A4}
      printSignatureProps={{
        signatures: [
          {
            gridColumn: { span: 6 },
            title: 'Created By',
            name: detailData?.creator_name ? detailData?.creator_name : '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Printed By',
            name: user?.username ? user?.username : '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Requested By',
            name: detailData?.editor_name ? detailData?.editor_name : '-',
          },
          { gridColumn: { span: 6 }, title: 'Approved By', name: '_____________' },
        ],
      }}
    >
      {/* <DetailBuilder
        columns={[
          {
            fields: [
              {
                gridColumn: { span: 12 },
                renderField(form) {
                  return (
                    <PrintTitle
                      title="Exchange Product"
                      {...(hasCode ? { description: `No. ${detailData?.code}` } : {})}
                    />
                  );
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Created Date',
                name: 'created_at',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Printed Date',
                name: 'id',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: () => moment().format('YYYY-MM-DD'),
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Canceled Date',
                name: 'id',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => '-',
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Status',
                name: 'status',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => stringFormatter.capitalizeEachWord(value?.split('_')?.join(' ')),
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Site',
                name: 'site',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.code;
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Sales Division',
                name: 'sales_division',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.code;
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Sales Area',
                name: 'sales_area',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.code;
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Salesman',
                name: 'salesman',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.staff?.name;
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Expected Date',
                name: 'receipt_information',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value?.expected_receipt_date
                      ? dateFormatter(value?.expected_receipt_date).toLocal('YYYY-MM-DD')
                      : '-';
                  },
                },
              },
              {
                gridColumn: { span: 9 },
                label: 'References',
                name: 'references',
                fieldType: 'text',
                renderTextProps: {
                  renderType: 'multi',
                  renderValue(value) {
                    return value?.transaction_code ?? value?.code;
                  },
                },
              },

              {
                gridColumn: { span: 24 },
                label: 'Customer',
                name: detailData?.contact_reference ? 'contact_reference' : 'customer',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.name;
                  },
                },
              },
            ],
          },
        ]}
      /> */}

      <Row gutter={10}>
        <Col span={8}>
          <p>
            {detailData?.customer?.name} ({detailData?.customer?.code})
          </p>
          <p>{detailData?.receive_information?.address ?? detailData?.deliver_information?.address ?? ''}</p>
        </Col>
        <Col span={6}>
          <h2>Exchange Product {type ? `(${stringFormatter.capitalizeEachWord(type)})` : ''}</h2>
          <table>
            <tr>
              <td>Number</td>
              <td>:</td>

              <td>{detailData?.code ?? '-'}</td>
            </tr>
            <tr>
              <td>Site</td>
              <td>:</td>

              <td>{detailData?.site?.code ?? ''}</td>
            </tr>
            <tr>
              <td>Sales Division</td>
              <td>:</td>

              <td>{detailData?.sales_division?.code ?? ''}</td>
            </tr>

            <tr>
              <td>Salesman</td>
              <td>:</td>

              <td>{detailData?.salesman?.staff?.name}</td>
            </tr>
          </table>
        </Col>

        <Col span={6}>
          <div style={{ marginTop: '30px' }}></div>
          <table>
            <tr>
              <td>Printed Date</td>
              <td>:</td>

              <td>{moment().format('YYYY-MM-DD')}</td>
            </tr>

            <tr>
              <td>Expected Date</td>
              <td>:</td>
              <td>
                {detailData?.receipt_information?.expected_receipt_date
                  ? dateFormatter(detailData?.receipt_information?.expected_receipt_date).toLocal('YYYY-MM-DD')
                  : '-'}
              </td>
            </tr>

            <tr>
              <td>Term of Payment</td>
              <td>:</td>

              <td>{detailData?.invoice_information?.top?.name ?? '-'}</td>
            </tr>

            <tr>
              <td style={{ paddingRight: '10px' }}>Your reference</td>
              <td>:</td>

              <td>
                {detailData?.references
                  ?.map((item) => {
                    if (item?.sub_module !== 'sales_order') {
                      return item?.transaction_code;
                    }
                    return null;
                  })
                  .filter(Boolean)
                  .join(', ')}
              </td>
            </tr>
          </table>
        </Col>
        <Col span={4} style={{ paddingTop: '10px' }}>
          <QRCode
            value={
              makeModuleBarcode({
                id: detailData?.id,
                module: printContent?.module,
                subModule: printContent?.sub_module,
              }) ?? ''
            }
            size={90}
          />
        </Col>
      </Row>
      <div style={{ marginBottom: '10px' }}></div>

      <Row gutter={[8, 8]}>
        <Col span={24}>
          <table style={{ width: '100%', marginTop: '30px', marginBottom: '30px' }}>
            <thead style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}>
              <tr>
                <td>Ref</td>
                <td>Product</td>
                <td>Unit</td>
                <td>UoM</td>
                <td>Reason</td>
              </tr>
            </thead>
            <tbody>
              {detailData?.exchange_product_details?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{RenderText({ value: item?.packing_slip?.code ?? '-' })}</td>
                    <td>{RenderText({ value: item?.product?.name })}</td>
                    <td>
                      {RenderText({
                        value:
                          item?.product?.breakdown_qty || item?.product?.uom?.category === 'UNIT'
                            ? item?.unit
                            : item?.qty,
                      })}
                    </td>
                    <td>
                      {RenderText({
                        value:
                          item?.product?.breakdown_qty || item?.product?.uom?.category === 'UNIT'
                            ? item?.uom_unit?.code
                            : item?.uom_qty?.code,
                      })}
                    </td>
                    <td>{item?.reasons ? item?.reasons : '-'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
      </Row>
      {/* <Table
        dataSource={dataSource}
        columns={configColumns}
        pagination={false}
        size="small"
        bordered
        style={{ marginBottom: '20px', marginTop: '20px' }}
      /> */}
    </DetailPrint>
  );
}
