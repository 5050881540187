import React from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCurrency, RenderText } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter, userPrinter } from '@base-helpers';
import moment from 'moment';
import { Col, Descriptions, Row, Table } from 'antd';
import QRCode from 'react-qr-code';

export function Detail() {
  const { detailData = {} } = usePagePrint();
  const salesType = detailData?.type;
  const hasCode = detailData?.code;
  const dataSource = salesType === 'sales_return' ? detailData?.product_line_returns : detailData?.product_line_orders;
  const user = userPrinter();

  const customer = detailData?.customer;
  const customerReference = detailData?.customer_reference;
  const labelCustomer = `${customer?.name}`;
  const treatmentPacking = detailData?.deliver_information?.treatment_packing?.product_attributes ?? [];
  const productAttributes = detailData?.product_attributes ?? [];

  //Create code po buyer
  const references = detailData?.references ?? [];
  const referencesCode = [];
  references?.forEach((item) => {
    const code = item?.transaction_code ?? item?.code;
    if (code) {
      referencesCode.push(code);
    }
  });
  // const codePOBuyer = referencesCode?.length >= 1 ? referencesCode?.toString() : '-';
  const codePOBuyer = detailData?.customer_reference?.name ?? '-';

  //Inisiasi untuk kalkulasi total
  let totalQty = 0;
  let totalUnit = 0;
  let totalQtyPerUnit = 0;
  const dataTracking = [];
  const dataBoM = [];

  //Hitung leadtime shipment
  const orderDate = detailData?.order_date ? moment(detailData?.order_date) : '-';
  const expectedDate = detailData?.deliver_information?.expected_date
    ? moment(detailData?.deliver_information?.expected_date)
    : null;
  const leadTimeShipment = orderDate && expectedDate ? expectedDate.diff(orderDate, 'days') : '-';

  const calculateUniqueData = (data: string[]): number => {
    const uniqueData = [...new Set(data)] ?? [];
    return uniqueData?.length ?? 0;
  };

  return (
    <DetailPrint orientation="portrait" paperFormat={PAPER_FORMAT.F4}>
      <div style={{ position: 'relative', height: '100% !important', fontSize: '9px' }}>
        <Row>
          <Col style={{ border: '1px solid black' }} span={24}>
            <Row>
              <Col span={12}>
                <div style={{ fontWeight: 'bold', display: 'block', marginTop: '10px', marginLeft: '10px' }}>
                  <h3 style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>PT Mahugi Jaya Sejahtera</h3>
                  <p>Batujajar km 6 no 177, Kabupaten Bandung Barat, Jawa Barat 40561</p>
                </div>
              </Col>
              <Col span={12} style={{ position: 'relative', height: '120px' }}>
                <div
                  style={{
                    position: 'absolute',
                    top: '-1px',
                    right: '-1px',
                    border: '1px solid black',
                    padding: '10px 15px 0',
                  }}
                >
                  <h1 style={{ fontWeight: 'bold', fontSize: '18px', textTransform: 'uppercase' }}>
                    {salesType === 'sales_return' ? 'Sales Return' : 'Sales Order'}
                  </h1>
                </div>
                <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                  <table>
                    <tr style={{ fontWeight: 'bold', fontSize: '12px' }}>
                      <td>No {salesType === 'sales_return' ? 'SR' : 'SO'}</td>
                      <td>Tanggal</td>
                    </tr>
                    <tr style={{ border: '1px solid black', fontSize: '14px' }}>
                      <td style={{ borderRight: '1px solid black', padding: '5px 10px' }}>
                        {detailData?.code ? detailData?.code : '-'}
                      </td>
                      <td style={{ padding: '5px 10px' }}>
                        {detailData?.order_date ? dateFormatter(detailData?.order_date).toLocal('DD/MM/YYYY') : '-'}
                      </td>
                    </tr>
                  </table>
                </div>
              </Col>
              <Col span={24} style={{ borderBottom: '1px solid black', margin: '10px 0 0' }} />
              <Col span={12} style={{ padding: '10px', textTransform: 'uppercase' }}>
                <div style={{ display: 'block', marginBottom: '5px' }}>
                  <strong>Customer Name</strong>
                  <div>{labelCustomer}</div>
                </div>
                <div style={{ display: 'block', marginBottom: '5px' }}>
                  <strong>Code PO Buyer</strong>
                  <div>{codePOBuyer}</div>
                </div>
                <div style={{ display: 'block', marginBottom: '5px' }}>
                  <strong>Leadtime Shipment</strong>
                  <div>{leadTimeShipment ? leadTimeShipment : '-'}</div>
                </div>
                <div style={{ display: 'block' }}>
                  <strong>Note</strong>
                  <div>{detailData?.notes ?? '-'}</div>
                </div>
              </Col>
              <Col span={12} style={{ padding: '10px', textTransform: 'uppercase' }}>
                {treatmentPacking?.map((item, index) => {
                  if (item?.attributes?.length >= 1 && item?.attribute_values?.length >= 1) {
                    const label = item?.attributes[0]?.name ? item?.attributes[0]?.name : 'Unknown';
                    const value = item?.attribute_values[0]?.item
                      ? item?.attribute_values[0]?.item?.toString()
                      : 'Uknown';
                    return (
                      <div style={{ display: 'block', marginBottom: '5px' }} key={index}>
                        <strong>{label}</strong>
                        <div>{value}</div>
                      </div>
                    );
                  }
                })}
                {treatmentPacking?.length >= 1 && productAttributes?.length >= 1 && (
                  <div style={{ borderBottom: '1px solid black' }} />
                )}
                {productAttributes?.map((item, index) => {
                  if (item?.attributes?.length >= 1 && item?.attribute_values?.length >= 1) {
                    const label = item?.attributes[0]?.name ? item?.attributes[0]?.name : 'Unknown';
                    const value = item?.attribute_values[0]?.item
                      ? item?.attribute_values[0]?.item?.toString()
                      : 'Unknown';
                    return (
                      <div style={{ display: 'block', marginBottom: '5px' }} key={index}>
                        <strong>{label}</strong>
                        <div>{value}</div>
                      </div>
                    );
                  }
                })}
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginTop: '10px' }}>
            <table style={{ width: '100%', border: '1px solid black' }}>
              <thead style={{ textTransform: 'uppercase', fontWeight: 'bold', border: '1px solid black' }}>
                <tr>
                  <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>No</td>
                  <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>Product</td>
                  <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>SJ Greige</td>
                  <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>No. Labdip</td>
                  <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>Qty Unit</td>
                  <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>UoM</td>
                  <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>Qty/Unit</td>
                  <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>Qty Total</td>
                  <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>UoM</td>
                  <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>Remark</td>
                </tr>
              </thead>
              <tbody>
                {dataSource?.map((item, index) => {
                  const number = index + 1;
                  const product = item?.product;
                  const isBreakdown = product?.breakdown_qty;
                  const productName = product ? product?.name : '-';

                  const qty = item?.qty ?? 0;
                  const unit = item?.unit ?? 0;
                  const qtyPerUnit = isBreakdown ? qty / unit : 0;

                  //Kalkulasi
                  totalQty += qty;
                  totalUnit += unit;
                  totalQtyPerUnit += qtyPerUnit;

                  //Push tracking, tracking material, dan bom
                  const tracking = item?.tracking_material ?? item?.tracking;
                  if (tracking) dataTracking.push(tracking?.id);
                  const bom = item?.bom;
                  if (bom) dataBoM.push(bom?.id);

                  return (
                    <tr key={index}>
                      <td
                        style={{
                          border: '1px solid black',
                          padding: '5px 7px',
                          textAlign: 'center',
                          width: 10,
                        }}
                      >
                        {number}
                      </td>
                      <td style={{ border: '1px solid black', padding: '5px 7px', width: 300 }}>{productName}</td>
                      <td style={{ border: '1px solid black', padding: '5px 7px', width: 100 }}>
                        {item?.tracking_material ? item?.tracking_material?.code : '-'}
                      </td>
                      <td style={{ border: '1px solid black', padding: '5px 7px', width: 80 }}>
                        {item?.bom ? item?.bom?.name : '-'}
                      </td>
                      <td style={{ border: '1px solid black', padding: '5px 7px', width: 15 }}>
                        {item?.unit ? item?.unit : '-'}
                      </td>
                      <td style={{ border: '1px solid black', padding: '5px 7px', width: 15 }}>
                        {item?.uom_unit ? item?.uom_unit?.code : '-'}
                      </td>
                      <td style={{ border: '1px solid black', padding: '5px 7px', width: 20 }}>
                        {qtyPerUnit ? qtyPerUnit : '-'}
                      </td>
                      <td style={{ border: '1px solid black', padding: '5px 7px', width: 20 }}>
                        {item?.qty ? item?.qty : '-'}
                      </td>
                      <td style={{ border: '1px solid black', padding: '5px 7px', width: 10 }}>
                        {item?.uom_qty ? item?.uom_qty?.code : '-'}
                      </td>
                      <td style={{ border: '1px solid black', padding: '5px 7px', width: 10 }}></td>
                    </tr>
                  );
                })}
              </tbody>
              {salesType === 'sales_return' ? (
                <tfoot>
                  <tr>
                    <td
                      colSpan={9}
                      style={{
                        borderRight: '1px solid black',
                        borderBottom: '1px solid black',
                        padding: '5px 7px',
                        fontWeight: 'bold',
                      }}
                    >
                      Total Return Qty
                    </td>
                    <td style={{ border: '1px solid black', padding: '5px 7px', width: 10 }}>{totalQty}</td>
                  </tr>
                  <tr>
                    <td
                      colSpan={9}
                      style={{
                        borderRight: '1px solid black',
                        borderBottom: '1px solid black',
                        padding: '5px 7px',
                        fontWeight: 'bold',
                      }}
                    >
                      Total Color/Design
                    </td>
                    <td style={{ border: '1px solid black', padding: '5px 7px', width: 10 }}>
                      {calculateUniqueData(dataBoM)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={9}
                      style={{
                        borderRight: '1px solid black',
                        borderBottom: '1px solid black',
                        padding: '5px 7px',
                        fontWeight: 'bold',
                      }}
                    >
                      Total Batch Production
                    </td>
                    <td style={{ border: '1px solid black', padding: '5px 7px', width: 10 }}>
                      {calculateUniqueData(dataTracking)}
                    </td>
                  </tr>
                </tfoot>
              ) : (
                <tfoot>
                  <tr>
                    <td
                      colSpan={4}
                      style={{
                        borderRight: '1px solid black',
                        borderBottom: '1px solid black',
                        padding: '5px 7px',
                        fontWeight: 'bold',
                      }}
                    >
                      Total Order Qty
                    </td>
                    <td style={{ border: '1px solid black', padding: '5px 7px', width: 10 }}>{totalUnit}</td>
                    <td style={{ border: '1px solid black', padding: '5px 7px', width: 10 }}></td>
                    <td style={{ border: '1px solid black', padding: '5px 7px', width: 10 }}>{totalQtyPerUnit}</td>
                    <td style={{ border: '1px solid black', padding: '5px 7px', width: 10 }}>{totalQty}</td>
                    <td style={{ border: '1px solid black', padding: '5px 7px', width: 10 }}></td>
                    <td style={{ border: '1px solid black', padding: '5px 7px', width: 10 }}></td>
                  </tr>
                  <tr>
                    <td
                      colSpan={9}
                      style={{
                        borderRight: '1px solid black',
                        borderBottom: '1px solid black',
                        padding: '5px 7px',
                        fontWeight: 'bold',
                      }}
                    >
                      Total Color/Design
                    </td>
                    <td style={{ border: '1px solid black', padding: '5px 7px', width: 10 }}>
                      {calculateUniqueData(dataBoM)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={9}
                      style={{
                        borderRight: '1px solid black',
                        borderBottom: '1px solid black',
                        padding: '5px 7px',
                        fontWeight: 'bold',
                      }}
                    >
                      Total Batch Production
                    </td>
                    <td style={{ border: '1px solid black', padding: '5px 7px', width: 10 }}>
                      {calculateUniqueData(dataTracking)}
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
          </Col>
        </Row>
      </div>
    </DetailPrint>
  );
}
