export const APPROVAL_MODULE = {
  module: {
    COMPANY: 'company',
    APPROVAL: 'company',
    SALES: 'sales',
    PURCHASE: 'purchase',
    INVENTORY: 'inventory',
    USER_CATEGORY: 'company',
    CONTACT: 'contact',
    LOGISTIC: 'logistic',
    ACCOUNTING: 'accounting',
    PRODUCTION: 'production',
  },
  subModule: {
    company: {
      COMPANY: 'data companies',
      SITE: 'data sites',
      BRANCH: 'data branches',
      LEGALITY: 'data legalities',
      LOCATION: 'data locations',
      USER: 'users',
      PRINTER: 'printer',
    },
    approval: {
      APPROVAL: 'data companies',
    },
    inventory: {
      INTERNAL_TRANSFER: 'internal_transfer', //
      SCRAP: 'Scraps', //
      STOCK_ADJUSTMENT: 'Stock Opname', //
      BRAND: 'Brands', //
      ATTRIBUTE: 'Attributes', //
      ATTRIBUTE_GROUP: 'Attribute Groups', //
      ATTRIBUTE_VALUE: 'Product Attribute Values', //
      UOM: 'Unit of Measurement', //
      PRODUCT_CATEGORY: 'Product Categories', //
      SITE: 'Site',
      LOCATIONS: 'Locations',
      PRODUCT_PLACEMENT_RULES: 'Product Placement & Rules',
      PRODUCT_MASTER: 'Product Master',
      STOCKS: 'Stocks',
      TRACKING: 'Tracking',
      PLACEMENT_RULES: 'Placement & Rules',
      SALES: 'Sales',
      PURCHASE: 'Purchase',
      PRODUCT_VARIANT: 'Product Variant',
      USAGE: 'Usage',
      PRODUCT_PACKAGES: 'Product Packages',
      PRODUCT_PLACEMENT_SUPPLY_RULES: 'Product Placement & Supply Rules',
      TRANSFER_ORDER: 'Transfer Order',
      TRANSFER_ORDER_LOCATION: 'Transfer Order ( Location )',
      TRANSFER_ORDER_WAREHOUSE_SITE: 'Transfer Order ( Warehouse/ Site)',
      TRANSFER_ORDER_RETURN_CONSIGNMENT: 'Transfer Order ( Return Consignment)',
      TRANSFER_ORDER_GENERAL: 'Transfer Order ( General)',
      PACKING_EXTERNAL: 'Packing External',
      SALES_ORDER_TYPE: 'Sales Order Type',
      SALES_ORDER_INTERCOMPANY_TYPE: 'Sales Order ( Intercompany ) Type',
      SALES_RETURN_TYPE: 'Sales Return Type',
      SALES_RETURN_INTERCOMPANY_TYPE: 'Sales Return ( Intercompany ) Type',
      PURCHASE_RETURN_TYPE: 'Purchase Return Type',
      PURCHASE_RETURN_INTERCOMPANY_TYPE: 'Purchase Return ( intercompany) Type',
      EXCHANGE_PRODUCT_TYPE: 'Exchange Product Type',
      EXCHANGE_PRODUCT_INTERCOMPANY_TYPE: 'Exchange Product ( intercompany ) Type',
      GENERAL_TYPE: 'General Type',
      RETURN_CONSIGMENT_TYPE: 'Return Consigment Type',
      WAREHOUSE_SITE_TRANSFER_TYPE: 'Warehouse/ Site Transfer Type',
      PACKING_EXTERNAL_DIFFERENCE: 'Packing External Difference',
      PACKING_INTERNAL: 'Packing Internal',
      INVENTORY_REQUEST_TYPE: 'Inventory Request Type',
      TRANSFER_ORDER_TYPE_LOCATION: 'Transfer Order Type ( Location )',
      PACKING_INTERNAL_DIFFERENCE: 'Packing Internal Difference',
      PICKING: 'Picking',
      PICKING_DIFFERENCE: 'Picking Difference',
      SHIPPING_ORDER: 'Shipping Order',
      SHIPPING_COST: 'Shipping Cost',
      RECEIPTS_EXTERNAL: 'Receipts External',
      PURCHASE_TYPE: 'Purchase Type',
      CONSIGNMENT_TYPE: 'Consignment Type',
      INTERCOMPANY_PURCHASE_TYPE: 'Intercompany Purchase Type',
      SERVICE_TYPE: 'Service Type',
      PICK_SERVICE_TYPE: 'Pick Service Type',
      VENDOR_SERVICE_TYPE: 'Vendor Service Type',
      PICK_UP: 'PIck Up',
      RECEIPT_EXTERNAL_DIFFERENCES: 'Receipt External Differences',
      RECEIPT_INTERNAL: 'Receipt Internal',
      INVENTORY_REQUEST: 'Inventory Request',
      RECEIPT_INTERNAL_DIFFERENCES: 'Receipt Internal Differences',
      PACK_UNPACK_ORDER: 'Pack/ Unpack Order',
      PACK_ORDER: 'Pack Order',
      UNPACK_ORDER: 'Unpack Order',
      STOCK_OPNAME_DIFFERENCE: 'Stock Opname Difference',
    },
    userCategory: {
      USER_CATEGORY: 'data companies',
    },
    contact: {
      CONTACT: 'Contact',
      PROJECT_TYPE: 'Project',
      BANK: 'Bank',
      CONTACT_OTHER_ADDRESSES: 'Contact & Other Addresses',
      LEGALITAS: 'Legalitas',
      BANK_ACCOUNT: 'Bank Account',
      SURVEY: 'Survey',
      CUSTOMER: 'Customer',
      VENDOR: 'Vendor',
    },
    sales: {
      CUSTOMER_GROUP: 'Customer Group',
      SALES_DIVISION: 'Sales Division',
      SALES_AREA: 'Sales Area',
      SALES_TEAM: 'Sales Team',
      SALES_SETTING: 'data companies',
      SALES_LIMIT: 'Sales Limit',
      TERM_PAYMENT: 'Sales Terms of Payment',
      SALES_TAX: 'Sales Tax',
      FISCAL_POSITION: 'Exchange Product',
      CUSTOMIZED_PRICING: 'Customized Price',
      BASIC_PRICE: 'Basic Price',
      EXCHANGE_PRODUCTS: 'Exchange Product',
      PRODUCT_MASTER: 'Product Master',
      STOCKS: 'Stocks',
      TRACKINGS: 'Trackings',
      PRODUCT_PLACEMENT_RULES: 'Product Placement & Rules',
      PRODUCT_VARIANT_STOCKS: 'Product Variant Stocks',
      USAGE: 'Usage',
      PRODUCT_CATALOG: 'Product Catalog',
      CUSTOMER: 'Customer',
      CONTACT_OTHER_ADDRESSES: 'Contact & Other Addresses',
      LEGALITIES: 'Legalities',
      BANK_ACCOUNT: 'Bank Account',
      SURVEY: 'Survey',
      SALES_PROMOTION: 'Sales Promotion',
      PRODUCT_VARIANT: 'Product Variant',
      PRODUCT_PACKAGES: 'Product Packages',
      SALES_ORDER: 'Sales Order',
      TAB_STOCK: 'Tab Stock',
      INTERCOMPANY_TYPE: 'Intercompany type',
      SALES_RETURN: 'Sales Return',
      SALES_LIMIT_TERMS_OF_PAYMENT_REQUEST: 'Sales Limit & Terms of Payment Request',
    },
    purchase: {
      VENDOR: 'Vendor',
      VENDOR_PROMOTION: 'Purchase Promotion',
      VENDOR_CATALOG: 'vendor catalog',
      VENDOR_GROUP: 'Vendor Group',
      DATA_VENDOR_PRICING: 'Vendor Customized Pricing',
      VENDOR_CUSTOMIZED: 'Customized Pricing',
      PRICE_AGREEMENT: 'Price Agreement',
      PENGAJUAN_KENAIKAN_HARGA_BELI: 'pengajuan kenaikan harga beli',
      PURCHASE_REQUEST: 'Purchase Request',
      PURCHASE_ORDER: 'Purchase Order',
      SERAH_TERIMA_DOKUMEN: 'Document Submission',
      DATA_PURCHASE_DIVISION: 'Purchase Division',
      DATA_PURCHASE_LIMIT: 'data companies',
      DATA_PURCHASE_TAX: 'Purchase Tax',
      DATA_TOP: 'data companies',
      DATA_FISCAL_POSITION: 'data companies',
      PURCHASE_SETTING: 'Purchase Settings',
      PRICE_LIST_VENDOR: 'Pricelist by Product',
      PRICE_LIST_PRODUCT: 'Pricelist by Product',

      PURCHASE_DIVISION: 'Purchase Division',
      PRODUCT: 'Product',
      USER__STAFF: 'User/ Staff',
      PURCHASE_TAX: 'Purchase Tax',
      CONTACT___OTHER_ADDRESSES: 'Contact & Other Addresses',
      LEGALITIES: 'Legalities',
      BANK_ACCOUNT: 'Bank Account',
      SURVEY: 'Survey',
      PRICELIST_BY_VENDOR: 'Pricelist by Product',
      VENDOR_CUSTOMIZED_PRICING: 'Vendor Customized Pricing',
      PURCHASE_PROMOTION: 'Purchase Promotion',
      BY_PURCHASE_REQUEST: 'By Purchase Request',
      BY_VENDOR: 'By Vendor',
      BY_PRODUCT: 'By Product',
      REJECT_PRODUCT: 'Reject Product',
      PR_TO_PO: 'PR to PO',
      TAB_STOCK: 'Tab Stock',
      TAB_VENDOR: 'Tab Vendor',
      INTERCOMPANY_TYPE: 'Intercompany Type',
      PURCHASE_RETURN: 'Purchase Return',
      DOCUMENT_SUBMISSION: 'Document Submission',
      PRINT_PAPER: 'Print Paper',
    },
    logistic: {
      VEHICLES: 'vehicles',
      DRIVERS_CREWS: 'drivers & crews',
      DELIVERY_ORDER: 'delivery order',
      DELIVERY_COST: 'delivery cost',
      DELIVERY_PLANNING: 'delivery planning',
      PEMINJAMAN_KENDARAAN: 'peminjaman kendaraan',
      VEHICLE_REPORT: 'vehicle report',
      VEHICLE_MAINTENANCE: 'vehicle maintenance',
      DOCUMENT_EXCHANGE: 'document exchange',
      PENEMPATAN_KENDARAAN: 'penempatan kendaraan',
      DATA_AREA_PENGIRIMAN: 'data area pengiriman',
      DATA_VEHICLE_TYPE: 'data vehicle type',
      VEHICLE_LEGALITY: 'vehicle legality',
      DELIVERY: 'delivery',
      DELIVERY_COSTS: 'delivery costs',
      TRAVEL_ALLOWANCE: 'travel allowances',
    },
    accounting: {
      // CHART_OF_ACCOUNT_LABELS: 'data sites',
      // COST_OF_GOODS: 'data sites',
      // CASH_IN_OUT: 'data sites',
      // // CASH: 'data sites',
      // INVOICE_BUNDLING: 'data sites',
      // JOURNAL_ENTRY: 'data sites',
      // JOURNAL_ITEM: 'data sites',
      // PRODUCT_TAXES: 'data sites',
      // // DATA_EDC: 'data sites',
      // // DATA_KARTU_FLAZZ: 'data sites',
      // DATA_ASSET: 'data sites',
      // DATA_JOURNALS: 'data sites',
      // // SERAH_TERIMA_DOKUMEN: 'data sites',
      // SALES_INVOICE: 'data sites',
      // SALES_INVOICE_PAYMENT: 'data sites',
      // SALES_TAX_BILLS: 'data_sites',
      // PURCHASE_INVOICE: 'data sites',
      // PURCHASE_PAYMENT: 'data sites',
      // PURCHASE_TAX_BILLS: 'data sites',
      // // PENGELUARAN_KAS: 'data sites',
      // // PENERIMAAN_KAS: 'data sites',
      // // LAPORAN_FLAZZ_RUSAK: 'data sites',
      // // REIMBURSMENT: 'data sites',
      // // REKONSILIASI_BANK: 'data sites',
      // DATA_COA: 'data sites',
      // DATA_BANK_ACCOUNT: 'data sites',
      // // DATA_FISCAL_POSITION_ACCOUNTING: 'data sites',
      // // DATA_TAX: 'data sites',
      // // DATA_LIMIT: 'data sites',
      // // DATA_TOP: 'data sites',
      // DATA_ASSET_TYPE: 'data sites',
      // // FINANCIAL_REPORT: 'data sites',
      // CASH_ACCOUNT: 'data sites',
      // PRODUCT_ACCOUNT: 'data sites',
      // PARTNER_ACCOUNT: 'data sites',
      // CUSTOMER_BUNDLING: 'data sites',
      // // TAXES: 'data sites',
      // // FISCAL_POSITION: 'data sites',
      // BANK_BILLS: 'data sites',
      // BANK_RECONCILIATION: 'data sites',
      //configuration
      DATA_COA: 'coa',
      CHART_OF_ACCOUNT_LABELS: 'coa label',
      PARTNER_ACCOUNT: 'partner account',
      PRODUCT_ACCOUNT: 'product account',
      DATA_BANK_ACCOUNT: 'bank account',
      CASH_ACCOUNT: 'cash account',
      DATA_ASSET_TYPE: 'asset type',
      PRODUCT_TAXES: 'product taxes',

      //data
      DATA_ASSET: 'data asset',
      DATA_DEFERRED_MODEL: 'data deferred model',
      DATA_JOURNALS: 'data journal',
      COST_OF_GOODS: 'data cogs',

      //sales act
      SALES_INVOICE: 'sales invoice',
      INVOICE_BUNDLING: 'sales invoice bundling',
      SALES_INVOICE_PAYMENT: 'sales payment',
      SALES_TAX_BILLS: 'sales tax bill',
      CUSTOMER_BUNDLING: 'sales customer bundling',

      //purchase act
      PURCHASE_INVOICE: 'purchase invoice',
      PURCHASE_PAYMENT: 'purchase payment',
      PURCHASE_TAX_BILLS: 'purchase tax bill',

      //accounting act
      // CASH_IN_OUT: 'cash in and out',
      CASH_IN_OUT: 'Cash And Bank',
      BANK_BILLS: 'bank bill',
      BANK_RECONCILIATION: 'bank reconciliation',
      JOURNAL_ENTRY: 'journal entry',
      JOURNAL_ITEM: 'journal item',

      //report finance
      PROFIT_AND_LOSS: 'profit and loss',
      BALANCE_SHEET: 'balance sheet',

      //report ledgers
      GENERAL_LEDGER: 'general ledger',
      PARTNER_LEDGER: 'partner ledger',

      //journals
      JOURNAL_SALES: 'journal sales',
      JOURNAL_PURCHASE: 'journal purchase',
      JOURNAL_BANK_AND_CASH: 'journal bank and cash',
      JOURNAL_MISCELLANEOUS: 'journal miscellaneous',
      JOURNAL_JOURNAL_ITEM: 'journal journal item',
      //tambahan
      // PROFIT_AND_LOSS: 'data sites',
      // BALANCE_SHEET: 'data sites',
    },
    production: {
      ATTRIBUTE: 'Production Attributes',
      ATTRIBUTE_VALUES: 'Production Attribute Value',
      BOM_CATEGORY: 'BoM Categories',
      SHIFT: 'Production Shift',
      SCRAP: 'Scraps',
      TEAM: 'Production Teams',
      MACHINE_TYPE: 'Machine Types',
      MACHINE: 'Machines',
      PROCESS: 'Production Processes',
      BILL_OF_MATERIAL: 'Build of Materials',
      WORK_CENTER: 'Work Centers',
      ROUTE: 'Production Routes',
      SHIFT_PLANNING: 'Shift Plannings',
      PRODUCTION_REQUEST: 'Production Request',
      PRODUCTION_ORDER: 'Production Order',
      WORK_ORDER: 'Work Order',
      WORK_ORDER_MATERIAL: 'Work Order Material',
      WORK_ORDER_LABEL: 'Work Order Label',
      WORK_ORDER_QC: 'Work Order QC',
      WORK_ORDER_CUSTOM_MATERIAL: 'Work Order Custom Material',
      PRODUCT_CATEGORIES: 'Product Categories',
      LOCATIONS: 'Locations',
      PRODUCT_MASTER: 'Product Master',
      STOCKS: 'Stocks',
      TRACKING: 'Tracking',
      PLACEMENT_RULES: 'Placement & Rules',
      PRODUCT_VARIANT: 'Product Variant',
      USAGE: 'Usage',
      BOM: 'Build of Materials',
      PRODUCTION_ROUTE: 'Production Routes',
      CONTINUING_PROCESS: 'Continuing Process',
      PAUSE_PROCESS: 'Pause Process',
      EDIT_MATERIAL: 'Edit Material',
      MATERIAL_REQUEST: 'Material Request',
      REPORT_PROBLEM: 'Report Problem',
      APPROVED_MATERIAL_REQUEST: 'Approved Material Request',
      PROCESS_DELIVERY: 'Process Delivery',
      PROCESS_REPROCESS_: 'Process Reprocess ',
      PRODUCTION_REQUESTS: 'Production Requests',
      PRODUCTION_ORDERS: 'Production Orders',
      WORK_ORDERS: 'Work Orders',
      PRODUCTION_TIME: 'Production Time',
      WASTE_MATERIAL_USAGE: 'Waste & Material Usage',
    },
    // production: { //TODO(jeje): nanti dikembalikan saat user category sudah ada
    //   ATTRIBUTE: 'production attributes',
    //   ATTRIBUTE_VALUES: 'production attribute values',
    //   BOM_CATEGORY: 'bill of material categories',
    //   SHIFT: 'shifts',
    //   SCRAP: 'scraps',
    //   TEAM: 'production teams',
    //   MACHINE_TYPE: 'machine types',
    //   MACHINE: 'machines',
    //   PROCESS: 'production processes',
    //   BILL_OF_MATERIAL: 'bill of materials',
    //   WORK_CENTER: 'work centers',
    //   ROUTE: 'production routes',
    //   SHIFT_PLANNING: 'shift plannings',
    //   PRODUCTION_REQUEST: 'production requests',
    //   PRODUCTION_ORDER: 'production orders',
    //   WORK_ORDER: 'work orders'
    // },
  },
};
