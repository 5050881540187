import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderText } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter, userPrinter } from '@base-helpers';
import moment from 'moment';
import { Col, Descriptions, Row, Table } from 'antd';
import { configColumns, configColumnsInvoice, configColumnsPayment } from './helpers';
import { renderCurrency } from '../components';

export const formatUnderscore = (data: string) => data?.replace(/_/g, ' ');

export function Detail() {
  const { detailData = {} } = usePagePrint();
  const hasCode = detailData?.code;
  const user = userPrinter();

  const renderChild = (record: any) => {
    return (
      <React.Fragment>
        <Table
          dataSource={[record]}
          columns={configColumnsPayment}
          pagination={false}
          size="small"
          bordered
          scroll={{ x: 'fit-content' }}
          style={{ marginTop: '10px', marginBottom: '10px', overflow: 'hidden', fontSize: '8px !important' }}
        />
        <Table
          dataSource={record?.purchase_transactions}
          columns={configColumnsInvoice}
          pagination={false}
          size="small"
          bordered
          scroll={{ x: 'fit-content' }}
          style={{ marginTop: '10px', marginBottom: '10px', overflow: 'hidden', fontSize: '8px !important' }}
        />
      </React.Fragment>
    );
  };

  return (
    <DetailPrint
      orientation="portrait"
      printHeaderProps={{ barcode: detailData?.code }}
      paperFormat={PAPER_FORMAT.A4}
      printSignatureProps={{
        signatures: [
          {
            gridColumn: { span: 6 },
            title: 'Received By',
            name: '_____________',
          },
          {
            gridColumn: { span: 6 },
            title: 'Collected By',
            name: detailData?.creator_name ? detailData?.creator_name : '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Confirmed By',
            name: detailData?.editor_name ? detailData?.editor_name : '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Printed By',
            name: user?.username ? user?.username : '-',
          },
        ],
      }}
    >
      <DetailBuilder
        columns={[
          {
            fields: [
              {
                gridColumn: { span: 12 },
                renderField(form) {
                  return (
                    <PrintTitle
                      title="Purchase Payment"
                      {...(hasCode ? { description: `No. ${detailData?.code}` } : {})}
                    />
                  );
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Created Date',
                name: 'created_at',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Printed Date',
                name: 'id',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: () => moment().format('YYYY-MM-DD'),
                },
              },
              {
                gridColumn: { span: 6 },
                label: 'Status',
                name: 'status',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    let status = value;
                    if (value?.toLowerCase() === 'waiting') status = 'in_process';
                    return stringFormatter.capitalizeEachWord(status?.split('_')?.join(' '));
                  },
                },
              },
              {
                gridColumn: { span: 2 },
                label: 'Site',
                name: 'site',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.code;
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Date',
                name: 'payment_date',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD HH:mm') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Purchase Divisions',
                name: 'purchase_division',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.code;
                  },
                },
              },
              {
                gridColumn: { span: 15 },
                label: 'Created By',
                name: 'creator_name',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value;
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'No of Vendor',
                name: 'purchase_transactions',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return 1;
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'No of Invoice',
                name: 'purchase_transactions',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return detailData?.purchase_transactions?.length;
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Total Invoice',
                name: 'total_invoices',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return renderCurrency({ value: value });
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Total Payment',
                name: 'total_payment',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return renderCurrency({ value: value });
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Total Return Invoice',
                name: 'total_return_invoice_applied',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return renderCurrency({ value: value });
                  },
                },
              },
              {
                gridColumn: { span: 8 },
                label: 'References',
                name: 'references',
                fieldType: 'text',
                renderTextProps: {
                  renderType: 'multi',
                  renderValue(value) {
                    return value?.transaction_code ?? value?.code;
                  },
                },
              },
            ],
          },
        ]}
      />
      <Table
        dataSource={[detailData]}
        columns={configColumns}
        pagination={false}
        size="small"
        bordered
        scroll={{ x: 'fit-content' }}
        key={JSON.stringify(detailData?.transactions)}
        style={{ marginTop: '10px', marginBottom: '10px', overflow: 'hidden', fontSize: '8px !important' }}
        expandable={{
          expandedRowRender: (record) => renderChild(record),
          defaultExpandAllRows: true,
          expandIcon: () => <span />,
          expandIconColumnIndex: 3,
        }}
      />

      <Row gutter={[8, 8]} style={{ marginBottom: '10px' }}>
        <Col span={14}>
          <div style={{ border: '1px solid #f0f0f0', padding: '10px', height: '100%' }}>
            <div style={{ display: 'block', fontWeight: 'bold', marginBottom: '10px' }}>Note :</div>
            <div>{detailData?.notes ? detailData?.notes : '-'}</div>
          </div>
        </Col>
      </Row>
    </DetailPrint>
  );
}
