import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter, userPrinter } from '@base-helpers';
import moment from 'moment';
import { getProducts } from './helpers';

const renderUom = (row, type) => {
  if (type === 'unit') {
    if (row?.product_variant?.breakdown_qty) {
      if (row?.product_variant?.uom?.category === 'UNIT') return row?.uom?.code;
      if (row?.product_variant?.uom?.category !== 'UNIT') return row?.uom_fraction?.code;
    } else {
      if (row?.product_variant?.uom?.category === 'UNIT') return row?.uom?.code;
    }
  }
  if (type === 'non_unit') {
    if (row?.product_variant?.breakdown_qty) {
      if (row?.product_variant?.uom?.category !== 'UNIT') return row?.uom?.code;
      if (row?.product_variant?.uom?.category === 'UNIT') return row?.uom_fraction?.code;
    } else {
      if (row?.product_variant?.uom?.category !== 'UNIT') return row?.uom?.code;
    }
  }
};

export function Detail() {
  const { detailData = {} } = usePagePrint();
  const hasCode = detailData?.code;
  const [products, setProducts] = useState([]);
  const user = userPrinter();

  const generateProduct = async (soId) => {
    const response = await getProducts(soId);
    setProducts(response);
  };

  // useEffect(() => {
  //   if (detailData?.id) {
  //     generateProduct(detailData?.id);
  //   }
  // }, [detailData?.id]);

  return (
    <DetailPrint
      onSuccessLoadData={(payload) => generateProduct(payload?.id)}
      orientation="portrait"
      printHeaderProps={{ barcode: detailData?.code, logoType: 'mjs' }}
      showHeader={true}
      paperFormat={PAPER_FORMAT.A4}
      printSignatureProps={{
        signatures: [
          { title: 'Requested By', gridColumn: { span: 6 }, name: user?.name ?? user?.username },
          { title: 'Printed By', gridColumn: { span: 6 }, name: user?.name ?? user?.username },
          { title: 'Checked By', gridColumn: { span: 6 }, name: detailData?.checker?.name ?? '__________' },
          { title: 'Approved By', gridColumn: { span: 6 }, name: '__________' },
        ],
      }}
    >
      <DetailBuilder
        columns={[
          {
            fields: [
              {
                gridColumn: { span: 12 },
                renderField(form) {
                  return (
                    <PrintTitle title="Stock Opname" {...(hasCode ? { description: `No. ${detailData?.code}` } : {})} />
                  );
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Created Date',
                name: 'created_at',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Printed Date',
                name: 'id',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: () => moment().format('YYYY-MM-DD'),
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Status',
                name: 'status',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => stringFormatter.capitalizeEachWord(value?.split('_')?.join(' ')),
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Start Date',
                name: 'start_date',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.includes('_') ? value?.replace('_', ' ') : value;
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'End Date',
                name: 'end_date',
                fieldType: 'text',
                renderTextProps: {
                  defaultRender: () => '-',
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 3 },
                label: 'Initial Stock',
                name: 'initial_stock',
                fieldType: 'text',
                renderTextProps: {
                  defaultRender: () => '-',
                  renderValue: (value) => {
                    return value ? 'Yes' : 'No';
                  },
                },
              },
              {
                gridColumn: { span: 12 },
                label: 'Reference',
                name: 'reference',
                fieldType: 'text',
                renderTextProps: {
                  defaultRender: () => '-',
                  renderValue: (value) => {
                    return value;
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Stock Opname Location',
                name: 'location',
                fieldType: 'text',
                renderTextProps: {
                  defaultRender: () => '-',
                  renderValue: (value) => {
                    return value?.name ?? '-';
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'User',
                name: 'checker',
                fieldType: 'text',
                renderTextProps: {
                  defaultRender: () => '-',
                  renderValue: (value) => {
                    return value?.name ?? value?.username ?? '-';
                  },
                },
              },

              {
                gridColumn: { span: 16 },
                label: 'Shift',
                name: 'shift',
                fieldType: 'text',
                renderTextProps: {
                  defaultRender: () => '-',
                  renderValue: (value) => {
                    return value;
                  },
                },
              },
            ],
          },
        ]}
      />

      <table
        style={{
          position: 'relative',
          width: '100%',
          border: '1px solid black',
          fontSize: '8px',
        }}
      >
        <thead style={{ fontWeight: 'bold', border: '1px solid black' }}>
          <tr>
            <td style={{ borderRight: '1px solid black', padding: '2px' }}>No</td>
            <td style={{ borderRight: '1px solid black', padding: '2px' }}>Code</td>
            <td style={{ borderRight: '1px solid black', padding: '2px' }}>Product</td>
            <td style={{ borderRight: '1px solid black', padding: '2px' }}>Ownership</td>
            <td style={{ borderRight: '1px solid black', padding: '2px' }}>Tracking ID</td>
            <td style={{ borderRight: '1px solid black', padding: '2px' }}>On Hand Unit</td>
            <td style={{ borderRight: '1px solid black', padding: '2px' }}>Real Unit</td>
            <td style={{ borderRight: '1px solid black', padding: '2px' }}>Diff</td>
            <td style={{ borderRight: '1px solid black', padding: '2px' }}>Unit</td>
            <td style={{ borderRight: '1px solid black', padding: '2px' }}>UoM</td>
            <td style={{ borderRight: '1px solid black', padding: '2px' }}>On Hand Qty</td>
            <td style={{ borderRight: '1px solid black', padding: '2px' }}>Real Qty</td>
            <td style={{ borderRight: '1px solid black', padding: '2px' }}>Diff</td>
            <td style={{ borderRight: '1px solid black', padding: '2px' }}>Qty</td>
            <td style={{ borderRight: '1px solid black', padding: '2px' }}>UoM</td>
            <td style={{ borderRight: '1px solid black', padding: '2px' }}>Sub Location</td>
            <td style={{ borderRight: '1px solid black', padding: '2px' }}>Result</td>
          </tr>
        </thead>
        <tbody>
          {products?.map((item, index) => {
            const number = index + 1;
            return (
              <tr key={index}>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px',
                    textAlign: 'center',
                  }}
                >
                  {number}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px',
                    width: '50px',
                  }}
                >
                  {item?.product_variant?.code ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px',
                    width: '250px',
                  }}
                >
                  {item?.product_variant?.name ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px',
                  }}
                >
                  {item?.product_variant?.ownership?.name ?? '-'}
                </td>

                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px',
                    width: '50px',
                  }}
                >
                  {item?.lot?.code ?? item?.batch?.code ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px',
                    width: '50px',
                  }}
                >
                  {item?.on_hand_unit ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px',
                    width: '50px',
                  }}
                >
                  {item?.real_unit ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px',
                    width: '50px',
                  }}
                >
                  {item?.unit_diff ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px',
                    width: '50px',
                  }}
                >
                  {item?.unit_diff ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px',
                    width: '50px',
                  }}
                >
                  {renderUom(item, 'unit')}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px',
                    width: '50px',
                  }}
                >
                  {item?.on_hand_qty ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px',
                    width: '50px',
                  }}
                >
                  {item?.real_qty ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px',
                    width: '50px',
                  }}
                >
                  {item?.qty_diff ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px',
                    width: '50px',
                  }}
                >
                  {item?.qty_diff ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px',
                    width: '50px',
                  }}
                >
                  {renderUom(item, 'non_unit')}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px',
                    width: '50px',
                  }}
                >
                  {item?.sub_location?.name ?? '-'}
                </td>

                <td
                  style={{
                    border: '1px solid black',
                    padding: '2px',
                    width: '50px',
                  }}
                ></td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ border: '1px solid black', padding: '10px', minHeight: '70px', marginBottom: '10px' }}>
        <div style={{ marginBottom: '10px' }}>Note :</div>
        <p>{detailData?.notes}</p>
      </div>
    </DetailPrint>
  );
}
