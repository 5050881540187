import React from 'react';
import { Menu, MenuProps } from 'antd';
import { BaseMenuEntity, BaseMenuGroupEntity } from '@base-entities';
import { DataMenu } from './data-menu';
import { Link, useLocation } from 'react-router-dom';
import './menu-style.less';

function CheckGroup(itemGroup) {
  if (itemGroup.title)
    return (
      <Menu.ItemGroup title={itemGroup.title}>
        {itemGroup.items.map((itemGroupItem: BaseMenuEntity) => {
          return MenuItem(itemGroupItem);
        })}
      </Menu.ItemGroup>
    );
  return itemGroup.items.map((itemGroupItem: BaseMenuEntity) => {
    return MenuItem(itemGroupItem);
  });
}

function MenuItem(props: BaseMenuEntity): JSX.Element {
  if (!props.children) {
    return (
      <Menu.Item key={props.key} icon={props.icon} disabled={props.disabled}>
        <Link to={props.path}>{props.label}</Link>
      </Menu.Item>
    );
  }

  return (
    <Menu.SubMenu title={props.label} key={props.key} icon={props.icon}>
      {props.children?.map((itemGroup: BaseMenuGroupEntity) => {
        return CheckGroup(itemGroup);
      })}
    </Menu.SubMenu>
  );
}

function makeActiveMenuKey(pathname: string): string {
  if (!pathname) return '';
  const pathnameArray = pathname.split('/');
  const keyOfPathname = pathnameArray.splice(0, 2).join('/');
  return keyOfPathname;
}

export interface MenuComponentProps extends MenuProps {
  dataMenu?: BaseMenuEntity[];
}

export function MenuComponent(props: MenuComponentProps) {
  const location = useLocation();
  const { pathname } = location;
  const realDataMenu = props.dataMenu || DataMenu;

  return (
    <Menu {...props} defaultSelectedKeys={[makeActiveMenuKey(pathname)]}>
      {realDataMenu.map((item) => {
        return MenuItem(item);
      })}
    </Menu>
  );
}
