export const AuthWebUrl = {
  // Sample Path
  simple_template: '/template/simple-template',
  advance_template: '/template/advance-template',

  //main
  multiple_print: '/print/page/:token',

  //company
  companies: '/print/companies',
  branches: '/print/branches',
  sites: '/print/sites',
  locations: '/print/locations',
  legalities: '/print/legalities',
  users: '/print/users',
  user_categories: '/print/user_categories',
  approvals: '/print/approvals',
  printers: '/print/printers',

  //contact
  contacts: '/print/contacts',
  banks: '/print/banks',
  project_types: '/print/project_types',

  //sales
  sales_customers: '/print/sales_customers',
  sales_teams: '/print/sales_teams',
  sales_basic_prices: '/print/sales_basic_prices',
  sales_customized_pricing: '/print/sales_customized_pricing',
  sales_promotions: '/print/sales_promotions',
  sales_orders: '/print/sales_orders',
  sales_exchange_products: '/print/sales_exchange_products',
  sales_limit_top_request: '/print/sales_limit_top_request',
  sales_customer_groups: '/print/sales_customer_groups  ',
  sales_divisions: '/print/sales_divisions',
  sales_areas: '/print/sales_areas',
  sales_limits: '/print/sales_limits',
  sales_top: '/print/sales_top',
  sales_taxes: '/print/sales_taxes',
  sales_settings: '/print/sales_settings',

  //purchase
  purchase_vendors: '/print/purchase_vendors',
  purchase_price_list: '/print/purchase_price_list',
  purchase_customized_pricing: '/print/purchase_customized_pricing',
  purchase_promotions: '/print/purchase_promotions',
  purchase_orders: '/print/purchase_orders',
  purchase_request: '/print/purchase_request',
  purchase_vendor_groups: '/print/purchase_vendor_groups  ',
  purchase_divisions: '/print/purchase_divisions',
  purchase_taxes: '/print/purchase_taxes',
  purchase_settings: '/print/purchase_settings',

  //inventory
  inventory_product_masters: '/print/inventory_product_masters',
  inventory_product_variants: '/print/inventory_product_variants',
  inventory_product_packages: '/print/inventory_product_packages',
  inventory_pickings: '/print/inventory_pickings',
  inventory_packing_internal: '/print/inventory_packing_internal',
  inventory_packing_external: '/print/inventory_packing_external',
  inventory_shipping_orders: '/print/inventory_shipping_orders',
  inventory_receipt_internal: '/print/inventory_receipt_internal',
  inventory_receipt_external: '/print/inventory_receipt_external',
  inventory_transfer_orders: '/print/inventory_transfer_orders',
  inventory_pack_unpack_orders: '/print/inventory_pack_unpack_orders',
  inventory_scraps: '/print/inventory_scraps',
  inventory_stock_opname: '/print/inventory_stock_opname',
  inventory_brands: '/print/inventory_brands',
  inventory_attributes: '/print/inventory_attributes',
  inventory_attribute_groups: '/print/inventory_attribute_groups',
  inventory_attribute_values: '/print/inventory_attribute_values',
  inventory_uom: '/print/inventory_uom',
  inventory_product_categories: '/print/inventory_product_categories',
  inventory_product_placement_rules: '/print/inventory_product_placement_rules',

  //logistic
  logistic_vehicles: '/print/logistic_vehicles',
  logistic_driver_crews: '/print/logistic_driver_crews',
  logistic_travel_allowances: '/print/logistic_travel_allowances',
  logistic_vehicle_type: '/print/logistic_vehicle_type',
  logistic_vehicle_legalities: '/print/logistic_vehicle_legalities',

  //production
  production_bill_of_materials: '/print/production_bill_of_materials',
  production_teams: '/print/production_teams',
  production_shift_plannings: '/print/production_shift_plannings',
  production_request: '/print/production_request',
  production_orders: '/print/production_orders',
  production_scraps: '/print/production_scraps',
  production_shifts: '/print/production_shifts',
  production_machine_types: '/print/production_machine_types',
  production_machines: '/print/production_machines',
  production_process: '/print/production_process',
  production_work_centers: '/print/production_work_centers',
  production_routes: '/print/production_routes',
  production_machine_attributes: '/print/production_machine_attributes',
  production_machine_attribute_values: '/print/production_machine_attribute_values',
  production_bill_of_material_categories: '/print/production_bill_of_material_categories',

  //accounting
  accounting_assets: '/print/accounting_assets',
  accounting_journals: '/print/accounting_journals',
  accounting_cost_of_goods: '/print/accounting_cost_of_goods',
  accounting_sales_invoices: '/print/accounting_sales_invoices',
  accounting_sales_invoice_bundlings: '/print/accounting_sales_invoice_bundlings',
  accounting_sales_payments: '/print/accounting_sales_payments',
  accounting_sales_tax_bills: '/print/accounting_sales_tax_bills',
  accounting_purchase_invoices: '/print/accounting_purchase_invoices',
  accounting_purchase_payments: '/print/accounting_purchase_payments',
  accounting_purchase_tax_bills: '/print/accounting_purchase _tax_bills',
  accounting_cash_and_banks: '/print/accounting_cash_and_banks',
  accounting_bank_bills: '/print/accounting_bank_bills',
  accounting_bank_reconciliations: '/print/accounting_bank_reconciliations',
  accounting_journal_entries: '/print/accounting_journal_entries',
  accounting_chart_of_accounts: '/print/accounting_chart_of_accounts',
  accounting_chart_of_account_labels: '/print/accounting_chart_of_account_labels',
  accounting_partner_accounts: '/print/accounting_partner_accounts',
  accounting_product_accounts: '/print/accounting_product_accounts',
  accounting_bank_accounts: '/print/accounting_bank_accounts',
  accounting_cash_accounts: '/print/accounting_cash_accounts',
  accounting_asset_types: '/print/accounting_asset_types',
};
