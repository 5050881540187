import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCountCurrency, RenderCurrency } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { makeModuleBarcode, PAPER_FORMAT, stringFormatter } from '@base-helpers';
import moment from 'moment';
import { Col, Row, Table } from 'antd';
import QRCode from 'react-qr-code';
import { getProducts } from './helpers';

const renderDiscount = (price) => {
  if (price?.after_tax_formula_render) {
    const formula = JSON.parse(price?.after_tax_formula_render);
    const newFormulaInNumber = formula
      ?.map((item) => {
        if (item?.label !== NaN) {
          return Number(item?.label);
        }
        return null;
      })
      .filter(Boolean);
    const totalDisc = newFormulaInNumber?.reduce((partialSum, a) => partialSum + a, 0);
    return Math.abs(totalDisc);
  }
  return '-';
};

export function Detail() {
  const { detailData = {}, printContent, type } = usePagePrint();
  const [products, setProducts] = useState([]);
  const defaultSignature: any[] = [
    {
      title: 'Hormat Kami',
      name: '',
      gridColumn: { span: 6 },
    },
  ];
  const columns: any[] = [
    {
      title: 'Description',
      key: 'product',
      dataIndex: 'product',
      render: (item, row) => row?.product_variant?.name ?? row?.product_package?.name,
      style: { minWidth: '200px' },
    },
    {
      title: 'Unit',
      key: 'uom_qty',
      dataIndex: 'uom_qty',
      render: (item, row) => row?.uom_qty?.code ?? row?.uom_unit?.code,
      style: { textAlign: 'right', minWidth: '80px' },
    },
    {
      title: 'Qty',
      key: 'total_qty',
      dataIndex: 'total_qty',
      render: (item, row) => (row?.shipping_qty > 0 ? row?.shipping_qty : row?.shipping_unit),
      style: { textAlign: 'right' },
    },
    ...(detailData?.activity_type === 'SALES_ORDER'
      ? [
          {
            title: 'Unit Price',
            key: 'price',
            dataIndex: 'price',
            render: (item) => RenderCurrency({ value: item?.price_per_unit ?? '0' }),
            style: { textAlign: 'right', minWidth: '150px' },
          },
          {
            title: 'Discount',
            key: 'price',
            dataIndex: 'price',
            render: (item) => renderDiscount(item),
            style: { textAlign: 'right' },
          },
          {
            title: 'Amount',
            key: 'price',
            dataIndex: 'price',
            render: (item) => RenderCurrency({ value: item?.subtotal_after_tax ?? '0' }),
            style: { textAlign: 'right' },
          },
        ]
      : []),
  ];

  const generateProduct = async () => {
    const response = await getProducts(detailData?.detail?.id);
    setProducts(response);
  };

  useEffect(() => {
    if (detailData?.detail) {
      generateProduct();
    }
  }, [detailData]);

  return (
    <DetailPrint paperFormat={PAPER_FORMAT.A4} orientation="portrait" printHeaderProps={{ showLogo: false }}>
      <div style={{ fontSize: '10px', color: 'black' }}>
        <Row gutter={10}>
          <Col span={8}>
            <p>
              {detailData?.send_to?.name} ({detailData?.send_to?.code})
            </p>
            <p>{detailData?.send_to?.address ?? ''}</p>
          </Col>
          <Col span={8}>
            <table>
              <tr>
                <td colSpan={2}>
                  Packing Slip/Surat Jalan {type ? `(${stringFormatter.capitalizeEachWord(type)})` : ''}
                </td>
              </tr>
              <tr>
                <td>Ship date</td>
                <td>
                  {detailData?.shipping_plan_date ? moment(detailData?.shipping_plan_date).format('DD/MM/YYYY') : '-'}
                </td>
              </tr>
              <tr>
                <td>Packing Slip</td>
                <td>{detailData?.code ?? ''}</td>
              </tr>
              {detailData?.activity_type === 'SALES_ORDER' ? (
                <tr>
                  <td>Sales Order</td>
                  <td>{detailData?.detail?.sales_header?.code ?? ''}</td>
                </tr>
              ) : (
                <tr>
                  <td>Source</td>
                  <td>{detailData?.detail?.site?.code ?? ''}</td>
                </tr>
              )}
            </table>
          </Col>
          <Col span={8}>
            <table>
              {detailData?.activity_type === 'SALES_ORDER' && (
                <tr>
                  <td>Sales Taker</td>
                  <td>{detailData?.detail?.sales_header?.salesman?.staff?.name ?? ''}</td>
                </tr>
              )}
              <tr>
                <td>Printed By</td>
                <td>{printContent?.editor_name ?? printContent?.creator_name ?? ''}</td>
              </tr>
              <tr>
                <td>Printed On</td>
                <td>{moment().format('DD/MM/YYYY HH:mm:ss')}</td>
              </tr>
            </table>
          </Col>
        </Row>
        NB:
        <table style={{ width: '100%', marginTop: '5px', lineHeight: '10px' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid black', textAlign: 'left' }}>
              {columns?.map((column) => {
                return (
                  <th style={column?.style} key={column?.key}>
                    {column?.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {products?.map((line, index) => {
              if (line?.shipping_qty > 0 || line?.shipping_unit > 0) {
                return (
                  <tr key={index}>
                    {columns?.map((column) => {
                      return (
                        <td style={column?.style} key={column?.key}>
                          {column?.render(line[column?.key], line)}
                        </td>
                      );
                    })}
                  </tr>
                );
              }
              return <></>;
            })}
            <tr style={{ borderTop: '1px solid black' }}>
              <td colSpan={2} style={{ textAlign: 'right' }}>
                Jumlah Qty
              </td>
              <td style={{ textAlign: 'right' }}>
                {products?.reduce(
                  (partialSum, a) => partialSum + (a?.shipping_qty > 0 ? a?.shipping_qty : a?.shipping_unit),
                  0,
                )}
              </td>
              {detailData?.activity_type === 'SALES_ORDER' && (
                <React.Fragment>
                  <td colSpan={2} style={{ textAlign: 'right' }}>
                    Total
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {RenderCurrency({
                      value: products?.reduce((partialSum, a) => partialSum + a?.price?.subtotal_after_tax, 0),
                    })}
                  </td>
                </React.Fragment>
              )}
            </tr>
          </tbody>
        </table>
        <table style={{ width: '100%', marginTop: '5px' }}>
          <tr>
            <td>Diterima oleh,</td>
            <td style={{ textAlign: 'right' }}>Hormat kami,</td>
          </tr>
        </table>
        <div style={{ marginTop: '80px' }}>
          <QRCode
            size={90}
            value={
              makeModuleBarcode({
                id: detailData?.id,
                module: printContent?.module,
                subModule: printContent?.sub_module,
              }) ?? ''
            }
          />
        </div>
      </div>
    </DetailPrint>
  );
}
