import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCountCurrency, RenderCurrency } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { makeModuleBarcode, PAPER_FORMAT, stringFormatter } from '@base-helpers';
import moment from 'moment';
import { Col, Row, Table } from 'antd';
import QRCode from 'react-qr-code';
import { getProducts } from './helpers';

export function Detail() {
  const { detailData = {}, printContent, type } = usePagePrint();
  const [products, setProducts] = useState([]);
  const defaultSignature: any[] = [
    {
      title: 'Hormat Kami',
      name: '',
      gridColumn: { span: 6 },
    },
  ];
  const columns: any[] = [
    {
      title: 'Item Number',
      key: 'product_variant',
      dataIndex: 'product_variant',
      render: (item, row) => row?.product_variant?.code ?? row?.product_package?.code,
    },
    {
      title: 'Description',
      key: 'product_variant',
      dataIndex: 'product_variant',
      render: (item, row) => row?.product_variant?.name ?? row?.product_package?.name,
      style: { minWidth: '300px' },
    },
    {
      title: 'Ordered',
      key: 'total_qty',
      dataIndex: 'total_qty',
      render: (item, row) => (row?.total_qty > 0 ? row?.total_qty : row?.total_unit),
      style: { textAlign: 'right' },
    },
    {
      title: 'Unit',
      key: 'uom_qty',
      dataIndex: 'uom_qty',
      render: (item, row) => row?.uom_qty?.code ?? row?.uom_unit?.code,
    },
    {
      title: 'Received',
      key: 'received_qty',
      dataIndex: 'received_qty',
      render: (item, row) => row?.received_qty ?? row?.received_unit,
      style: { textAlign: 'right' },
    },
    {
      title: 'Remaining Qty',
      key: 'remaining_qty',
      dataIndex: 'remaining_qty',
      render: (item, row) => row?.total_qty - row?.received_qty ?? row?.total_unit - row?.received_unit,
      style: { textAlign: 'right' },
    },
  ];

  const generateProduct = async (detailId) => {
    const response = await getProducts(detailId);
    setProducts(response);
  };

  useEffect(() => {
    if (detailData?.detail) {
      generateProduct(detailData?.detail?.id);
    }
  }, [detailData?.detail]);

  return (
    <DetailPrint paperFormat={PAPER_FORMAT.A4} orientation="portrait" printHeaderProps={{ showLogo: false }}>
      <div style={{ fontSize: '11px' }}>
        <Row gutter={10} style={{ lineHeight: '11px' }}>
          <Col span={8}>
            <table>
              {detailData?.detail?.purchase_header && (
                <React.Fragment>
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>
                      {detailData?.detail?.purchase_header?.vendor?.name} (
                      {detailData?.detail?.purchase_header?.vendor?.code})
                    </td>
                  </tr>
                  <tr>
                    <td>{detailData?.detail?.purchase_header?.vendor?.address})</td>
                  </tr>
                </React.Fragment>
              )}
              <tr>
                <td colSpan={2} style={{ fontWeight: 'bold' }}>
                  Delivery Address
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  {/* {detailData?.detail?.purchase_header?.deliver_information?.site?.name} -{' '} */}
                  {detailData?.receipt_type === 'WAREHOUSE_TRANSFER'
                    ? detailData?.detail?.site?.address
                    : detailData?.from?.address}
                </td>
              </tr>
            </table>
          </Col>
          <Col span={12}>
            <table>
              <tr>
                <td colSpan={2}>
                  <h2>Product Receipt External {type ? `(${stringFormatter.capitalizeEachWord(type)})` : ''}</h2>
                </td>
              </tr>
              {detailData?.detail?.purchase_header && (
                <tr>
                  <td style={{ paddingRight: '10px' }}>Purchase Order</td>
                  <td>{detailData?.detail?.purchase_header?.code}</td>
                </tr>
              )}
              {detailData?.detail?.sales_header && (
                <tr>
                  <td>Sales Order</td>
                  <td>{detailData?.detail?.sales_header?.code}</td>
                </tr>
              )}
              <tr>
                <td>Receipt Date</td>
                <td>{detailData?.receipt_date ? moment(detailData?.receipt_date).format('DD/MM/YYYY HH:mm') : '-'}</td>
              </tr>
              <tr>
                <td>References</td>
                <td>
                  {detailData?.detail?.references
                    ?.map((item) => {
                      if (item?.sub_module !== 'purchase_order') return item?.transaction_code;
                      return null;
                    })
                    .filter(Boolean)
                    .join(', ')}
                </td>
              </tr>
              <tr>
                <td>Status</td>
                <td>
                  {detailData?.status?.toLowerCase() === 'waiting'
                    ? 'In Process'
                    : stringFormatter.capitalizeEachWord(detailData?.status?.split('_')?.join(' '))}
                </td>
              </tr>
            </table>
          </Col>
          <Col span={4} style={{ textAlign: 'right' }}>
            <div style={{ textAlign: 'center' }}>
              <QRCode
                value={
                  makeModuleBarcode({
                    id: detailData?.id,
                    module: printContent?.module,
                    subModule: printContent?.sub_module,
                  }) ?? ''
                }
                size={80}
              />
              <h4 style={{ fontWeight: 'bold', marginTop: '5px' }}>{detailData?.code ?? ''}</h4>
            </div>
          </Col>
        </Row>
        <table style={{ width: '100%', marginTop: '5px' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid black', textAlign: 'left' }}>
              {columns?.map((column) => {
                return (
                  <th style={column?.style} key={column?.key}>
                    {column?.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {products?.map((line, index) => {
              return (
                <tr key={index}>
                  {columns?.map((column) => {
                    return (
                      <td style={column?.style} key={column?.key}>
                        {column?.render(line[column?.key], line)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr style={{ borderTop: '1px solid black' }}>
              <td colSpan={2}>Total</td>
              <td style={{ textAlign: 'right' }}>
                {products?.reduce((partialSum, a) => partialSum + (a?.total_qty > 0 ? a?.total_qty : a?.total_unit), 0)}
              </td>
              <td></td>
              <td style={{ textAlign: 'right' }}>
                {products?.reduce(
                  (partialSum, a) => partialSum + (a?.received_qty > 0 ? a?.received_qty : a?.received_unit),
                  0,
                )}
              </td>
              <td style={{ textAlign: 'right' }}>
                {products?.reduce(
                  (partialSum, a) => partialSum + (a?.total_qty - a?.received_qty ?? a?.total_unit - a?.received_unit),
                  0,
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ width: '100%', textAlign: 'center' }}>
          <tr>
            <td style={{ height: '60px' }}>Pengirim,</td>
            <td style={{ height: '60px' }}>Gudang,</td>
            <td style={{ height: '60px' }}>Mengetahui,</td>
          </tr>
        </table>
      </div>
    </DetailPrint>
  );
}
