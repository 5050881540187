import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCountCurrency, RenderCurrency } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter } from '@base-helpers';
import moment from 'moment';
import { Col, Row, Table } from 'antd';
import QRCode from 'react-qr-code';
import { columnsMaterial, columnsStep, getProducts } from './helpers';
import { useBarcode } from '@createnextapp/react-barcode';

export function Detail() {
  const { detailData = {}, printContent } = usePagePrint();
  const hasCode = detailData?.code;

  return (
    <DetailPrint
      paperFormat={PAPER_FORMAT.A5}
      orientation="landscape"
      printHeaderProps={{ barcode: detailData?.code, logoType: 'mjs' }}
      showHeader={true}
      printSignatureProps={{
        signatures: [
          {
            title: 'Requestor,',
            name: detailData?.requestor?.name,
            gridColumn: { span: 8 },
          },
          {
            title: 'Mengetahui,',
            name: '_____________',
            gridColumn: { span: 8 },
          },
          {
            title: 'Diterima Oleh,',
            name: '_____________',
            gridColumn: { span: 8 },
          },
        ],
      }}
    >
      <div style={{ fontSize: '11px' }}>
        <DetailBuilder
          columns={[
            {
              fields: [
                {
                  gridColumn: { span: 24 },
                  renderField(form) {
                    return (
                      <PrintTitle
                        title="Production Request"
                        {...(hasCode ? { description: `No. ${detailData?.code}` } : {})}
                      />
                    );
                  },
                },
              ],
            },
          ]}
        />
        <Row style={{ verticalAlign: 'text-top' }}>
          <Col span={4}>
            <table style={{ lineHeight: '12px' }}>
              <tr>
                <td>Status</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.status === 'waiting'
                    ? 'In Process'
                    : stringFormatter.capitalizeEachWord(detailData?.status)}
                </td>
              </tr>
            </table>
          </Col>
          <Col span={12}>
            <table style={{ lineHeight: '12px' }}>
              <tr>
                <td>Customer</td>
                <td style={{ fontWeight: 'bold' }}>{detailData?.order_from?.name ?? '-'}</td>
              </tr>
              <tr>
                <td style={{ paddingRight: '5px' }}>Customer Reference</td>
                <td style={{ fontWeight: 'bold' }}>{detailData?.contact_references?.[0]?.name ?? '-'}</td>
              </tr>
              <tr>
                <td>References</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.production_request_references?.map((item) => item?.transaction_code)?.join(', ')}
                </td>
              </tr>
            </table>
          </Col>
          <Col span={8}>
            <table style={{ lineHeight: '12px' }}>
              <tr>
                <td>Order Date</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.order_date ? moment(detailData?.order_date).format('DD/MM/YYYY HH:mm') : '-'}
                </td>
              </tr>
              <tr>
                <td>Due Date</td>
                <td style={{ fontWeight: 'bold' }}>
                  {detailData?.expected_finish_date
                    ? moment(detailData?.expected_finish_date).format('DD/MM/YYYY HH:mm')
                    : '-'}
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: '5px' }}>Production Order</td>
                <td style={{ fontWeight: 'bold' }}>{detailData?.production_order?.code ?? '-'}</td>
              </tr>
            </table>
          </Col>
        </Row>
        <hr />
        <div style={{ lineHeight: '12px' }}>
          <DetailBuilder
            columns={[
              {
                fields: [
                  {
                    gridColumn: { span: 4 },
                    label: 'Product',
                    name: 'product_master',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return value?.name;
                      },
                    },
                  },
                  ...(detailData?.production_request_attributes?.length > 0
                    ? detailData?.production_request_attributes?.map((attr) => {
                        return {
                          gridColumn: { span: 3 },
                          label: attr?.attribute?.name,
                          name: 'id',
                          fieldType: 'text',
                          renderTextProps: {
                            renderValue: (value) => {
                              return attr?.attribute_value
                                ? `${attr?.attribute_value?.value} ${
                                    attr?.attribute_value?.unit_of_measurement?.code ?? ''
                                  }`
                                : '-';
                            },
                          },
                        };
                      })
                    : []),
                ],
              },
            ]}
          />
          <DetailBuilder
            columns={[
              {
                fields: [
                  {
                    gridColumn: { span: 4 },
                    label: 'Qty Order',
                    name: 'request_qty',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return value ? `${value} ${detailData?.request_qty_unit_of_measurement?.code ?? ''}` : '-';
                      },
                    },
                  },
                  {
                    gridColumn: { span: 4 },
                    label: 'Order Unit',
                    name: 'unit_qty',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return value ? `${value} ${detailData?.unit_qty_unit_of_measurement?.code ?? ''}` : '-';
                      },
                    },
                  },
                  {
                    gridColumn: { span: 16 },
                    label: 'Order Qty/Unit',
                    name: 'qty_unit',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return value ? `${value} ${detailData?.unit_qty_unit_of_measurement?.code ?? ''}` : '-';
                      },
                    },
                  },
                  {
                    gridColumn: { span: 4 },
                    label: 'BoM',
                    name: 'bill_of_materials',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return detailData?.bill_of_materials?.map((item) => item?.name)?.join(', ');
                      },
                    },
                  },
                  {
                    gridColumn: { span: 4 },
                    label: 'Route',
                    name: 'route',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return value?.name;
                      },
                    },
                  },
                  {
                    gridColumn: { span: 4 },
                    label: 'Label Size',
                    name: 'steps',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return detailData?.steps
                          ?.map((item) => item?.label_dimensions)
                          ?.filter(Boolean)
                          ?.join(', ');
                      },
                    },
                  },
                  ...(detailData?.treatment_packing?.product_attributes?.map((item) => {
                    return {
                      gridColumn: { span: 4 },
                      label: item?.attributes?.[0]?.name ?? '',
                      name: 'id',
                      fieldType: 'text',
                      renderTextProps: {
                        renderValue: (value) => {
                          if (item?.attribute_values?.[0]) {
                            return `${item?.attribute_values?.[0]?.item?.[0] ?? ''} ${
                              item?.attribute_values?.[0]?.uom?.code ?? ''
                            }`;
                          }
                          return '-';
                        },
                      },
                    };
                  }) ?? []),
                  // {
                  //   gridColumn: { span: 8 },
                  //   label: 'Treatment Packing',
                  //   name: 'treatment_packing',
                  //   fieldType: 'text',
                  //   renderTextProps: {
                  //     renderValue: (value) => {
                  //       return value?.product_attributes
                  //         ?.map((item) => {
                  //           return `${item?.attributes?.[0]?.name}: ${item?.attribute_values?.[0]?.item?.[0]} ${
                  //             item?.attribute_values?.[0]?.uom?.code ?? ''
                  //           }`;
                  //         })
                  //         .join(', ');
                  //     },
                  //   },
                  // },
                ],
              },
            ]}
          />
        </div>
        <hr />
        <h4 style={{ height: '80px' }}>Catatan:</h4>
        <hr />
      </div>
    </DetailPrint>
  );
}
