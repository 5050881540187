import { APPROVAL_MODULE } from '@base-helpers';
import { IncomingTransfer } from '@pages/inventory/incoming-transfer';
import { OutgoingTransfer } from '@pages/inventory/outgoing-transfer';
import { PackUnpackOrder } from '@pages/inventory/pack-unpack-order';
import { PackingSlip } from '@pages/inventory/packing-slip';
import { PickingList } from '@pages/inventory/picking-list';
import { Receipt } from '@pages/inventory/receipt';
import { ShippingOrder } from '@pages/inventory/shipping-order';
import { StockAdjustment } from '@pages/inventory/stock-adjustment';
import { TransferOrder } from '@pages/inventory/transfer-order';

export const ListInventoryContent = [
  {
    module: APPROVAL_MODULE.module.INVENTORY,
    sub_module: APPROVAL_MODULE.subModule.inventory.PACKING_EXTERNAL,
    content: PackingSlip,
    // showPreviewDetail: false,
    // downloadDetail: false,
  },
  {
    module: APPROVAL_MODULE.module.INVENTORY,
    sub_module: APPROVAL_MODULE.subModule.inventory.PACKING_INTERNAL,
    content: OutgoingTransfer,
  },
  {
    module: APPROVAL_MODULE.module.INVENTORY,
    sub_module: APPROVAL_MODULE.subModule.inventory.PICKING,
    content: PickingList,
  },
  {
    module: APPROVAL_MODULE.module.INVENTORY,
    sub_module: APPROVAL_MODULE.subModule.inventory.RECEIPTS_EXTERNAL,
    content: Receipt,
  },
  {
    module: APPROVAL_MODULE.module.INVENTORY,
    sub_module: APPROVAL_MODULE.subModule.inventory.RECEIPT_INTERNAL,
    content: IncomingTransfer,
  },
  {
    module: APPROVAL_MODULE.module.INVENTORY,
    sub_module: APPROVAL_MODULE.subModule.inventory.SHIPPING_ORDER,
    content: ShippingOrder,
  },
  {
    module: APPROVAL_MODULE.module.INVENTORY,
    sub_module: APPROVAL_MODULE.subModule.inventory.TRANSFER_ORDER,
    content: TransferOrder,
  },
  {
    module: APPROVAL_MODULE.module.INVENTORY,
    sub_module: APPROVAL_MODULE.subModule.inventory.STOCK_ADJUSTMENT,
    content: StockAdjustment,
  },
  {
    module: APPROVAL_MODULE.module.INVENTORY,
    sub_module: APPROVAL_MODULE.subModule.inventory.PACK_UNPACK_ORDER,
    content: PackUnpackOrder,
  },
];
