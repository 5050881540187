import { atom } from 'recoil';

export const listPrintContent = atom({
  key: 'listPrintContent',
  default: [],
});

export const listFilePrint = atom({
  key: 'listFilePrint',
  default: {},
});
