import React from 'react';
import { Layout } from 'antd';
const { Footer } = Layout;
export const htmlFooterContent = `
<div class="ant-layout-footer" style="text-align: center !important; font-size:8px !important; width:100%">PT. Pancamas Pipasakti, Jl. Soekarno Hatta No. 409, Bandung, Jawa Barat - Indonesia, 40235, Ph: 4223355, Email: customerservice@pmps.co.id</div>`;

export function PrintFooter() {
  return (
    <Footer style={{ textAlign: 'center' }}>
      PT. Pancamas Pipasakti, Jl. Soekarno Hatta No. 409, Bandung, Jawa Barat - Indonesia, 40235, Ph: 4223355, Email:
      customerservice@pmps.co.id
    </Footer>
  );
}
