import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCountCurrency, RenderCurrency } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { makeModuleBarcode, PAPER_FORMAT, stringFormatter } from '@base-helpers';
import moment from 'moment';
import { Col, Row, Table } from 'antd';
import QRCode from 'react-qr-code';
import { getProducts, grouping } from './helpers';

export function Detail() {
  const { detailData = {}, printContent, type } = usePagePrint();
  const [groups, setGroups] = useState([]);
  const defaultSignature: any[] = [
    {
      title: 'Hormat Kami',
      name: '',
      gridColumn: { span: 6 },
    },
  ];
  const columns: any[] = [
    {
      title: 'Order Account',
      key: 'code',
      dataIndex: 'code',
      render: (item, row) => detailData?.code,
    },
    {
      title: 'Name',
      key: 'destination_site',
      dataIndex: 'destination_site',
      render: (item, row) => row?.send_to?.name ?? row?.destination_site?.name,
      style: { minWidth: '250px' },
    },
    {
      title: '#',
      key: '#',
      dataIndex: '#',
      render: (item, row) => `1`,
      style: { textAlign: 'right' },
    },
    {
      title: '# of PS',
      key: 'no_of_packing',
      dataIndex: 'no_of_packing',
      render: (item, row) => row?.packing_slips?.length,
      style: { textAlign: 'right' },
    },
    {
      title: '# of Items',
      key: 'no_of_items',
      dataIndex: 'no_of_items',
      render: (item, row) => {
        let no_of_items = 0;
        row?.packing_slips?.forEach((packing) => {
          packing?.detail?.detail_products?.forEach((product) => {
            if (product?.shipping_qty > 0 || product?.shipping_unit > 0) {
              no_of_items += 1;
            }
          });
        });
        return no_of_items;
        // return row?.packing_slips?.reduce((partialSum, a) => partialSum + (a?.detail?.detail_products?.length ?? 0), 0);
      },
      style: { textAlign: 'right' },
    },
    {
      title: 'Total Qty',
      key: 'total_qty',
      dataIndex: 'total_qty',
      render: (item, row) =>
        row?.packing_slips?.reduce(
          (partialSum, a) =>
            partialSum +
            (a?.detail?.detail_products?.reduce(
              (partialSum, a) => partialSum + (a?.shipping_qty > 0 ? a?.shipping_qty : a?.shipping_unit),
              0,
            ) ?? 0),
          0,
        ),
      style: { textAlign: 'right', paddingRight: '10px' },
    },
    {
      title: 'PS #',
      key: 'packing_slips',
      dataIndex: 'packing_slips',
      render: (item, row) => item?.map((item) => item?.code)?.join(', '),
    },
    {
      title: 'Jam Tiba',
      key: 'jam_tiba',
      dataIndex: 'jam_tiba',
      render: (item, row) => (detailData?.arrival_date ? moment(detailData?.arrival_date).format('HH:mm:ss') : ''),
    },
    {
      title: 'Jam Brkt',
      key: 'jam_brkt',
      dataIndex: 'jam_brkt',
      render: (item, row) => (detailData?.departure_date ? moment(detailData?.departure_date).format('HH:mm:ss') : ''),
    },
    {
      title: 'Ket',
      key: 'ket',
      dataIndex: 'ket',
      render: (item, row) => '',
      style: { minWidth: '100px' },
    },
  ];
  const generalInfoColumns = [
    {
      title: `(C) Transportation Journal Summary ${type ? `(${stringFormatter.capitalizeEachWord(type)})` : ''}`,
      colSpan: 2,
    },
    {
      title: 'Journal ID',
      value: detailData?.code,
    },
    {
      title: 'License Plate',
      value: detailData?.external_plat_no ?? detailData?.vehicle?.plat_no,
    },
    {
      title: 'Waktu brkt',
      value: detailData?.departure_date ? moment(detailData?.departure_date).format('DD/MM/YYYY HH:mm:ss') : '-',
    },
    {
      title: 'Waktu tiba',
      value: detailData?.arrival_date ? moment(detailData?.arrival_date).format('DD/MM/YYYY HH:mm:ss') : '-',
    },
    {
      title: 'KM brkt',
      value: detailData?.initial_km,
    },
    {
      title: 'KM kmbl',
      value: detailData?.final_km,
    },
    // {
    //   title: 'Posted',
    //   value: detailData?.status === 'done' ? 'Yes' : 'No',
    // },
    {
      title: 'Description',
      value: detailData?.references?.map((item) => item?.transaction_code)?.join(', '),
    },
  ];

  const generateGroups = (packings) => {
    const result = grouping(packings);
    setGroups(result);
  };

  useEffect(() => {
    if (detailData?.packing_slips?.length > 0) {
      generateGroups(detailData?.packing_slips);
    }
  }, [detailData]);

  return (
    <DetailPrint paperFormat={PAPER_FORMAT.A4} orientation="portrait" printHeaderProps={{ showLogo: false }}>
      <div style={{ fontSize: '11px' }}>
        <table style={{ width: '100%', lineHeight: '10px' }}>
          {generalInfoColumns?.map((row, index) => {
            return (
              <tr key={index} style={{ paddingTop: '5px' }}>
                <td
                  style={{ paddingRight: '20px', width: '100px', borderBottom: '1px solid black' }}
                  colSpan={row?.colSpan}
                >
                  {row?.title}
                </td>
                {!row?.colSpan && (
                  <td colSpan={index > 7 ? 2 : 1} style={{ borderBottom: '1px solid black' }}>
                    {row?.value ?? '-'}
                  </td>
                )}
                {index === 0 && (
                  <td rowSpan={7} style={{ textAlign: 'right', width: '100px', paddingTop: '20px' }}>
                    <QRCode
                      value={
                        makeModuleBarcode({
                          id: detailData?.id,
                          module: printContent?.module,
                          subModule: printContent?.sub_module,
                        }) ?? ''
                      }
                      size={80}
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </table>
        NB:
        <table style={{ width: '100%', marginTop: '5px', borderSpacing: '10px' }}>
          <thead>
            <tr style={{ textAlign: 'left', verticalAlign: 'text-bottom' }}>
              {columns?.map((column) => {
                return (
                  <th
                    style={{
                      borderBottom: '1px solid black',
                      borderRight: '5px solid white',
                      borderRadius: '10px',
                      paddingRight: '5px',
                      fontSize: '10px',
                      lineHeight: '10px',
                      ...column?.style,
                    }}
                    key={column?.key}
                  >
                    {column?.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {groups?.map((line, index) => {
              return (
                <tr key={index}>
                  {columns?.map((column) => {
                    return (
                      <td style={{ ...column?.style }} key={column?.key}>
                        {column?.render(line[column?.key], line)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <table style={{ width: '100%', marginTop: '5px' }}>
          <tr>
            <td>Menyerahkan,</td>
            <td colSpan={3}>Menerima,</td>
          </tr>
          <tr>
            <td style={{ paddingTop: '50px' }}>Ka. Bag. Ekspedisi</td>
            <td style={{ paddingTop: '50px', width: '100px' }}>Driver</td>
            <td style={{ paddingTop: '50px', width: '100px' }}>Co. Driver 1</td>
            <td style={{ paddingTop: '50px' }}>Co. Driver 2</td>
          </tr>
        </table>
        {/* <div style={{ marginTop: '80px' }}>
          <QRCode size={90} value={detailData?.detail?.code ?? ''} />
        </div> */}
      </div>
    </DetailPrint>
  );
}
