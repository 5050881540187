import { RenderText } from '@base-components';
import moment from 'moment';
import { renderCurrency } from '../components';

export const configColumns = [
  {
    title: 'Vendor ID',
    key: 'vendor',
    dataIndex: 'vendor',
    width: 100,
    render: (item: any, row: any) => RenderText({ value: item?.code }),
  },
  {
    title: 'Vendor Name',
    key: 'vendor',
    dataIndex: 'vendor',
    width: 200,
    render: (item: any, row: any) => RenderText({ value: item?.name }),
  },
  {
    title: 'Address',
    key: 'vendor',
    dataIndex: 'vendor',
    render: (item: any, row: any) => RenderText({ value: item?.address }),
  },
];

export const configColumnsPayment = [
  {
    title: 'Payment Type',
    key: 'payment_method',
    dataIndex: 'payment_method',
    render: (item: any, row: any) => RenderText({ value: item }),
  },
  {
    title: 'Bank Reference',
    key: 'source_account',
    dataIndex: 'source_account',
    render: (item: any, row: any) => RenderText({ value: item?.bank_account?.bank?.code ?? '-' }),
  },
  {
    title: 'Source Account',
    key: 'source_account',
    dataIndex: 'source_account',
    render: (item: any, row: any) =>
      RenderText({ value: item?.bank_account?.account_no ?? item?.cash_account?.account_name }),
  },
  {
    title: 'Payment Reference',
    key: 'payment_reference',
    dataIndex: 'payment_reference',
    render: (item: any, row: any) => RenderText({ value: item }),
  },
  {
    title: 'Due Date',
    key: 'payment_date',
    dataIndex: 'payment_date',
    render: (item: any, row: any) => RenderText({ value: item ? moment(item).format('DD/MM/YYYY') : '-' }),
  },
  {
    title: 'Total Payment',
    key: 'total_payment',
    dataIndex: 'total_payment',
    render: (item: any, row: any) => renderCurrency({ value: item ?? 0 }),
  },
  {
    title: 'Rounding',
    key: 'purchase_transactions',
    dataIndex: 'purchase_transactions',
    render: (item: any, row: any) =>
      renderCurrency({ value: item?.reduce((partialSum, a) => partialSum + a?.rounding, 0) }),
  },
  {
    title: 'Total Invoice Amount',
    key: 'total_invoices',
    dataIndex: 'total_invoices',
    render: (item: any, row: any) => renderCurrency({ value: item ?? 0 }),
  },
  {
    title: 'Total Invoice Balance',
    key: 'purchase_transactions',
    dataIndex: 'purchase_transactions',
    render: (item: any, row: any) =>
      renderCurrency({ value: item?.reduce((partialSum, a) => partialSum + a?.invoice_balance, 0) }),
  },
];

export const configColumnsInvoice = [
  {
    title: 'Purchase Invoice Reference',
    key: 'purchase_invoice',
    dataIndex: 'purchase_invoice',
    render: (item: any, row: any) => RenderText({ value: item?.code }),
  },
  {
    title: 'Division',
    key: 'purchase_invoice',
    dataIndex: 'purchase_invoice',
    render: (item: any, row: any) => RenderText({ value: item?.purchase_division?.code }),
  },
  {
    title: 'Description',
    key: 'description',
    dataIndex: 'description',
    render: (item: any, row: any) => RenderText({ value: item }),
  },
  {
    title: 'Rounding',
    key: 'rounding',
    dataIndex: 'rounding',
    render: (item: any, row: any) => renderCurrency({ value: item ? item : 0 }),
  },
  {
    title: 'Invoice Amount',
    key: 'invoice_amount',
    dataIndex: 'invoice_amount',
    render: (item: any, row: any) => renderCurrency({ value: item ?? 0 }),
  },
  {
    title: 'Payment',
    key: 'payment',
    dataIndex: 'payment',
    render: (item: any, row: any) => renderCurrency({ value: item ?? 0 }),
  },
  {
    title: 'Invoice Balance',
    key: 'invoice_balance',
    dataIndex: 'invoice_balance',
    render: (item: any, row: any) => renderCurrency({ value: item }),
  },
];
