import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCountCurrency, RenderCurrency } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter } from '@base-helpers';
import moment from 'moment';
import { Col, Row, Table } from 'antd';
import QRCode from 'react-qr-code';
import { columnsMaterial, columnsStep, getProducts } from './helpers';
import { useBarcode } from '@createnextapp/react-barcode';
import { getDetailStep } from '../production-order/helpers';

export function Detail() {
  const { detailData = {}, printContent, type } = usePagePrint();
  const [products, setProducts] = useState([]);
  const [steps, setSteps] = useState([]);
  const [labels, setLabels] = useState([]);
  const defaultSignature: any[] = [
    {
      title: 'Requestor',
      name: printContent?.editor_name,
      gridColumn: { span: 8 },
    },
    {
      title: 'Approved By',
      name: '____________',
      gridColumn: { span: 8 },
    },
    {
      title: 'Received By',
      name: '____________',
      gridColumn: { span: 8 },
    },
  ];

  const generateProduct = async (detailId) => {
    const response = await getProducts(detailId);
    setProducts(response);
  };

  useEffect(() => {
    if (detailData?.detail) {
      generateProduct(detailData?.detail?.id);
    }
  }, [detailData?.detail]);

  const { inputRef } = useBarcode({
    value: detailData?.code ?? '-',
    options: {
      displayValue: false,
      height: 20,
      width: 1,
      marginTop: -1,
      marginLeft: -1,
      marginBottom: -1,
    },
  });

  async function generateStep(data) {
    let steps = [];
    let labels = [];
    if (data?.of_steps > 0) {
      for (let i = 1; i <= data?.of_steps; i++) {
        const detailStep = await getDetailStep({ id: data?.id, step: i });
        steps = [...steps, detailStep];
        if (detailStep?.label_dimensions) {
          labels = [...labels, detailStep?.label_dimensions];
        }
      }
    }
    setLabels(labels);
    setSteps(steps);
  }

  return (
    <DetailPrint
      paperFormat={PAPER_FORMAT.A5}
      orientation="landscape"
      printHeaderProps={{ showLogo: false }}
      printSignatureProps={{ signatures: defaultSignature }}
      onSuccessLoadData={generateStep}
    >
      <div style={{ fontSize: '11px' }}>
        <Row gutter={10} style={{ lineHeight: '9px' }}>
          <Col span={6}>
            <svg ref={inputRef} />
            <div style={{ lineHeight: '14px' }}>
              <h2>Production Request {type ? `(${stringFormatter.capitalizeEachWord(type)})` : ''}</h2>
              <h3>{detailData?.code ?? ''}</h3>
            </div>
          </Col>
          <Col span={6}>
            <table>
              <tr>
                <td>Prod. Site</td>
                <td>{detailData?.production_site?.code ?? '-'}</td>
              </tr>
              <tr>
                <td style={{ paddingRight: '5px' }}>Activation Date</td>
                <td>{detailData?.order_date ? moment(detailData.order_date).format('DD/MM/YYYY') : '-'}</td>
              </tr>
              <tr>
                <td>End Date/Time</td>
                <td>{detailData?.expected_date ? moment(detailData.expected_date).format('DD/MM/YYYY') : '-'}</td>
              </tr>
            </table>
          </Col>
          <Col span={6}>
            <table>
              <tr>
                <td>Prod. Order</td>
                <td>{detailData?.production_order?.code ?? '-'}</td>
              </tr>
              <tr>
                <td style={{ paddingRight: '5px' }}>Order Date</td>
                <td>{detailData?.planning_date ? moment(detailData.planning_date).format('DD/MM/YYYY') : '-'}</td>
              </tr>
              <tr>
                <td>Due Date</td>
                <td>
                  {detailData?.expected_finish_date
                    ? moment(detailData.expected_finish_date).format('DD/MM/YYYY')
                    : '-'}
                </td>
              </tr>
            </table>
          </Col>
          <Col span={6}>
            <table>
              <tr>
                <td>Customer</td>
                <td>{detailData?.order_from?.name ?? '-'}</td>
              </tr>
              <tr>
                <td style={{ paddingRight: '5px' }}>Contact Reference</td>
                <td>{detailData?.contact_references?.length > 0 ? detailData?.contact_references[0]?.name : '-'}</td>
              </tr>
              <tr>
                <td>References</td>
                <td>{detailData?.references?.map((item) => item?.transaction_code)?.join(', ')}</td>
              </tr>
            </table>
          </Col>
        </Row>
        <hr />
        <DetailBuilder
          columns={[
            {
              fields: [
                {
                  gridColumn: { span: 6 },
                  label: 'Product Order',
                  name: 'product_variant',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return detailData?.product_variant?.name ?? detailData?.product_master?.name;
                    },
                  },
                },
                {
                  gridColumn: { span: 6 },
                  label: 'Attributes',
                  name: 'production_request_attributes',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      if (value?.length > 0) {
                        return value?.map((item) => {
                          return `${item?.attribute?.name}: ${item?.attribute_value?.value} ${
                            item?.attribute_value?.unit_of_measurement
                              ? item?.attribute_value.unit_of_measurement?.code
                              : ''
                          }`;
                        });
                      }
                      return '-';
                    },
                  },
                },
                {
                  gridColumn: { span: 4 },
                  label: 'BoM',
                  name: 'bill_of_materials',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return detailData?.bill_of_materials?.[0]?.name;
                    },
                  },
                },
                {
                  gridColumn: { span: 4 },
                  label: 'Route',
                  name: 'route',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return detailData?.route?.name;
                    },
                  },
                },
                {
                  gridColumn: { span: 4 },
                  label: 'Label Size',
                  name: 'steps',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return labels?.join(', ');
                    },
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Qty Order',
                  name: 'produce_qty_unit_of_measurement',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      if (detailData?.produce_qty > 0) {
                        return `${detailData?.produce_qty ?? ''} ${value?.code ?? ''}`;
                      }
                      return '-';
                    },
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Unit Qty Order',
                  name: 'produce_unit_unit_of_measurement',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      if (detailData?.produce_unit > 0) {
                        return `${detailData?.produce_unit ?? ''} ${value?.code ?? ''}`;
                      }
                      return '-';
                    },
                  },
                },
                {
                  gridColumn: { span: 3 },
                  label: 'Qty/Unit Order',
                  name: 'produce_qty_unit_unit_of_measurement',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      if (detailData?.produce_qty_unit > 0) {
                        return `${detailData?.produce_qty_unit ?? ''} ${value?.code ?? ''}`;
                      }
                      return '-';
                    },
                  },
                },
              ],
            },
          ]}
        />
        <div
          style={{ width: '100%', height: '100px', border: '1px solid black', padding: '10px', marginBottom: '10px' }}
        >
          <span>Notes</span>
        </div>
      </div>
    </DetailPrint>
  );
}
