import { APPROVAL_MODULE } from '@base-helpers';
import { PurchaseOrderMJS } from '@pages/purchases-mjs/purchase-order';
import { PurchaseRequestMJS } from '@pages/purchases-mjs/purchase-request';

export const ListPurchaseContent = [
  {
    module: APPROVAL_MODULE.module.PURCHASE,
    sub_module: APPROVAL_MODULE.subModule.purchase.PURCHASE_ORDER,
    content: PurchaseOrderMJS,
  },
  {
    module: APPROVAL_MODULE.module.PURCHASE,
    sub_module: APPROVAL_MODULE.subModule.purchase.PURCHASE_REQUEST,
    content: PurchaseRequestMJS,
  },
];
