import React from 'react';
import { DetailBuilder, DetailPrint, PrintTitle, RenderCurrency } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { dateFormatter, PAPER_FORMAT, stringFormatter, userPrinter } from '@base-helpers';
import moment from 'moment';
import { Col, Row } from 'antd';

export function Detail() {
  const { detailData = {} } = usePagePrint();
  const purchaseType = detailData?.type;
  const hasCode = detailData?.code;
  const dataSource =
    purchaseType === 'purchase_return' ? detailData?.product_line_returns : detailData?.product_line_orders;
  const user = userPrinter();

  return (
    <DetailPrint
      orientation="portrait"
      printHeaderProps={{ barcode: detailData?.code, logoType: 'mjs' }}
      showHeader={true}
      paperFormat={PAPER_FORMAT.A4}
      printSignatureProps={{
        signatures: [
          {
            gridColumn: { span: 6 },
            title: 'Created By',
            name: detailData?.creator_name ? detailData?.creator_name : '-',
          },
          {
            gridColumn: { span: 6 },
            title: 'Checked By',
            name: 'Yulius Thomas',
          },
          {
            gridColumn: { span: 6 },
            title: 'Approved By',
            name: 'Charles H. Senjaya',
          },
          {
            gridColumn: { span: 6 },
            title: 'Vendor',
            name: detailData?.vendor ? detailData?.vendor?.name : '-',
          },
        ],
      }}
    >
      <DetailBuilder
        columns={[
          {
            fields: [
              {
                gridColumn: { span: 12 },
                renderField(form) {
                  return (
                    <PrintTitle
                      title={purchaseType === 'purchase_return' ? 'Purchase Return' : 'Purchase Order'}
                      {...(hasCode ? { description: `No. ${detailData?.code}` } : {})}
                    />
                  );
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Created Date',
                name: 'created_at',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => {
                    return value ? dateFormatter(value).toLocal('YYYY-MM-DD') : '-';
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Printed Date',
                name: 'id',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: () => moment().format('YYYY-MM-DD'),
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Status',
                name: 'status',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => stringFormatter.capitalizeEachWord(value?.split('_')?.join(' ')),
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Vendor',
                name: 'vendor',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.name;
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Telephone',
                name: 'vendor',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.telephone ?? '-';
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Bank Account',
                name: 'vendor',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.bank_account ?? '-';
                  },
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Purchase Division',
                name: 'purchase_division',
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return value?.name;
                  },
                },
              },
              {
                gridColumn: { span: 8 },
                label: 'Reference',
                name: 'references',
                fieldType: 'text',
                renderTextProps: {
                  renderType: 'multi',
                  renderValue: (value) => {
                    const code = value?.transaction_code ?? value?.code;
                    return code;
                  },
                },
              },
              {
                gridColumn: { span: 20 },
                label: 'Address',
                name: 'vendor',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => value?.address ?? '-',
                },
              },
              {
                gridColumn: { span: 12 },
                label: purchaseType === 'purchase_return' ? 'Pickup Address' : 'Delivery Address',
                name: purchaseType === 'purchase_return' ? 'pickup_information' : 'deliver_information',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => value?.address ?? '-',
                },
              },
              {
                gridColumn: { span: 12 },
                label: 'Invoice Address',
                name: 'invoice_information',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => value?.address ?? '-',
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Location',
                name: purchaseType === 'purchase_return' ? 'pickup_information' : 'deliver_information',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => value?.location?.name ?? '-',
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Expected Date',
                name: purchaseType === 'purchase_return' ? 'pickup_information' : 'deliver_information',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) =>
                    value?.expected_date ? dateFormatter(value?.expected_date).toLocal('YYYY-MM-DD') : '-',
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'PIC',
                name: purchaseType === 'purchase_return' ? 'pickup_information' : 'deliver_information',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => value?.name ?? value?.username ?? '-',
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Location',
                name: 'invoice_information',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => value?.location?.name ?? '-',
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'Terms of Payment',
                name: 'invoice_information',
                fieldType: 'text',
                renderTextProps: {
                  defaultRender: () => '-',
                  renderValue: (value) => value?.top ?? '-',
                },
              },
              {
                gridColumn: { span: 4 },
                label: 'PIC',
                name: 'invoice_information',
                fieldType: 'text',
                renderTextProps: {
                  renderValue: (value) => value?.name ?? value?.username ?? '-',
                },
              },
            ],
          },
        ]}
      />

      <table
        style={{
          position: 'relative',
          width: '100%',
          border: '1px solid black',
          margin: '10px 0',
        }}
      >
        <thead style={{ fontWeight: 'bold', border: '1px solid black' }}>
          <tr>
            <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>No</td>
            <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>Product Variant</td>
            <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>Unit</td>
            <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>UoM</td>
            <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>Qty</td>
            <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>UoM</td>
            <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>Price/Unit</td>
            <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>Discount</td>
            <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>DPP</td>
            <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>PPN</td>
            <td style={{ borderRight: '1px solid black', padding: '5px 7px' }}>Net</td>
          </tr>
        </thead>
        <tbody>
          {dataSource?.map((item, index) => {
            const number = index + 1;
            const subTotal = item?.subtotal;
            const subTotalAfterDisc = item?.subtotal_before_tax ?? 0;
            const discount = subTotal - subTotalAfterDisc;
            return (
              <tr key={index}>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '5px 7px',
                    textAlign: 'center',
                  }}
                >
                  {number}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '5px 7px',
                  }}
                >
                  {item?.product?.name ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '5px 7px',
                  }}
                >
                  {item?.unit ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '5px 7px',
                  }}
                >
                  {item?.uom_unit?.code ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '5px 7px',
                  }}
                >
                  {item?.qty ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '5px 7px',
                  }}
                >
                  {item?.uom_qty?.code ?? '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '5px 7px',
                    textAlign: 'right',
                  }}
                >
                  {item?.price_per_unit ? RenderCurrency({ value: item?.price_per_unit }) : '-'}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '5px 7px',
                    textAlign: 'right',
                  }}
                >
                  {discount ? RenderCurrency({ value: discount }) : '-'}
                </td>

                <td
                  style={{
                    border: '1px solid black',
                    padding: '5px 7px',
                    textAlign: 'right',
                  }}
                >
                  {item?.subtotal_after_tax ? RenderCurrency({ value: item?.subtotal_after_tax }) : '-'}
                </td>

                <td
                  style={{
                    border: '1px solid black',
                    padding: '5px 7px',
                    textAlign: 'right',
                  }}
                >
                  {item?.tax_value_after_tax ? RenderCurrency({ value: item?.tax_value_after_tax }) : '-'}
                </td>

                <td
                  style={{
                    border: '1px solid black',
                    padding: '5px 7px',
                    textAlign: 'right',
                  }}
                >
                  {item?.net_price ? RenderCurrency({ value: item?.net_price }) : '-'}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Row style={{ marginBottom: '20px' }}>
        <Col span={14} style={{ border: '1px solid black', padding: '10px' }}>
          Syarat Kontra Bon/Billing Terms : <br />
          <div>- Melampirkan PO bertanda tangan.</div>
          <div>- Surat Jalan/Deliver Node mencantumkan nomor PO</div>
          <div>- Mencantumkan Nomor PO pada Invoice/Kwintansi</div>
          <div>- Mencantumkan Nomor Rekening di Kwitansi/Invoice</div>
          <div>- Melampirkan Faktur Pajak/Tax Invoice</div>
        </Col>
        <Col span={10} style={{ paddingLeft: '10px' }}>
          <table style={{ position: 'relative', border: '1px solid black', width: '100%' }}>
            <tr>
              <td
                style={{
                  border: '1px solid black',
                  padding: '5px 7px',
                  textAlign: 'left',
                  fontWeight: 'bold',
                }}
              >
                DPP
              </td>
              <td style={{ border: '1px solid black', padding: '5px 7px', textAlign: 'right' }}>
                {detailData?.outcome_total?.sub_total
                  ? RenderCurrency({ value: detailData?.outcome_total?.sub_total })
                  : '-'}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: '1px solid black',
                  padding: '5px 7px',
                  textAlign: 'left',
                  fontWeight: 'bold',
                }}
              >
                Discount
              </td>
              <td style={{ border: '1px solid black', padding: '5px 7px', textAlign: 'right' }}>
                {detailData?.outcome_total?.disc_before_tax
                  ? RenderCurrency({ value: detailData?.outcome_total?.disc_before_tax })
                  : '-'}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: '1px solid black',
                  padding: '5px 7px',
                  textAlign: 'left',
                  fontWeight: 'bold',
                }}
              >
                PPN
              </td>

              <td style={{ border: '1px solid black', padding: '5px 7px', textAlign: 'right' }}>
                {detailData?.outcome_total?.tax ? RenderCurrency({ value: detailData?.outcome_total?.tax }) : '-'}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: '1px solid black',
                  padding: '5px 7px',
                  textAlign: 'left',
                  fontWeight: 'bold',
                }}
              >
                Total
              </td>
              <td style={{ border: '1px solid black', padding: '5px 7px', textAlign: 'right' }}>
                {detailData?.outcome_total?.total ? RenderCurrency({ value: detailData?.outcome_total?.total }) : '-'}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: '1px solid black',
                  padding: '5px 7px',
                  textAlign: 'left',
                  fontWeight: 'bold',
                }}
              >
                Down Payment
              </td>
              <td style={{ border: '1px solid black', padding: '5px 7px', textAlign: 'right' }}>-</td>
            </tr>
          </table>
        </Col>
      </Row>
    </DetailPrint>
  );
}
