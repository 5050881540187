import React, { useEffect, useState } from 'react';
import { DetailBuilder, DetailPrint } from '@base-components';
import { usePagePrint } from '@base-hooks';
import { PAPER_FORMAT, stringFormatter } from '@base-helpers';
import { Col, Row } from 'antd';
import { updateCalendarTransformer } from './helpers';
// import { useBarcode } from '@createnextapp/react-barcode';
import DetailPlanning from './detail-planning/calendar';

export function Detail() {
  const { detailData = {}, printContent, setDetailData, type } = usePagePrint();
  const [defaultDataPlanning, setDefaultDataPlanning] = useState([]);
  const hasCode = detailData?.code;
  const [productGroup, setProductGroup] = useState([]);
  // const { inputRef } = useBarcode({
  //   value: detailData?.code ?? '-',
  //   options: {
  //     displayValue: false,
  //     height: 20,
  //     width: 1,
  //     marginTop: -1,
  //     marginLeft: -1,
  //     marginBottom: -1,
  //   },
  // });

  const generateDataPlanning = async (data) => {
    const dataCalendar = updateCalendarTransformer(data?.shift_planning_shifts);
    setDefaultDataPlanning(dataCalendar);
    setTimeout(() => {
      //console.log('masuk');
    }, 1000);
  };

  return (
    <DetailPrint
      paperFormat={PAPER_FORMAT.A4}
      orientation="portrait"
      onSuccessLoadData={(payload) => generateDataPlanning(payload)}
      printSignatureProps={{
        signatures: [
          {
            title: 'Created By',
            gridColumn: { span: 6 },
            name: detailData?.creator_name ?? '_____________',
          },
          {
            title: 'Printed By',
            gridColumn: { span: 6 },
            name: printContent?.creator_name ?? '_____________',
          },
          {
            title: 'Requested By',
            gridColumn: { span: 6 },
            name: printContent?.creator_name ?? '_____________',
          },
          {
            title: 'Approved By',
            gridColumn: { span: 6 },
            name: '_____________',
          },
        ],
      }}
      // printHeaderProps={{ barcode: detailData?.code, logoType: 'mjs' }}
      // showHeader={true}
    >
      <div style={{ fontSize: '10px' }}>
        {/* <Row gutter={10} style={{ lineHeight: '12px' }}>
          <Col span={6}>
            <svg ref={inputRef} />
            <div style={{ lineHeight: '12px' }}>
              <h2>Shift Planning</h2>
            </div>
          </Col>
        </Row> */}
        <div style={{ lineHeight: '12px' }}>
          <DetailBuilder
            columns={[
              {
                fields: [
                  {
                    gridColumn: { span: 4 },
                    label: 'Site',
                    name: 'site',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return value?.code;
                      },
                    },
                  },
                  {
                    gridColumn: { span: 4 },
                    label: 'Start Date',
                    name: 'start_date',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return value;
                      },
                    },
                  },
                  {
                    gridColumn: { span: 4 },
                    label: 'End Date',
                    name: 'end_date',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return value;
                      },
                    },
                  },
                  {
                    gridColumn: { span: 4 },
                    label: 'Shifts',
                    name: 'shift_planning_shifts',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return detailData?.shift_planning_shifts?.map((item) => item?.shift?.name)?.join(', ');
                      },
                    },
                  },
                  {
                    gridColumn: { span: 4 },
                    label: 'PIC',
                    name: 'pic',
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue: (value) => {
                        return value?.name;
                      },
                    },
                  },
                ],
              },
            ]}
          />
        </div>
        <DetailPlanning show={detailData} defaultData={defaultDataPlanning} />
      </div>
    </DetailPrint>
  );
}
