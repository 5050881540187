import React from 'react';
import { DataTablePrint, RenderText } from '@base-components';

function filterReference(data: any[], isGenerated: boolean): any[] {
  if (!data) return [];
  else if (isGenerated) return data.filter((item) => item.is_generated);
  else if (!isGenerated) return data.filter((item) => !item.is_generated);
  else return data;
}

function customLabelSalesman(value): string {
  if (!value) return '';
  return value?.staff?.name;
}

export function DataTable() {
  return (
    <DataTablePrint
      ignoreDefaultColumn={['name']}
      printTitleProps={{ title: 'Sales Order' }}
      columns={[
        {
          title: 'References',
          key: 'references',
          dataIndex: 'references',
          render: (item) => (
            <RenderText renderType="multi" value={filterReference(item, false)} keyValue="transaction_code" />
          ),
        },
        {
          title: 'Internal References',
          key: 'internal_references',
          dataIndex: 'references',
          render: (item) => (
            <RenderText renderType="multi" value={filterReference(item, true)} keyValue="transaction_code" />
          ),
        },

        {
          title: 'Salesman',
          key: 'salesman',
          dataIndex: 'salesman',
          render: (item) => <RenderText value={item} renderValue={(value) => customLabelSalesman(value)} />,
        },
      ]}
    />
  );
}
